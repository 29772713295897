import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { GetTelecomCategoryList, GetTelecomList } from "Datas/swr";
import { deletePlan, deleteTelecomCategory } from "Datas/api";

import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import CustomPagination from "Settings/pagination";
import { useConfirm } from "Settings/util";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { TopSelectComp } from "../User/User";
const Wrapper = styled.div``;

const TopLabel = styled.div``;
export const ButtonCompoPath = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;
const Top = styled.div`
  border: 1px solid #eee;
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;
const SearchBox = styled.div`
  width: 200px;
  margin-right: 12px;
  margin-top: -12px;
  @media screen and (max-width: 768px) {
    width: 90%;
    margin-right: 0px;
    margin-top: 0px;
  }
`;

const TopButton = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;

    margin-top: 12px;
    width: 100%;
  }
`;
const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const Delete = styled.div`
  display: flex;
  background: #ed6060;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;
const TableContainer = styled(Paper)`

  width: 100%;
  overflow: auto;
`;
const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const HoverTag = styled.td`
  border-bottom: 1.5px solid #eee;
  &:hover {
    opacity: 0.5;
  }
`;

const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;
const useStyles = makeStyles({
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#3c4b64",
      padding: 7,
    },
  },

  tableBody: {
    "& .MuiTableBody-root": {
      padding: 7,
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      padding: 7,
    },
  },
});

const UsageCategory = () => {
  const classes = useStyles();
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState([]);
  const [ListData, setListData] = useState([]);
  const [telecom, setTelecom] = useState("");
  const [teleList, setTeleList] = useState([]);
  //SWR
  const [checked, setChecked] = useState(false);
  const { TelecomListData } = GetTelecomList();
  const { TelecomCategoryData, TelecomCategoryMutate, isLoading } =
    GetTelecomCategoryList(telecom, offset);

  useEffect(() => {
    if (!isLoading) {
      setListData(TelecomCategoryData?.rows);
    }
  }, [TelecomCategoryData]);

  useEffect(() => {
    if (TelecomListData !== undefined) {
      setTelecom(TelecomListData[0].id);
      let newArray = [];
      if (TelecomListData.length !== 0) {
        for (let i = 0; i < TelecomListData.length; i++) {
          newArray.push({
            value: TelecomListData[i].id,
            label: TelecomListData[i].name,
          });
        }
        setTeleList(newArray);
      }
    }
  }, [TelecomListData]);

  const _handleSelect = (id) => {
    let temp = [...ListData];
    ListData?.map((el, idx) => {
      if (el?.id === id) {
        let data = {
          ...el,
          validate: !el?.validate,
        };
        temp[idx] = data;
      }
    });
    setListData(temp);
  };

  useEffect(() => {
    let checkd = ListData?.every((item) => {
      return item.validate;
    });
    setChecked(checkd);
  }, [ListData]);

  const _handleAllSelct = () => {
    //전체샐렉트동기화

    let data = [...ListData];
    let resdata;
    if (checked) {
      resdata = data.map((item) => {
        return { ...item, validate: false };
      });
    } else {
      resdata = data.map((item) => {
        return { ...item, validate: true };
      });
    }

    setListData(resdata);
    setChecked(true);
  };
  const desk = useConfirm(
    "삭제하시겠습니까?",
    async () => {
      for (let index = 0; index < ListData.length; index++) {
        if (ListData[index]?.validate) {
          let body = ListData[index]?.id;
          await deleteTelecomCategory(body);
        }
      }
      await TelecomCategoryMutate();
    },
    () => {
      alert("삭제 를 취소하였습니다.");
    }
  );
  const _handleDelete = async () => {
    await desk();
  };

  return (
    <Fade Button>
      <Wrapper>
        <TopLabel style={{ marginRight: 15 }}>요금제카테고리 관리</TopLabel>
        <Top>
          <SearchBox>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">통신사</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={telecom}
                onChange={(e) => {
                  setTelecom(e.target.value);
                }}
              >
                {teleList?.map((item, idx) => {
                  return <MenuItem value={item?.value}>{item?.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </SearchBox>
          <TopButton>
            <ButtonCompoPath>
              <Link to={`/admin/usageCategory/detail/add/${telecom}`}>
                <Button fullWidth variant="contained" color="success">
                  카테고리 추가
                </Button>
              </Link>
            </ButtonCompoPath>
            <ButtonCompoPath>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={_handleDelete}
              >
                삭제
              </Button>
            </ButtonCompoPath>
          </TopButton>
        </Top>
        <TableContainer>
          <Table style={{ minWidth: 1100 }} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    size="small"
                    color="success"
                    checked={checked}
                    onClick={() => {
                      _handleAllSelct();
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  요금제
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  통신사
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  혜택
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  더보기
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {ListData?.length !== 0 &&
                ListData?.map((el, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell className={classes.tableCell} align="center">
                        <Checkbox
                          color="success"
                          size="small"
                          checked={el?.validate}
                          type="checkbox"
                          onClick={() => _handleSelect(el?.id)}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.title}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.giga}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.option}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <Link
                          to={{
                            pathname: `/admin/usageCategory/detail/${el?.id}/${el?.telecomid}`,
                            state: el,
                          }}
                        >
                          더보기
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          <CustomPagination
            data={TelecomCategoryData}
            setOffset={setOffset}
            page={page}
            setPage={setPage}
          />
        </TableContainer>
      </Wrapper>
    </Fade>
  );
};

export default UsageCategory;
