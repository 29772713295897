import { GetInforMation, GetInformationOrigin, GetOrderInfoData } from "Datas/swr";
import React from "react";
import styled from "styled-components";
import KakaoIcon from "kakaotalk.png";
import CallIconPng from "call.png";
import { Arrow_Up } from "assets";
import { handlerTopMovement } from "Settings/util";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

const breakpoints = [375, 768, 992, 1200, 1425]; // 0 1 번쨰사용
const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

const StartTalk = styled.div`
  position: fixed;
  right: 20px;
  bottom: 85px;
  z-index: 100;
  @media screen and (max-width: 786px) {
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    right: 10px;
    bottom: 75px;
  }
`;

const KakaoTalk = styled.a`
  display: flex;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }

  height: 60px;
  align-items: center;

  border-radius: 30px;
  & > div {
    margin-left: 15px;
  }
  font-size: 20px;
  @media screen and (max-width: 450px) {
    width: 42px;
    height: 42px;
    font-size: 12px;
    box-shadow: none;
  }
`;

const KakaoTalkIcon = styled.div`
  height: 50px;
  width: 50px;
  margin-left:auto;

  @media screen and (max-width: 786px) {
    height: 40px;
    width: 40px;
  }

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
`;

const CallTalk = styled.a`
  display: flex;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  align-items: center;
  margin-top: 15px;
  & > div {
    margin-left: 15px;
  }
  font-size: 20px;
  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 12px;
    box-shadow: none;
  }
`;

const CallIcon = styled.div`
  height: 45px;
  width: 45px;
  margin-left: auto !important;


  @media screen and (max-width: 786px) {
    height: 40px;
    width: 40px;
  }

  & > img {
    width: 100%;
    height: 100%;

  }
`;

const PhoneText = styled.div`
  font-size: 17px;
  font-weight: 500;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

const StartTalkBox = ({ location }) => {
  const { OriginData, OriginMutate } = GetInformationOrigin();

  if (location.pathname.indexOf("admin") !== -1) {
    return <></>;
  } else {
    return (
      <>
        <StartTalk>
          <a
            target={"_blank"}
            href={OriginData !== undefined && OriginData?.kakaoTalk}
          >
            <KakaoTalkIcon>
              <img alt="" src={KakaoIcon} />
            </KakaoTalkIcon>
          </a>
          <CallTalk
            target={"_blank"}
            href={`tel:${OriginData !== undefined && OriginData?.call}`}
          >
            <CallIcon>
              <img alt="" src={CallIconPng} />
            </CallIcon>
          </CallTalk>

          <CallTalk
            onClick={() => {
              handlerTopMovement();
            }}
          >
            <CallIcon>
              <img alt="" src={Arrow_Up} />
            </CallIcon>
          </CallTalk>
        </StartTalk>
      </>
    );
  }
};

export default withRouter(StartTalkBox);
