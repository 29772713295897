import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { withRouter } from "react-router";
import { GetBoardByIds, GetBoardList } from "Datas/swr";

import moment from "moment";
import { Fade } from "react-reveal";

const Wrapper = styled.div``;

const Post = styled.div`
  margin: 0 auto;
  margin-top: 150px;
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
  @media (max-width: 1300px) {
    margin-top: 200px;
  }
  @media (max-width: 900px) {
    margin-bottom: 100px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 170px;
  }
  @media screen and (max-width: 415px) {
    margin: 150px 0 100px;
  }
`;

const PostTop = styled.div`
  align-items: flex-start;
  display: flex;

  @media (max-width: 1300px) {
    margin-top: -40px;
  }
  @media (max-width: 1300px) {
    margin-top: -20px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const PostTopMetadata = styled.div`
  display: flex;
  flex-direction: column;
`;

const PostTopText = styled.div`
  width: 120px;
  min-width: 110px;
  height: 40px;
  background-color: #000;
  font-size: 18px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin-right: 20px;
  border-radius: 4px;
  font-weight: bold;
  @media (max-width: 768px) {
    height: 35px;
    font-size: 15px;
    line-height: 35px;
    width: 110px;
  }
  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
  @media (max-width: 340px) {
    margin-bottom: 10px;
  }
`;

const PostTopTitle = styled.div`
  font-size: 26px;
  line-height: 38px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 23px;
  }
  @media (max-width: 415px) {
    font-size: 19px;
    min-width: 240px;
    line-height: 33px;
  }
`;

const PostTopDate = styled.div`
  font-size: 25px;
  color: #989898;
  margin-left: auto;
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    font-weight: 600;
  }
`;

const PostContentArea = styled.div`
  margin-top: 20px;
  font-size: 18px;
  border: 1px solid #ccc;
  padding: 40px 50px;
  border-radius: 10px;
  min-width: 268px;
  @media (max-width: 580px) {
    padding: 20px 25px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
  }
`;

const PostContent = styled.div`
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 16px;
  }
`;

const AnnouncementPost = ({ match }) => {
 
  const { BoardData, BoardMutate } = GetBoardByIds({ id: match?.params?.id });
  
  //SWR

  return (
    <Fade Button>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Wrapper style={{ maxWidth: 1024, width: "100%" }}>
          <Post>
            <PostTop>
              <PostTopText>공지사항</PostTopText>
              <PostTopMetadata>
                <PostTopTitle>{BoardData?.title}</PostTopTitle>
              </PostTopMetadata>
            </PostTop>
            {/*  <PostTopDate>
            {moment(createdAt).format("YYYY년 MM월 DD일")}
          </PostTopDate> */}
            <PostContentArea>
              <PostContent>
                <div
                  style={{ marginBottom: 25 }}
                  dangerouslySetInnerHTML={{
                    __html: BoardData?.contents,
                  }}
                ></div>
              </PostContent>
            </PostContentArea>
          </Post>
        </Wrapper>
      </div>
    </Fade>
  );
};

export default withRouter(AnnouncementPost);
