import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import {
  GetBannerList,
  GetPointAct,
  GetStoredL,
  GetStoreList,
  GetStoreListTele,
} from "Datas/swr";
import { deleteBanner, deletePointList } from "Datas/api";
import { Fade } from "react-reveal";
import { NavItem, Pagination } from "react-bootstrap";
import CustomPagination from "Settings/pagination";
import { withRouter } from "react-router-dom";

import moment from "moment";
import Banner from "../Banner/Banner";
import { useConfirm } from "Settings/util";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import User, { TopSelectComp } from "../User/User";

const Wrapper = styled.div``;
const Top = styled.div`
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;

const TopLabel = styled.div``;

const TopButton = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const Delete = styled.div`
  display: flex;
  background: #ed6060;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const Imgskeleton = styled.img`
  max-width: 200px;
  height: 100px;
  border: none;
  resize: both;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: contain;
`;

const HoverTag = styled.td`
  border-bottom: 1.5px solid #eee;
  &:hover {
    opacity: 0.5;
  }
`;
const TableContainer = styled(Paper)`
  width: 100%;
  overflow: auto;
`;
export const ButtonCompoPath = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    margin-top: 12px;
    width: 90%;
  }
`;
const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;
const useStyles = makeStyles({
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#3c4b64",
      padding: 7,
    },
  },

  tableBody: {
    "& .MuiTableBody-root": {
      padding: 7,
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      padding: 7,
    },
  },
});

const PointAct = ({ profile }) => {
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState([]);
  const classes = useStyles();
  // check
  const [select, setSelect] = useState([]);
  const [checked, setChecked] = useState(false);

  //SWR
  const { StoreListTeleData, isLoading, StoreListTeleMutate } =
    GetStoreListTele(0);
  const [storeSelect, setStoreSelect] = useState("전체");
  const [opt1select, setOpt1Select] = useState("최신순");
  const [storesSelect, setStoresSelect] = useState("전체");
  const [storeList, setStoreList] = useState([]);
  const { StoredLList, StoredLMutate } = GetStoredL({ storeid: storeSelect });
  const [dlSelect, setDlSelect] = useState("전체");
  const [DLList, setDLList] = useState([]);
  let optList = [
    {
      value: "최신순",
      label: "최신순",
    },
  ];
  const { PointActData, PointActMutate } = GetPointAct(
    storeSelect,
    offset,
    storesSelect,
    opt1select,
    dlSelect
  );
  useEffect(() => {
    let Arrays = [];
    for (let i = 0; i < StoredLList?.length + 1; i++) {
      if (StoredLList?.length === i) {
        Arrays.push({
          value: "전체",
          label: "전체",
        });
      } else {
        Arrays.push({
          value: StoredLList?.[i]?.id,
          label: StoredLList?.[i]?.nickname,
        });
      }
    }
    setDLList(Arrays);
  }, [StoredLList]);
  const storedata1 = [
    { label: "전체", value: "전체" },
    { label: "수수료", value: 1 },
    { label: "포인트", value: 0 },
  ];

  const [BannerData, setBannerData] = useState([]);

  useEffect(() => {
    if (profile) {
      if (profile.gm < 1 || profile.DLMode === true) {
        setStoreSelect(profile?.storeid);
        setDlSelect(profile?.id);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (StoreListTeleData?.rows !== undefined) {
      let Arrays = [];
      if (StoreListTeleData?.rows?.length !== 0) {
        for (let i = 0; i < StoreListTeleData?.rows?.length + 1; i++) {
          if (StoreListTeleData?.rows?.length === i) {
            Arrays.push({
              value: "전체",
              label: "전체",
            });
          } else {
            Arrays.push({
              value: StoreListTeleData?.rows?.[i]?.id,
              label: StoreListTeleData?.rows?.[i]?.name,
            });
          }
        }

        setStoreList(Arrays);
      }
    }
  }, [StoreListTeleData]);

  useEffect(() => {
    if (PointActData !== undefined) {
      let Banners = PointActData?.rows?.map((item, idx) => {
        return { ...item, validate: false };
      });
      setBannerData(Banners);
    }
  }, [PointActData]);

  const _handleSelect = (id) => {
    //셀렉트 메서드를 정렬시켜줌
    let newArray = [...select];
    let temp = [...BannerData];
    temp?.map((el, idx) => {
      if (el?.id === id) {
        let data = {
          ...el,
          validate: !el?.validate,
        };
        temp[idx] = data;

        if (!el?.validate) {
          if (newArray.findIndex((item) => item === el.id) === -1) {
            newArray.push(el.id);
          }
        } else {
          newArray = newArray.filter((item) => item !== el.id);
        }
      }
    });

    setBannerData(temp);
    setSelect(newArray);

    //셀렉트처리
  };

  const _handleAllSelct = () => {
    //전체샐렉트동기화
    let data = [...BannerData];
    let resdata;
    let status;
    if (
      BannerData?.some((item, idx) => {
        return item.validate === false;
      }) === true
        ? false
        : true
    ) {
      status = false;
      resdata = data.map((item) => {
        return { ...item, validate: false };
      });
    } else {
      status = true;
      resdata = data.map((item) => {
        return { ...item, validate: true };
      });
    }

    setBannerData(resdata);
  };

  const deleteItem = useConfirm(
    "삭제하시겠습니까?",
    async () => {
      let set = BannerData.map((item, idx) => {
        if (item.validate) {
          return item.id;
        }
      }).filter((item) => {
        return item !== undefined;
      });

      await deletePointList({ id: set });
      setSelect([]);
      await PointActMutate();
    },
    () => {
      alert("삭제를 취소하였습니다.");
    }
  );

  const _handleDelete = async () => {
    let set = BannerData.map((item, idx) => {
      if (item.validate) {
        return item.id;
      }
    }).filter((item) => {
      return item !== undefined;
    });

    if (set.length === 0) {
      alert("삭제할 상품을 선택해주세요");
      return;
    }
    deleteItem();
  };

  return (
    <Fade Button>
      <Wrapper>
        <TopLabel style={{ marginTop: 12, marginBottom: 12 }}>
          포인트/수수료 이력관리
        </TopLabel>
        <Top>
          <TopButton>
            <TopSelectComp>
              <FormControl fullWidth>
                <InputLabel id="store-label">분류</InputLabel>
                <Select
                  labelId="store-label"
                  id="store-select"
                  value={storeSelect}
                  onChange={(e) => setStoresSelect(e.target.value)}
                >
                  {storedata1?.map((item, idx) => {
                    return (
                      <MenuItem value={item.value}>{item?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TopSelectComp>

            {profile?.gm > 1 && (
              <TopSelectComp>
                <FormControl fullWidth>
                  <InputLabel id="store-label">스토어</InputLabel>
                  <Select
                    labelId="store-label"
                    id="store-select"
                    value={storeSelect}
                    onChange={(e) => setStoreSelect(e.target.value)}
                  >
                    {storeList?.map((item, idx) => {
                      return (
                        <MenuItem value={item.value}>{item?.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </TopSelectComp>
            )}

            <TopSelectComp>
              <FormControl fullWidth>
                <InputLabel id="store-label">분류</InputLabel>
                <Select
                  labelId="store-label"
                  id="store-select"
                  value={opt1select}
                  onChange={(e) => setOpt1Select(e.target.value)}
                >
                  {optList?.map((item, idx) => {
                    return (
                      <MenuItem value={item.value}>{item?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TopSelectComp>
            {profile?.gm > 0 && (
              <TopSelectComp>
                <FormControl fullWidth>
                  <InputLabel id="store-label">딜러</InputLabel>
                  <Select
                    labelId="store-label"
                    id="store-select"
                    value={dlSelect}
                    onChange={(e) => setDlSelect(e.target.value)}
                  >
                    {DLList?.map((item, idx) => {
                      return (
                        <MenuItem value={item.value}>{item?.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </TopSelectComp>
            )}
            {profile.gm > 1 && (
              <ButtonCompoPath>
                <Button
                  fullWidth
                  onClick={_handleDelete}
                  color="error"
                  variant="contained"
                >
                  삭제
                </Button>
              </ButtonCompoPath>
            )}
          </TopButton>
        </Top>

        <TableContainer style={{ marginTop: 24 }}>
          <Table style={{ minWidth: 1100 }} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    size="small"
                    color="success"
                    checked={
                      BannerData?.some((item, idx) => {
                        return item.validate === false;
                      }) === true
                        ? false
                        : true
                    }
                    onClick={() => {
                      _handleAllSelct();
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  분류
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  가맹점명
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  딜러명
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  사용여부
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  포인트/수수료
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  내용
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  일자
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBody}>
              {BannerData?.length !== 0 &&
                BannerData?.map((el, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell className={classes.tableCell} align="center">
                        <Checkbox
                          size="small"
                          color="success"
                          onClick={() => _handleSelect(el?.id)}
                          checked={el?.validate}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.ptype === 0 ? "포인트" : "누적수수료"}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.Store?.name}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.User?.nickname}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.otype === false ? "차감" : "부여"}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {Number(el.point).toLocaleString()}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.memo}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {moment(el?.createdAt).format("YYYY-MM-DD hh:mm")}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomPagination
            data={PointActData}
            setOffset={setOffset}
            page={page}
            setPage={setPage}
          />
        </TableContainer>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(PointAct);
