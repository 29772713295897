import React, { Component, useState } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import logo from "../../logo.png";

import { GetMe, useAuth } from "Datas/storage";
import {
  GetCategoryList,
  GetInforMation,
  GetInformationOrigin,
} from "Datas/swr";
import { ArrowBack } from "@material-ui/icons";
import { BackIco, SideLine_IC } from "assets";

const Wrapper = styled.div`
  height: 97px;
  width: 100%;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 2;
  border-bottom: 1px solid #eee;
  @media screen and (max-width: 768px) {
    height: 80px;
    border: 0;
  }
  @media screen and (max-width: 375px) {
    height: 70px;
  }
`;

const PcFloatting = styled.div`
  display: inline-block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileFloatting = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const Inner = styled.div`
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  align-items: center;
  background-color: #fff;
  padding: 0;
  justify-content: space-between;
  @media(max-width: 450px){
    padding: 10px 10px 5px;
  }
`;

const NAv1_InnerPare = styled.div`
  height: 100%;
  border-bottom: ${(props) => {
    if (props.action) {
      return "2px solid black";
    }
  }};
  padding-right: 16px;
  padding-left: 16px;
  :last-child {
    padding-right: 0;
  }
`;

const NAv1_InnerText = styled.div`
  font-weight: bold;
  font-size: 18px;
  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

const Nav1_inner = styled.div`
  display: flex;
  overflow: auto;
  white-space: nowrap;
  padding-left: 16px;
  height: 100%;
  @media(max-width: 340px) {
    padding-left: 5px;
  }
`;

const Nav1 = styled.div`
  display: none;
  @media screen and (max-width: 1300px) {
    display: block;
    height: 30px;
    margin-top: 5px;
    box-shadow: 0 3px 3px -4px black;
    background-color: #fff;
  }
`;

const Nav = styled.div`
  display: flex;
  height: 40px;
  margin-top: 35px;
  @media screen and (max-width: 1300px) {
    display: none;
  }
  & > div {
    margin: 0 15px;
    font-size: 22px;
  }
`;

const LoginAndSignup = styled.div`
  display: flex;
  @media screen and (max-width: 1300px) {
    display: none;
  }
  & > div {
    margin: 0 5px;
    font-size: 22px;
    cursor: pointer;
  }
`;

const OpenMenu = styled.div`
  width: 30px;
  display: none;
  @media screen and (max-width: 1300px) {
    display: block;
    margin: 30px 20px -10px 0;
  }
  @media(max-width: 450px) {
    display: block;
    margin: -15px 10px -10px 0;
    width: 20px;
  }
  @media(max-width: 360px) {
    display: block;
    margin: -10px 5px 0px 0px;
    width: 20px;
  }
`;

const Logo = styled.img`
  max-width: 278px;
  max-height: 57px;
  @media screen and (max-width: 1300px) {
    max-width: 238px;
    max-height: 43px;
  }
`;

const ExpandedMenu = styled.div`
  position: fixed;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  display: ${(props) => (props.expanded ? "flex" : "none")};
  background: #fff;
  z-index: 3;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  & > div {
    margin: 10px 0;
    font-size: 24px;
    font-weight: 500;
  }
  @media screen and (max-width: 768px) {
    top: 80px;
  }

  @media screen and (max-width: 375px) {
    top: 70px;
  }
`;

const MenuButton = styled.div`
  color: ${(props) => {
    if (props.isOn === true) {
      console.log("트루발생");
      return "orange";
    }
    return "black";
  }};
  :hover {
    opacity: 0.5;
  }
`;
const MainButonsPare = styled.div`
  display: flex;
  margin-bottom: -12px;
  padding-top: 16px;
  z-index: 999;
  margin-right: -16px;
  @media(max-width: 1300px){
    display: none;
  }
`;

const MainButtonsFlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 1380px;
  margin: 0 auto;
  @media(max-width: 1298px) {
    display: none;
  }
`;

const MainButtons = styled.div`
  width: 80px;
  text-align: center;
  font-size: 12px;
  /* color: ${(props) => {
    if (props.loc === false) {
      return "#444";
    } return "#999"
  }}; */
  color: #999;
  cursor: pointer;
  :hover {
    color: #444;
  }
`;

const MainButtonBox_Pare = styled.div`
  display: flex;
`;

export const BackStyles = styled.label`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
  @media(max-width: 768px){
    margin-left: 15px;
  }
  @media(max-width: 450px){
    margin-left: 5px;
  }
`;

const Header = ({ profile, location, onDrawerToggle, history }) => {
  const { CategoryListData, isLoading } = GetCategoryList();
  const { OriginData, OriginDataMutate } = GetInformationOrigin();
  // state
  const [type, setType] = useState(false);
  const typeData = ["one", "two", "three"];
  console.log(OriginData);
  const openMenu = () => {
    setType(!type);
    window.scrollTo(0, 0);
  };

  const _handlerTopScroll = () => {
    window.scrollTo(0, 0);
  };

  if (location.pathname.indexOf("admin") === -1) {
    return (
      <Wrapper>
        <MainButtonsFlexBox>
          <MainButonsPare>
            <Link
              to={`/profile`}
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                _handlerTopScroll();
              }}
            >
              <MainButtons loc={location.pathname.indexOf(`profile`) !== -1}>
                프로필
              </MainButtons>
              <img
                style={{ height: 10, marginTop: -2 }}
                src={SideLine_IC}
              ></img>
            </Link>

            <Link
              to={`/contractlist`}
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                _handlerTopScroll();
              }}
            >
              <MainButtons
                loc={location.pathname.indexOf(`contractlist`) !== -1}
              >
                주문내역
              </MainButtons>
              <img
                style={{ height: 10, marginTop: -2 }}
                src={SideLine_IC}
              ></img>
            </Link>

            {profile?.DLMode === true && (
              <>
                <Link
                  to={`/admin`}
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    _handlerTopScroll();
                  }}
                >
                  <MainButtons>딜러</MainButtons>
                </Link>
              </>
            )}
            {profile?.gm > 0 && (
              <Link
                to={`/admin`}
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  _handlerTopScroll();
                }}
              >
                <MainButtons>관리자</MainButtons>
              </Link>
            )}
          </MainButonsPare>
        </MainButtonsFlexBox>
        <div style={{ backgroundColor: "#fff" }}>
          <Inner>
            <MobileFloatting>
              {location.pathname === "/" ? (
                <Link to="/">
                  <Logo
                    alt=""
                    src={OriginData !== undefined && OriginData.image}
                  />
                </Link>
              ) : (
                // <div
                //   onClick={() => {
                //     history.goBack();
                //   }}
                //   style={{ cursor: "pointer" }}
                // >
                //   <ArrowBack style={{
                //     width: 32, height: 32, marginTop: 5
                //   }}></ArrowBack>
                // </div>
                <BackStyles
                  onClick={() => {
                    history.goBack();
                  }}
                  style={{ marginRight: 10 }}
                >
                  <img src={BackIco} style={{ width: 30, height: 30 }} />
                </BackStyles>
              )}
            </MobileFloatting>
            <PcFloatting>
              <Link to="/">
                <Logo
                  alt=""
                  src={OriginData !== undefined && OriginData.image}
                />
              </Link>
            </PcFloatting>
            {/* 로고 수정하실때 style값 수정 X */}

            <Nav>
              {/* <MenuButton isOn={location.pathname.indexOf("y/one") !== -1}>
              <Link to="/category/one">번호이동</Link>
            </MenuButton>
            <MenuButton isOn={location.pathname.indexOf("y/two") !== -1}>
              <Link to="/category/two">기기변경</Link>
            </MenuButton>
            <MenuButton isOn={location.pathname.indexOf("y/three") !== -1}>
              <Link to="/category/three">사은품</Link>
            </MenuButton> */}
              {CategoryListData?.length !== 0 &&
                CategoryListData?.map((el, idx) => {
                  return (
                    <NAv1_InnerPare
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      action={location.pathname.indexOf(`${el?.name}`) !== -1}
                    >
                      <Link
                        to={`/category/${el?.name}/${el.id}`}
                        onClick={() => {
                          _handlerTopScroll();
                        }}
                      >
                        <NAv1_InnerText>{el?.name}</NAv1_InnerText>
                      </Link>
                    </NAv1_InnerPare>
                  );
                })}


              <NAv1_InnerPare
                style={{ display: "flex", alignItems: "center" }}
                action={location.pathname.indexOf(`/preorder`) !== -1}
              >
                <Link
                  to={`/preorder`}
                  onClick={() => {
                    _handlerTopScroll();
                  }}
                >
                  <NAv1_InnerText>이벤트</NAv1_InnerText>
                </Link>
              </NAv1_InnerPare>
              <NAv1_InnerPare
                style={{ display: "flex", alignItems: "center" }}
                action={location.pathname.indexOf(`/announcement`) !== -1}
              >
                <Link
                  to={`/announcement`}
                  onClick={() => {
                    _handlerTopScroll();
                  }}
                >
                  <NAv1_InnerText>공지사항</NAv1_InnerText>
                </Link>
              </NAv1_InnerPare>

            </Nav>

            <OpenMenu
              onClick={() => {
                onDrawerToggle();
              }}
            >
              <svg aria-hidden="true" viewBox="0 0 448 512">
                <path
                  fill="currentColor"
                  d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"
                ></path>
              </svg>
            </OpenMenu>
          </Inner>
        </div>
        <Nav1>
          <Nav1_inner>
            <NAv1_InnerPare action={location.pathname === "/"}>
              <Link
                to={`/`}
                onClick={() => {
                  _handlerTopScroll();
                }}
              >
                <NAv1_InnerText>홈</NAv1_InnerText>
              </Link>
            </NAv1_InnerPare>
            {CategoryListData?.length !== 0 &&
              CategoryListData?.map((el, idx) => {
                console.log(location.pathname.indexOf(el?.name));
                console.log(location.pathname, el?.name);
                return (
                  <>
                    <NAv1_InnerPare
                      action={location.pathname.indexOf(`${el?.name}`) !== -1}
                    >
                      <Link
                        to={`/category/${el?.name}/${el.id}`}
                        onClick={() => {
                          _handlerTopScroll();
                        }}
                      >
                        <NAv1_InnerText>{el?.name}</NAv1_InnerText>
                      </Link>
                    </NAv1_InnerPare>
                  </>
                );
              })}
          </Nav1_inner>
        </Nav1>
        {/*   <ExpandedMenu expanded={type}>

          <MenuButton isOn={location.pathname.indexOf("announcement") !== -1}>
            <Link onClick={openMenu} to="/announcement">
              공지사항
            </Link>
          </MenuButton>
          <MenuButton isOn={location.pathname.indexOf("preorder") !== -1}>
            <Link onClick={openMenu} to="/preorder">
              이벤트
            </Link>
          </MenuButton>

          <MenuButton isOn={location.pathname.indexOf("profile") !== -1}>
            <Link onClick={openMenu} to="/profile">
              프로필
            </Link>
          </MenuButton>
          <MenuButton isOn={location.pathname.indexOf("contractlist") !== -1}>
            <Link onClick={openMenu} to="/contractlist">
              주문 내역
            </Link>
          </MenuButton>
          {profile?.gm > 0 && (
            <MenuButton isOn={location.pathname.indexOf("admin") !== -1}>
              <Link onClick={openMenu} to="/admin">
                관리자
              </Link>
            </MenuButton>
          )}
        </ExpandedMenu> */}
      </Wrapper>
    );
  } else {
    return "";
  }
};

export default withRouter(Header);
