import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory, withRouter } from "react-router-dom";
import { HomeIcon, OutIcon } from "assets";
import Logo from "../../logo.png";

import { useAuth } from "Datas/storage";
import { GetInforMation } from "Datas/swr";

const Wrapper = styled.div``;

const Top = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  z-index: 2;
  background: #fff;
  border: 1px solid #eee;
`;

const Side = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  background: #4b52bc;
  width: 200px;
  height: 873px;
  overflow-y: scroll;
  top: 56px;
  padding-top: 0px;
  & > span {
    display: flex;
    cursor: pointer;
    :hover {
      color: black;
      background-color: #fff;
    }
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    color: #fff;
    font-weight: 500;
  }
`;

const Main = styled.div`
  margin: 56px 0 0 200px;
`;

const MenuItems = styled.span`
  display: flex;
  cursor: pointer;
  :hover {
    color: black;
    background-color: #fff;
  }
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  color: #fff;
  font-size:12px;
  font-weight: 500;
`;

const MainLogo = styled.div`
  width: 175px;
  height: 30px;
  background-image: ${(props) => {
    if (props.src) {
      return `url(${props.src})`;
    }
  }};
  background-size: contain;
  background-repeat: no-repeat;
`;

const Icons = styled.img`
  width: 30px;
  height: 30px;
  resize: both;
  &:hover {
    opacity: 0.5;
  }
`;

const Template = ({ location, profile }) => {
  const { InfoData, InfoDataMutate } = GetInforMation();
  console.log(InfoData);
  const history = useHistory();
  const { setLogout } = useAuth();

  console.log(profile);
  // states

  const [selected, setSelected] = useState();

  useEffect(() => {
    if (selected !== history.location.pathname.slice(7)) {
      setSelected(history.location.pathname.slice(7));
    }
  }, [history.location.pathname]);

  const _handleStyles = (value) => {
    if (selected === value) {
      return { backgroundColor: "#fff", color: "black" };
    }
  };

  if (location.pathname.indexOf("admin/") !== -1) {
    return (
      <Wrapper style={{ width: "12vw" }}>
        <Top
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 20px",
          }}
        >
          <Link to="/admin/user">
            <MainLogo src={InfoData !== undefined && InfoData?.image} />
          </Link>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <p style={{ marginRight: 12 }}>이름:{profile?.name} 점주님</p>
              <p style={{ marginRight: 12, fontWeight: "bold" }}>
                누적수수료: {Number(profile?.salea).toLocaleString()} 원
              </p>
              <p style={{ marginRight: 12, fontWeight: "bold" }}>
                가맹포인트: {Number(profile?.point).toLocaleString()} 원
              </p>
            </div>
            <a href="/">
              <Icons src={HomeIcon} style={{ marginRight: 15 }} />
            </a>
          </div>
        </Top>
        <Side>
          {profile?.gm > 0 && (
            <Link to="/admin/mainsetting">
              <MenuItems style={_handleStyles("mainsetting")}>
                홈페이지 관리
              </MenuItems>
            </Link>
          )}
          {profile?.gm > 1 ? (
            <Link to="/admin/user">
              <MenuItems style={_handleStyles("user")}>회원관리</MenuItems>
            </Link>
          ) : (
            <Link to="/admin/user">
              <MenuItems style={_handleStyles("user")}>가맹회원관리</MenuItems>
            </Link>
          )}
          <Link to="/admin/pointact">
            <MenuItems style={_handleStyles("pointact")}>
              포인트/누적수수료 관리
            </MenuItems>
          </Link>

          <Link to="/admin/product">
            <MenuItems style={_handleStyles("product")}>상품관리</MenuItems>
          </Link>

          <Link to="/admin/banner">
            <MenuItems style={_handleStyles("banner")}>배너관리</MenuItems>
          </Link>

          <Link to="/admin/contract">
            <MenuItems style={_handleStyles("contract")}>개통관리</MenuItems>
          </Link>
          {profile.gm > 0 && (
            <>
              {profile?.gm < 3 && (
                <>
                  {profile.gm === 2 && (
                    <Link to="/admin/store">
                      <MenuItems style={_handleStyles("store")}>
                        가맹점 관리
                      </MenuItems>
                    </Link>
                  )}
                  <Link to="/admin/card">
                    <MenuItems style={_handleStyles("card")}>
                      제휴카드 관리
                    </MenuItems>
                  </Link>
                  <Link to="/admin/usage">
                    <MenuItems style={_handleStyles("usage")}>
                      요금제 관리
                    </MenuItems>
                  </Link>
                  <Link to="/admin/qa">
                    <MenuItems style={_handleStyles("qa")}>QA 관리</MenuItems>
                  </Link>
                  <Link to="/admin/usageCategory">
                    <MenuItems style={_handleStyles("usageCategory")}>
                      요금제 카테고리 관리
                    </MenuItems>
                  </Link>
                  <Link to="/admin/category">
                    <MenuItems style={_handleStyles("category")}>
                      카테고리 관리
                    </MenuItems>
                  </Link>
                  <Link
                    to="/admin/promotion"
                    onClick={() => setSelected("promotion")}
                  >
                    <MenuItems style={_handleStyles("promotion")}>
                      프로모션 관리
                    </MenuItems>
                  </Link>
                  <Link to="/admin/post" onClick={() => setSelected("post")}>
                    <MenuItems style={_handleStyles("post")}>
                      공지사항 관리
                    </MenuItems>
                  </Link>
                  <Link
                    to="/admin/pushnoti"
                    onClick={() => setSelected("pushnotiCustom")}
                  >
                    <MenuItems style={_handleStyles("pushnotiCustom")}>
                      푸쉬 관리
                    </MenuItems>
                  </Link>

                  <Link to="/admin/TeleAc" onClick={() => setSelected("terms")}>
                    <MenuItems style={_handleStyles("TeleAc")}>
                      통신사 관리
                    </MenuItems>
                  </Link>

                  <Link
                    to="/admin/postrec"
                    onClick={() => setSelected("postrec")}
                  >
                    <MenuItems style={_handleStyles("postrec")}>
                      이벤트 관리
                    </MenuItems>
                  </Link>
                  <Link to="/admin/terms" onClick={() => setSelected("terms")}>
                    <MenuItems style={_handleStyles("terms")}>
                      이용약관 관리
                    </MenuItems>
                  </Link>
                </>
              )}
            </>
          )}
        </Side>
        <Main></Main>
      </Wrapper>
    );
  } else {
    return "";
  }
};

export default withRouter(Template);
