import { atom } from "recoil";

const StorePageState = atom({
  key: "StorePageState",
  default: 0,
});
const CurrentPageState = atom({
  key: "CurrentPageState",
  default: 1,
});

const CateGoryState = atom({
  key: "CateGoryState",
  default: "전체",
});
const OptionCategory = atom({
  key: "OptionCategory",
  default: 0,
});
const LoginStore = atom({
  key: "LoginStore",
  default: false,
});
export {
  StorePageState,
  CateGoryState,
  OptionCategory,
  LoginStore,
  CurrentPageState,
};
