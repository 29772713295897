import React, { useEffect, useState } from "react";
import { sendPushNoti } from "../../../Datas/api";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import CustomPagination from "Settings/pagination";
import { GetPushLogs, GetStoreListTele } from "../../../Datas/swr";
import { deletePushLogs } from "../../../Datas/api";
import moment from "moment";
import { useConfirm } from "../../../Settings/util";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";

const Wrapper = styled.div``;

const TopLabel = styled.div``;
export const TopSelectComp = styled.div`
  margin-top: -12px;
  width: ${(props) => {
    if (props.invite) {
      return "10%";
    }
    if (props.inviteR) {
      return "50%";
    }
    return "180px";
  }};
  margin-left: ${(props) => {
    if (props.inviteR) {
      return "34px";
    }
    if (props.invite) {
      return "12px";
    }
  }};
  margin-right: 12px;
  @media screen and (max-width: 768px) {
    margin-right: 0px;
    width: 100%;
    margin-top: 0px;
  }
`;

const Top = styled.div`
  border: 1px solid #eee;
  display: flex;
  margin-top: 12px;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;
export const ButtonCompoPath = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;
const TableContainer = styled(Paper)`
  width: 100%;
  overflow: auto;
`;
const useStyles = makeStyles({
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#3c4b64",
      padding: 7,
    },
  },

  tableBody: {
    "& .MuiTableBody-root": {
      padding: 7,
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      padding: 7,
    },
  },
});

const TopButton = styled.div`
  display: flex;
  width: 140px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;

    margin-top: 12px;
    width: 100%;
  }
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const Delete = styled.div`
  display: flex;
  background: #ed6060;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;

const Pushnoti = ({ profile }) => {
  // States
  const classes = useStyles();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [checked, setChecked] = useState(false);
  const [pushData, setPushData] = useState([]);
  const [storeSelect, setStoreSelect] = useState("전체");

  const [storeList, setStoreList] = useState([]);
  const { StoreListTeleData, StoreListTeleMutate } = GetStoreListTele(0);
  // SWR
  const { PushLogsData, PushLogsMutate, isLoading } = GetPushLogs(
    offset,
    limit,
    storeSelect
  );
  useEffect(() => {
    if (StoreListTeleData?.rows !== undefined) {
      let Arrays = [];
      if (StoreListTeleData?.rows?.length !== 0) {
        for (let i = 0; i < StoreListTeleData?.rows?.length + 1; i++) {
          if (StoreListTeleData?.rows?.length === i) {
            Arrays.push({
              value: "전체",
              label: "전체",
            });
          } else {
            Arrays.push({
              value: StoreListTeleData?.rows?.[i]?.id,
              label: StoreListTeleData?.rows?.[i]?.name,
            });
          }
        }
        console.log(Arrays);
        setStoreList(Arrays);
      }
    }
  }, [StoreListTeleData]);
  useEffect(() => {
    let datas = PushLogsData?.rows?.map((item, idx) => {
      return { ...item, validate: false };
    });
    setPushData(datas);
  }, [PushLogsData]);

  useEffect(() => {
    if (pushData?.length !== 0) {
      let checkd = pushData?.every((item) => {
        return item.validate;
      });
      setChecked(checkd);
    } else {
      setChecked(false);
    }
  }, [pushData]);

  // Delete Push Logs
  const desk = useConfirm(
    "삭제하시겠습니까?",
    async () => {
      for (let index = 0; index < pushData.length; index++) {
        if (pushData[index]?.validate) {
          let id = pushData[index]?.id;
          await deletePushLogs({ id: id });
        }
      }
    },
    () => {
      alert("삭제 를 취소하였습니다.");
    }
  );

  const _handleDelete = () => {
    desk();
  };

  const _handleSelect = (id) => {
    let temp = [...pushData];
    pushData?.map((el, idx) => {
      if (el?.id === id) {
        let data = {
          ...el,
          validate: !el?.validate,
        };
        temp[idx] = data;
      }
    });
    setPushData(temp);
  };

  const _handleAllSelct = () => {
    let data = [...pushData];
    let resdata;
    if (checked) {
      resdata = data.map((item) => {
        return { ...item, validate: false };
      });
    } else {
      resdata = data.map((item) => {
        return { ...item, validate: true };
      });
    }

    setPushData(resdata);
    setChecked(true);
  };

  return (
    <Fade Button>
      <Wrapper>
        <TopLabel>푸쉬 로그</TopLabel>
        <Top>
          {profile?.gm >= 2 && (
            <TopSelectComp>
              <FormControl fullWidth>
                <InputLabel id="store-label">가맹점</InputLabel>
                <Select
                  labelId="store-label"
                  id="store-select"
                  value={storeSelect}
                  onChange={(e) => setStoreSelect(e.target.value)}
                >
                  {storeList?.map((item, idx) => {
                    return (
                      <MenuItem value={item.value}>{item?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TopSelectComp>
          )}
          <TopButton>
            <ButtonCompoPath>
              <Button
                fullWidth
                variant="contained"
                color="success"
                onClick={_handleDelete}
              >
                삭제
              </Button>
            </ButtonCompoPath>
          </TopButton>
        </Top>
        <TableContainer></TableContainer>
        <TableContainer style={{ marginTop: 24 }}>
          <Table style={{ minWidth: 1100 }} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    size="small"
                    color="success"
                    checked={checked}
                    onClick={() => {
                      _handleAllSelct();
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  제목
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  내용
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  발송날짜
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  수신인
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {pushData?.length !== 0 &&
                pushData?.map((el, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell className={classes.tableCell} align="center">
                        <Checkbox
                          color="success"
                          size="small"
                          onClick={() => _handleSelect(el?.id)}
                          checked={el?.validate}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.title}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.message}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {moment(el?.createdAt).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.resultName}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <CustomPagination
          data={BoardListData}
          setOffset={setOffset}
          page={page}
          setPage={setPage}
        /> */}
      </Wrapper>
    </Fade>
  );
};

export default Pushnoti;
