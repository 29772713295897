import {
  BrowserRouter as Router,
  redirect,
  Route,
  Switch,
} from "react-router-dom";
import Login from "Components/Admin/Login.js";
import Register from "Components/Admin/Register.js";

import Product from "Components/Admin/Product/Product.js";
import ProductDetail from "Components/Admin/Product/Detail.js";

import User from "Components/Admin/User/User.js";
import UserDetail from "Components/Admin/User/Detail.js";

import Store from "Components/Admin/Store/Store.js";
import Storedetail from "Components/Admin/Store/Detail.js";

import Pushnoti from "Components/Admin/Pushnoti/Pushnoti.js";

import Discount from "Components/Admin/Discount/Discount.js";
import TermsDetail from "Components/Terms/detail";
import Card from "Components/Admin/Card/Card.js";
import CardDetail from "Components/Admin/Card/Detail.js";

import Usage from "Components/Admin/Usage/Usage.js";
import UsageDetail from "Components/Admin/Usage/Detail.js";

import Category from "Components/Admin/Cateogry/Category.js";
import CategoryDetail from "Components/Admin/Cateogry/Detail.js";

import Banner from "Components/Admin/Banner/Banner.js";
import BannerDetail from "Components/Admin/Banner/Detail.js";

import Contract from "Components/Admin/Contract/Contract.js";
import ContractDetail from "Components/Admin/Contract/Detail.js";

import Postrec from "Components/Postrec";
import PostrecDetail from "Components/Postrec/detail";
import Promotion from "Components/Admin/Promotion/Promotion.js";
import PromotionDetail from "Components/Admin/Promotion/Detail.js";
import UsageCategory from "./UsageCategory";
import UsageCategoryDetail from "./UsageCategory/Detail";
import Post from "Components/Admin/Post/Post";
import PostDetail from "Components/Admin/Post/detail";
import AuthRoute from "Settings/authRouter";
import Template from "./Template";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Terms from "Components/Terms";
import MainSetting from "./MainSetting/MainSetting";
import PointAct from "./PointAct/PointAct";
import PushDetail from "../Admin/Pushnoti/detail";
import Qa from "./Qa";
import Detail from "./Qa/Detail";
import AsideNav from "./Aside";
import Header from "./Header";
import TeleAc from "./TeleAc";
import BackNavi from "./BackNavi";
import { GetStoreInfo } from "Datas/swr";
import GroupUsage from "./GroupUsage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  content: {
    flexGrow: 1,
    padding: "12px 16px 0px 260px",
  },
  contentM: {
    flexGrow: 1,
    padding: 24,
  },
  toolbar: theme.mixins.toolbar,
}));

const AdminLayer = ({
  profile,
  onDrawerToggle,
  mobileOpen,
  handleDrawerToggleFasle,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  console.log(profile, "프로필");
  const classes = useStyles();
  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />

        {profile?.gm > 0 ? (
          <>
            <Header onDrawerToggle={onDrawerToggle} />
            <AsideNav
              handleDrawerToggleFasle={handleDrawerToggleFasle}
              profile={profile}
              mobileOpen={mobileOpen}
              onDrawerToggle={onDrawerToggle}
            />
          </>
        ) : (
          profile?.DLMode && (
            <>
              <Header onDrawerToggle={onDrawerToggle} />
              <AsideNav
                handleDrawerToggleFasle={handleDrawerToggleFasle}
                profile={profile}
                mobileOpen={mobileOpen}
                onDrawerToggle={onDrawerToggle}
              />
            </>
          )
        )}

        <main className={isMobile ? classes.contentM : classes.content}>
          <div className={classes.toolbar} />
          <BackNavi></BackNavi>
          <Switch>
            <Route path="/admin/login" component={Login} /> {/* 로그인 */}
            <Route path="/admin/register" component={Register} />{" "}
            {/* 회원가입 */}
            <Route
              exact
              path="/admin"
              render={() => {
                if (profile?.gm >= 1 || profile?.DLMode) {
                  return <redirect to="/admin/user" />;
                } else {
                  return <Login />;
                }
              }}
            />
            {/* 기본 어드민 로그인 라우팅 */}
            <Route exact path="/admin/product" component={Product} />
            <Route
              exact
              path="/admin/product/detail"
              component={ProductDetail}
            />
            <Route
              exact
              path="/admin/user"
              render={() => {
                if (profile?.gm >= 1 || profile?.DLMode) {
                  return <User profile={profile} />;
                } else {
                  return <Login />;
                }
              }}
            />
            <Route exact path="/admin/user/detail" component={UserDetail} />
            <Route
              exact
              path="/admin/store"
              render={() =>
                profile?.gm >= 2 ? (
                  <Store profile={profile} />
                ) : (
                  <redirect to="/admin/user" />
                )
              }
            />
            <Route
              exact
              path="/admin/groupusage"
              render={() =>
                profile?.gm >= 1 ? (
                  <GroupUsage profile={profile} />
                ) : (
                  <redirect to="/admin/user" />
                )
              }
            />
            <Route
              exact
              path="/admin/store/detail/:id"
              component={Storedetail}
            />
            <Route
              exact
              render={() => {
                if (profile?.gm >= 1 || profile?.DLMode) {
                  return <Pushnoti profile={profile} />;
                } else {
                  return <Login />;
                }
              }}
              path="/admin/pushnoti"
            />
            <Route
              exact
              path="/admin/pushnoti/detail"
              authenticated={profile}
              render={() => <PushDetail profile={profile}></PushDetail>}
            />
            <Route
              exact
              path="/admin/discount"
              render={() => <Discount profile={profile} />}
            />
            <Route
              exact
              path="/admin/mainsetting"
              render={() => <MainSetting profile={profile} />}
            />
            <Route
              exact
              path="/admin/TeleAc"
              render={() => <TeleAc profile={profile} />}
            />
            <Route exact path="/admin/category" component={Category} />
            <Route
              exact
              path="/admin/category/detail/:id"
              component={CategoryDetail}
            />
            <Route exact path="/admin/terms" component={Terms} />
            <Route
              exact
              path="/admin/terms/detail/:id"
              component={TermsDetail}
            />
            <Route
              exact
              path="/admin/contract"
              render={() => {
                if (profile.gm >= 1 || profile.DLMode) {
                  return <Contract profile={profile} />;
                } else {
                  <redirect to="/admin/" />;
                }
              }}
            />
            <Route
              exact
              path="/admin/contract/detail/:id"
              render={() => {
                if (profile.gm >= 1 || profile.DLMode) {
                  return <ContractDetail profile={profile} />;
                } else {
                  <redirect to="/admin/" />;
                }
              }}
            />
            <Route exact path="/admin/usage" component={Usage} />
            <Route
              exact
              path="/admin/usageCategory"
              component={UsageCategory}
            />
            <Route
              exact
              path="/admin/usage/detail/:id/:telecom"
              component={UsageDetail}
            />
            <Route exact path="/admin/qa/detail/:id/:type" component={Detail} />
            <Route
              exact
              path="/admin/pointact"
              render={() => {
                if (profile.gm >= 1 || profile.DLMode) {
                  return <PointAct profile={profile} />;
                } else {
                  <redirect to="/admin/" />;
                }
              }}
            />
            <Route
              exact
              path="/admin/usageCategory/detail/:id/:telecom"
              component={UsageCategoryDetail}
            />
            <AuthRoute
              exact
              path="/admin/banner"
              authenticated={profile}
              render={(props) => <Banner {...props} />}
            />
            <Route
              exact
              path="/admin/banner/detail/:id"
              component={BannerDetail}
            />
            <Route exact path="/admin/promotion" component={Promotion} />
            <Route
              exact
              path="/admin/promotion/detail/:id"
              component={PromotionDetail}
            />
            <Route
              exact
              path="/admin/card"
              render={() => <Card profile={profile} />}
            />
            <Route
              exact
              path="/admin/qa"
              render={() => <Qa profile={profile} />}
            />
            <Route
              exact
              path="/admin/card/detail/:id/:telecomname"
              component={CardDetail}
            />
            <Route exact path="/admin/post" component={Post} />
            <Route exact path="/admin/post/detail/:id" component={PostDetail} />
            <Route exact path="/admin/postrec" component={Postrec} />
            <Route
              exact
              path="/admin/postrec/detail/:id"
              component={PostrecDetail}
            />
          </Switch>
        </main>
      </div>
    </Router>
  );
};

export default AdminLayer;
