import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { GetCardList, GetTelecomList } from "Datas/swr";
import { Link } from "react-router-dom";
import { deleteCard } from "Datas/api";
import { Fade } from "react-reveal";
import CustomPagination from "Settings/pagination";
import { useConfirm } from "Settings/util";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";

const Wrapper = styled.div``;

const TopLabel = styled.div``;
export const ButtonCompoPath = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;
const Top = styled.div`
  border: 1px solid #eee;
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;
const TableContainer = styled(Paper)`

  width: 100%;
  overflow: auto;
`;
const TopButton = styled.div`
  display: flex;
  width: 160px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;

    margin-top: 12px;
    width: 100%;
  }
`;
const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const Delete = styled.div`
  display: flex;
  background: #ed6060;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;
const SearchBox = styled.div`
  width: 200px;
  margin-right: 12px;
  margin-top: -12px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
    margin-top: 0px;
  }
`;

const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const Imgskeleton = styled.img`
  max-width: 200px;
  height: 100px;
  border: none;
  resize: both;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: contain;
`;

const SelectBox = styled.select`
  margin-left: 15px;
  width: 100px;
  height: 40px;
  padding-left: 10px;
  border: none;
  background-color: #f0f0f0;
  border-radius: 7px;
  font-size: 16px;
`;

const HoverTag = styled.td`
  border-bottom: 1.5px solid #eee;
  &:hover {
    opacity: 0.5;
  }
`;
const useStyles = makeStyles({
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#3c4b64",
      padding: 7,
    },
  },

  tableBody: {
    "& .MuiTableBody-root": {
      padding: 7,
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      padding: 7,
    },
  },
});
const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;

const Card = ({ profile }) => {
  const classes = useStyles();
  const [cardData, setCardData] = useState([]);
  const [telecom, setTelecom] = useState("SKT");
  const [teleList, setTeleList] = useState([]);
  const [page, setPage] = useState([]);
  const [offset, setOffset] = useState(0);
  const [checked, setChecked] = useState(false);
  //SWR
  const { TelecomListData } = GetTelecomList();
  const { CardListData, CardListMutate, isLoading } = GetCardList(
    telecom,
    offset
  );

  useEffect(() => {
    if (!isLoading) {
      let Cards = CardListData?.rows?.map((item, idx) => {
        return { ...item, validate: false };
      });
      setCardData(Cards);
    }
  }, [CardListData]);

  const _handleAllSelct = () => {
    //전체샐렉트동기화

    let data = [...cardData];
    let resdata;
    if (
      cardData?.some((item, idx) => {
        return item.validate === false;
      }) === true
        ? false
        : true
    ) {
      resdata = data.map((item) => {
        return { ...item, validate: false };
      });
    } else {
      resdata = data.map((item) => {
        return { ...item, validate: true };
      });
    }

    setCardData(resdata);
  };

  useEffect(() => {
    if (TelecomListData !== undefined) {
      let newArray = [];

      if (TelecomListData.length !== 0) {
        for (let i = 0; i < TelecomListData.length; i++) {
          newArray.push({
            value: TelecomListData[i].id,
            label: TelecomListData[i].name,
          });
        }
        setTeleList(newArray);
      }
    }
  }, [TelecomListData]);

  const _handleSelect = (id) => {
    let temp = [...cardData];
    cardData?.map((el, idx) => {
      if (el?.id === id) {
        let data = {
          ...el,
          validate: !el?.validate,
        };
        temp[idx] = data;
        console.log("data");
        console.log(data);
      }
    });
    setCardData(temp);
  };

  const desk = useConfirm(
    "삭제하시겠습니까?",
    async () => {
      for (let index = 0; index < cardData.length; index++) {
        if (cardData[index]?.validate) {
          let body = {
            id: cardData[index]?.id,
          };
          await deleteCard(body);
        }
      }
    },
    () => {
      alert("삭제 를 취소하였습니다.");
    }
  );

  const _handleDelete = async () => {
    await desk();
  };

  return (
    <Fade Button>
      <Wrapper>
        <TopLabel style={{ marginRight: 15 }}>제휴카드</TopLabel>
        <Top>
          <SearchBox>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">통신사</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={telecom}
                onChange={(e) => {
                  setTelecom(e.target.value);
                }}
              >
                {teleList?.map((item, idx) => {
                  return <MenuItem value={item?.label}>{item?.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </SearchBox>
          <TopButton>
            <ButtonCompoPath>
              <Link to={`/admin/card/detail/add/new`}>
                <Button fullWidth variant="contained" color="success">
                  카드 추가
                </Button>
              </Link>
            </ButtonCompoPath>
            <ButtonCompoPath>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={_handleDelete}
              >
                삭제
              </Button>
            </ButtonCompoPath>
          </TopButton>
        </Top>

        <TableContainer>
          <Table style={{ minWidth: 1100 }} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    size="small"
                    color="success"
                    checked={
                      cardData?.some((item, idx) => {
                        return item.validate === false;
                      }) === true
                        ? false
                        : true
                    }
                    onClick={() => {
                      _handleAllSelct();
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  카드 이미지
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  카드 명
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  할인 (전월 실적 / 월별 할인금)
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  더보기
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {cardData?.length !== 0 &&
                cardData?.map((el, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell className={classes.tableCell} align="center">
                        <Checkbox
                          size="small"
                          color="success"
                          onClick={() => _handleSelect(el?.id)}
                          checked={el.validate}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <Imgskeleton src={el?.images[0]} />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.name}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.price?.map((item, idx) => {
                          console.log(item);
                          return (
                            <span>
                              {item?.last
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                              /
                              {item?.discount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          );
                        })}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <Link
                          to={{
                            pathname: `/admin/card/detail/${el?.id}/${el?.telecomname}`,
                            state: el,
                          }}
                        >
                          더보기
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomPagination
            data={CardListData}
            setOffset={setOffset}
            page={page}
            setPage={setPage}
          />
        </TableContainer>
      </Wrapper>
    </Fade>
  );
};

export default Card;
