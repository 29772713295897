import useSWR from "swr";

export const GetCardMain = ({ siteName }) => {
    const { data, error, mutate } = useSWR(() => `user/getCardMain/${siteName}`);
  
    return {
      CardMainData: data?.result,
      CardMainMutate: mutate,
      CardMainError: !data && !mutate,
      isLoading: !data && !error,
    };
  };
  export const GetReqTelList = ({ offset, limit, pageid }) => {
    const { data, error, mutate } = useSWR(
      () => `user/getReqTelList/${offset}/${limit}/${pageid}`
    );
  
    return {
      ReqTelListData: data?.result,
      ReqTelListMutate: mutate,
      ReqTelListError: !data && !mutate,
      isLoading: !data && !error,
    };
  };