import { GetInforMation, GetInforMationStore, GetStoredL, GetStoreListTele } from "Datas/swr";
import React, { useState } from "react";
import { useEffect } from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import { sendPushNoti, updateInfo, uploadImage } from "../../../Datas/api";
import { MenuItem, Select, TextField } from "@material-ui/core";
import { Select as SelCompo } from 'antd';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Button from "@mui/material/Button";
import { TopSelectComp } from "../User/User";
const Wrapper = styled.div`
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const TopLabel = styled.div``;
const Top = styled.div`
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputTextArea = styled.textarea`
  height: 293px;
  padding: 16px;
  width: 100%;
  border-radius: 7px;
  background-color: #f4f4f4;
  border: none;
`;

const TextFieldtext = styled.textarea`
  height: 345px;
  border: none;
  width: 100%;
  background: #f4f4f4;
  border-radius: 7px;
  margin-bottom: 110px;
`;

const Content = styled.div`
  margin: 15px 0 0 20px;
  max-width: 690px;
  @media screen and (max-width: 768px) {
    margin: 0px;
  }
`;
const NameCardAdd = styled.label`
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  font-size: 17px;
  background: #6091ed;
  border-radius: 30px;
  padding: 9px 15px;
  color: #fff;
  margin-left: auto;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;
const Images = styled.div`
  width: 175px;
  height: 30px;
  background-image: ${(props) => {
    if (props.src) {
      return `url(${props.src})`;
    }
  }};
  background-size: contain;
  background-repeat: no-repeat;
`;

const MainSetting = ({ profile }) => {
  const [datas, setDatas] = useState({
    id: "",
    kakaoTalk: "",
    call: "",
    pagename: "",
    image: [],
    ceo: "",
    ceoName: "",
    ceoCode: "",
    TeleCode: "",
    zip: "",
    mainCall: "",
    email: "",
    live: "",
    salesPartner: "",
    salePrice: 0.059,
    bmanName: "",
    DcTrans: false,
    gmbase: false,

    optlink:
      "https://www.ictmarket.or.kr:8443/precon/pop_CertIcon.do?PRECON_REQ_ID=PRE0000123159", //사전승낙서 주손
    optimage: "",
    security: true,
    acceptOption: true,
    직장인: [],
    법인: [],
    소상공인: [],

  });
  const [image, setImage] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);
  const { StoreListTeleData, StoreListTeleError, StoreListTeleMutate } = GetStoreListTele(0)
  const { StoredLList, StoredLMutate } = GetStoredL({ storeid: "전체" });
  const [DlList, setDlList] = useState([]);
  const [storeList, setStoreList] = useState([]);


  const { InfoStoredata, InfoStoredataMutate, isLoading } =
    GetInforMationStore();
  //불러오기로직실행



  useEffect(() => {
    if (!isLoading) {
      setDatas(InfoStoredata[0]);
      setImgBase64(InfoStoredata[0].image);


    }
  }, [InfoStoredata]);

  const handleChange1 = (event, newAlignment) => {
    let letr = newAlignment === null ? false : newAlignment;

    setDatas({ ...datas, gmbase: letr });
  };

  useEffect(() => {
    if (StoredLList !== undefined) {
      let Arrays = [];

      for (let i = 0; i < StoredLList?.length + 1; i++) {
        if (StoredLList?.length === i) {
          Arrays.push({
            value: "인증방식",
            label: "인증방식",
          });
        } else {
          Arrays.push({
            value: StoredLList?.[i]?.code,
            label: StoredLList?.[i]?.code,
          });
        }
      }

      setDlList(Arrays);
    }
  }, [StoredLList]);
  const handleChange2 = (event, newAlignment) => {
    let letr = newAlignment === null ? false : newAlignment;

    setDatas({ ...datas, security: letr });
  };
  const handleChange3 = (event, newAlignment) => {
    let letr = newAlignment === null ? false : newAlignment;

    setDatas({ ...datas, acceptOption: letr });
  };



  const _handleUpdate = async () => {
    if (
      datas.kakaoTalk === "" ||
      datas.call === "" ||
      datas.pagename === "" ||
      datas.id === "" ||
      datas?.ceo === "" ||
      datas?.ceoName === "" ||
      datas?.ceoCode === "" ||
      datas?.TeleCode === "" ||
      datas?.zip === "" ||
      datas?.mainCall === "" ||
      datas?.email === "" ||
      datas?.live === "" ||
      datas?.salesPartner === "" ||
      datas?.salePrice === "" ||
      datas?.bmanName === ""
    ) {
      alert("모든정보를 입력해주세요");
      return;
    }
    if (image.length > 0) {
      console.log("이미지잇음");
      const ids = await Promise.all(
        image.map((uri) => {
          if (!!uri.id) return uri.id;
          let formData = new FormData();
          formData.append("files", uri);
          return uploadImage(formData);
        })
      );
      datas.image = ids;
    }
    console.log(datas);
    let res = await updateInfo(
      {
        call: datas.call,
        kakaoTalk: datas.kakaoTalk,
        pagename: datas.pagename,
        image: datas.image,
        ceo: datas?.ceo,
        ceoName: datas?.ceoName,
        ceoCode: datas?.ceoCode,
        TeleCode: datas?.TeleCode,
        zip: datas?.zip,
        mainCall: datas?.mainCall,
        email: datas?.email,
        live: datas?.live,
        salesPartner: datas?.salesPartner,
        salePrice: datas?.salePrice,
        gmbase: datas?.gmbase,

        bmanName: datas?.bmanName,
        DcTrans: datas?.DcTrans,
        optlink: datas?.optlink,
        optimage: datas?.optimage,
        security: datas?.security,
        acceptOption: datas?.acceptOption,
        직장인: datas?.직장인,
        법인: datas?.법인,
        소상공인: datas?.소상공인,
      },

      datas.id
    );
    if (res === undefined) {
      alert("잘못된 데이터입니다다");
      return;
    } else {
      alert("수정완료");
      await InfoStoredataMutate();
    }
    //업데이트로직실행
    //조건부검증
  };

  const handleImage = (e) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64([base64.toString()]);
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImage([e.target.files[0]]); //경로탈착
    }
  };

  return (
    <Fade Button>
      <Wrapper>
        <Top>
          <TopLabel>
            사이트 관리{" "}
            <span style={{ color: "gray", fontSize: 12, fontWeight: "bold" }}>
              {InfoStoredata?.name} 가맹점
            </span>
          </TopLabel>
          <TopButton>
            <Button
              fullWidth
              variant="contained"
              color="success"
              onClick={() => _handleUpdate()}
            >
              저장
            </Button>
          </TopButton>
        </Top>
        <Content>
          {/*    <InputLabel style={{ display: "flex", alignItems: "center" }}>
            DC 숨김기능
            <p style={{ fontSize: 12, color: "gray", marginLeft: 6 }}>
              (ON: DC 출력 OFF:DC 0원)
            </p>
          </InputLabel>
          <ToggleButtonGroup
            color="primary"
            value={datas.DcTrans}
            exclusive
            onChange={handleChange1}
          >
            <ToggleButton value={true}>DC ON</ToggleButton>
            <ToggleButton value={false}>DC OFF</ToggleButton>
          </ToggleButtonGroup> */}
          {profile.gm > 0 && (
            <>
              <InputLabel style={{ display: "flex", alignItems: "center" }}>
                회원 승인 기능
                <p style={{ fontSize: 12, color: "gray", marginLeft: 6 }}>
                  (회원승인 ON OFF)
                </p>
              </InputLabel>
              <ToggleButtonGroup
                color="primary"
                value={datas.acceptOption}
                exclusive
                onChange={handleChange3}
              >
                <ToggleButton value={true}>회원 승인 ON</ToggleButton>
                <ToggleButton value={false}>회원 승인 OFF</ToggleButton>
              </ToggleButtonGroup>
              <InputLabel style={{ display: "flex", alignItems: "center" }}>
                인증코드 해제기능
                <p style={{ fontSize: 12, color: "gray", marginLeft: 6 }}>
                  (로그인시 인증코드 설정)
                </p>
              </InputLabel>
              <ToggleButtonGroup
                color="primary"
                value={datas.security}
                exclusive
                onChange={handleChange2}
              >
                <ToggleButton value={true}>인증코드 ON</ToggleButton>
                <ToggleButton value={false}>인증코드 OFF</ToggleButton>
              </ToggleButtonGroup>
            </>
          )}

          {profile?.gm === 2 && (
            <>
              <InputLabel style={{ display: "flex", alignItems: "center" }}>
                직장인 가입딜러목록
              </InputLabel>

              <SelCompo mode="multiple"
                value={datas?.직장인}
                placeholder="직장인 가입 딜러목록을 선택해주세요"
                onChange={(e) => {
                  setDatas({ ...datas, 직장인: e })
                }}
                style={{
                  width: '100%',
                }}
                options={DlList} />

              {/*   <Select
                labelId="store-label"
                id="store-select"
                value={codeSelect}
                onChange={(e) => setCodeSelect(e.target.value)}
              >
                {DlList?.map((item, idx) => {
                  return (
                    <MenuItem value={item.value}>{item?.label}</MenuItem>
                  );
                })}
              </Select> */}
            </>
          )}

          {profile?.gm === 2 && (
            <>
              <InputLabel style={{ display: "flex", alignItems: "center" }}>
                법인 가입딜러목록
              </InputLabel>
              <SelCompo mode="multiple"
                value={datas?.법인}
                placeholder="법인 가입 딜러목록을 선택해주세요"
                onChange={(e) => {
                  setDatas({ ...datas, 법인: e })
                }}
                style={{
                  width: '100%',
                }}
                options={DlList} />
            </>
          )}

          {profile?.gm === 2 && (
            <>
              <InputLabel style={{ display: "flex", alignItems: "center" }}>
                소상공인 가입딜러목록
              </InputLabel>
              <SelCompo mode="multiple"
                value={datas?.소상공인}
                placeholder="소상공인 가입 딜러목록을 선택해주세요"
                onChange={(e) => {
                  setDatas({ ...datas, 소상공인: e })
                }}
                style={{
                  width: '100%',
                }}
                options={DlList} />
            </>
          )}



          {profile?.gm === 1 && (
            <>
              <InputLabel style={{ display: "flex", alignItems: "center" }}>
                하단 사업장 정보 연동 기능
                <p style={{ fontSize: 12, color: "gray", marginLeft: 6 }}>
                  (ON: 사업자 기본정보 출력 OFF:사업자 기본정보 해제)
                </p>
              </InputLabel>
              <ToggleButtonGroup
                color="primary"
                value={datas.gmbase}
                exclusive
                onChange={handleChange1}
              >
                <ToggleButton value={true}> ON</ToggleButton>
                <ToggleButton value={false}> OFF</ToggleButton>
              </ToggleButtonGroup>
            </>
          )}
          <InputLabel>업체정보</InputLabel>
          <TextField
            fullWidth
            value={datas?.bmanName}
            onChange={(e) => setDatas({ ...datas, bmanName: e.target.value })}
            style={{
              marginTop: 20,
            }}
            id="outlined-basic"
            label="사업자 명"
            variant="outlined"
          />

          <TextField
            fullWidth
            value={datas?.zip}
            onChange={(e) => setDatas({ ...datas, zip: e.target.value })}
            style={{
              marginTop: 20,
            }}
            placeholder="주소를 입력해주세요"
            id="outlined-basic"
            label="사업자 주소"
            variant="outlined"
          />

          <TextField
            fullWidth
            value={datas?.pagename}
            onChange={(e) => setDatas({ ...datas, pagename: e.target.value })}
            style={{
              marginTop: 20,
            }}
            placeholder="페이지 명을 입력해주세요"
            id="outlined-basic"
            label="사이트 명"
            variant="outlined"
          />

          <TextField
            value={datas?.ceoName}
            fullWidth
            onChange={(e) =>
              setDatas({
                ...datas,
                ceoName: e.target.value,
                ceo: e.target.value,
              })
            }
            style={{
              marginTop: 20,
            }}
            placeholder="대포자명을 입력해주세요"
            id="outlined-basic"
            label="대표자 명"
            variant="outlined"
          />

          <TextField
            fullWidth
            value={datas?.ceoCode}
            onChange={(e) => setDatas({ ...datas, ceoCode: e.target.value })}
            style={{
              marginTop: 20,
            }}
            placeholder="사업자 번호를 입력해주세요"
            id="outlined-basic"
            label="사업자 번호"
            variant="outlined"
          />
          {/* <InputLabel>통신판매 번호</InputLabel> */}
          <TextField
            fullWidth
            value={datas?.TeleCode}
            onChange={(e) => setDatas({ ...datas, TeleCode: e.target.value })}
            style={{
              marginTop: 12,
            }}
            placeholder="통신판매 번호를 입력해주세요"
            id="outlined-basic"
            label="통신판매 번호"
            variant="outlined"
          />

          <TextField
            fullWidth
            value={datas?.mainCall}
            onChange={(e) => setDatas({ ...datas, mainCall: e.target.value })}
            style={{
              marginTop: 20,
            }}
            placeholder="대표번호를 입력해주세요"
            id="outlined-basic"
            label="대표 번호"
            variant="outlined"
          />

          <TextField
            fullWidth
            value={datas?.email}
            onChange={(e) => setDatas({ ...datas, email: e.target.value })}
            style={{
              marginTop: 20,
            }}
            placeholder="이메일을 입력해주세요"
            id="outlined-basic"
            label="이메일"
            variant="outlined"
          />

          <TextField
            fullWidth
            value={datas?.live}
            onChange={(e) => setDatas({ ...datas, live: e.target.value })}
            style={{
              marginTop: 20,
            }}
            placeholder="운영시간을 입력해주세요"
            id="outlined-basic"
            label="운영시간"
            variant="outlined"
          />

          <TextField
            fullWidth
            value={datas?.salesPartner}
            onChange={(e) =>
              setDatas({ ...datas, salesPartner: e.target.value })
            }
            style={{
              marginTop: 20,
            }}
            placeholder="개인정보관리책임자를 입력해주세요"
            id="outlined-basic"
            label="개인정보관리책임자"
            variant="outlined"
          />

          <TextField
            fullWidth
            value={datas?.kakaoTalk}
            onChange={(e) => setDatas({ ...datas, kakaoTalk: e.target.value })}
            style={{
              marginTop: 20,
            }}
            placeholder="카카오톡 링크를 입력해주세요"
            id="outlined-basic"
            label="카카오톡 연결링크"
            variant="outlined"
          />

          <TextField
            fullWidth
            value={datas?.call}
            onChange={(e) => setDatas({ ...datas, call: e.target.value })}
            style={{
              marginTop: 20,
            }}
            placeholder="010-0000-0000"
            id="outlined-basic"
            label="연결 전화번호"
            variant="outlined"
          />
          <InputLabel>상점 정보</InputLabel>
          <InputLabel>사이트 로고</InputLabel>
          {imgBase64?.length !== 0 ? (
            imgBase64?.map((item, idx) => {
              return (
                <label for="upload-file">
                  <Images src={item} />
                </label>
              );
            })
          ) : (
            <div style={{ padding: 8 }}>
              <NameCardAdd for="upload-file">이미지 추가</NameCardAdd>
            </div>
          )}
          <input
            type="file"
            name="upload-file"
            style={{ display: "none" }}
            onChange={handleImage}
            id="upload-file"
          ></input>
          <p
            style={{
              color: "gray",
              fontWeight: "bold",
              fontSize: 12,
              marginTop: 12,
            }}
          >
            799 × 138 사이즈의 이미지를 첨부해주세요
          </p>

          <TextField
            fullWidth
            value={datas?.salePrice}
            onChange={(e) => setDatas({ ...datas, salePrice: e.target.value })}
            style={{
              marginTop: 20,
            }}
            placeholder="5.9"
            id="outlined-basic"
            label="할부금 설정"
            variant="outlined"
          />

          <TextField
            fullWidth
            value={datas?.optlink}
            onChange={(e) => setDatas({ ...datas, optlink: e.target.value })}
            style={{
              marginTop: 20,
            }}
            placeholder="사전 승낙서 주소를 입력해주세요"
            id="outlined-basic"
            label="사전 승낙서 링크"
            variant="outlined"
          />
          <p
            style={{
              color: "gray",
              fontWeight: "bold",
              fontSize: 12,
              marginTop: 12,
            }}
          >
            아이콘 링크를 입력해주세요 (src="" ""속안의 내용입력)
          </p>
          <TextField
            fullWidth
            value={datas?.optimage}
            onChange={(e) => setDatas({ ...datas, optimage: e.target.value })}
            style={{
              marginTop: 20,
            }}
            placeholder="사전 아이콘 링크를 입력해주세요"
            id="outlined-basic"
            label="사전 아이콘 링크"
            variant="outlined"
          />
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default MainSetting;
