import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { withRouter } from "react-router";
import { createPromotion, updatePromotion } from "Datas/api";
import { GetPromotionList } from "Datas/swr";
import { Fade } from "react-reveal";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;
const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-left: 30px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
`;

const Content = styled.div``;

const Button = styled.div`
  width: 47.5%;
  height: 45px;
  background-color: #a7a7a7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  :hover {
    opacity: 0.8;
  }
  cursor: pointer;
`;

const CategoryDetail = ({ match, history, location }) => {
  //SWR
  const { PromotionListData, PromotionListMutate, isLoading } =
    GetPromotionList(10);

  const [datas, setDatas] = useState({
    title: "",
    type: false,
    order: 0,
  });

  useEffect(() => {
    if (match?.params?.id !== "add") {
      /* let res = PromotionListData?.filter(
        (el, idx) => Number(el?.id) === Number(match?.params?.id)
      ); */
      setDatas({
        title: location?.state?.title,
        order: location?.state?.order,
        type: location?.state?.type,
      });
    }
  }, [match?.params?.id]);

  const _handleCreate = async () => {
    datas.order = Number(datas.order);
    await createPromotion(datas);
    history.goBack();
  };

  const _handleUpdate = async () => {
    let body = {
      id: Number(match?.params?.id),
      ...datas,
    };
    await updatePromotion(body);
    history.goBack();
  };
  return (
    <Fade Button>
      <Wrapper style={{ width: "100%" }}>
        <Top>
          <TopLabel>프로모션 정보</TopLabel>
          <TopButton>
            <Add
              onClick={() =>
                match?.params?.id === "add" ? _handleCreate() : _handleUpdate()
              }
            >
              완료
            </Add>
          </TopButton>
        </Top>
        <Content style={{ width: "100%" }}>
          <MainContainer>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>프로모션명</td>
                <td>
                  <InputMain
                    placeholder="프로모션 메뉴명을 입력해주세요"
                    value={datas.title}
                    onChange={(e) =>
                      setDatas({ ...datas, title: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>프로모션 순서</td>
                <td>
                  <InputMain
                    type="number"
                    placeholder="프로모션 순서를 입력해주세요"
                    value={datas.order}
                    onChange={(e) =>
                      setDatas({ ...datas, order: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
            </table>
          </MainContainer>

          <MainContainer>
            <InputLabel>카테고리 노출 여부</InputLabel>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => setDatas({ ...datas, type: true })}
                style={{
                  backgroundColor: datas.type ? "#535353" : "#a7a7a7",
                }}
              >
                노출
              </Button>
              <Button
                onClick={() => setDatas({ ...datas, type: false })}
                style={{
                  backgroundColor: !datas.type ? "#535353" : "#a7a7a7",
                }}
              >
                숨김
              </Button>
            </div>
          </MainContainer>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(CategoryDetail);
