import React, { Component } from "react";
import styled from "styled-components";

import PhoneImgPng from "phone.png";
import { Link, withRouter } from "react-router-dom";
import { Fade } from "react-reveal";
import { GetUserOrderList } from "Datas/swr";
import { DeleteContract } from "Datas/api";
import AlertModal from "Modal";
import { useState } from "react";

const Wrapper = styled.div`
  width: 1370px;
  margin: 0 auto;
  min-height: 73vh;
  @media (max-width: 1440px) {
    width: 80%;
  }
  @media (max-width: 870px) {
    width: 90%;
  }
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const Inner = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: 150px;
`;

export const OrdercheckInner = styled.div`
  /* min-width: 768px; */
  width: 100%;
  margin: 0 auto;
  margin-top: 115px;
  @media (max-width: 870px) {
    margin-top: 90px;
    /* min-width: 450px; */
  }
`;

const ContractText = styled.div`
  font-size: 20px;
  font-weight: 700;
  padding-top: 50px;
  @media screen and (max-width: 550px) {
    font-size: 28px;
    text-align: center;
    padding-top: 0px;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto -15px;
    z-index: 100;
  }
  @media screen and (max-width: 360px) {
    font-size: 22px;
  }
`;

const Row = styled.div`
  margin: 5px 0 30px 0;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 0px 10%;
  justify-content: center;
  align-items: center;
  min-height: 218px;
  @media (max-width: 1280px) {
    position: relative;
    padding-bottom: 120px;
    min-height: 418px;
  }
  @media (max-width: 768px) {
    position: relative;
    padding-bottom: 120px;
    padding: 30px 55px 100px;
  }
  @media (max-width: 550px) {
    display: block;
    padding: 0px 22px 200px;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ddd;
    min-height: 650px;
  }
`;

const PhoneImg = styled.div`
  & > img {
    width: 100%;
  }
  max-width: 250px;
  min-width: 150px;
  width: 250px;
  margin-right: 50px;
  @media screen and (max-width: 1280px) {
    margin-right: 50px;
    width: 300px;
  }
  @media screen and (max-width: 550px) {
    width: 230px;
    min-height: 350px;
    margin: -10px auto -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      margin-top: -21px;
      z-index: -1;
      overflow: hidden;
    }
  }
  /* @media screen and (max-width: 360px) {
    width: 230px;
    margin-left: 0;
  } */
`;

const OnlyDataRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  /* margin: 10px 20px 0; */
  @media (max-width: 1280px) {
    flex-wrap: wrap;
    min-width: 250px;
  }
  @media (max-width: 550px) {
    width: 100%;
    margin-bottom: -20px;
  }
`;

const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 6 - 16px - 0.01px);
  margin-left: calc(16px / 5);
  margin-right: calc(16px / 5);
  margin-bottom: 20px;
  @media screen and (max-width: 1280px) {
    width: calc(100% / 2 - 16px - 0.01px);
  }

  @media screen and (max-width: 550px) {
    width: calc(100% / 2 - 32px - 0.01px);
    margin-left: calc(16px / 2);
    /* margin-right: calc(16px / 2); */
    margin-bottom: 10px;
  }
`;

const RowItemTitle = styled.div`
  min-width: 80px;
  width: 80%;
  height: 35px;
  line-height: 35px;
  color: #989898;
  font-size: 13px;
  margin-bottom: 12px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  @media screen and (max-width: 550px) {
    width: 100%;
    margin-bottom: 5px;
  }
`;
export const SortationLine = styled.div`
  width: 1px;
  height: 55px;
  background-color: #ccc;
  margin: -20px 20px 0 0;
  @media (max-width: 1280px) {
    display: none;
  }
`;

const RowItemValue = styled.div`
  min-width: 120px;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -1px;
  justify-content: center;
  white-space: nowrap;
  @media screen and (max-width: 950px) {
    font-size: 15px;
  }
  @media screen and (max-width: 550px) {
    padding-left: 5px;
  }
`;
export const RowWriteReviewBtn_Link = styled(Link)`
  width: 70px;
  height: 70px;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background: black;
  color: #fff;
  border: 10px solid black;
  cursor: pointer;
  & > svg {
    width: 22px;
    margin-right: 5px;
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
    height: 57px;
  }
  @media screen and (max-width: 950px) {
    font-size: 16px;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
    width: 100%;
    margin-bottom: -10px;
  }
`;
const RowWriteReviewBtn = styled.div`
  width: 70px;
  height: 70px;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background: black;
  color: #fff;
  border: 10px solid black;
  cursor: pointer;
  & > svg {
    width: 22px;
    margin-right: 5px;
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
    height: 57px;
  }
  @media screen and (max-width: 950px) {
    font-size: 16px;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
    width: 100%;
    margin-bottom: -10px;
  }
`;

export const RowDeleteBtn = styled.div`
  width: 70px;
  font-size: 14px;
  margin-left: 7px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #111;
  background-color: #fff;
  border: 1px solid #111;
  cursor: pointer;
  & > svg {
    width: 22px;
    margin-right: 15px;
  }
  @media screen and (max-width: 1280px) {
    min-width: 170px;
    width: 100%;
    height: 57px;
  }
  @media screen and (max-width: 950px) {
    font-size: 16px;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
    margin-left: 0;
    margin-top: 15px;
  }
`;

export const BtnArea = styled.div`
  display: flex;
  margin-top: -15px;
  margin-left: 20px;
  min-width: 170px;
  @media (max-width: 1280px) {
    position: absolute;
    bottom: 15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0 120px;
  }
  @media (max-width: 768px) {
    position: absolute;
    bottom: 15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0 75px;
  }
  @media (max-width: 650px) {
    padding: 0 12%;
  }
  @media (max-width: 550px) {
    display: block;
    padding: 0 30px;
    bottom: 8%;
  }
  @media (max-width: 360px) {
    bottom: 7%;
  }
`;

export const OrderBtnText = styled.span`
  display: block;
  @media (max-width: 1280px) {
    display: none;
  }
`;

export const OrderBtnText2 = styled.span`
  display: none;
  @media (max-width: 1280px) {
    display: block;
  }
`;

const Contract = ({ profile }) => {
  const { GetUserOrderListData, GetUserOrderListMutate } = GetUserOrderList(
    profile?.userId
  );
  console.log(GetUserOrderListData, "ㄹ뷰");
  const _deleteOrder = async (id) => {
    setIsModal(true);
    return;
  };

  console.log(GetUserOrderListData, "데이터");
  const [isModal, setIsModal] = useState(false);
  return (
    <Fade Button>
      <Wrapper>
        <AlertModal
          open={isModal}
          close={() => {
            setIsModal(false);
          }}
          header="알림"
        >
          개통 취소는 고객센터 문의 바랍니다.
        </AlertModal>

        <OrdercheckInner>
          <ContractText>주문조회</ContractText>
          {GetUserOrderListData?.map((item, idx) => {
            return (
              <Row>
                <PhoneImg>
                  <img
                    alt=""
                    src={
                      item?.OrderProduct?.image !== null &&
                      item?.OrderProduct?.image?.length !== 0 &&
                      item?.OrderProduct?.image[0]
                    }
                  />
                </PhoneImg>
                <OnlyDataRow>
                  <RowItem>
                    <RowItemTitle>제품명</RowItemTitle>
                    <RowItemValue>
                      {item?.OrderProduct?.productname}
                    </RowItemValue>
                  </RowItem>
                  <SortationLine />
                  <RowItem>
                    <RowItemTitle>진행상태</RowItemTitle>
                    <RowItemValue>
                      {item.isvalidate === 0 ? "개통 진행중" : "개통 완료"}
                    </RowItemValue>
                  </RowItem>
                  <SortationLine />
                  <RowItem>
                    <RowItemTitle>할부원금</RowItemTitle>
                    <RowItemValue>
                      <RowItemValue style={{ marginRight: 25 }}>
                        {item.OrderProduct?.salemonth
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        원
                      </RowItemValue>
                    </RowItemValue>
                  </RowItem>
                  <SortationLine />
                  <RowItem>
                    <RowItemTitle>월 할부금</RowItemTitle>
                    <RowItemValue>
                      {Number(item.OrderProduct?.monthprice).toLocaleString()}
                      원/월
                    </RowItemValue>
                  </RowItem>
                  <BtnArea>
                    {item?.reviewd === false && item?.isvalidate === 7 && (
                      <RowWriteReviewBtn_Link
                        to={`/writereview/${item.productid}/${item.id}`}
                      >
                        {/* <svg viewBox="0 0 512 512">
                          <path
                            fill="currentColor"
                            d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
                          ></path>
                        </svg> */}

                        <OrderBtnText>
                          리뷰
                          <br />
                          쓰기
                        </OrderBtnText>
                        <OrderBtnText2>리뷰쓰기</OrderBtnText2>
                      </RowWriteReviewBtn_Link>
                    )}
                    {item?.isvalidate !== 7 && (
                      <RowDeleteBtn
                        onClick={() => {
                          _deleteOrder(item.id);
                        }}
                      >
                        <OrderBtnText>
                          개통
                          <br />
                          취소
                        </OrderBtnText>
                        <OrderBtnText2>개통취소</OrderBtnText2>
                      </RowDeleteBtn>
                    )}
                  </BtnArea>
                </OnlyDataRow>
              </Row>
            );
          })}
        </OrdercheckInner>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(Contract);
