import styled from "styled-components";

export const Wrapper = styled.div``;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 520px;
  padding: 0 20px;
  & .CardAddImg {
    margin: 5px 0 -10px;
  }
`;

export const SignupText = styled.div`
  min-width: 100px;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  @media screen and (max-width: 450px) {
    font-size: 22px;
  }
  margin-bottom: 15px;
  font-weight: 700;
`;

export const CredentialsInputText = styled.div`
  font-size: 25px;
  color: #838383;
  margin-top: 20px;

  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`;

export const CredentialsInputInput = styled.input`
  min-width: ${(props) => {
    if (props.basecode === true) {
      return `80px`
    }
    return `131px`;
  }};
  width: 100%;
  height: 60px;
  border: 1px solid red;
  border-radius: 10px;
  color: #000;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 15px;
`;

export const SignupBtn = styled.div`
  min-width: 282px;
  width: 100%;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  height: 65px;
  color: #fff;
  background: #000;
  border-radius: 10px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 80px;
`;

export const NameCardVerification = styled.div`
  width: 32%;
  min-width: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const NameCardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NameCardText = styled.div`
  color: #838383;
  font-size: 25px;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`;

export const SubTagButton = styled.div`
  min-width: 60px;
  width: 85px;
  height: 60px;
  :hover {
    opacity: 0.9;
  }
  text-align: center;
  cursor: pointer;
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
  background: ${(props) => {
    let color;
    if (props.able === true) {
      color = "black !important";
    } else {
      color = "#ddd";
    }
    return color;
  }};

  /* border: 1px solid #feb43c; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubTagButton2 = styled.div`
  min-width: 65px;
  width: 65px;
  height: 60px;
  :hover {
    opacity: 0.9;
  }
  cursor: pointer;
  color: #fff;
  background: ${(props) => {
    let color;
    if (props.able === true) {
      color = "#000";
    } else {
      color = "#000";
    }
    return color;
  }};
  margin-left: 10px;
  /* border: 1px solid #feb43c; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

export const NameCardAdd = styled.label`
  :hover {
    opacity: 0.5;
  }
  width: 100%;
  height: 60px;
  cursor: pointer;
  font-size: 15px;
  background: #000;
  border-radius: 9px;
  color: #fff;
  margin-left: auto;
  text-align: center;
  line-height: 60px;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;

export const NameCardBox = styled.div`
  display: flex;
  width: 335px;
  height: 175px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  margin-top: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: ${(props) => {
    let url;
    if (props.url) {
      url = `url(${props.url})`;
    } else {
      url = "";
    }
    return url;
  }};

  @media screen and (max-width: 450px) {
    width: 216px;
    height: 113px;
  }
`;

export const TintBox = styled.p`
  min-width: 180px;
  color: red;
  margin: 0 0 10px 5px;
  font-size: 14px;
`;