import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import {
  GetCategoryDatas,
  GetProductAdminList,
  GetproductList,
  GetPromotionDatas,
} from "Datas/swr";
import { useState } from "react";
import { CopyProduct, deleteProduct } from "Datas/api";
import { exportDataToXlsx } from "Settings/excel";

import CustomPagination from "Settings/pagination";
import { useConfirm } from "Settings/util";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { Pagination } from "react-pagination-bar";
import "react-pagination-bar/dist/index.css";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { useHistory } from "react-router";
import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { TopSelectComp } from "../User/User";
import { useRecoilState } from "recoil";
import {
  CateGoryState,
  CurrentPageState,
  OptionCategory,
  StorePageState,
} from "Datas/store";
const Wrapper = styled.div``;

const Top = styled.div`
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;

const TopLabel = styled.div``;

const TopButton = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const Add = styled.div`
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
`;

const Delete = styled.div`
  display: flex;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  background: #ed6060;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
`;

const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const Imgskeleton = styled.img`
  max-width: 200px;
  height: 100px;
  border: none;
  resize: both;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: contain;
`;

const HoverTag = styled.td`
  border-bottom: 1.5px solid #eee;
  &:hover {
    opacity: 0.5;
  }
`;
export const ButtonCompoPath = styled.div`
  margin-right: 12px;
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    margin-top: 12px;
    width: 90%;
  }
`;
const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;
const TableContainer = styled(Paper)`
  width: 100%;
  overflow: auto;
`;
const useStyles = makeStyles({
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#3c4b64",
      padding: 7,
    },
  },

  tableBody: {
    "& .MuiTableBody-root": {
      padding: 7,
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      padding: 7,
      fontSize: 12,
    },
  },
});

const Product = () => {
  const [page, setPage] = useState([]);
  const [offset, setOffset] = useRecoilState(StorePageState);
  const [filtervalue, setFilterValue] = useState(1);
  const [productName, setProductName] = useState(null);
  const [typeSel, setTypeSel] = useRecoilState(OptionCategory);
  const [CateId, setCateId] = useRecoilState(CateGoryState);
  const [currentPage, setCurrentPage] = useRecoilState(CurrentPageState);

  const { ProductListData, ProductListMutate } = GetProductAdminList(
    "admin",
    typeSel,
    CateId,
    offset,
    filtervalue,
    productName
  );
  const [cateList, setCateList] = useState([]);
  const { PromotionDatas, PromotionMutate } = GetPromotionDatas();
  const { CategoryDatas, CategoryMutate } = GetCategoryDatas();

  const history = useHistory();
  const [ProductData, setProductData] = useState([]);

  const _joinStringData = (data) => {
    let result = "";
    for (let idx = 0; idx < data?.length; idx++) {
      if (data?.length === idx + 1) {
        result = result + data[idx]?.name;
      } else {
        result = result + data[idx]?.name + ", ";
      }
    }
    return result;
  };

  useEffect(() => {
    let datas;
    if (typeSel === 0) {
      datas = CategoryDatas?.map((item, idx) => {
        return { label: item.name, value: item.id };
      });
    }
    if (typeSel === 1) {
      datas = PromotionDatas?.map((item, idx) => {
        return { label: item.title, value: item.id };
      });
    }
    setCateList(datas);
  }, [typeSel, CategoryDatas, PromotionDatas]);

  console.log(cateList);
  useEffect(() => {
    let checkd = ProductData.every((item) => {
      return item.validate;
    });
  }, [ProductData]);

  useEffect(() => {
    if (ProductListData?.rows !== undefined) {
      let Products = [...ProductListData?.rows];
      setProductData(Products);
    }
  }, [ProductListData]);

  const _handleSelect = (id) => {
    //셀렉트 메서드를 정렬시켜줌
    let temp = [...ProductData];
    temp?.map((el, idx) => {
      if (el?.id === id) {
        let data = {
          ...el,
          validate: !el?.validate,
        };
        temp[idx] = data;
      }
    });

    setProductData(temp);

    //셀렉트처리
  };

  const _handleAllSelct = () => {
    //전체샐렉트동기화
    let data = [...ProductData];
    let resdata;
    if (
      ProductData?.some((item, idx) => {
        return item.validate === false;
      }) === true
        ? false
        : true
    ) {
      resdata = data.map((item) => {
        return { ...item, validate: false };
      });
    } else {
      resdata = data.map((item) => {
        return { ...item, validate: true };
      });
    }

    setProductData(resdata);
  };
  const desk = useConfirm(
    "삭제하시겠습니까?",
    async () => {
      let res = ProductData?.filter((item, idx) => {
        return item.validate === true;
      })?.map((item, idx) => {
        return item.id;
      });
      await deleteProduct({ formid: res });
      await ProductListMutate();
    },
    () => {
      alert("삭제 를 취소하였습니다.");
    }
  );
  console.log(ProductData, "프로덕트데이터");
  const _handleCopy = async () => {
    let res = ProductData?.filter((item, idx) => {
      return item.validate === true;
    })?.map((item, idx) => {
      return item.id;
    });
    if (res.length === 0) {
      alert("복제할 상품을 선택해주세요");
      return;
    }
    let body = {
      formid: res,
    };
    await CopyProduct(body);

    await ProductListMutate();
  };
  const _handleDelete = async () => {
    let res = ProductData?.filter((item, idx) => {
      return item.validate === true;
    })?.map((item, idx) => {
      return item.id;
    });
    if (res.length === 0) {
      alert("삭제할 상품을 선택해주세요");
      return;
    }

    desk();
  };
  const classes = useStyles();

  /*   useEffect(() => {
    setCurrentPage(1);
    setOffset(0);
    setPage([]);
  }, []); */
  console.log(ProductData);
  useEffect(() => {
    console.log("오프셋 변경");
    window.scrollTo(0, 0);
  }, [offset]);
  return (
    <Fade Button>
      <Wrapper>
        <TopLabel>상품관리</TopLabel>
        <Top>
          <TopSelectComp>
            <FormControl fullWidth>
              <InputLabel id="store-label">분류</InputLabel>
              <Select
                labelId="store-label"
                id="store-select"
                value={typeSel}
                onChange={(e) => {
                  setTypeSel(e.target.value);
                  setCurrentPage(1);
                  setOffset(0);
                  setCateId("전체");
                }}
              >
                {[
                  { label: "카테고리", value: 0 },
                  { label: "프로모션", value: 1 },
                ]?.map((item, idx) => {
                  return <MenuItem value={item.value}>{item?.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </TopSelectComp>

          <TopSelectComp>
            <FormControl fullWidth>
              <InputLabel id="store-label">카테고리</InputLabel>
              <Select
                labelId="store-label"
                id="store-select"
                value={CateId}
                onChange={(e) => {
                  setCateId(e.target.value);
                  setCurrentPage(1);
                  setOffset(0);
                }}
              >
                <MenuItem value="전체">전체</MenuItem>
                {cateList?.map((item, idx) => {
                  return <MenuItem value={item.value}>{item?.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </TopSelectComp>
          <TopSelectComp inviteR={true}>
            <TextField
              fullWidth
              type="text"
              label={"상품명"}
              id="standard-basic"
              value={productName}
              onChange={(e) => {
                if (e.target.value === "") {
                  setProductName(null);
                }
                setProductName(e.target.value);
              }}
              variant="standard"
            />
          </TopSelectComp>
        </Top>
        <Top>
          <TopButton>
            <ButtonCompoPath>
              <Link
                to={{
                  pathname: "/admin/product/Detail",
                  state: {
                    type: "Add",
                  },
                }}
              >
                <Button fullWidth variant="contained" color="success">
                  상품 추가
                </Button>
              </Link>
            </ButtonCompoPath>
            <ButtonCompoPath>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={_handleDelete}
              >
                삭제
              </Button>
            </ButtonCompoPath>

            <ButtonCompoPath>
              <Button
                fullWidth
                variant="contained"
                color="success"
                onClick={() =>
                  exportDataToXlsx({
                    data: ProductData.filter((item, idx) => {
                      return item.validate === true;
                    }).map((item1, idx1) => {
                      return {
                        번호: item1.id,
                        기기명: item1.name,
                        용량: _joinStringData(item1.HardPrices),
                        색상: _joinStringData(item1.ColorPrices),
                        통신사: _joinStringData(
                          item1.telecom_product_key_product
                        ),
                        출고가: item1.marketprice,
                      };
                    }),
                    filename: "상품_추출",
                  })
                }
              >
                엑셀 다운
              </Button>
            </ButtonCompoPath>
          </TopButton>
        </Top>
        <TableContainer style={{ marginTop: 12 }}>
          <Table style={{ minWidth: 1100 }} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    size="small"
                    color="success"
                    checked={
                      ProductData?.some((item, idx) => {
                        return item.validate === false;
                      }) === true
                        ? false
                        : true
                    }
                    onClick={() => {
                      _handleAllSelct();
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  이미지
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  상품명
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  용량
                </TableCell>
                <TableCell
                  style={{ width: 300 }}
                  className={classes.tableCell}
                  align="center"
                >
                  메모
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  노출요금제
                </TableCell>

                <TableCell className={classes.tableCell} align="center">
                  출고가격
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  순서
                </TableCell>

                <TableCell
                  style={{ width: 80 }}
                  className={classes.tableCell}
                  align="center"
                >
                  더보기
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {ProductData?.map((item, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell className={classes.tableCell} align="center">
                      <Checkbox
                        color="success"
                        onClick={() => _handleSelect(item?.id)}
                        checked={item?.validate}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <Imgskeleton
                        src={
                          item?.images !== undefined &&
                          item?.images !== null &&
                          item?.images[0]
                        }
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item?.name}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item?.HardPrices?.map((hard, idx1) => {
                        return `${hard.name} `;
                      })}
                    </TableCell>
                    <TableCell
                      style={{ width: 300 }}
                      className={classes.tableCell}
                      align="center"
                    >
                      {item?.memoadmin?.length > 16
                        ? item?.memoadmin?.substring(0, 16) + "..."
                        : item?.memoadmin}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item?.telecom_product_key_product?.map((tel, idx3) => {
                        return `${tel.name} `;
                      })}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <div
                        style={{ backgroundColor: "#eee", padding: "2px 5px" }}
                      >
                        SKT+
                      </div>
                      <div>
                        {String(item?.SKTPrice)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div>
                      <div
                        style={{ backgroundColor: "#eee", padding: "2px 5px" }}
                      >
                        KT+
                      </div>
                      <div style={{ borderTop: "1px solid #eee" }}>
                        {String(item?.KTPrice)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div>
                      <div
                        style={{ backgroundColor: "#eee", padding: "2px 5px" }}
                      >
                        LGU+
                      </div>
                      <div style={{ borderTop: "1px solid #eee" }}>
                        {String(item?.LGUPrice)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div>
                    </TableCell>

                    <TableCell className={classes.tableCell} align="center">
                      {item?.order}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <Link
                        to={{
                          pathname: "/admin/product/Detail",
                          state: {
                            type: "Edit",
                            id: item?.id,
                            offset: offset,
                          },
                        }}
                      >
                        더보기
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              currentPage={currentPage}
              itemsPerPage={10}
              onPageChange={(pageNumber) => {
                setCurrentPage(pageNumber);
                setOffset((pageNumber - 1) * 10);
              }}
              totalItems={ProductListData?.count}
              pageNeighbours={14}
            />
          </div>
        </TableContainer>

        {/* <DataTable>
          <colgroup></colgroup>
          <thead>
            <tr>
              <LinedTag style={{ width: 80, textAlign: "center" }}>
                <input
                  type="checkbox"
                  checked={checked}
                  onClick={() => {
                    _handleAllSelct();
                  }}
                  style={{ WebkitAppearance: "auto" }}
                />
              </TableCell>
              <th>이미지</th>
              <th>상품명</th>
              <th>용량</th>
              <th>메모</th>
              <th>노출요금제</th>
              <th>출고가격</th>
              <th>순서</th>
              <th>더보기</th>
            </tr>
          </thead>
          <tbody>
            {ProductData?.map((item, idx) => {
              console.log(item);
              return (
                <TableRow key={idx}>
                  <LinedTag>
                    <input
                      type="checkbox"
                      onClick={() => _handleSelect(item?.id)}
                      checked={item?.validate}
                    />
                  </TableCell>
                  <LinedTag>
                    <Imgskeleton
                      src={
                        item?.images !== undefined &&
                        item?.images !== null &&
                        item?.images[0]
                      }
                    />
                  </TableCell>
                  <LinedTag>{item?.name}</TableCell>
                  <LinedTag>
                    {item?.HardPrices?.map((hard, idx1) => {
                      return `${hard.name} `;
                    })}
                  </TableCell>
                  <LinedTag>
                    {item?.memoadmin?.length > 16
                      ? item?.memoadmin?.substring(0, 16) + "..."
                      : item?.memoadmin}
                  </TableCell>
                  <LinedTag>
                    {item?.telecom_product_key_product?.map((tel, idx3) => {
                      return `${tel.name} `;
                    })}
                  </TableCell>
                  <LinedTag>
                    {String(item?.marketprice)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </TableCell>

                  <LinedTag>{item?.order}</TableCell>
                  <HoverTag>
                    <Link
                      to={{
                        pathname: "/admin/product/Detail",
                        state: {
                          type: "Edit",
                          id: item?.id,
                        },
                      }}
                    >
                      더보기
                    </Link>
                  </HoverTag>
                </TableRow>
              );
            })}
          </tbody>
        </DataTable> */}
      </Wrapper>
    </Fade>
  );
};

export default Product;
