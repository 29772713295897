import React, { Component } from "react";
import styled from "styled-components";

import { GetBoardList, GetInforMation, GetOrderInfoData } from "Datas/swr";
import { Link } from "react-router-dom";
import Sliders from "Components/Sliders";
import { Fade } from "react-reveal";
import { useState } from "react";
import { useEffect } from "react";
import CustomPagination from "Settings/pagination";


const Wrapper = styled.div`
min-height: 72vh;
`;

const Leaderboard = styled.div`
  width: 100%;
  margin-top: 100px;
  height: 550px;
  background: #f0f0f0;

  @media screen and (max-width: 768px) {
    height: 320px;
  }

  @media screen and (max-width: 375px) {
    height: 207px;
  }
`;

const AnnouncementWrapper = styled.div`
  max-width: 1410px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
  margin-top: 140px;
  @media screen and (max-width: 1344px) {
    width: 99%;
    margin-left: 12px;
  }
  @media screen and (max-width: 1100px) {
    width: 96.5%;
    margin-left: 30px;
  }
  @media screen and (max-width: 768px) {
    width: 97%;
    margin-left: 14px;
  }
`;

const AnnouncementText = styled.div`
  font-size: 25px;
  color: #feb43c;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    font-weight: 600;
    margin-left: 2%;
  }
  @media screen and (max-width: 375px) {
    font-size: 14px;
    font-weight: 600;
    margin-left: 2%;
  }
`;

const AnnouncementSubText = styled.div`
  margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  @media screen and (max-width: 659px) {
    text-align: center;
    margin: 10px 0 30px -10px;
    font-size: 25px;
  }
`;

const AnnouncementBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

const AnnouncementBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  border: 1px solid #dcdcdc;
  max-width: 735px;
  background: #fff;
  width: 31.8%;
  margin-bottom: 35px;
  border-radius: 10px;
  :nth-child(3n) {
    margin-right: 0;
  }
  @media(max-width: 1344px) {
    width: 31.2%;
  }
  @media(max-width: 1080px) {
    width: 47.5%;
    :nth-child(2n) {
      margin-right: 0;
    }
    :nth-child(3n) {
      margin-right: 30px;
    }
  }
  @media(max-width: 659px) {
    width: 99%;
    margin-right: 0;
    :nth-child(3n) {
      margin-right: 0;
    }
  }
  @media(max-width: 500px) {
    width: 97%;
  }
  @media(max-width: 360px) {
    width: 95%;
  }
`;

const BoxThunbmail = styled.img`
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  width: 100%;
  object-fit: cover;
  height: 226px;
  border-radius: 10px 10px 0 0;
`;

const BoxTitle_B = styled.div`
  padding: 3px 10px;
  color: white;
  background-color: #000;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
`;

const BoxTitle = styled.div`
  padding: 5px 13px 3px;
  font-size: 13px;
  font-weight: 500;
  color: #777;
  @media screen and (max-width: 768px) {
    font-size: 13px;
    font-weight: 500;
  }
  @media screen and (max-width: 375px) {
    font-size: 13px;
    font-weight: 500;
  }
`;

const BoxDesc = styled.div`
  margin: 10px 20px 25px 20px;
  color: #a7a7a7;
`;


const Announcement = () => {
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState([]);
  const { InfoData, InfoDataMutate } = GetInforMation();
  const { BoardListData, BoardListMutate, isLoading } = GetBoardList(offset);

  const [BoardData, setBoardData] = useState([]);
  useEffect(() => {
    if (!isLoading) {
      setBoardData(BoardListData?.rows);
    }
  }, [BoardListData]);

return (  <Fade Button>
  <Wrapper>
    {/*   <Sliders /> */}
    <AnnouncementWrapper>
      <AnnouncementSubText>공지사항</AnnouncementSubText>
      <AnnouncementBoxWrapper>
        {BoardData?.map((el, idx) => {
          return (
            <>
              <AnnouncementBox>
                <Link
                   to={{
                    pathname: `/announcementpost/${el.id}`,
                    state: {
                      title: el?.title,
                      createdAt: el?.createdAt,
                      contents: el?.contents,
                      //프로덕트 텔레콤의 데이터 아이디를 넣어준다
                    },
                  }}
                >
                  <BoxThunbmail src={el?.images[0]} />
                  <div style={{display:'flex',paddingBottom:16,paddingTop:5,paddingLeft:15,alignItems:'center'}}>
                  <BoxTitle_B>공지사항</BoxTitle_B>
                    <BoxTitle>{el?.title}</BoxTitle>
                    </div>
                  {/* <BoxDesc
                      style={{ marginBottom: 25 }}
                      dangerouslySetInnerHTML={{
                        __html: el?.contents,
                      }}
                    ></BoxDesc> */}
                  {/* <BoxDesc style={{ marginBottom: 15 }}></BoxDesc> */}
                </Link>
              </AnnouncementBox>

            </>
          );
        })}
         <CustomPagination
              data={BoardListData}
              setOffset={setOffset}
              page={page}
              setPage={setPage}
            />
      </AnnouncementBoxWrapper>
    </AnnouncementWrapper>
  </Wrapper>
</Fade>
)
};

export default Announcement;

