import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import {
  GetCardList,
  GetInforMation,
  GetProductInfo,
  GetStoreInfo,
  GetUsageCate,
} from "Datas/swr";

const breakpoints = [450, 768, 992, 1200, 420, 345]; // 0 1 번쨰사용
const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

const Product = ({ location, profile }) => {
  // State
  const [telId, setTelId] = useState(1);
  const [datas, setDatas] = useState({
    storage: "",
    color: "",
    oldtelecom: "",
    newtelecom: "",
    plan: "",
    discount: location?.state?.discount,
    Sales: 0,
    checkout: "",
    sim: "",
    sktDiscout: "",
    card: "",
    storagePrice: 0,
  });

  const [currentTele, setCurrentTele] = useState(undefined); //현재 텔레데이터
  const { ProductInfoData, isLoading, ProductInfoMutate } = GetProductInfo(
    location?.state?.id
  );

  //전체 텔레콤리스트를 필터링하여야함
  const { GetUsageCateData } = GetUsageCate(telId);
  const { CardListData } = GetCardList(datas?.newtelecom, 0);
  const { StoreInfoData } = GetStoreInfo(profile?.storeid);
  const [totalPrice, setTotalPrice] = useState(0);

  const CheckoutMonth = datas?.checkout.replace("개월", "");

  console.log("ProductInfoData");
  console.log(ProductInfoData);
  console.log(ProductInfoData?.marketprice); // 출고가격

  console.log("GetUsageCateData");
  console.log(GetUsageCateData);

  console.log("CardListData");
  console.log(CardListData);

  console.log("StoreInfoData");
  console.log(StoreInfoData?.dc);

  useEffect(() => {
    if (ProductInfoData !== undefined) {
      setTotalPrice(ProductInfoData?.marketprice);
    }
  }, [ProductInfoData]);

  useEffect(() => {
    // 용량 가격 덧셈
    setTotalPrice(ProductInfoData?.marketprice + datas?.storage?.price);
  }, [datas]);

  return (
    <div style={{ height: 1240, marginTop: 250, marginLeft: 250 }}>
      <h1>총 가격: {totalPrice}</h1>
      <div>
        <h1>용량</h1>
        {ProductInfoData?.HardPrices?.map((el, idx) => {
          return (
            <div
              onClick={() =>
                setDatas({
                  ...datas,
                  storage: { name: el?.name, price: el?.price },
                })
              }
            >
              {el?.name}
            </div>
          );
        })}
      </div>

      <div>
        <h1>용량</h1>
        {ProductInfoData?.HardPrices?.map((el, idx) => {
          return (
            <div
              onClick={() =>
                setDatas({
                  ...datas,
                  storage: { name: el?.name, price: el?.price },
                })
              }
            >
              {el?.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Product;
