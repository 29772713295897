import { DeleteContract, UpdateContract } from "Datas/api";
import {
  GetAllOrderList,
  GetStoredL,
  GetStoreList,
  GetStoreListTele,
} from "Datas/swr";
import React, { Component, useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

import { exportDataToXlsx } from "Settings/excel";
import styled from "styled-components";
import { Pagination } from "react-bootstrap";
import { withRouter } from "react-router";
import CustomPagination from "Settings/pagination";
import moment from "moment";
import { useConfirm } from "Settings/util";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { TopSelectComp } from "../User/User";
const Wrapper = styled.div``;

const TopLabel = styled.div``;
const Top = styled.div`
  border: 1px solid #eee;
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;

const TopButton = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const Add = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
`;

const Delete = styled.div`
  display: flex;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  background: #ed6060;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
`;
export const ButtonCompoPath = styled.div`
  margin-left: 12px;

  @media screen and (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
    margin-top: 12px;
  }
`;
const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const HoverTag = styled.td`
  border-bottom: 1.5px solid #eee;
  &:hover {
    opacity: 0.5;
  }
`;
const useStyles = makeStyles({
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#3c4b64",
      padding: 7,
    },
  },

  tableBody: {
    "& .MuiTableBody-root": {
      padding: 7,
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      padding: 7,
    },
  },
});
export const DateBox = styled.div`
  width: 300px;
  margin-left: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-left: 0px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
`;

const SearchBox = styled.input`
  width: 100%;
  height: 40px;
  border: none;

  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #f8f8f8;
  padding-left: 12px;
  @media screen and (max-width: 768px) {
    margin-top: 12px;
  }
`;
const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;

const Contract = ({ profile }) => {
  const TableContainer = styled(Paper)`
    max-height: 800px;

    width: 100%;
    overflow: auto;
  `;
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState([]);
  const [selected, setSelected] = useState("전체");
  const [storeList, setStoreList] = useState([]);

  const { StoreListTeleData, isLoading, StoreListTeleMutate } =
    GetStoreListTele(0);
  const [storeSelect, setStoreSelect] = useState("전체");
  const [checked, setChecked] = useState(false);
  const [TypeSelect, setTypeSelect] = useState(0);
  const classes = useStyles();
  const { StoredLList, StoredLMutate } = GetStoredL({ storeid: storeSelect });
  const [dlSelect, setDlSelect] = useState("전체");
  const [DLList, setDLList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [type, setType] = useState({ label: "전체", value: "전체" });
  const [rowData, setRowData] = useState([]);
  const [SearchData, setSearchData] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: "2025-01-01",
    text: null,
  });
  console.log(profile, "프로필");
  const { allOrderListData, allOrderListMutate } = GetAllOrderList(
    offset, //오프셋
    selected, //개통여부
    TypeSelect, //설정값 검색타입
    SearchData.start, //시작일
    SearchData.end, //종료일
    SearchData.text === "" ? null : SearchData.text, //검색어
    storeSelect,
    profile?.DLMode,
    dlSelect
  );

  console.log(StoredLList);
  useEffect(() => {
    if (profile) {
      if (profile.gm < 2) {
        setStoreSelect(profile.storeid);
        setTypeSelect(0);
      }
      if (profile?.DLMode === true) {
        setDlSelect(profile?.code);
      }
    }
  }, [profile]);
  useEffect(() => {
    let Arrays = [];
    for (let i = 0; i < StoredLList?.length + 1; i++) {
      if (StoredLList?.length === i) {
        Arrays.push({
          value: "전체",
          label: "전체",
        });
      } else {
        Arrays.push({
          value: StoredLList?.[i]?.code,
          label: StoredLList?.[i]?.nickname,
        });
      }
    }
    setDLList(Arrays);
  }, [StoredLList]);
  useEffect(() => {
    if (StoreListTeleData?.rows !== undefined) {
      let Arrays = [];
      if (StoreListTeleData?.rows?.length !== 0) {
        for (let i = 0; i < StoreListTeleData?.rows?.length + 1; i++) {
          if (StoreListTeleData?.rows?.length === i) {
            Arrays.push({
              value: "전체",
              label: "전체",
            });
          } else {
            Arrays.push({
              value: StoreListTeleData?.rows?.[i]?.id,
              label: StoreListTeleData?.rows?.[i]?.name,
            });
          }
        }
        console.log(Arrays);
        setStoreList(Arrays);
      }
    }
  }, [StoreListTeleData]);

  const _getRow = (value) => {
    let res = value.map((item) => {
      let trueth;
      if (item.isvalidate === 1) {
        trueth = "해피콜 완료";
      } else if (item.isvalidate === 2) {
        trueth = "서식지 완료";
      } else if (item.isvalidate === 3) {
        trueth = "기변 개통";
      } else if (item.isvalidate === 4) {
        trueth = "상품 준비중";
      } else if (item.isvalidate === 5) {
        trueth = "배송중";
      } else if (item.isvalidate === 6) {
        trueth = "배송 완료";
      } else if (item.isvalidate === 7) {
        trueth = "개통 완료";
      } else if (item.isvalidate === 8) {
        trueth = "교품중";
      } else if (item.isvalidate === 9) {
        trueth = "교품회수";
      } else if (item.isvalidate === 10) {
        trueth = "주문취소";
      } else if (item.isvalidate === 0) {
        trueth = "대기중";
      }
      else if (item.isvalidate === 11) {
        trueth = "개통중";
      }
      return {
        번호: item.id,
        이름: item.name,
        이메일: item.User.email,
        기기명: item.OrderProduct.productname,
        소속: item.User.Store.name,
        딜러: item?.DLCode ? item.DLCode : "가맹개통",
        승인여부: trueth,
        memoadmin: item.memoadmin,
        validate: item.validate,
        validateAccpetDay: item?.isvalidate ? item.validateAccpetDay : "미승인",
        validateOrderDay: item.validateOrderDay,
      };
    });

    setRowData(res);
  };

  const storedata1 = [
    { label: "접수날짜 검색", value: 1 },
    { label: "승인날짜 검색", value: 2 },
    { label: "이름 검색", value: 3 },
  ];

  let storedata2 = [
    { label: "소속별 검색", value: 0 },
    { label: "접수날짜 검색", value: 1 },
    { label: "승인날짜 검색", value: 2 },
    { label: "이름 검색", value: 3 },
  ];

  useEffect(() => {
    let checkd = rowData.every((item) => {
      return item.validate;
    });
    setChecked(checkd);
  }, [rowData]);

  const _handleAllSelct = () => {
    //전체샐렉트동기화

    let data = [...rowData];
    let resdata;
    if (checked) {
      resdata = data.map((item) => {
        return { ...item, validate: false };
      });
    } else {
      resdata = data.map((item) => {
        return { ...item, validate: true };
      });
    }

    setRowData(resdata);
    setChecked(true);
  };

  useEffect(() => {
    console.log(allOrderListData);
    if (allOrderListData?.rows !== undefined) {
      /* console.log(allOrderListData?.rows);
      if (type?.label === "전체") {
        setOrderList(allOrderListData?.rows);
        _getRow(allOrderListData?.rows);
      } else if (type?.label === "승인") {
        const tempData = allOrderListData?.rows?.filter(
          (el, idx) => el?.isvalidate === 1
        );
        setOrderList(tempData);
        _getRow(tempData);
      } else {
        const tempData = allOrderListData?.rows?.filter(
          (el, idx) => el?.isvalidate !== 1
        );
        setOrderList(tempData);
        _getRow(tempData);
      } */
      //setOrderList(allOrderListData?.rows);
      _getRow(allOrderListData?.rows);
    }
  }, [allOrderListData, type]);

  console.log(rowData, "라우데이터");

  const _handleSelect = (id) => {
    console.log("호출");

    let temp = [...rowData];
    temp?.map((el, idx) => {
      if (el?.번호 === id) {
        let data = {
          ...el,
          validate: !el?.validate,
        };
        temp[idx] = data;
      }
    });
    setRowData(temp);
  };

  const desk = useConfirm(
    "삭제하시겠습니까?",
    async () => {
      for (let index = 0; index < rowData?.length; index++) {
        if (rowData[index]?.validate === true) {
          let body = {
            id: rowData[index]?.번호,
          };
          await DeleteContract(body);
        }
      }
      await allOrderListMutate();
    },
    () => {
      alert("삭제 를 취소하였습니다.");
    }
  );
  const _handleDelete = async () => {
    desk();
  };

  const _handleUpdate = async (type, otype) => {
    let newArray = [];
    if (rowData.every((item) => item.validate === false)) {
      alert("선택된 상품이 없습니다.");
      return;
    }

    for (let index = 0; index < rowData?.length; index++) {
      if (rowData[index]?.validate === true) {
        let body = {
          isvalidate: otype,
          typeOrder: type,
          validateAccpetDay: moment(new Date()).format("YYYY-MM-DD"),
          storeid: allOrderListData?.rows[index].storeid,
          userid: allOrderListData?.rows[index].userid,
        };
        newArray.push(await UpdateContract(rowData[index]?.번호, body));
      }
    }
    let task = newArray.every((item) => item === true);
    if (task === true) {
      if (type === "해피콜") {
        alert("해피콜 성공");
      } else if (type === "서식지") {
        alert("서식지 성공");
      } else if (type === "개통중") {
        alert("개통중 등록 완료");
      } else if (type === "기변") {
        alert("기변 개통 완료");
      } else if (type === "준비중") {
        alert("상품 준비중 완료");
      } else if (type === "배송중") {
        alert("배송중 완료");
      } else if (type === "배송완료") {
        alert("배송완료");
      } else if (type === "교품중") {
        alert("교품중 완료");
      } else if (type === "교품회수") {
        alert("교품회수 완료");
      } else if (type === "주문취소") {
        alert("주문취소 완료");
      } else if (type === "대기중") {
        alert("대기상태 변경완료");
      }
    } else {
      alert("업데이트 실패");
    }
    await allOrderListMutate();
  };

  return (
    <Fade Button>
      <Wrapper>
        <TopLabel>개통관리</TopLabel>
        <Top>
          <TopSelectComp>
            <FormControl fullWidth>
              <InputLabel id="store-label">분류</InputLabel>
              <Select
                labelId="store-label"
                id="store-select"
                value={selected}
                onChange={(e) => {
                  setSelected(e.target.value);
                }}
              >
                {[
                  { label: "전체", value: "전체" },
                  { label: "개통중", value: "11" },
                  { label: "대기중", value: "0" },
                  { label: "해피콜 완료", value: "1" },
                  { label: "서식지 완료", value: "2" },
                  { label: "기변 개통", value: "3" },
                  { label: "상품 준비중", value: "4" },
                  { label: "배송중", value: "5" },
                  { label: "배송 완료", value: "6" },
                  { label: "개통 완료", value: "7" },
                  { label: "교품중", value: "8" },
                  { label: "교품회수", value: "9" },
                  { label: "주문취소", value: "10" },
                ]?.map((item, idx) => {
                  return <MenuItem value={item.value}>{item?.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </TopSelectComp>
          {profile?.gm > 1 && (
            <TopSelectComp>
              <FormControl fullWidth>
                <InputLabel id="store-label">검색 분류</InputLabel>
                <Select
                  labelId="store-label"
                  value={TypeSelect}
                  id="store-select"
                  onChange={(e) => {
                    setStoreSelect("전체");
                    setDlSelect("전체");
                    setTypeSelect("전체");
                    setSearchData(""); //초기화
                    setTypeSelect(e.target.value);
                  }}
                >
                  {profile?.gm < 2
                    ? storedata1?.map((item, idx) => {
                        return (
                          <MenuItem value={item.value}>{item?.label}</MenuItem>
                        );
                      })
                    : storedata2?.map((item, idx) => {
                        return (
                          <MenuItem value={item.value}>{item?.label}</MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </TopSelectComp>
          )}
          {profile?.gm > 1 && (
            <>
              {TypeSelect === 0 && (
                <TopSelectComp>
                  <FormControl fullWidth>
                    <InputLabel id="store-label">스토어</InputLabel>
                    <Select
                      labelId="store-label"
                      id="store-select"
                      value={storeSelect}
                      onChange={(e) => setStoreSelect(e.target.value)}
                    >
                      {storeList?.map((item, idx) => {
                        return (
                          <MenuItem value={item.value}>{item?.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </TopSelectComp>
              )}
            </>
          )}
          {profile?.gm > 0 && (
            <>
              {storeSelect !== "전체" && (
                <TopSelectComp>
                  <FormControl fullWidth>
                    <InputLabel id="store-label">딜러</InputLabel>
                    <Select
                      labelId="store-label"
                      id="store-select"
                      value={dlSelect}
                      onChange={(e) => setDlSelect(e.target.value)}
                    >
                      {DLList?.map((item, idx) => {
                        return (
                          <MenuItem value={item.value}>{item?.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </TopSelectComp>
              )}
            </>
          )}
          {TypeSelect === 1 && (
            <DateBox>
              <SearchBox
                value={SearchData.start}
                onChange={(e) =>
                  setSearchData({ ...SearchData, start: e.target.value })
                }
                type="date"
                style={{ marginRight: 12, paddingRight: 12 }}
              ></SearchBox>
              <SearchBox
                value={SearchData.end}
                onChange={(e) =>
                  setSearchData({ ...SearchData, end: e.target.value })
                }
                style={{ paddingRight: 12 }}
                type="date"
              ></SearchBox>
            </DateBox>
          )}
          {TypeSelect === 2 && (
            <DateBox>
              <SearchBox
                value={SearchData.start}
                onChange={(e) =>
                  setSearchData({ ...SearchData, start: e.target.value })
                }
                type="date"
                style={{ marginRight: 12, paddingRight: 12 }}
              ></SearchBox>
              <SearchBox
                value={SearchData.end}
                onChange={(e) =>
                  setSearchData({ ...SearchData, end: e.target.value })
                }
                style={{ paddingRight: 12 }}
                type="date"
              ></SearchBox>
            </DateBox>
          )}
          {TypeSelect === 3 && (
            <TopSelectComp>
              <TextField
                fullWidth
                onChange={(e) => {
                  setSearchData({ ...SearchData, text: e.target.value });
                }}
                id="standard-basic"
                label={"이름"}
                variant="standard"
              />
            </TopSelectComp>
          )}
          <TopButton></TopButton>
        </Top>
        {profile?.gm > 0 && (
          <Top>
            <>
              <ButtonCompoPath>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => _handleUpdate("개통중", 11)}
                >
                  개통중
                </Button>
              </ButtonCompoPath>
              <ButtonCompoPath>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => _handleUpdate("대기중", 0)}
                >
                  대기중
                </Button>
              </ButtonCompoPath>
              <ButtonCompoPath>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => _handleUpdate("해피콜", 1)}
                >
                  해피콜 완료
                </Button>
              </ButtonCompoPath>
              <ButtonCompoPath>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  onClick={() => _handleUpdate("서식지", 2)}
                >
                  서식지 완료
                </Button>
              </ButtonCompoPath>
              <ButtonCompoPath>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => _handleUpdate("기변", 3)}
                >
                  기변 개통
                </Button>
              </ButtonCompoPath>
              <ButtonCompoPath>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  onClick={() => _handleUpdate("준비중", 4)}
                >
                  상품 준비중
                </Button>
              </ButtonCompoPath>

              <ButtonCompoPath>
                <Button
                  fullWidth
                  color="success"
                  variant="contained"
                  onClick={() => _handleUpdate("배송중", 5)}
                >
                  배송중
                </Button>
              </ButtonCompoPath>
              <ButtonCompoPath>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => _handleUpdate("배송완료", 6)}
                >
                  배송완료
                </Button>
              </ButtonCompoPath>
              <ButtonCompoPath>
                <Button
                  fullWidth
                  color="success"
                  variant="contained"
                  onClick={() => _handleUpdate("개통완료", 7)}
                >
                  개통완료
                </Button>
              </ButtonCompoPath>
              <ButtonCompoPath>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => _handleUpdate("교품중", 8)}
                >
                  교품중
                </Button>
              </ButtonCompoPath>
              <ButtonCompoPath>
                <Button
                  fullWidth
                  color="success"
                  variant="contained"
                  onClick={() => _handleUpdate("교품회수", 9)}
                >
                  교품회수
                </Button>
              </ButtonCompoPath>
              <ButtonCompoPath>
                <Button
                  fullWidth
                  color="success"
                  variant="contained"
                  onClick={() => _handleUpdate("주문취소", 10)}
                >
                  주문 취소
                </Button>
              </ButtonCompoPath>

              <ButtonCompoPath>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  onClick={() => _handleDelete()}
                >
                  삭제
                </Button>
              </ButtonCompoPath>
            </>
          </Top>
        )}
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={() =>
            exportDataToXlsx({ data: rowData, filename: "개통_추출" })
          }
        >
          엑셀 추출
        </Button>

        <TableContainer>
          <Table style={{ minWidth: 1100 }} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    size="small"
                    color="success"
                    checked={checked}
                    onClick={() => {
                      _handleAllSelct();
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  아이디(휴대폰번호)
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  이름
                </TableCell>

                <TableCell className={classes.tableCell} align="center">
                  소속
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  제품명
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  상태
                </TableCell>
                {profile?.gm > 1 ? (
                  <TableCell className={classes.tableCell} align="center">
                    딜러
                  </TableCell>
                ) : (
                  <TableCell className={classes.tableCell} align="center">
                    메모
                  </TableCell>
                )}
                <TableCell className={classes.tableCell} align="center">
                  개통승인/취소 날짜
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  개통신청 날짜
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  더보기
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {rowData?.map((item, idx) => {
                console.log(String(item.validateAccpetDay).indexOf("Z") !== -1);
                return (
                  <TableRow key={idx}>
                    <TableCell className={classes.tableCell} align="center">
                      <Checkbox
                        color="success"
                        checked={item?.validate}
                        onClick={() => _handleSelect(item?.번호)}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item?.이메일}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item?.이름}
                    </TableCell>
                    {profile?.gm > 1 ? (
                      <TableCell className={classes.tableCell} align="center">
                        {item?.소속}
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableCell} align="center">
                        {item?.딜러}
                      </TableCell>
                    )}

                    <TableCell className={classes.tableCell} align="center">
                      {item?.기기명}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item?.승인여부}
                    </TableCell>
                    {profile?.gm > 1 ? (
                      <TableCell className={classes.tableCell} align="center">
                        {item?.딜러}
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableCell} align="center">
                        {item?.memoadmin?.substring(0, 17)}
                      </TableCell>
                    )}

                    <TableCell className={classes.tableCell} align="center">
                      {item?.validateAccpetDay?.indexOf("Z") !== -1
                        ? moment(item?.validateAccpetDay).format("YYYY-MM-DD")
                        : item?.validateAccpetDay}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {moment(item?.validateOrderDay).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <Link
                        to={{
                          pathname: `/admin/contract/detail/${allOrderListData?.rows[idx]?.id}`,
                          state: allOrderListData?.rows[idx],
                        }}
                      >
                        더보기
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <CustomPagination
          data={allOrderListData}
          setOffset={setOffset}
          page={page}
          setPage={setPage}
        />
      </Wrapper>
    </Fade>
  );
};
export default withRouter(Contract);
