import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { GetStoreList } from "Datas/swr";
import { deleteStore, updateStore } from "Datas/api";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import CustomPagination from "Settings/pagination";
import { useConfirm } from "Settings/util";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";
const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  margin-top: 12px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;
export const ButtonCompoPath = styled.div`
  margin-left: 12px;
  margin-right: ${(props) => {
    if (props.turn) {
      return "12px";
    }
  }};
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    margin-left: 0px;
    width: 100%;
    margin-top: 12px;
    margin-right: 0px;
  }
`;

const TopButton = styled.div`
  display: flex;
  width: 280px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;

    margin-top: 12px;
    width: 100%;
  }
`;

const Add = styled.div`
  display: flex;
  background: ${(props) => {
    if (props.color) {
      return "#212121";
    }
    return "#6091ed";
  }};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  color: #fff;
  margin-right: 10px;
  border-radius: 10px;

  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const Delete = styled.div`
  display: flex;
  background: #ed6060;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;
const useStyles = makeStyles({
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#3c4b64",
      padding: 7,
    },
  },

  tableBody: {
    "& .MuiTableBody-root": {
      padding: 7,
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      padding: 7,
    },
  },
});
const InputLeft = styled.input`
  width: 282px;
  height: 40px;
  border-radius: 5px;
  padding: 16px;
  background-color: #f8f8f8;
  border: none;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  margin-left: 12px;
  margin-right: 12px;
`;
const InputRight = styled.input`
  width: 180px;
  margin-left: 16px;
  margin-right: 16px;
  height: 40px;
  padding: 16px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #f8f8f8;
`;
const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const HoverTag = styled.td`
  border-bottom: 1.5px solid #eee;
  &:hover {
    opacity: 0.5;
  }
`;
const TableContainer = styled(Paper)`

  width: 100%;
  overflow: auto;
`;

const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;
const TextCompo = styled.div`
  margin-left: ${(props) => {
    if (props.left) {
      return "12px";
    }
  }};
  width: ${(props) => {
    if (props.Sales) {
      return "180px";
    }
    return "300px";
  }};
  margin-top: -12px;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;
const Store = ({ profile }) => {
  const classes = useStyles();
  const [page, setPage] = useState([]);
  const [offset, setOffset] = useState(0);
  const [checked, setChecked] = useState(false);
  const [sale, setSale] = useState(null);
  const [Salea, setSalseA] = useState(0);
  const [dc, setDc] = useState(0);
  const [search, setSearch] = useState(null);
  const [select, setSelect] = useState(null);
  //SWR
  const { StoreListData, StoreListMutate, isLoading } = GetStoreList(
    offset,
    search
  );

  const [StoreData, setStoreData] = useState([]);

  console.log(StoreListData);

  useEffect(() => {
    if (!isLoading) {
      setStoreData(StoreListData?.rows);
    }
  }, [StoreListData]);

  const _handleSelect = (id) => {
    //셀렉트 메서드를 정렬시켜줌
    /* let newArray = [...select];
    let temp = [...StoreData];
    temp?.map((el, idx) => {
      if (el?.id === id) {
        let data = {
          ...el,
          isvalidate: !el?.isvalidate,
        };
        temp[idx] = data;

        if (!el?.isvalidate) {
          setDc(el.dc);

          if (newArray.findIndex((item) => item === el.id) === -1) {
            newArray.push(el.id);
          }
        } else {
          newArray = newArray.filter((item) => item !== el.id);

          setSale("");
          setDc("");
          console.log("언체크");
        }
      }
    }); */
    /* 
    
    setStoreData(temp); */
    setSelect(id);
    const DC = StoreData?.map((item, idx) => {
      if (item.id === id) {
        return item.dc;
      }
    }).filter((item, idx) => {
      return item !== undefined
    })[0];
    console.log(DC);
    setDc(DC);
    //셀렉트처리
  };

  useEffect(() => {
    let checkd = StoreData?.every((item) => {
      return item.isvalidate;
    });
    setChecked(checkd);
  }, [StoreData]);

  const desk = useConfirm(
    "삭제하시겠습니까?",
    async () => {
      await deleteStore({ id: select });
    },
    () => {
      alert("삭제 를 취소하였습니다.");
    }
  );

  const _handleDelete = async () => {
    await desk();
  };

  //차감 추가 수정

  const _handlePoint = async (type) => {
    console.log(select);
    if (select === null) {
      alert("선택된 정보가 없습니다.");
      return;
    }

    await updateStore({
      id: select,
      body: {
        dc: dc,
        type: type,
        option: "Point",
        formid: select,
      },
    });
    setSelect(null);
    await StoreListMutate();
  };
  useEffect(() => {
    if (search === "") {
      setSearch(null);
    }
  }, [search]);

  const _handleAddPrice = async (type) => {
    if (sale === null) {
      alert("수수료를 입력해주세요");
      return;
    }

    if (select === null) {
      alert("선택된 정보가 없습니다.");
      return;
    }

    //가맹점 수수료 패치 멀티.
    await updateStore({
      id: select,
      body: {
        type: type,
        sale: sale,
        option: "price",
        formid: select,
      },
    });
    setSelect(null);
    await StoreListMutate();
  };
  const _updateStore = async () => {
    //스토어를업데이트한다

    await updateStore({
      id: select,
      body: {
        fomrid: select,
        sale:Salea
      }
    })
    setSelect(null);
    await StoreListMutate();
  };
  return (
    <Fade Button>
      <Wrapper>
        <TopLabel>가맹점 현황</TopLabel>
        <Top>
          {/* <TopLabel style={{ marginLeft: 32 }}>가맹점 수수료</TopLabel>
          <InputLeft
            style={{ width: 130 }}
            value={sale}
            onChange={(e) => {
              setSale(e.target.value);
            }}
          />

          <Add
            onClick={() => {
              _handleAddPrice("Add");
            }}
          >
            추가
          </Add>

          <Add
            style={{ backgroundColor: "red" }}
            onClick={() => {
              _handleAddPrice("Miner");
            }}
          >
            환급
          </Add>
          <TopLabel style={{ marginLeft: 24 }}>포인트 입력</TopLabel>
          <InputRight
            value={dc}
            onChange={(e) => {
              setDc(e.target.value);
            }}
          ></InputRight>
          <Add
            onClick={() => {
              _handlePoint("Add");
            }}
          >
            추가
          </Add>
          <Delete
            onClick={() => {
              _handlePoint("Miner");
            }}
          >
            차감
          </Delete> */}
          <TextCompo>
            <TextField
              fullWidth
              type="text"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
              id="standard-basic"
              label={"가맹점 명"}
              variant="standard"
            />
          </TextCompo>
          <TopButton>
            <ButtonCompoPath>
              <Button fullWidth variant="contained">
                검색
              </Button>
            </ButtonCompoPath>
            <ButtonCompoPath>
              <Link to="/admin/store/detail/add">
                <Button fullWidth variant="contained" color="success">
                  가맹점 생성
                </Button>
              </Link>
            </ButtonCompoPath>
            <ButtonCompoPath>
              <Button
                fullWidth
                color="error"
                variant="contained"
                onClick={_handleDelete}
              >
                삭제
              </Button>
            </ButtonCompoPath>
          </TopButton>
        </Top>
        <Top>
          <TextCompo Sales>
            <TextField
              fullWidth
              type="text"
              value={Salea}
              onChange={(e) => {
                setSalseA(e.target.value);
              }}
              id="standard-basic"
              label={"가맹점 수수료"}
              variant="standard"
            />
          </TextCompo>
          <ButtonCompoPath turn>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                _updateStore();
              }}
            >
              변경
            </Button>
          </ButtonCompoPath>

          <TextCompo Sales>
            <TextField
              fullWidth
              type="text"
              value={sale}
              onChange={(e) => {
                setSale(e.target.value);
              }}
              id="standard-basic"
              label={"가맹점 추가 수수료"}
              variant="standard"
            />
          </TextCompo>

          <ButtonCompoPath>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                _handleAddPrice("Add");
              }}
            >
              추가
            </Button>
          </ButtonCompoPath>
          <ButtonCompoPath turn>
            <Button
              fullWidth
              variant="contained"
              color="success"
              onClick={() => {
                _handleAddPrice("Miner");
              }}
            >
              환급
            </Button>
          </ButtonCompoPath>
          <TextCompo Sales left>
            <TextField
              fullWidth
              type="text"
              value={dc}
              onChange={(e) => {
                setDc(e.target.value);
              }}
              id="standard-basic"
              label={"포인트 입력"}
              variant="standard"
            />
          </TextCompo>

          <ButtonCompoPath>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                _handlePoint("Add");
              }}
            >
              추가
            </Button>
          </ButtonCompoPath>
          <ButtonCompoPath>
            <Button
              fullWidth
              color="error"
              variant="contained"
              onClick={() => {
                _handlePoint("Miner");
              }}
            >
              차감
            </Button>
          </ButtonCompoPath>
        </Top>
        <TableContainer style={{ marginTop: 24 }}>
          <Table style={{ minWidth: 1100 }} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  {/*      <Checkbox
                    size="small"
                    color="success"
                    checked={checked}
                    onClick={() => {
                      _handleAllSelct();
                    }}
                  /> */}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  가맹점 명
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  누적 수수료
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  인증코드
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  가맹 수수료
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  대표번호
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  누적 포인트
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  더보기
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {StoreData?.length !== 0 &&
                StoreData?.map((el, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell className={classes.tableCell} align="center">
                        <Checkbox
                          type="checkbox"
                          onClick={() => _handleSelect(el.id)}
                          checked={el.id === select}
                          size="small"
                          color="success"
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.name}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {Number(el?.salea).toLocaleString()}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.code}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {Number(el?.sale).toLocaleString()}원
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.tel}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.dc}p
                      </TableCell>

                      <TableCell className={classes.tableCell} align="center">
                        <Link
                          to={{
                            pathname: `/admin/store/detail/${el?.id}`,
                          }}
                        >
                          더보기
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomPagination
            data={StoreListData}
            setOffset={setOffset}
            page={page}
            setPage={setPage}
          />
        </TableContainer>
      </Wrapper>
    </Fade>
  );
};

export default Store;
