import React, {
  useReducer,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import axios from "axios";

const initialState = { profile: null };
const store = React.createContext(initialState);
const { Provider } = store;

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET_LOGIN":
        return { ...state, profile: action.payload };
      case "SET_LOGOUT":
        return { profile: null };
      default:
        return;
    }
  }, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const getProfile = async ({ id }) => {
  let data;
  try {
    data = await axios.get(`user/me/${id}`);
  } catch {
    data = undefined;
  }
  return data;
};

const GetMe = () => {
  const { profile, setLogin } = useAuth();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const setAuth = async () => {
      let jwt = await getStoarge("jwt");
      let AutoLogin = await getStoarge("AutoLogin");
      let LogedInfo = await getStoarge("LoginData");

      if (AutoLogin === "true") {
        if (jwt) {
          const result = await getProfile({
            id:
              LogedInfo === undefined || LogedInfo === null
                ? profile?.id
                : LogedInfo,
          });
          if (result !== undefined) {
            setLogin(result.data);
            await setStoarge("LoginData", result?.data?.userId);
          }
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    setAuth();
  }, []);

  return { profile, loading };
};

const useAuth = () => {
  const {
    state: { profile },
    dispatch,
  } = useContext(store);

  const setLogin = useCallback(
    async (payload) => {
      dispatch({ type: "SET_LOGIN", payload });
    },
    [dispatch]
  );

  const setLogout = useCallback(async () => {
    await RemoveStorage("jwt");
    await RemoveStorage("LoginData");
    await RemoveStorage("AutoLogin");
    dispatch({ type: "SET_LOGOUT" });
  }, [dispatch]);

  return { profile, setLogin, setLogout };
};

const setStoarge = async (key, val) => {
  let data = await localStorage.setItem(key, val);
  return data;
}; //스토리지 저장
const getStoarge = async (key) => {
  let data = await localStorage.getItem(key);
  return data;
}; //스토리지 가져오기

const RemoveStorage = async (key) => {
  let data = await localStorage.removeItem(key);
  return data;
}; //스토리지 제거

export { setStoarge, getStoarge, RemoveStorage, GetMe, UserProvider, useAuth };
