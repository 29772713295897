import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { GetBoardList, GetBoardrecList, GetTermList } from "Datas/swr";
import { Link } from "react-router-dom";
import { deleteBoardRec, deletePost, DestroyTerms } from "Datas/api";
import { Fade } from "react-reveal";
import CustomPagination from "Settings/pagination";

import { useConfirm } from "Settings/util";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";

const Wrapper = styled.div``;

const TopLabel = styled.div``;
const Top = styled.div`
  border: 1px solid #eee;
  display: flex;
  margin-top: 12px;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;

const TopButton = styled.div`
  display: flex;
  width: 140px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;

    margin-top: 12px;
    width: 100%;
  }
`;
export const ButtonCompoPath = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;
const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const Delete = styled.div`
  display: flex;
  background: #ed6060;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const HoverTag = styled.td`
  border-bottom: 1.5px solid #eee;
  &:hover {
    opacity: 0.5;
  }
`;

const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;
const useStyles = makeStyles({
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#3c4b64",
      padding: 7,
    },
  },

  tableBody: {
    "& .MuiTableBody-root": {
      padding: 7,
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      padding: 7,
    },
  },
});

const TableContainer = styled(Paper)`

  width: 100%;
  overflow: auto;
`;
const Imgskeleton = styled.img`
  max-width: 200px;
  height: 100px;
  border: none;
  resize: both;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: contain;
`;
export const TopSelectComp = styled.div`
  width: ${(props) => {
    if (props.invite) {
      return "10%";
    }
    if (props.inviteR) {
      return "50%";
    }
    return "150px";
  }};
  margin-left: ${(props) => {
    if (props.inviteR) {
      return "34px";
    }
    if (props.invite) {
      return "12px";
    }
    return "15px";
  }};
  margin-right: 12px;
  margin-top: -12px;
  @media screen and (max-width: 768px) {
    width: 90%;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 12px;
  }
`;
const Terms = () => {
  const classes = useStyles();

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState([]);
  const TermsType = [
    {
      value: 0,
      label: "회원가입",
    },
    { value: 1, label: "개통신청" },
  ];
  //SWR
  const [terms, setTerms] = useState(0);

  const { TermListData, TermListMutate, isLoading } = GetTermList(
    offset,
    terms
  );

  const [BoardData, setBoardData] = useState([]);
  const [checked, setChecked] = useState(false);

  const _handleAllSelct = () => {
    //전체샐렉트동기화

    let data = [...BoardData];
    let resdata;
    if (
      BoardData?.some((item, idx) => {
        return item.validate === false;
      }) === true
        ? false
        : true
    ) {
      resdata = data.map((item) => {
        return { ...item, validate: false };
      });
    } else {
      resdata = data.map((item) => {
        return { ...item, validate: true };
      });
    }

    setBoardData(resdata);
  };

  useEffect(() => {
    if (!isLoading) {
      const dts = TermListData?.rows?.map((item, idx) => {
        return { ...item, validate: false };
      });
      setBoardData(dts);
    }
  }, [TermListData]);

  const _handleSelect = (id) => {
    let temp = [...BoardData];
    BoardData?.map((el, idx) => {
      if (el?.id === id) {
        let data = {
          ...el,
          validate: !el?.validate,
        };
        temp[idx] = data;
        console.log(data);
      }
    });
    setBoardData(temp);
  };
  const desk = useConfirm(
    "삭제하시겠습니까?",
    async () => {
      let bodys = [];
      for (let index = 0; index < BoardData.length; index++) {
        if (BoardData[index]?.validate) {
          let id = BoardData[index]?.id;
          bodys.push(id);
        }
      }

      await DestroyTerms(bodys);
      await TermListMutate();
    },
    () => {
      alert("삭제 를 취소하였습니다.");
    }
  );
  const _handleDelete = async () => {
    await desk();
  };

  return (
    <Fade Button>
      <Wrapper>
        <TopLabel>이용약관 관리</TopLabel>
        <Top>
          <TopSelectComp>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                이용약관 관리
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={terms}
                onChange={(e) => setTerms(e.target?.value)}
              >
                {TermsType?.map((item, idx) => {
                  return <MenuItem value={item?.value}>{item?.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </TopSelectComp>
          <TopButton>
            <ButtonCompoPath>
              <Link to="/admin/terms/detail/add">
                <Button fullWidth variant="contained">
                  추가
                </Button>
              </Link>
            </ButtonCompoPath>
            <ButtonCompoPath>
              <Button
                color="error"
                fullWidth
                variant="contained"
                onClick={_handleDelete}
              >
                삭제
              </Button>
            </ButtonCompoPath>
          </TopButton>
        </Top>{" "}
        <TableContainer style={{ marginTop: 24 }}>
          <Table style={{ minWidth: 1100 }} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    size="small"
                    color="success"
                    checked={
                      BoardData?.some((item, idx) => {
                        return item.validate === false;
                      }) === true
                        ? false
                        : true
                    }
                    onClick={() => {
                      _handleAllSelct();
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  제목
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  순서{" "}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  분류
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  더보기
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBody}>
              {BoardData?.length !== 0 &&
                BoardData?.map((el, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell className={classes.tableCell} align="center">
                        <Checkbox
                          checked={el?.validate}
                          size="small"
                          color="success"
                          onClick={() => _handleSelect(el?.id)}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.title}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.order}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.termstype === false ? "이용약관" : "개통관리"}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <Link
                          to={{
                            pathname: `/admin/terms/detail/${el?.id}`,
                            state: el,
                          }}
                        >
                          수정
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomPagination
            data={TermListData}
            setOffset={setOffset}
            page={page}
            setPage={setPage}
          />
        </TableContainer>
      </Wrapper>
    </Fade>
  );
};

export default Terms;
