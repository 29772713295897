import React from "react";
import { Fade } from "react-reveal";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import styled from "styled-components";
import { useState } from "react";
import {
  GetProductGroup,
  GetTelecomList,
  GettypeTelecomCateListDatas,
  GettypeTelecomListDatas,
} from "Datas/swr";
import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import { TextField } from "@material-ui/core";
import {
  createGroup,
  createProductGroupList,
  deleteGroup,
  deleteProductGroupList,
} from "Datas/api";

const Top = styled.div`
  border: 1px solid #eee;
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;
const TopLabel = styled.div``;
export const ButtonCompoPath = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

const SearchBox = styled.div`
  width: 200px;
  margin-right: 12px;
  margin-top: -12px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
    margin-top: 0px;
  }
`;
const TelecomPriceWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  align-items: center;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
export const SelfViewBox = styled.div`
  width: 45%;
  justify-content: space-between;

  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  border: 1px solid #eee;
  height: 500px;
  padding-bottom: 150px;
  @media screen and (max-width: 960px) {
    width: 100%;

    height: 300px;
  }
`;
const Wrapper = styled.div``;
const useStyles = makeStyles((theme) => ({
  hideIcon: {
    display: "none",
  },
  RootMuti: {
    "& .MuiSelect-select.MuiSelect-select": {
      height: "100%",
    },
    "& .MuiInput-formControl": {
      height: "100%",
    },
    height: "100%",
    paddingBottom: 40,
  },
}));
const GroupBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
export const TouchableOption = styled.div`
  width: 100%;
  background-color: ${(props) => {
    if (props.action) {
      return "#eee";
    }
  }};
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;
const ButtonInner = styled.div`
  width: 50px;
  margin-right: 20px;
  @media screen and (max-width: 960px) {
    width: 100%;
    margin-right: 0px;
    margin-top: 12px;
  }
`;
const GroupLeftBox = styled.div`
  width: ${(props) => {
    if (props.right) {
      return "35%";
    }
    return "65%";
  }};
  display: flex;
  justify-content: ${(props) => {
    if (props.right) {
      return "flex-end";
    }
  }};
  @media screen and (max-width: 960px) {
    flex-direction: column;
    width: 100%;
  }
`;
const GorupText = styled.p`
  font-size: 14px;
  color: #3e3e3e;
`;
const TextFiledBox = styled.div`
  width: 50%;
  margin-top: -12px;
  margin-left: 12px;
  @media screen and (max-width: 960px) {
    width: 100%;
    margin-top: 12px;
  }
`;
const GroupUsage = () => {
  const classes = useStyles();
  const [telecom, setTelecom] = useState("");
  const [teleList, setTeleList] = useState([]);
  const { TelecomListData } = GetTelecomList();
  const [personName, setPersonName] = useState("");
  const [groupnames, setGroupName] = useState("");
  const [telecomName, setTelecomName] = useState("");
  const [telecom2, setTelecom2] = useState("SKT");
  const [GroupNameIn, setGroupNameIn] = useState("");
  const [GroupTelList, setGorupTelList] = useState([]);
  const { ProductGroupDatas, ProductGroupMutates } = GetProductGroup();
  const [GroupInnerId, setGroupInnerId] = useState(null);
  const [Lsp, setLsp] = useState(false);
  const { typeTelecomListDatas, typeTelecomListMutates, isLoadings } =
    GettypeTelecomListDatas(telecom, 0, 9999);
  const [idx, setIdx] = useState(null);
  const { typeTelecomCateListDatas, typeTelecomCateListMutates } =
    GettypeTelecomCateListDatas(telecom);
  useEffect(() => {
    if (TelecomListData !== undefined) {
      setTelecom(TelecomListData[0]?.name);
      let newArray = [];
      if (TelecomListData.length !== 0) {
        for (let i = 0; i < TelecomListData.length; i++) {
          newArray.push({
            value: TelecomListData[i].id,
            label: TelecomListData[i].name,
          });
        }
        setTeleList(newArray);
      }
    }
  }, [TelecomListData]); 
  console.log(ProductGroupDatas)
  useEffect(() => {
    if (idx !== null) {
      let data = ProductGroupDatas[idx]?.Product_Group_list_key?.filter(
        (item, idx) => {
        
          return item.telecomname === telecom2;
        }
        
      );
 
      setGorupTelList(data);
    }
  }, [telecom2, idx, Lsp]);
  return (
    <Fade Button>
      <Wrapper>
        <TopLabel style={{ marginRight: 15 }}>요금제 그룹 관리</TopLabel>

        <SelfViewBox>
          <GroupBox>
            <GroupLeftBox>
              <GorupText>그룹관리</GorupText>
              <TextFiledBox>
                <TextField
                  fullWidth
                  type="text"
                  id="standard-basicGroupName"
                  onChange={(e) => {
                    setGroupNameIn(e.target.value);
                  }}
                  value={GroupNameIn}
                  variant="standard"
                />
              </TextFiledBox>
            </GroupLeftBox>
            <GroupLeftBox right>
              <ButtonInner>
                <Button
                  onClick={async () => {
                    if (GroupNameIn === "") {
                      alert("공백은 삽입이 불가능합니다.");
                      return;
                    }
                    await createGroup({ GroupName: GroupNameIn });
                    await ProductGroupMutates();
                    await setLsp(!Lsp);
                  }}
                  fullWidth
                  variant="contained"
                  color="success"
                >
                  추가
                </Button>
              </ButtonInner>
              <ButtonInner>
                <Button
                  onClick={async () => {
                    await deleteGroup({ id: groupnames });
                    await ProductGroupMutates();
                    await setLsp(!Lsp);
                  }}
                  fullWidth
                  variant="contained"
                  color="error"
                >
                  삭제
                </Button>
              </ButtonInner>
            </GroupLeftBox>
          </GroupBox>

          {ProductGroupDatas?.map((item, idx) => (
            <TouchableOption
              onClick={() => {
                setGroupName(item.id);
                setIdx(idx);
              }}
              action={item?.id === groupnames}
              key={item?.id}
              value={item?.id}
            >
              {item?.GroupName}
            </TouchableOption>
          ))}
        </SelfViewBox>
        <TelecomPriceWrapper>
          <SelfViewBox>
            <GroupBox>
              <GroupLeftBox>
                <GorupText>그룹 요금제 관리</GorupText>
                <TextFiledBox>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={telecom2}
                    onChange={(e) => {
                      setTelecom2(e.target.value);
                    }}
                  >
                    {teleList?.map((item, idx) => {
                      return (
                        <MenuItem value={item?.label}>{item?.label}</MenuItem>
                      );
                    })}
                  </Select>
                </TextFiledBox>
              </GroupLeftBox>
              <GroupLeftBox right>
                <ButtonInner>
                  <Button
                    onClick={async () => {
                      if (GroupInnerId === null) {
                        alert("그룹 요금제를 선택해주세요");
                      }
                      await deleteProductGroupList({ id: GroupInnerId });
                      await ProductGroupMutates();
                      await setLsp(!Lsp);
                    }}
                    fullWidth
                    variant="contained"
                    color="error"
                  >
                    삭제
                  </Button>
                </ButtonInner>
              </GroupLeftBox>
            </GroupBox>

            {idx !== null &&
              GroupTelList?.map((item, idx) => (
                <TouchableOption
                  onClick={() => {
                    setGroupInnerId(item?.Product_Group_list?.id);
                  }}
                  action={item?.Product_Group_list?.id === GroupInnerId}
                  key={item?.Product_Group_list?.id}
                  value={item?.Product_Group_list?.id}
                >
                  {item?.title}
                </TouchableOption>
              ))}
          </SelfViewBox>

          <SelfViewBox>
            <GroupBox>
              <GroupLeftBox>
                <GorupText>요금제 관리</GorupText>
                <TextFiledBox>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={telecom}
                    onChange={(e) => {
                      setTelecom(e.target.value);
                    }}
                  >
                    {teleList?.map((item, idx) => {
                      return (
                        <MenuItem value={item?.label}>{item?.label}</MenuItem>
                      );
                    })}
                  </Select>
                </TextFiledBox>
              </GroupLeftBox>
              <GroupLeftBox right>
                <ButtonInner>
                  <Button
                    onClick={async () => {
                      if (groupnames?.length === 0) {
                        alert("그룹을 선택해주세요");
                        return;
                      }
                      if (telecomName?.length === 0) {
                        alert("요금제를 선택해주세요");
                        return;
                      }
                      let res = await createProductGroupList({
                        Product_GroupId: groupnames,
                        typetelecom_categoryid: telecomName,
                      });
                      if (res !== undefined) {
                        if (res?.data?.status === 301) {
                          alert(res?.data?.message);
                          return;
                        }
                      }
                      await ProductGroupMutates();
                      await setLsp(!Lsp);
                    }}
                    fullWidth
                    variant="contained"
                    color="success"
                  >
                    등록
                  </Button>
                </ButtonInner>
              </GroupLeftBox>
            </GroupBox>

            {typeTelecomCateListDatas?.map((item) => (
              <TouchableOption
                onClick={() => {
                  setTelecomName(item.id);
                }}
                action={item.id === telecomName}
                key={item?.id}
                value={item?.id}
              >
                {item?.title}
              </TouchableOption>
            ))}
          </SelfViewBox>
        </TelecomPriceWrapper>
      </Wrapper>
    </Fade>
  );
};

export default GroupUsage;
