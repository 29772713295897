import React from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
// Material-ui 컴포넌트들
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// 아이콘들
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import TableIcon from "@material-ui/icons/TableChart";
import ChartIcon from "@material-ui/icons/ShowChart";
import PagesIcon from "@material-ui/icons/Pages";
import IconsIcon from "@material-ui/icons/Mood";
import { GetInformationOrigin } from "Datas/swr";
// others
import styled from "styled-components";
import { AddCircleOutlineRounded } from "@material-ui/icons";
import { WebRounded } from "@material-ui/icons";
import { PersonRounded } from "@material-ui/icons";
import { ShoppingCart } from "@material-ui/icons";
import { ShoppingCartOutlined } from "@material-ui/icons";
import { PersonOutlineRounded } from "@material-ui/icons";
import { ImageSearchOutlined } from "@material-ui/icons";
import { LibraryAddRounded } from "@material-ui/icons";
import { LibraryAddOutlined } from "@material-ui/icons";
import { ShopOutlined } from "@material-ui/icons";
import { CreditCardOutlined } from "@material-ui/icons";
import { LocalPhoneOutlined } from "@material-ui/icons";
import { PermPhoneMsgOutlined } from "@material-ui/icons";
import { CategoryOutlined } from "@material-ui/icons";
import { EventOutlined } from "@material-ui/icons";
import { NotificationsOutlined } from "@material-ui/icons";
import { ContactlessOutlined } from "@material-ui/icons";
import { ControlPointDuplicateOutlined } from "@material-ui/icons";
import { useEffect } from "react";
import { Settings } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,

    padding: "16px 30px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    color: "black",
    fontSize: "1.2em",
    backgroundColor: "#fafafa",
  },
  logoimg: {
    width: "32px",
    height: "32px",
    verticalAlign: "middle",
  },
  mainmenuBox: {
    padding: "0px 0",
    backgroundColor: "#fff",
    borderTop: "1px solid #dedede",
  },
  mainmenuWrap: {
    padding: "10px 34px 10px 30px",
    borderBottom: "1px solid #dedede",
  },
  mainmenu: { color: "black", fontSize: 15 },
}));

const SideProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SideProfileRow = styled.div`
  display: flex;
  align-items: center;
`;

const SideProfileName = styled.div`
  font-size: 12px;
  color: black;
`;

const SideProfileJob = styled.div`
  font-size: 12px;
  color: black;
`;
const SideImg = styled.div`
  background-image: ${(props) => {
    if (props.url) {
      return `url(${props.url})`;
    }
  }};
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
  width: 95px;
  height: 95px;
  border-radius: 999px;
  margin-bottom: 10px;
  border: 1px solid #01c9af;
`;
const SideImgFlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SideProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;

  align-items: center;
  justify-content: center;
  padding: 0px;
  padding-bottom: 16px;
  padding-top: 5px;
`;
const MainLogo = styled.div`
  width: 175px;
  height: 30px;
  background-image: ${(props) => {
    if (props.src) {
      return `url(${props.src})`;
    }
  }};
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
`;
const ResponsiveDrawer = ({ profile, onDrawerToggle }) => {
  const history = useHistory();
  const classes = useStyles();
  const { OriginData, OriginDataMutate } = GetInformationOrigin();
  console.log(profile);
  const locaton = useLocation();
  console.log(locaton, "현재로케이션");
  console.log(profile, "프로필");
  const Navigator = () => {
    onDrawerToggle();
  };
  useEffect(() => {
    let unlisten = history.listen((location) => {
      if (history.action === "PUSH") {
        onDrawerToggle();
      }
      if (history.action === "POP") {
        onDrawerToggle();
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);
  console.log(profile);
  return (
    <div>
      <NavLink to="/admin/user" activeClassName="selected">
        <div className={classes.toolbar}>
          {/* <img src={LogoImage} alt="Logo" className={classes.logoimg} /> */}

          {/* 작업 시작 */}

          <MainLogo
            src={OriginData?.image?.length > 0 && OriginData?.image[0]}
          />
        </div>
      </NavLink>
      <div
        style={{ display: "flex", width: "90%", justifyContent: "flex-end" }}
      >
        <Settings
          onClick={() => {
            history.push("/profile");
          }}
          style={{ width: 25, height: 25, cursor: "pointer" }}
          className={classes.mainmenu}
        />
      </div>
      <SideProfileWrapper>
        <SideImgFlexBox>
          <SideImg
            url={profile?.image?.length > 0 && profile?.image[0]}
          ></SideImg>
          <SideProfile>
            <SideProfileRow style={{ marginBottom: 5 }}>
              <SideProfileName>
                {profile?.name}{" "}
                {
                  <>
                    {profile?.gm >= 1 && <>가맹주님</>}
                    {profile?.DLMode && "딜러님"}
                  </>
                }
              </SideProfileName>
            </SideProfileRow>
            <SideProfileJob></SideProfileJob>
            {profile?.gm >= 1 && (
              <>
                <SideProfileJob>
                  누적수수료: {Number(profile?.salea).toLocaleString()}원
                </SideProfileJob>
                <SideProfileJob>
                  수수료: {Number(profile?.sale).toLocaleString()}원
                </SideProfileJob>
                <SideProfileJob>
                  가맹포인트: {Number(profile?.point).toLocaleString()}원
                </SideProfileJob>
              </>
            )}
            {profile.DLMode && (
              <>
                <SideProfileJob>
                  누적수수료: {Number(profile?.salea).toLocaleString()}원
                </SideProfileJob>
                <SideProfileJob>
                  수수료: {Number(profile?.sale).toLocaleString()}원
                </SideProfileJob>
                <SideProfileJob>
                  가맹포인트: {Number(profile?.point).toLocaleString()}원
                </SideProfileJob>
              </>
            )}
          </SideProfile>
        </SideImgFlexBox>
      </SideProfileWrapper>

      <List
        style={{ marginTop: 0, paddingTop: 0 }}
        className={classes.mainmenuBox}
      >
        <NavLink to="/" activeClassName="selected">
          <ListItem
            selected={locaton.pathname === "/"}
            className={classes.mainmenuWrap}
            button
          >
            <WebRounded
              style={{ marginRight: 12 }}
              className={classes.mainmenu}
            />
            <ListItemText>
              <Typography className={classes.mainmenu}>홈</Typography>
            </ListItemText>
          </ListItem>
        </NavLink>

        <NavLink to="/preorder" activeClassName="selected">
          <ListItem
            selected={locaton.pathname.indexOf("/preorder") !== -1}
            className={classes.mainmenuWrap}
            button
          >
            <WebRounded
              style={{ marginRight: 12 }}
              className={classes.mainmenu}
            />
            <ListItemText>
              <Typography className={classes.mainmenu}>이벤트</Typography>
            </ListItemText>
          </ListItem>
        </NavLink>
        <NavLink to="/announcement" activeClassName="selected">
          <ListItem
            selected={locaton.pathname.indexOf("/announcement") !== -1}
            className={classes.mainmenuWrap}
            button
          >
            <WebRounded
              style={{ marginRight: 12 }}
              className={classes.mainmenu}
            />
            <ListItemText>
              <Typography className={classes.mainmenu}>공지사항</Typography>
            </ListItemText>
          </ListItem>
        </NavLink>
        <NavLink to="/contractlist" activeClassName="selected">
          <ListItem
            selected={locaton.pathname.indexOf("/contractlist") !== -1}
            className={classes.mainmenuWrap}
            button
          >
            <WebRounded
              style={{ marginRight: 12 }}
              className={classes.mainmenu}
            />
            <ListItemText>
              <Typography className={classes.mainmenu}>주문내역</Typography>
            </ListItemText>
          </ListItem>
        </NavLink>
        {profile?.gm > 0 && (
          <NavLink to="/admin/mainsetting" activeClassName="selected">
            <ListItem
              selected={locaton.pathname.indexOf("/admin/mainsetting") !== -1}
              className={classes.mainmenuWrap}
              button
            >
              <WebRounded
                style={{ marginRight: 12 }}
                className={classes.mainmenu}
              />
              <ListItemText>
                <Typography className={classes.mainmenu}>관리자</Typography>
              </ListItemText>
            </ListItem>
          </NavLink>
        )}
        {profile?.DLMode !== false && (
          <NavLink to="/admin/mainsetting" activeClassName="selected">
            <ListItem
              selected={locaton.pathname.indexOf("/admin/mainsetting") !== -1}
              className={classes.mainmenuWrap}
              button
            >
              <WebRounded
                style={{ marginRight: 12 }}
                className={classes.mainmenu}
              />
              <ListItemText>
                <Typography className={classes.mainmenu}>딜러</Typography>
              </ListItemText>
            </ListItem>
          </NavLink>
        )}
      </List>
    </div>
  );
};

export default ResponsiveDrawer;
