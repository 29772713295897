import { LoginUser } from "Datas/api";
import { getStoarge, setStoarge, useAuth } from "Datas/storage";
import AlertModal from "Modal";
import React, { Component, useState } from "react";
import { Fade } from "react-reveal";
import { CheckPhone, validatePassword } from "Settings/common";
import styled from "styled-components";

const Wrapper = styled.div``;

const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 570px;
  padding: 0 20px;
`;

const LoginText = styled.div`
  font-size: 35px;
  @media screen and (max-width: 450px) {
    font-size: 25px;
  }
  margin-bottom: 15px;
  font-weight: 700;
`;

const CredentialsInput = styled.div`
  width: 100%;
  margin-top: 15px;
`;

const CredentialsInputText = styled.div`
  font-size: 25px;
  color: #838383;

  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`;

const CredentialsInputInput = styled.input`
  width: 100%;
  margin-top: 5px;
  height: 65px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  color: #000;
  font-size: 16px;
  padding: 10px;

  @media screen and (max-width: 450px) {
    height: 45px;
  }
`;

const LoginBtn = styled.div`
  width: 100%;
  height: 65px;
  color: #fff;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  background: #4b52bc;
  border: 1px solid #4b52bc;
  border-radius: 10px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  @media screen and (max-width: 450px) {
    height: 45px;
    font-size: 18px;
  }
`;

const SignupBtn = styled.div`
  width: 100%;
  height: 65px;
  color: #4b52bc;
  border: 1px solid #4b52bc;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  font-size: 25px;
  @media screen and (max-width: 450px) {
    height: 45px;
    font-size: 18px;
  }
`;

const TintBox = styled.p`
  color: red;
  margin-top: 12px;
`;
const FindIdPW = styled.div`
  font-size: 17px;
  cursor: pointer;
  color: #4b52bc;
  text-align: center;
  margin-top: 10px;
  @media screen and (max-width: 450px) {
    height: 45px;
    font-size: 15px;
  }
`;

const Login = () => {
  const { setLogin } = useAuth();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [isModal, setIsModal] = useState(false);

  //로그인날려주기

  const _handleLogin = async () => {
    if (!CheckPhone(data.email) || data.password === "") {
      setIsModal(true);
      return;
    } else {
      let token = await getStoarge("fcmToken");
      if (token) {
        data.fcm = token;
      }

      let res = await LoginUser(data, "admin");
      if (res === undefined) {
        alert("회원 정보가 일치하지않습니다!");
        return;
      } else {
        if (res.data.status == 400) {
          setIsModal(true);
          return;
        }

        await setStoarge("jwt", res?.data?.jwt); //로그인후 저장 메인이동
        await setLogin(res.data);
      }
    }
  };
  const _closeModal = () => {
    setIsModal(false);
  };
  return (
    <Wrapper>
      <AlertModal open={isModal} close={_closeModal} header="로그아웃 완료">
       로그인 정보가 잘못 되었습니다.
      </AlertModal>
      <Inner>
        <LoginText>로그인</LoginText>
        <CredentialsInput>
          <CredentialsInputText>아이디</CredentialsInputText>
          <CredentialsInputInput
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            placeholder="아이디를 입력해주세요"
          ></CredentialsInputInput>
          {!CheckPhone(data.email) && (
            <TintBox>휴대폰 번호를 입력해주세요.</TintBox>
          )}
        </CredentialsInput>
        <CredentialsInput>
          <CredentialsInputText>비밀번호</CredentialsInputText>
          <CredentialsInputInput
            value={data.password}
            type="password"
            onChange={(e) => setData({ ...data, password: e.target.value })}
            placeholder="비밀번호를 입력해주세요"
          ></CredentialsInputInput>
          <TintBox>{validatePassword(data.password)}</TintBox>
        </CredentialsInput>
        <LoginBtn onClick={() => _handleLogin()}>로그인</LoginBtn>
        <SignupBtn>회원가입</SignupBtn>

        <FindIdPW>비밀번호 찾기</FindIdPW>
      </Inner>
    </Wrapper>
  );
};

export default Login;
