import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import {
  FooterBox,
  FooterContainer,
  FooterImg,
  FooterContentBox,
  FooterContent,
  FooterText,
  ConfirmImage,
  ImageView,
  FooterWidthLine,
  FooterServiceArea,
  CopyrightArea,
  CopyrightArea2,
} from "Settings/Footer";
import { GetAuthData, GetInforMation, GetInformationOrigin, GetInformationOriginNone } from "Datas/swr";
import { CircleLogo } from "assets";

const Footer = ({ location }) => {
  const { OriginData } = GetInformationOrigin();
  const [data, setData] = useState([]);
  const [isToggle, setIsToggle] = useState(true); //펄스해야함
  const { OriginNData } = GetInformationOriginNone()
  const { AuthData } = GetAuthData()
  console.log(OriginNData, '데이터222')
  useEffect(() => {
    if (true) {
      setData(OriginData)
    } else {
      setData(OriginNData)
    }


  }
    , [OriginData, OriginNData]

  );

  console.log(OriginData)

  if (location.pathname.indexOf("admin") !== -1) {
    return <></>;
  } else if (data !== undefined && data !== null) {
    return (
      <>
        <FooterContainer isToggle={isToggle} style={{ cursor: "pointer" }}>
          <FooterBox
            onClick={() => setIsToggle(!isToggle)}
            style={{
              alignItems: isToggle && "center",
              justifyContent: isToggle && "flex-end",
            }}
          >
            {isToggle ? (
              <div
                style={{
                  color: "#fff",
                  fontWeight: "600",
                  
                }}
              >
                <p>더보기</p>
              </div>
            ) : (
              <>
                <ImageView>
                  <a href={data?.optlink} target="_blank">
                    {/* <FooterImg src={data?.length !== 0 && data?.image} /> */}
                    {/* <ConfirmImage src={data?.optimage} /> */}
                    <img src={data?.optimage} style={{ height: 90 }} />
                  </a>
                </ImageView>
                <FooterContentBox>
                  <div>
                    <FooterContent>
                      <FooterText>{data?.bmanName}</FooterText>
                      <FooterText>{data?.ceo}</FooterText>
                      <FooterText>{data?.ceoCode}</FooterText>
                      <FooterText>{data?.zip}</FooterText>
                    </FooterContent>
                    <FooterContent>
                      <FooterText>
                        <span style={{ color: "#999" }}>
                          통신판매업신고번호 :{" "}
                        </span>
                        {data?.TeleCode}
                      </FooterText>
                      <FooterText>
                        <span style={{ color: "#999" }}>이메일 : </span>
                        {data?.email}
                      </FooterText>
                      <FooterText>
                        <span style={{ color: "#999" }}>
                          개인정보관리책임자 :{" "}
                        </span>
                        {data?.salesPartner}
                      </FooterText>
                    </FooterContent>
                    <CopyrightArea>
                      <FooterWidthLine />
                      <FooterText>
                        copyrightⓒ 2021 폰라인. All Rights Reserved.
                      </FooterText>
                    </CopyrightArea>
                  </div>
                  <FooterServiceArea>
                    <FooterContent style={{ flexDirection: "column" }}>
                      <FooterText style={{ color: "#999" }}>
                        서비스 지원센터
                      </FooterText>
                      <FooterText
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {data?.mainCall}
                      </FooterText>
                    </FooterContent>
                    <FooterContent style={{ flexDirection: "column" }}>
                      <FooterText style={{ color: "#999" }}>
                        운영시간
                      </FooterText>
                      <FooterText>{data?.live}</FooterText>
                    </FooterContent>
                  </FooterServiceArea>
                  <CopyrightArea2>
                    <FooterWidthLine />
                    <FooterText>
                      copyrightⓒ 2021 폰라인. All Rights Reserved.
                    </FooterText>
                  </CopyrightArea2>
                </FooterContentBox>
              </>
            )}
          </FooterBox>
        </FooterContainer>
      </>
    );
  } else {
    return <></>;
  }
};

export default withRouter(Footer);
