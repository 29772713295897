import { CreateTerms, UpdateTerms } from "Datas/api";
import { GetTermsGetById } from "Datas/swr";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Fade } from "react-reveal";
import Select from "react-select";
import styled from "styled-components";
const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;

const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-left: 30px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
`;

const Content = styled.div``;
const Detail = ({ match }) => {
  const TermsType = [
    {
      value: false,
      label: "회원가입",
    },
    { value: true, label: "개통신청" },
  ];
  const [data, setData] = useState({
    title: "",
    contents: "",
    order: 0,
    termstype: false,
  });
  const { TermsData, TermsMutate, isLoading } = GetTermsGetById(
    match?.params?.id
  );
  const [terms, setTerms] = useState({ value: false, label: "회원가입" });

  useEffect(() => {
    if (!isLoading) {
      if (TermsData) {
        setData({
          ...data,
          title: TermsData?.title,
          order: TermsData?.order,
          contents: TermsData?.contents,
          termstype: TermsData?.termstype,
        });
        if (TermsData?.termstype === false) {
          setTerms({ value: false, label: "회원가입" });
        } else {
          setTerms({ value: true, label: "개통신청" });
        }
      }
    }
  }, [TermsData]);
  const _handleCreate = async () => {
    if (data.title === "") {
      alert("제목을 제대로 입력해주세요");
    }
    data.termstype = terms.value;
    await CreateTerms(data);
    window.history.back();
  };
  const _handleUpdate = async () => {
    data.termstype = terms.value;
    await UpdateTerms(data, TermsData?.id);
    window.history.back();
  };
  return (
    <Fade Button>
      <Wrapper style={{ width: "100%" }}>
        <Top>
          <TopLabel>이용약관 정보</TopLabel>
          <TopButton>
            <Add
              onClick={() =>
                match?.params?.id === "add" ? _handleCreate() : _handleUpdate()
              }
            >
              완료
            </Add>
          </TopButton>
        </Top>
        <Content style={{ width: "100%" }}>
          <MainContainer>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>제목</td>
                <td>
                  <InputMain
                    style={{
                      paddingLeft: 15,
                    }}
                    name="title"
                    value={data.title}
                    onChange={(e) => {
                      setData({ ...data, title: e.target.value });
                    }}
                    placeholder="제목을 을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>분류</td>
                <td>
                  <Select
                    onChange={(e) => {
                      setTerms(e);
                    }}
                    value={terms}
                    options={TermsType}
                  />
                </td>
              </RightTable>

              <RightTable>
                <td>순서</td>
                <td>
                  <InputMain
                    type="number"
                    name="order"
                    value={data.order}
                    style={{
                      paddingLeft: 15,
                    }}
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        return;
                      }
                      setData({ ...data, order: e.target.value });
                    }}
                    placeholder="메뉴 순서를 입력해주세요"
                  />
                </td>
              </RightTable>
            </table>
          </MainContainer>
          <MainContainer>
            <p style={{ fontSize: 16, fontWeight: "bold", marginTop: 24 }}>
              내용
            </p>

            <textarea
              style={{
                width: "100%",
                boxSizing: "border-box",
                marginTop: 12,
                borderRadius: 7,
                backgroundColor: "#f4f4f4",
                border: "none",
                padding: 16,
                height: 294,
              }}
              cols="50"
              rows="10"
              value={data.contents}
              onChange={(e) => {
                setData({ ...data, contents: e.target.value });
              }}
            ></textarea>
          </MainContainer>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default Detail;
