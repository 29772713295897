import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import Select from "react-select";
import { withRouter } from "react-router";
import { createPlan, updatePlan } from "Datas/api";
import {
  GetPlanList,
  GetTelecomCategoryList,
  GetTelecomList,
  GettypeTelecomListDatas,
} from "Datas/swr";
import { Fade } from "react-reveal";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;
const TopButton = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-left: 30px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;
const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
  background: #fff;
  border-radius: 7px;
`;

const Content = styled.div`
  //margin: 15px 0 0 20px;
  //max-width: 690px;
`;

const UsageDetail = ({ match, history, location }) => {
  const [teleList, setTeleList] = useState([]);
  const [cateList, setCateList] = useState([]);
  const [telecom, setTelecom] = useState();
  // SWR
  const { TelecomListData } = GetTelecomList();
  const { PlanListData, PlanListMutate, isLoading } = GetPlanList(
    match?.params?.telecom
  );
  console.log(match);
  const {
    typeTelecomListDatas,
    typeTelecomListtErrors,
    typeTelecomListMutates,
    isLoadings,
  } = GettypeTelecomListDatas(match?.params?.telecom, 0);

  const { TelecomCategoryData, TelecomCategoryMutate } = GetTelecomCategoryList(
    telecom,
    0
  );

  console.log(location);

  const [datas, setDatas] = useState({
    telecomname: "",
    name: "",
    pricemonth: "",
    datarem: "",
    callrem: "",
    smsrem: "",
  });

  useEffect(() => {
    if (!isLoading && match?.params?.id !== "add") {
      setDatas({
        telecomid: location?.state?.telecomid,
        telecomname: location?.state?.telecomname,
        name: location?.state?.name,
        pricemonth: location?.state?.pricemonth,
        datarem: location?.state?.datarem,
        callrem: location?.state?.callrem,
        smsrem: location?.state?.smsrem,
        catename: location?.state?.catename,
        typetelecom_categoryid: location?.state?.typetelecom_categoryid,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (TelecomCategoryData !== undefined) {
      let newArray = [];
      if (TelecomCategoryData?.rows?.length !== 0) {
        for (let i = 0; i < TelecomCategoryData?.rows?.length; i++) {
          newArray.push({
            value: TelecomCategoryData?.rows[i].id,
            label: TelecomCategoryData?.rows[i].title,
          });
        }
        setCateList(newArray);
      }
    }
  }, [TelecomCategoryData]);

  useEffect(() => {
    if (TelecomListData !== undefined) {
      let newArray = [];
      if (TelecomListData.length !== 0) {
        for (let i = 0; i < TelecomListData.length; i++) {
          newArray.push({
            value: TelecomListData[i].id,
            label: TelecomListData[i].name,
          });
        }
        setTeleList(newArray);
      }
      if (match.params.id === "add") {
        let datas1 = TelecomListData?.map((item, idx) => {
          if (item.name === match?.params?.telecom) {
            return { value: item.id, label: item.name };
          }
        }).filter((item, idx) => {
          return item !== undefined;
        })[0];
        console.log(datas1, "데이터스1");
        console.log(match?.params?.telecom)
        setDatas({
          ...datas,
          telecomname: datas1?.label,
          telecomid: datas1?.value,
        });
        console.log('데이터스셋',datas1)
        setTelecom(datas1.value);
      }
    }
  }, [TelecomListData]);

  const _handleCreate = async () => {
    await createPlan(datas);
    history.goBack();
    await PlanListMutate();
  };

  const _handleUpdate = async () => {
    await updatePlan({ id: match?.params?.id, body: datas });
    history.goBack();
    await PlanListMutate();
  };
  console.log(match?.params);
  return (
    <Fade Button>
      <Wrapper style={{ width: "100%" }}>
        <Top>
          <TopLabel>요금제 정보</TopLabel>
          <TopButton>
            <Add
              onClick={() =>
                match?.params?.id === "add" ? _handleCreate() : _handleUpdate()
              }
            >
              완료
            </Add>
          </TopButton>
        </Top>
        <Content
          style={{
            width: "100%",
          }}
        >
          <MainContainer>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>통신사</td>
                <td>
                  <Select
                    value={{
                      value: datas?.telecomname,
                      label: datas?.telecomname,
                    }}
                    onChange={(e) => {
                      setTelecom(e.value);
                      setDatas({
                        ...datas,
                        telecomname: e.label,
                        telecomid: e.value,
                        catename: "",
                        typetelecom_categoryid: "",
                      });
                    }}
                    style={{ paddingLeft: 15 }}
                    options={teleList}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>카테고리</td>
                <td>
                  <Select
                    value={{
                      value: datas?.typetelecom_categoryid,
                      label: datas?.catename,
                    }}
                    onChange={(e) => {
                      setDatas({
                        ...datas,
                        catename: e.label,
                        typetelecom_categoryid: e.value,
                      });
                    }}
                    style={{ paddingLeft: 15 }}
                    options={cateList}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>요금제 명</td>
                <td>
                  <InputMain
                    placeholder="요금제명을 입력해주세요"
                    value={datas.name}
                    onChange={(e) =>
                      setDatas({ ...datas, name: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>납부금액 / 월</td>
                <td>
                  <InputMain
                    placeholder="월 기준 납부금액을 입력해주세요"
                    value={datas.pricemonth}
                    onChange={(e) =>
                      setDatas({ ...datas, pricemonth: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>데이터 용량</td>
                <td>
                  <InputMain
                    placeholder="데이터 용량을 입력해주세요"
                    value={datas.datarem}
                    onChange={(e) =>
                      setDatas({ ...datas, datarem: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>음성통화 용량</td>
                <td>
                  <InputMain
                    placeholder="음성통화 용량을 입력해주세요"
                    value={datas.callrem}
                    onChange={(e) =>
                      setDatas({ ...datas, callrem: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>문자 용량</td>
                <td>
                  <InputMain
                    placeholder="문자 용량을 입력해주세요"
                    value={datas.smsrem}
                    onChange={(e) =>
                      setDatas({ ...datas, smsrem: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
            </table>
          </MainContainer>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(UsageDetail);
