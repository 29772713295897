import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import Select from "react-select";
import { withRouter } from "react-router";
import { createdQa, createPlan, updatedQa, updatePlan } from "Datas/api";
import {
  GetPlanList,
  GetTelecomCategoryList,
  GetTelecomList,
  GettypeTelecomListDatas,
} from "Datas/swr";
import { Fade } from "react-reveal";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-left: 30px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
  background: #fff;
  border-radius: 7px;
`;

const Content = styled.div`
  //margin: 15px 0 0 20px;
  //max-width: 690px;
`;

const QaDetail = ({ match, history, location }) => {
  // SWR

  const [ArrayDatasQa, setArrayDatasQa] = useState({
    label: "신청 및 개통문의",
    value: 0,
  });
  const [datas, setDatas] = useState({
    title: "",
    contents: "",
    opttype: 0,
  });
  const opttypeData = [
    { label: "신청 및 개통문의", value: 0 },
    { label: "배송문의", value: 1 },
    { label: "취소 교환 반품문의", value: 2 },
  ];

  const _handleCreate = async () => {
    datas.opttype = ArrayDatasQa.value;
    //전송
    let res = await createdQa(datas);
    if (res !== undefined) {
      alert("QA 생성완료");
      await history.goBack();
    }
  };
  useEffect(() => {
    if (match.params.type !== "add") {
      setDatas({
        title: location.state.title,
        contents: location.state.contents,
      });
      let ArrayDatas = opttypeData
        .map((item, idx) => {
          if (item.value === Number(location?.state?.opttype)) {
            return { label: item.label, value: item.value };
          }
        })
        .filter((item, idx) => {
          return item !== undefined;
        })[0];
      setArrayDatasQa(ArrayDatas);
    }
  }, [location.state]);
  console.log("매치");
  const _handleUpdate = async () => {
    datas.opttype = ArrayDatasQa.value;
    let res = await updatedQa(datas, location.state.id);
    if (res !== undefined) {
      alert("수정 완료");
      await history.goBack();
    }
  };
  return (
    <Fade Button>
      <Wrapper style={{ width: "100%", height: 800 }}>
        <Top style={{ width: "100%", margin: "0px auto", marginTop: 25 }}>
          <TopLabel>QA 정보</TopLabel>
          <TopButton>
            <Add
              onClick={() =>
                match?.params?.type === "add"
                  ? _handleCreate()
                  : _handleUpdate()
              }
            >
              완료
            </Add>
          </TopButton>
        </Top>
        <Content
          style={{
            width: "100%",
          }}
        >
          <div style={{ width: "100%", margin: "0px auto" }}>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>분류</td>
                <td>
                  <Select
                    placeholder="분류"
                    onChange={(e) => setArrayDatasQa(e)}
                    value={ArrayDatasQa}
                    options={opttypeData}
                  />
                </td>
              </RightTable>

              <RightTable>
                <td>제목</td>
                <td>
                  <InputMain
                    placeholder="문의 제목을 입력해주세요"
                    value={datas.title}
                    onChange={(e) =>
                      setDatas({ ...datas, title: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>문의 내용</td>
                <td>
                  <InputMain
                    placeholder="문의 내용을 입력해주세요"
                    value={datas.contents}
                    onChange={(e) =>
                      setDatas({ ...datas, contents: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
            </table>
          </div>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(QaDetail);
