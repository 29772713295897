import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { withRouter } from "react-router";
import {
  createCategory,
  createPost,
  createPostrec,
  deleteBoardRec,
  updateBoardRec,
  updateCategory,
  updatePost,
  uploadImage,
} from "Datas/api";

import {
  GetUri,
  ImageHandler,
  ImageHandlermini,
  GetUrimini,
} from "Settings/imageHandler";

import { GetBoardList, GetBoardrecList, GetCategoryList } from "Datas/swr";
import { Fade } from "react-reveal";
import Editor from "Components/Editor";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;
const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;
const BannerImg = styled.img`
  max-width: 200px;
  height: 100px;
  border: none;
  resize: both;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: contain;
`;

const LabelImage = styled.label`
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  font-size: 17px;
  background: #6091ed;
  border-radius: 30px;
  padding: 9px 15px;
  color: #fff;
  margin-left: auto;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-left: 30px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 40px;
  border: none;
  width: 100%;
  //background: #f4f4f4;
  border-radius: 7px;
  /* &:hover {
    opacity: 0.75;
  }
  &:focus {
    border: 1.5px solid #a7a7a7;
  } */
`;

const Content = styled.div`
  //margin: 15px 0 0 20px;
  //max-width: 690px;
`;

const PostrecDetail = ({ match, history, location }) => {
  //SWR
  const [image, setImage] = useState([]);
  const [imagemini, setImagemini] = useState();
  const [imgBase64mini, setImgBase64mini] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);
  const { BoardListrecData, BoardListrecMutate, isLoading } = GetBoardrecList();
  const [filesdata, setfilesCheck] = useState([]);
  const { handleRemove, handleCreate } = ImageHandler({
    setImgBase64: setImgBase64,
    setImage: setImage,
  });
  const { handleRemovemini, handleCreatemini } = ImageHandlermini({
    setImagemini: setImagemini,
    setImgBase64mini: setImgBase64mini,
  });
  const [productBoardData, setProductBoardData] = useState(null);
  const [urls, setUrl] = useState([]);
  const [ur, setUrData] = useState([]);
  const [imagdata, setimgdata] = useState([]);
  const [imagdata64, setimgdata64] = useState([]);
  const [datas, setDatas] = useState({
    title: "",
    contents: "",
    images: [],
    titleimages: [],
    url: [],
  });

  const [files, setFiles] = useState([]);
  const onEditorChange = (value) => {
    setProductBoardData(value);
  };

  const onFilesChange = (files) => {
    setFiles(files);
  };

  useEffect(() => {
    if (!isLoading && match?.params?.id !== "add") {
      /* let res = BoardListrecData?.filter(
        (el, idx) => Number(el?.id) === Number(match?.params?.id)
      );
      console.log(res[0]); */
      setImgBase64(location?.state?.titleimages);
      setDatas({
        title: location?.state?.title,
        contents: location?.state?.contents,
        titleimages: location?.state?.titleimages,
        images: location?.state?.images,
      });
      setUrl(location?.state?.url);
      setimgdata64(location?.state?.images);
      setimgdata(location?.state?.images);
      setProductBoardData(location?.state?.contents);
 
    }
  }, [match?.params?.id, isLoading]);

  const _handleCreate = async () => {
    const { url } = await GetUri({ image: image });

    const ids = await Promise.all(
      imagdata.map((uri) => {
        if (!!uri.id) return uri.id;
        let formData = new FormData();

        formData.append("files", uri);
        return uploadImage(formData);
      })
    );
    datas.titleimages = url;
    datas.url = urls;
    datas.images = ids;
    datas.contents = productBoardData;
    await createPostrec(datas);
    history.goBack();
  };

  console.log(imagdata, "미니데이터");
  console.log(imagdata64, "미니");
  console.log(urls, "미니");
  const _handleUpdate = async () => {
    let URL;
    if (image?.length !== 0) {
      const { url } = await GetUri({ image: image });
      URL = url;
    } else {
      URL = datas?.titleimages;
    }
    let ids;
    if (imagdata.length !== 0) {
      ids = await Promise.all(
        imagdata.map((uri) => {
          if (uri.toString().indexOf("http") !== -1) return uri;
          let formData = new FormData();

          formData.append("files", uri);
          return uploadImage(formData);
        })
      );
    }
    datas.titleimages = URL;
    datas.url = urls;
    datas.images = ids;
    console.log(datas?.images);
    datas.contents = productBoardData;
    await updateBoardRec(match?.params?.id, datas);
    history.goBack();
  };
 
  const _AddProduct = () => {
    setimgdata([...imagdata, ...imagemini]);
    setUrl([...urls, ur]);
    setimgdata64([...imagdata64, ...imgBase64mini]);

    setUrData("");
    setImgBase64mini([]);
    setImagemini([]);
  };

  console.log(imgBase64mini);
  const _DeleteProduct = (value) => {
    const tempImage = imagdata?.filter((el, idx) => idx !== value);
    const tempUrl = urls?.filter((el, idx) => idx !== value);
    const tempImg64 = imagdata64?.filter((el, idx) => idx !== value);

    setimgdata(tempImage);
    setUrl(tempUrl);
    setimgdata64(tempImg64);
  };

 
  return (
    <Fade Button>
      <Wrapper>
        <Top>
          <TopLabel>이벤트 정보</TopLabel>
          <TopButton>
            <Add
              onClick={() =>
                match?.params?.id === "add" ? _handleCreate() : _handleUpdate()
              }
            >
              {match?.params?.id === "add" ? "완료" : "수정"}
            </Add>
          </TopButton>
        </Top>

        <Content
          style={{
            width: "100%",
          }}
        >
          <MainContainer>
            <InputLabel>기본 설정</InputLabel>
            <table
              id="customers"
              style={{
                width: "100%",
              }}
            >
              <RightTable>
                <td>제목</td>
                <td>
                  <InputMain
                    value={datas.title}
                    onChange={(e) =>
                      setDatas({ ...datas, title: e.target.value })
                    }
                    style={{
                      paddingLeft: 15,
                    }}
                    placeholder="제목을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>썸네일</td>
                <td>
                  {imgBase64?.length !== 0 ? (
                    imgBase64.map((item, idx) => {
                      return (
                        <BannerImg
                          onClick={() => handleRemove(idx)}
                          src={item}
                        ></BannerImg>
                      );
                    })
                  ) : (
                    <div style={{ paddingTop: 12, paddingBlock: 12 }}>
                      <LabelImage style={{ borderRadius: 0 }} for="upload-file">
                        썸네일 등록
                      </LabelImage>
                      <input
                        type="file"
                        name="upload-file"
                        style={{ display: "none" }}
                        onChange={handleCreate}
                        id="upload-file"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    name="upload-file"
                    style={{ display: "none" }}
                    onChange={handleCreate}
                    id="upload-file"
                  />
                </td>
              </RightTable>
            </table>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 15,
              }}
            >
              <InputLabel>상품 설정</InputLabel>
              <LabelImage
                onClick={() => _AddProduct()}
                style={{
                  marginBottom: 5,
                  marginTop: 30,
                  borderRadius: 0,
                }}
              >
                추가하기
              </LabelImage>
            </div>
            <table
              id="customers"
              style={{
                width: "100%",
              }}
            >
              <RightTable>
                <td>상품 URL</td>
                <td>
                  <InputMain
                    type="text"
                    placeholder="상품 주소를 입력해주세요"
                    value={ur}
                    onChange={(e) => setUrData(e.target.value)}
                  ></InputMain>
                </td>
              </RightTable>
              <RightTable>
                <td>이미지</td>
                <td>
                  {imgBase64mini?.length !== 0 ? (
                    imgBase64mini.map((el, idx) => {
                      return (
                        <BannerImg
                          onClick={() => handleRemovemini(idx)}
                          src={el}
                        ></BannerImg>
                      );
                    })
                  ) : (
                    <div style={{ paddingTop: 12, paddingBlock: 12 }}>
                      <LabelImage
                        style={{ borderRadius: 0 }}
                        for="upload-file1"
                      >
                        이미지 추가
                      </LabelImage>
                      <input
                        type="file"
                        name="upload-file1"
                        onChange={handleCreatemini}
                        style={{ display: "none" }}
                        id="upload-file1"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    name="upload-file1"
                    onChange={handleCreatemini}
                    style={{ display: "none" }}
                    id="upload-file1"
                  />
                </td>
              </RightTable>
            </table>

            <table
              id="customers"
              style={{
                width: "100%",
                marginTop: 25,
              }}
            >
              <thead>
                <RightTable>
                  <th>상품 URL</th>
                  <th>상품 이미지</th>
                  <th>삭제</th>
                </RightTable>
              </thead>
              <tbody>
                {urls?.map((item, idx) => {
                  return (
                    <RightTable>
                      <td>{item}</td>
                      <td>
                        <BannerImg src={imagdata64[idx]}></BannerImg>
                      </td>
                      <td
                        onClick={() => {
                          _DeleteProduct(idx);
                        }}
                      >
                        삭제
                      </td>
                    </RightTable>
                  );
                })}
              </tbody>
            </table>

            <InputLabel>내용</InputLabel>
            <Editor
              value={productBoardData}
              placeholder={"내용을 입력해주세요."}
              onEditorChange={onEditorChange}
              setfilesCheck={setfilesCheck}
              filesdata={filesdata}
              onFilesChange={onFilesChange}
            />
          </MainContainer>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(PostrecDetail);
