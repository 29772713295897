import styled from "styled-components";

const breakpoints = [600, 868, 992, 1200]; // 0 1 번쨰사용
const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

const FooterContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  background-color: #333;
  padding: 30px 0 100px;
  @media (max-width: 768px){
    margin-top: 50px;
    ${(props)=> props?.isToggle  ?`   padding-bottom: 20px;`  : `   padding-bottom: 90px;` }
  
  }
`;

const FooterBox = styled.div`
  width: 75%;
  margin: 0px auto;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${mq[0]} {
    flex-direction: column;
  }
  ${mq[1]} {
    flex-direction: column;
  }
  @media(max-width: 1300px) {
    width: 95%;
    padding-top: 0;
  }
`;

const FooterImg = styled.img`
  width: 268px;
  height: 48px;
  object-fit: contain;
  ${mq[1]} {
    width: 258px;
    object-fit: contain;
  }
  ${mq[0]} {
    width: 238px;
    object-fit: contain;
  }
`;

const FooterContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  ${mq[1]} {
    margin-left: 0px;
    margin-top: 10px;
    font-size: 14px;
  }
  ${mq[0]} {
    flex-direction: column;
    font-size: 14px;
  }
  @media(max-width: 990px){
    display: block;
  }
`;

const FooterContent = styled.div`
  color: white;
  display: flex;
  flex-wrap: wrap;
  line-height:18px;
  ${mq[1]} {
    flex-direction: column;
    font-size: 14px;
  };
`;

export const FooterWidthLine = styled.div`
  width: 100%;
  height: .7px;
  background: #999;
  margin: 15px 0 15px 15px;
  @media(max-width:1170px){
    width: 95%;
  }
`;

const FooterText = styled.div`
  margin: 2px 12px;
  font-weight:bold;
`;

const ImageView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 11px;
`;

const ConfirmImage = styled.img`
  width: 72px;
  height: 72px;
  margin-top: 48px;
  object-fit: contain;
  //background-color: #a7a7a7;
  ${mq[0]} {
    margin-top: 24px;
    width: 72px;
    height: 72px;
  }
`;

export const FooterServiceArea = styled.div`
  padding-left: 10px;
  border-left: 3px solid #999;
  flex-direction: column;
  @media(max-width: 990px){
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 18px
  }
`;

export const CopyrightArea = styled.div`
  color: white;
  display: flex;
  flex-wrap: wrap;
  line-height:18px;
  display: block;
  margin-top: 10px;
  @media(max-width:990px){
    display: none;
  }
`;
export const CopyrightArea2 = styled.div`
  color: #fff;
  display: none;
  margin-top: 10px;
  @media(max-width: 990px){
    display: block;
  }
`;

export {
  ConfirmImage,
  ImageView,
  FooterContainer,
  FooterBox,
  FooterImg,
  FooterContentBox,
  FooterContent,
  FooterText,
};
