import styled from "styled-components";
import Fade from "react-reveal";
import { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import {
  createCategory,
  createCategoryList,
  createInfo,
  createStore,
  createTelecomList,
  uploadImage,
} from "Datas/api";
import { Redirect } from "react-router-dom";
const NameCardAdd = styled.label`
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  font-size: 17px;
  background: #6091ed;
  border-radius: 30px;
  padding: 9px 15px;
  color: #fff;
  margin-left: auto;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;
const Images = styled.img`
  height: 155px;
  border: none;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  width: 100%;
  border-radius: 7px;
  //margin-bottom: 110px;
  object-fit: cover;
`;
const SettingPage = () => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [type, setType] = useState("Start");
  const [image, setImage] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);

  const [error, setError] = useState(undefined);
  const [data, setData] = useState({
    name: "",
    code: "",
    tel: "",
    email: "",
    gm: 2,
  });
  const [infoData, setInfoData] = useState({
    kakaoTalk: "",
    call: "",
    pagename: "",
    image: [],
  });
  const [message, setMessage] = useState("관리자 설정중");

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
        //Rogic
        if (seconds === 2) {
          setMessage("데이터 수정 처리중.");
        } else {
          setMessage(message + ".");
        }
        if (seconds === 1) {
          setMessage("업데이트가 완료되었습니다. 재접속 해주세요!");
        }

        //30초간격으로 진행시작
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  const _InitialSetting = async () => {
    const TelecomData = {
      telecomData: [{ name: "SKT" }, { name: "KT" }, { name: "LGU+" }],
    };
    const CategoryData = {
      CateData: [
        { name: "번호이동", order: "순서입력" },
        { name: "기기변경", order: "순서입력" },
        { name: "이벤트", order: "순서입력" },
      ],
    };

    await createTelecomList(TelecomData);
    await createCategoryList(CategoryData);
  };

  const _HahndleSetDataBase = async () => {
    if (
      data.name === "" ||
      data.code === "" ||
      data.tel === "" ||
      data.email === "" ||
      infoData.kakaoTalk === "" ||
      infoData.call === "" ||
      infoData.pagename === "" ||
      image.length === 0
    ) {
      let msg;
      if (data.name === "") {
        msg = "상점명을 입력해주세요";
      }
      if (data.code === "") {
        msg = "승인 코드를 입력해주세요";
      }
      if (data.tel === "") {
        msg = "휴대폰 번호를 입력해주세요";
      }
      if (data.email === "") {
        msg = "이메일을 입력해주세요";
      }
      if (infoData.kakaoTalk === "") {
        msg = "카카오톡 연결 채팅아이디를 입력해주세요";
      }
      if (infoData.call === "") {
        msg = "연결 전화번호를 입력해주세요";
      }
      if (infoData.pagename === "") {
        msg = "사이트 이름을 입력해주세요";
      }
      if (image.length === 0) {
        msg = "로고 이미지를 등록해주세요";
      }

      setError(msg);
      return;
    }
    console.log("검증식완료");
    data.code = Number(data.code);

    //uploadServer
    let result = await createStore(data);

    if (result !== undefined) {
      if (image.length > 0) {
        const ids = await Promise.all(
          image.map((uri) => {
            if (!!uri.id) return uri.id;
            let formData = new FormData();
            formData.append("files", uri);
            return uploadImage(formData);
          })
        );
        infoData.image = ids;
      }
      let result2 = await createInfo(infoData);
      if (result2 !== undefined) {
        _InitialSetting();
        setSeconds(6);
        setType("End"); //checker
      }
    } else {
      return;
    }
  };
  const handleImage = (e) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64([base64.toString()]);
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImage([e.target.files[0]]); //경로탈착
    }
  };
  const handleRemoveImage = (idx) => {
    setImage((images) => images.filter((el, index) => index !== idx));
    setImgBase64((imgBase64) => imgBase64.filter((el, index) => index !== idx));
  };
  return (
    <Fade Button>
      <MainView>
        <FlexBoxMain>
          <CardView>
            <Container>
              {type === "Start" ? (
                <>
                  <p style={{ fontWeight: "bold", marginTop: 24 }}>
                    관리자 정보 설정
                  </p>
                  <table id="customers" style={{ marginTop: 24 }}>
                    <tr>
                      <td>사이트명</td>
                      <td>
                        <input
                          style={{ border: "none", width: "100%" }}
                          type="text"
                          placeholder="사이트명을 입력해주세요"
                          value={infoData.pagename}
                          onChange={(e) =>
                            setInfoData({
                              ...infoData,
                              pagename: e.target.value,
                            })
                          }
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td>로고 이미지</td>
                      <td>
                        {imgBase64?.length !== 0 ? (
                          imgBase64.map((item, idx) => {
                            return (
                              <label for="upload-file">
                                <Images src={item} />
                              </label>
                            );
                          })
                        ) : (
                          <div style={{ padding: 8 }}>
                            <NameCardAdd for="upload-file">
                              이미지 추가
                            </NameCardAdd>
                          </div>
                        )}
                        <input
                          type="file"
                          name="upload-file"
                          style={{ display: "none" }}
                          onChange={handleImage}
                          id="upload-file"
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td>연결 전화번호</td>
                      <td>
                        <input
                          style={{ border: "none", width: "100%" }}
                          type="text"
                          placeholder="010-0000-0000"
                          value={infoData.call}
                          onChange={(e) =>
                            setInfoData({ ...infoData, call: e.target.value })
                          }
                        ></input>
                      </td>
                    </tr>

                    <tr>
                      <td>연결 카카오 링크</td>
                      <td>
                        <input
                          style={{ border: "none", width: "100%" }}
                          type="text"
                          placeholder="http://kakako"
                          value={infoData.kakaoTalk}
                          onChange={(e) =>
                            setInfoData({
                              ...infoData,
                              kakaoTalk: e.target.value,
                            })
                          }
                        ></input>
                      </td>
                    </tr>

                    <tr>
                      <td>상점명</td>
                      <td>
                        <input
                          style={{ border: "none", width: "100%" }}
                          type="text"
                          placeholder="초기 가맹상점이름"
                          value={data.name}
                          onChange={(e) =>
                            setData({ ...data, name: e.target.value })
                          }
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td>승인코드</td>
                      <td>
                        <input
                          style={{ border: "none", width: "100%" }}
                          type="text"
                          placeholder="가맹점 승인번호"
                          value={data.code}
                          onChange={(e) =>
                            setData({ ...data, code: e.target.value })
                          }
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td>휴대폰 번호 </td>
                      <td>
                        <input
                          style={{ border: "none", width: "100%" }}
                          type="text"
                          placeholder="초기 관리자 권한 발급용 휴대폰번호"
                          value={data.tel}
                          onChange={(e) =>
                            setData({ ...data, tel: e.target.value })
                          }
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td>이메일</td>
                      <td>
                        <input
                          style={{ border: "none", width: "100%" }}
                          type="text"
                          placeholder="이메일"
                          value={data.email}
                          onChange={(e) =>
                            setData({ ...data, email: e.target.value })
                          }
                        ></input>
                      </td>
                    </tr>
                  </table>
                  <OpgButton
                    onClick={() => {
                      _HahndleSetDataBase();
                    }}
                  >
                    설정
                  </OpgButton>
                  <p
                    style={{
                      color: "red",
                      fontSize: 16,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {error}
                  </p>
                </>
              ) : (
                <>
                  <div
                    style={
                      seconds !== 0
                        ? {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "70%",
                          }
                        : {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                          }
                    }
                  >
                    {seconds !== 0 ? (
                      <Loader
                        type="TailSpin"
                        color="orange"
                        height={50}
                        width={50}
                      />
                    ) : (
                      <p
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: 16,
                        }}
                      >
                        {message}
                      </p>
                    )}
                  </div>
                  {seconds !== 0 && (
                    <p
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        marginTop: -64,
                        fontSize: 16,
                      }}
                    >
                      {message}
                    </p>
                  )}
                </>
              )}
            </Container>
          </CardView>
        </FlexBoxMain>
      </MainView>
    </Fade>
  );
};

const MainView = styled.div`
  background-color: #f3f3f3;
  width: 100%;
  height: 100vh;
`;

const OpgButton = styled.button`
  display: flex;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  padding-bottom: 12px;
  margin-top: 34px;
  height: 50px;
  width: 100%;
  border: none;
  background-color: #33a0df;
  color: white;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;

const FlexBoxMain = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 90%;
  height: 100%;
  margin: 0 auto;
`;

const CardView = styled.div`
  box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%),
    0 4px 4px rgb(0 0 0 / 12%), 0 8px 8px rgb(0 0 0 / 12%),
    0 16px 16px rgb(0 0 0 / 12%);
  height: 650px;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    width: 90%;
    height: 600px;
  }
  width: 50%;
  background-color: #fff;
`;

export default SettingPage;
