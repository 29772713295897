import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import {
  CheckPhone,
  validateEmail,
  validatePassword,
  validatePasswordConfirm,
} from "Settings/common";
import {
  sends,
  userCreate,
  uploadImage,
  updateUser,
  SendSecure,
  sendsPw,
} from "Datas/api";
import { getStoarge, setStoarge, useAuth } from "Datas/storage";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { LoginUser } from "Datas/api";
import { ArrowBack } from "@material-ui/icons";
// import { BackStyles } from "Components/Register/Register";
import { BackIco, LogoIco } from "assets";

export const BackStyles = styled.label`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 515px;
  margin: 0px 12px;
  padding: 0 15px;
`;

const SignupText = styled.div`
  min-width: 116px;
  font-size: 23px;
  //padding-top: 1000px;
  margin-bottom: 15px;
  font-weight: 700;
  @media screen and (max-width: 450px) {
    font-size: 23px;
  }
  @media screen and (max-width: 360px) {
    font-size: 18px;
    margin: 2px 0 0 -10px;
  }
`;

const CredentialsInputText = styled.div`
  font-size: 25px;
  color: #838383;
  margin-top: 20px;

  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`;

const CredentialsInputInput = styled.input`
  min-width: 288px;
  width: 100%;
  margin-top: 5px;
  height: 60px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  color: #000;
  padding: 10px 10px 10px 20px;
  font-size: 15px;
`;

const SignupBtn = styled.div`
  width: 100%;

  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  height: 65px;
  color: #fff;
  background: #feb43c;
  border: 1px solid #feb43c;
  border-radius: 10px;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  @media screen and (max-width: 450px) {
    height: 45px;
    font-size: 18px;
  }
  margin-bottom: 80px;
`;

const SubTagButton = styled.div`
  min-width: 288px;
  width: 100%;
  height: 60px;
  :hover {
    opacity: 0.65;
  }
  cursor: pointer;
  color: #fff;
  background: ${(props) => {
    let color;
    if (props.able === true) {
      color = "#111";
    } else {
      color = "#eee";
    }
    return color;
  }};
  border-radius: 10px;
  margin-right: 12px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
`;

const TintBox = styled.p`
  min-width: 340px;
  color: red;
  margin-top: 12px;
`;

const FinwPw = ({ history, location, match }) => {
  const [isTimer, setIsTimer] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });

  const [auth, setAuth] = useState({
    id: null,
    authcode: "",
    userauth: "",
    auth: false,
    check: false,
  });
  const [seconds, setSeconds] = useState(parseInt(0));
  const [minutes, setMinutes] = useState(parseInt(5));

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  const _confrim = () => {
    if (auth.authcode == auth.userauth) {
      setAuth({ ...auth, check: true, auth: false });
      alert("인증 완료!");
    } else {
      alert("인증번호가 틀립니다");
    }
  };

  const _sends = async () => {
    //특점함수 호출
    setIsTimer(true);
    let result = await sendsPw({ tel: data.email });
    if (result === undefined) {
      alert("인증번호 발급실패 네트워크를 확인하여주세요");
      return;
    } else {
      alert("인증 번호 발급성공 인증을 진행하여주세요");
    }

    setAuth({
      ...auth,
      authcode: result.data.code,
      auth: true,
      id: result.data.id,
    }); //코드할당 및 인증상태
    //타이머 관련 세팅
  };

  //제거용
  //이미지 업로드 핸들러

  const _handleChangePassword = async () => {
    if (validatePasswordConfirm(data.password, data.password_confirmation)) {
      alert("패스워드를 확인하여주세요");
      return;
    }

    let res = await updateUser({
      id: auth.id,
      body: {
        password: data.password,
      },
    });

    if (res) {
      history.push("/");
    }
  };
  //로그인날려주기

  //이미지 업로더 만들어야함
  return (
    <Wrapper style={{ alignItems: "flex-start", marginTop:"150px" }}>
      <Inner>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <BackStyles
              onClick={() => {
                history.goBack();
              }}
              style={{ marginRight: 24 }}
            >
              {/* <ArrowBack style={{ width: 50, height: 50 }}></ArrowBack> */}
              <img src={BackIco} style={{
                width: 28, height: 28, marginRight: -5
              }} />
            </BackStyles>
            <SignupText>비밀번호 찾기</SignupText>
          </div>
          <img src={LogoIco} style={{ height: 28 }} />
        </div>
        {auth.check === false ? (
          auth.auth ? (
            <>
              <CredentialsInputText>인증번호 입력</CredentialsInputText>
              <CredentialsInputInput
                value={auth.userauth}
                onChange={(e) => setAuth({ ...auth, userauth: e.target.value })}
                placeholder="인증번호를 입력해주세요"
              />
              <TintBox>
                {minutes} 분 : {seconds} 초
              </TintBox>
              <div style={{ display: "flex" }}>
                <SubTagButton able onClick={() => _confrim()}>
                  인증
                </SubTagButton>
                <SubTagButton
                  able
                  onClick={() =>
                    setAuth({ ...auth, authcode: "", auth: false })
                  }
                >
                  재인증
                </SubTagButton>
              </div>
            </>
          ) : (
            <>
              {/* <CredentialsInputText>아이디 (휴대폰번호 입력)</CredentialsInputText> */}
              <CredentialsInputInput
                value={data.email}
                disabled={auth.check}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                placeholder="휴대폰 번호 입력(숫자만)"
              />
              {auth.check === false && (
                <>
                  {!CheckPhone(data.email) && (
                    <TintBox>휴대폰 번호 형식이 아닙니다.</TintBox>
                  )}

                  <SubTagButton able onClick={() => _sends()}>
                    {isTimer ? (
                      <Loader
                        type="TailSpin"
                        color="white"
                        height={35}
                        width={35}
                      />
                    ) : (
                      "인증"
                    )}
                  </SubTagButton>
                </>
              )}
            </>
          )
        ) : (
          <div>
            <CredentialsInputText>새로운 비밀번호 입력</CredentialsInputText>
            <CredentialsInputInput
              value={data.password}
              type="password"
              onChange={(e) => setData({ ...data, password: e.target.value })}
              placeholder="새로운 비밀번호를 입력해주세요"
              security={true}
            />
            <CredentialsInputInput
              value={data.password_confirmation}
              type="password"
              onChange={(e) =>
                setData({ ...data, password_confirmation: e.target.value })
              }
              placeholder="비밀번호 확인"
              security={true}
            />

            <p style={{ color: "red", fontSize: 16, fontWeight: "bold" }}>
              {validatePasswordConfirm(
                data.password,
                data.password_confirmation
              )}
            </p>
            <>
              <SubTagButton able onClick={() => _handleChangePassword()}>
                재설정
              </SubTagButton>
            </>
          </div>
        )}
      </Inner>
    </Wrapper>
  );
};

export default withRouter(FinwPw);
