import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { createBanner, updateBanner } from "Datas/api";
import { GetBannerList } from "Datas/swr";
import {
  GetUri,
  GetUrimini,
  ImageHandler,
  ImageHandlermini,
} from "Settings/imageHandler";
import { Fade } from "react-reveal";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
`;

const InputMaintext = styled.textarea`
  height: 345px;
  border: none;
  width: 100%;
  border-radius: 7px;
  margin-bottom: 110px;
`;

const Content = styled.div``;

const BannerImgLabel = styled.div`
  & > span:last-child {
    color: blue;
    text-decoration: underline;
    margin-left: auto;
  }
  display: flex;
  margin-bottom: 5px;
`;

const BannerImg = styled.img`
  width: 690px;
  background: #f0f0f0;
  height: 210px;
  @media screen and (max-width: 768px) {
    width: 90%;
    height: 200px;
  }
`;

const Button = styled.div`
  width: 47.5%;
  height: 45px;
  background-color: #a7a7a7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  :hover {
    opacity: 0.8;
  }
  cursor: pointer;
`;

const LabelImage = styled.label`
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  font-size: 17px;
  background: #6091ed;
  border-radius: 30px;
  padding: 9px 15px;
  color: #fff;
  margin-left: auto;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;
const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const BannerDetail = ({ match, history, location }) => {
  console.log(location?.state);

  const [datas, setDatas] = useState({
    order: 0,
    ison: true,
    image: [],
    url: "",
    mobileimage: "",
  });
  const [image, setImage] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);

  //SWR
  const { BannerListData, BannerListMutate, isLoading } = GetBannerList(0);

  //Image Hooks
  const { handleRemove, handleCreate } = ImageHandler({
    setImgBase64: setImgBase64,
    setImage: setImage,
  });

  const isEmptyArr = (arr) => {
    if (Array.isArray(arr) && arr.length === 0) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!isLoading && match?.params?.id !== "add") {
      /* console.log(BannerListData?.rows);
      let res = await BannerListData?.rows?.filter(
        (el, idx) => Number(el?.id) === Number(match?.params?.id)
      ); */

      setImgBase64(location?.state?.image);

      setDatas({
        order: location?.state?.order,
        ison: location?.state?.ison,
        image: location?.state?.image,
        url: location?.state?.url,
        mobileimage: location?.state?.mobileimage,
        mobileon: location?.state?.mobileon,
      });
    }
  }, [match?.params?.id]);

  const _handleCreate = async () => {
    const { url } = await GetUri({ image: image });

    datas.mobileon = false;

    datas.image = url;
    await createBanner(datas);
    await BannerListMutate();
    history.goBack();
    //뒤로가기
  };

  const _handleUpdate = async () => {
    if (image?.length !== 0) {
      const { url } = await GetUri({ image: image });

      datas.image = url;
    } else {
      datas.image = imgBase64;
    }

    datas.mobileon = false;

    await updateBanner({
      id: Number(match?.params?.id),
      body: datas,
    });
    await BannerListMutate();
    history.goBack();
  };

  return (
    <Fade Button>
      <Wrapper style={{ width: "100%" }}>
        <Top>
          <TopLabel>배너관리</TopLabel>
          <TopButton>
            <Add
              onClick={() =>
                match?.params?.id === "add" ? _handleCreate() : _handleUpdate()
              }
            >
              {match?.params?.id === "add" ? "배너 추가" : "수정"}
            </Add>
          </TopButton>
        </Top>
        <Content
          style={{
            width: "100%",
          }}
        >
          <MainContainer>
            <p style={{ color: "#666", fontSize: 12, marginTop: 5 }}>
              * 배너크기 : (350px) × (795px) 이상 권장
            </p>

            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>배너 이미지 </td>
                <td>
                  {imgBase64?.length !== 0 ? (
                    imgBase64.map((item, idx) => {
                      return (
                        <BannerImg
                          onClick={() => handleRemove(idx)}
                          src={item}
                        ></BannerImg>
                      );
                    })
                  ) : (
                    <div style={{ paddingTop: 12, paddingBlock: 12 }}>
                      <LabelImage style={{ borderRadius: 0 }} for="upload-file">
                        추가
                      </LabelImage>
                      <input
                        type="file"
                        name="upload-file"
                        style={{ display: "none" }}
                        onChange={handleCreate}
                        id="upload-file"
                      />
                    </div>
                  )}
                </td>
              </RightTable>
              <RightTable>
                <td>배너 순서</td>
                <td>
                  <InputMain
                    type="number"
                    placeholder="배너 순서를 입력해주세요"
                    value={datas.order}
                    style={{ paddingLeft: 15 }}
                    onChange={(e) =>
                      setDatas({ ...datas, order: e.target.value })
                    }
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>배너 링크</td>
                <td>
                  <InputMain
                    type="text"
                    placeholder="배너 링크를 설정해주세요 (/admin/banner/detail/14) 뒷자리만 기재"
                    value={datas.url}
                    style={{ paddingLeft: 15 }}
                    onChange={(e) =>
                      setDatas({ ...datas, url: e.target.value })
                    }
                  />
                </td>
              </RightTable>
            </table>
          </MainContainer>

          <MainContainer>
            <InputLabel>배너 활성화</InputLabel>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => setDatas({ ...datas, ison: true })}
                style={{
                  backgroundColor: datas.ison ? "#535353" : "#a7a7a7",
                }}
              >
                활성화
              </Button>
              <Button
                onClick={() => setDatas({ ...datas, ison: false })}
                style={{
                  backgroundColor: !datas.ison ? "#535353" : "#a7a7a7",
                }}
              >
                비활성화
              </Button>
            </div>
          </MainContainer>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(BannerDetail);
