import React, { Component } from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 570px;
  padding: 0 20px;
`;

const SignupText = styled.div`
  font-size: 35px;
  padding-top: 500px;
  @media screen and (max-width: 450px) {
    font-size: 25px;
  }
  margin-bottom: 15px;
  font-weight: 700;
`;

const CredentialsInputText = styled.div`
  font-size: 25px;
  color: #838383;
  margin-top: 20px;

  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`;

const CredentialsInputInput = styled.input`
  width: 100%;
  margin-top: 5px;
  height: 65px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  color: #000;
  padding: 10px;

  @media screen and (max-width: 450px) {
    height: 45px;
  }
`;

const SignupBtn = styled.div`
  width: 100%;
  height: 65px;
  color: #fff;
  background: #4b52bc;
  border-radius: 10px;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  @media screen and (max-width: 450px) {
    height: 45px;
    font-size: 18px;
  }
  margin-bottom: 80px;
`;

const NameCardVerification = styled.div`
  margin-top: 20px;
`;

const NameCardTop = styled.div`
  display: flex;
  align-items: center;
`;

const NameCardText = styled.div`
  color: #838383;
  font-size: 25px;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`;

const NameCardAdd = styled.div`
  font-size: 20px;
  background: #4b52bc;
  border-radius: 30px;
  padding: 7px 14px;
  color: #fff;
  margin-left: auto;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;

const NameCardBox = styled.div`
  display: flex;
  width: 335px;
  height: 175px;
  background: #d1d1d1;
  margin-top: 10px;

  @media screen and (max-width: 450px) {
    width: 216px;
    height: 113px;
  }
`;

class Register extends Component {
  render() {
    return (
      <Wrapper>
        <Inner>
          <SignupText>가입정보</SignupText>
          <CredentialsInputText>아이디 (휴대폰번호 입력)</CredentialsInputText>
          <CredentialsInputInput placeholder="아이디를 입력해주세요" />

          <CredentialsInputText>비밀번호</CredentialsInputText>
          <CredentialsInputInput placeholder="비밀번호를 입력해주세요" />

          <CredentialsInputText>비밀번호 확인</CredentialsInputText>
          <CredentialsInputInput placeholder="비밀번호를 입력해주세요" />

          <CredentialsInputText>소속사 명</CredentialsInputText>
          <CredentialsInputInput placeholder="소속사명을 입력해주세요" />

          <CredentialsInputText>이메일</CredentialsInputText>
          <CredentialsInputInput
            type="email"
            placeholder="이메일을 입력해주세요"
          />

          <CredentialsInputText>인증코드</CredentialsInputText>
          <CredentialsInputInput placeholder="인증코드를 입력해주세요" />

          <NameCardVerification>
            <NameCardTop>
              <NameCardText>명함 인증</NameCardText>
              <NameCardAdd>추가</NameCardAdd>
            </NameCardTop>
            <NameCardBox />
          </NameCardVerification>

          <SignupBtn>회원가입</SignupBtn>
        </Inner>
      </Wrapper>
    );
  }
}

export default Register;
