import { CircleChkIco, SuccessIcon } from "assets";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const breakpoints = [450, 768, 992, 1200]; // 0 1 번쨰사용
const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

const Wrapper = styled.div``;

const OpenSuccessInner = styled.div`
  margin: 0 auto;
  border: 1px solid #f4125c;
  border-radius: 10px;
  padding: 30px 50px;
  text-align: center;
  @media(max-width: 500px){
    padding: 30px;
    width: 100%;
    border: 0;
  }
`;

const SuccessImage = styled.img`
  width: 60px;
  resize: both;
  object-fit: contain;
  margin-bottom: 20px;
  @media screen and (max-width: 450px) {
    width: 60px;
  }
`;

const SuccessTextBox = styled.div`
  font-size: 30px;
  font-weight: 600;
  ${mq[0]} {
    font-size: 27px;
  }
  ${mq[1]} {
    font-size: 27px;
  }
`;

const SuccessTextDesc = styled.div`
  text-align: center;
  margin: 24px 0 40px;
  word-break: keep-all;
  font-size: 16px;
  color: #666;
`;

const Success = () => {
  return (
    <Wrapper
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <OpenSuccessInner>
        <SuccessImage src={CircleChkIco} />
        <SuccessTextBox>개통신청 완료!</SuccessTextBox>
        <SuccessTextDesc>
          수정사항 및 개통 취소는<br />카카오톡 고객센터및 전화 부탁드려요<br />
          [ 개통 완료 페이지 ]<br/>
          담당자를 통해서 해피콜이 진행 됩니다.
        </SuccessTextDesc>
        {/* <SuccessImage src={SuccessIcon} /> */}
        <div style={{
          display: "flex", alignItems: "center", justifyContent: "center"
        }}>
          <div
            style={{
              padding: "15px 25px",
              borderRadius: 10,
              backgroundColor: "#f4125c",
              color: "#fff",
              marginRight: 10,
            }}
          >
            <Link to="/">
              <span className="homeTo1">홈으로 이동하기</span>
              <span className="homeTo2">홈으로<br />이동하기</span>
            </Link>
          </div>
          <div
            style={{
              padding: "15px 25px",
              borderRadius: 10,
              backgroundColor: "#fff",
              border: "1px solid #000",
              color: "#000",
            }}
          >
            <Link to="/contractList">
              <span className="contListTo1">주문내역 조회하기</span>
              <span className="contListTo2">주문내역<br />조회하기</span>
            </Link>
          </div>
        </div>
      </OpenSuccessInner>
    </Wrapper>
  );
};

export default Success;
