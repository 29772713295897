import React from "react";
import PropTypes from "prop-types";
// Material-ui 컴포넌트들
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { HomeIcon } from "assets";
import styled from "styled-components";
import { useHistory } from "react-router";
const drawerWidth = 240; // 메뉴 Drawer 가로크기
const useStyles = makeStyles((theme) => ({
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: "#3c4b64",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  searchBox: {
    position: "relative",
    backgroundColor: "#3c4b64",
    width: "256px",
    height: "38px",
    borderRadius: "19px",
    lineHeight: "38px",
    padding: "0 40px 0 24px",
  },
  searchField: {
    backgroundColor: "#eceff1",
    width: "100%",
    border: "none",
    fontSize: "1.2em",
  },
  searchIcon: {
    position: "absolute",
    top: "7px",
    right: "15px",
    color: "#304ffe",
  },
}));
const Icons = styled.img`
  width: 30px;
  height: 30px;
  resize: both;
  &:hover {
    opacity: 0.5;
  }
`;
const MobileBox = styled.div`
  display: none;

  @media (max-width: 768px) {
    background-color: #fff;
    display: block;
  }
`;
const Header = ({ onDrawerToggle }) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar} color="default">
      <Toolbar>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <a href="/">
              <Icons src={HomeIcon} style={{ marginRight: 15 }} />
            </a>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  onDrawerToggle: PropTypes.func,
};

export default Header;
