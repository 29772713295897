import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { withRouter } from "react-router";
import { createCategory, updateCategory } from "Datas/api";
import { GetCategoryList } from "Datas/swr";
import { Fade } from "react-reveal";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;
const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const TopButton = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-left: 30px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
`;

const Content = styled.div``;

const CategoryDetail = ({ match, history, location }) => {
  //SWR
  const { CategoryListData, CategoryListMutate, isLoading } = GetCategoryList();

  const [datas, setDatas] = useState({
    name: "",
    order: 0,
  });

  useEffect(() => {
    if (!isLoading && match?.params?.id !== "add") {
      /* let res = CategoryListData?.filter(
        (el, idx) => Number(el?.id) === Number(match?.params?.id)
      ); */
      setDatas({
        name: location?.state?.name,
        order: location?.state?.order,
      });
    }
  }, [match?.params?.id]);

  const _handleCreate = async () => {
    await createCategory(datas);
    history.goBack();
  };

  const _handleUpdate = async () => {
    await updateCategory({
      id: Number(match?.params?.id),
      body: datas,
    });
    history.goBack();
  };

  return (
    <Fade Button>
      <Wrapper style={{ width: "100%" }}>
        <Top>
          <TopLabel>카테고리 정보</TopLabel>
          <TopButton>
            <Add
              onClick={() =>
                match?.params?.id === "add" ? _handleCreate() : _handleUpdate()
              }
            >
              완료
            </Add>
          </TopButton>
        </Top>
        <Content style={{ width: "100%" }}>
          <MainContainer>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>메뉴명</td>
                <td>
                  <InputMain
                    value={datas.name}
                    onChange={(e) =>
                      setDatas({ ...datas, name: e.target.value })
                    }
                    style={{
                      paddingLeft: 15,
                    }}
                    placeholder="메뉴명을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>순서</td>
                <td>
                  <InputMain
                    type="number"
                    value={datas.order}
                    onChange={(e) =>
                      setDatas({ ...datas, order: e.target.value })
                    }
                    style={{
                      paddingLeft: 15,
                    }}
                    placeholder="메뉴 순서를 입력해주세요"
                  />
                </td>
              </RightTable>
            </table>
          </MainContainer>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(CategoryDetail);
