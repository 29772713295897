import React, { useState } from "react";
/* import { Fade } from "react-reveal";
import styled from "styled-components";
import { sendPushNoti } from "../../../Datas/api"; */
import { sendPushNoti } from "../../../Datas/api";
import styled from "styled-components";
import { GetBoardList, GetPushList } from "Datas/swr";
import { Link } from "react-router-dom";
import { deletePost } from "Datas/api";
import { Fade } from "react-reveal";
import CustomPagination from "Settings/pagination";
import { useConfirm } from "Settings/util";
import { FormControl } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { useEffect } from "react";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 50px 0 20px;
  @media screen and (max-width: 1344px) {
    flex-direction: column;
    width: 100%;
  }
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
  background: #f4f4f4;
  border-radius: 7px;
`;

const InputTextArea = styled.textarea`
  height: 293px;
  padding: 16px;
  width: 100%;
  border-radius: 7px;
  background-color: #f4f4f4;
  border: none;
`;

const InputMaintext = styled.textarea`
  height: 345px;
  border: none;
  width: 100%;
  background: #f4f4f4;
  border-radius: 7px;
  margin-bottom: 110px;
`;

const Content = styled.div`
  margin: 15px 0 0 20px;
  max-width: 690px;
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
  @media screen and (max-width: 1344px) {
    flex-direction: column;
    width: 100%;
  }
`;

const Add = styled.div`
  display: flex;
  background: ${(props) => {
    if (props.mode === true) {
      return "red";
    }
    if (props.mode === false) {
      return "white";
    }
    return "#6091ed";
  }};
  border: ${(props) => {
    if (props.mode === false) {
      return "1px solid #ddd";
    }
  }};
  padding: 10px 40px;
  color: ${(props) => {
    if (props.mode === false) {
      return "black";
    }
    return "#fff";
  }};
  margin-right: 20px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  @media screen and (max-width: 1344px) {
    margin-top: 12px;
  }
`;

const Delete = styled.div`
  display: flex;
  background: #ed6060;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const HoverTag = styled.td`
  border-bottom: 1.5px solid #eee;
  &:hover {
    opacity: 0.5;
  }
`;

const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;

const Pushnoti = ({ profile }) => {
  const [datas, setDatas] = useState({
    title: "",
    contents: "",
    url: "",
    mode: "회원",
    selectGroupId: null,
  });
  const { PushListsData, PushListsMutate } = GetPushList({ mode: datas?.mode });
  const [selects, setSelects] = useState([]);
  const _handleSend = async () => {
    if (datas?.selectGroupId === null && datas?.mode !== "회원") {
      alert("회원 또는 가맹점을 선택해주세요.");
      return;
    }
    await sendPushNoti(datas);
  };
  useEffect(() => {
    if (PushListsData) {
      const res = PushListsData?.data?.map((item, idx) => {
        return { label: item?.name, value: item?.id };
      });
      setSelects(res);
    }
  }, [PushListsData]);

  //관리자권한가져오기
  return (
    <Fade Button>
      <Wrapper>
        <Top>
          <TopLabel>푸쉬알림 전송</TopLabel>
          <TopButton>
            <Add onClick={() => _handleSend()}>발송</Add>
          </TopButton>
        </Top>
        <Content>
          <TopButton>
            <Add
              mode={datas.mode === "회원"}
              onClick={() => setDatas({ ...datas, mode: "회원" })}
            >
              회원 발송
            </Add>
            {profile?.DLMode === false && (
              <>
                {profile?.gm === 1 && (
                  <Add
                    mode={datas.mode === "딜러"}
                    onClick={() => setDatas({ ...datas, mode: "딜러" })}
                  >
                    딜러 발송
                  </Add>
                )}
              </>
            )}

            {profile?.DLMode === false && (
              <>
                {profile?.gm > 1 && ( //최종관리자
                  <Add
                    mode={datas.mode === "가맹"}
                    onClick={() => setDatas({ ...datas, mode: "가맹" })}
                  >
                    가맹 발송
                  </Add>
                )}
              </>
            )}
          </TopButton>
          {datas?.mode !== "회원" && (
            <>
              <FormControl>
                <InputLabel id="store-label">발송대상</InputLabel>
                <Select
                  value={datas?.selectGroupId}
                  onChange={(e) => {
                    setDatas({ ...datas, selectGroupId: e.target.value });
                  }}
                  labelId="store-label"
                  id="store-select"
                >
                  {selects?.map((item, idx) => {
                    return (
                      <MenuItem value={item.value}>{item?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          )}
          <InputLabel>제목</InputLabel>
          <InputMain
            value={datas?.title}
            onChange={(e) => setDatas({ ...datas, title: e.target.value })}
            style={{
              paddingLeft: 15,
              marginTop: 12,
            }}
            placeholder="제목을 입력해주세요"
          />
          <InputLabel>내용</InputLabel>
          <InputTextArea
            value={datas?.contents}
            onChange={(e) => setDatas({ ...datas, contents: e.target.value })}
            style={{
              paddingLeft: 15,
              marginTop: 12,
            }}
            placeholder="내용을 입력해주세요"
          />
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default Pushnoti;
