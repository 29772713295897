import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { withRouter } from "react-router";
import { createCard, updateCard } from "Datas/api";
import { GetCardList, GetTelecomList } from "Datas/swr";
import { Fade } from "react-reveal";
import Select from "react-select";
import { GetUri, ImageHandler } from "Settings/imageHandler";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;
const TopButton = styled.div`
  display: flex;
  margin-left: auto;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;
const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 40px;
  border: none;
  width: 100%;
  background: transparent;
  border-radius: 7px;
  padding-left: 15px;
`;

const SelectMain = styled.select`
  height: 45px;
  border: none;
  width: 100%;
  background: #f4f4f4;
  border-radius: 7px;
  padding-left: 15px;
`;

const InputMaintext = styled.textarea`
  height: 345px;
  border: none;
  width: 100%;
  background: #f4f4f4;
  border-radius: 7px;
  margin-bottom: 40px;
`;

const Content = styled.div`
  //margin: 15px 0 0 20px;
  //max-width: 690px;
`;

const ProductSupportCOM = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  width: 25%;
  max-width: 690px;
  & > input {
    height: 45px;
    border: none;
    background: #f4f4f4;
    border-radius: 7px;
  }
`;

const ProductSupportCOMLabel = styled.div``;

const ProductSupportDC = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 690px;
  width: 65%;
  & > input {
    height: 45px;
    border: none;
    background: #f4f4f4;
    border-radius: 7px;
  }
`;

const Flex = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;
  margin-bottom: 80px;
  max-width: 690px;
`;

const ProductSupportDCLabel = styled.div``;

const ProductSupportADD = styled.div`
  width: 10%;
  display: flex;
  margin-left: 15px;
  background: #6c74f4;
  align-items: center;
  justify-content: center;
  padding: 6px 11px;
  color: #fff;
  border-radius: 7px;
`;

const ProductSupportDiscuntLabel = styled.div`
  margin-top: 35px;
  margin-bottom: 5px;
`;

const ProductSupportDiscountInput = styled.div`
  background: #f4f4f4;
  height: 50px;
  display: flex;
  align-items: center;
  /* max-width: 690px; */
  border-radius: 7px;
  & > input {
    height: 100%;
    border: none;
    background: transparent;
    padding: 0 15px;
  }
  & > span:nth-child(3) {
    color: red;
    margin-left: auto;
  }
  width: ${(props) => {
    if (props.L) {
      return "20%";
    }
    if (props.R) {
      return "30%";
    }
  }};
  padding: 0 15px;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const LabelImage = styled.label`
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  font-size: 15px;
  background: #6091ed;
  border-radius: 5px;
  padding: 9px 15px;
  color: #fff;
  margin-left: auto;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;

const Imgskeleton = styled.img`
  max-width: 350px;
  height: 175px;
  border: none;
  resize: both;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: contain;
  &:hover {
    opacity: 0.75;
  }
`;

const ProductMemoryInputAddBtn = styled.div`
  background: #6c74f4;
  padding: 6px 11px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
  //margin-left: 10px;
`;

const SalesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const FLexBleButton = styled.div`
  background-color: #6091ed;
  height: 45px;
  width: 100%;
  color: #fff;
  font-size: 15px;
  border-radius: 7px;
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CardDetail = ({ match, history, location }) => {
  const [teleList, setTeleList] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);
  const [image, setImage] = useState([]);

  //SWR
  const { TelecomListData } = GetTelecomList();
  const { CardListData, CardListMutate, isLoading } = GetCardList(
    match?.params?.telecomname !== "new" ? match?.params?.telecomname : null
  );

  //Custom Hooks
  const { handleRemove, handleCreate } = ImageHandler({
    setImgBase64: setImgBase64,
    setImage: setImage,
  });

  const [price, setPrice] = useState({
    id: 0,
    last: "",
    discount: "",
  });
  const [datas, setDatas] = useState({
    telecomid: null,
    telecomname: "",
    link: "",
    name: "",
    price: [],
    tel: "",
    images: [],
  });
 
  useEffect(() => {
    if (!isLoading && match?.params?.id !== "add") {
      /* let res = CardListData?.filter(
        (el, idx) => Number(el?.id) === Number(match?.params?.id)
      ); */
      setDatas({
        telecomid: location?.state?.telecomid,
        telecomname: location?.state?.telecomname,
        name: location?.state?.name,
        price: location?.state?.price,
        tel: location?.state?.tel,
        images: location?.state?.images,
        link: location?.state?.link,
      });
      setImgBase64(location?.state?.images);
    }
  }, [match?.params, isLoading]);

  useEffect(() => {
    if (TelecomListData !== undefined) {
      let newArray = [];
      if (TelecomListData.length !== 0) {
        for (let i = 0; i < TelecomListData.length; i++) {
          newArray.push({
            value: TelecomListData[i].id,
            label: TelecomListData[i].name,
          });
        }
        setTeleList(newArray);
      }
    }
  }, [TelecomListData]);

  const _handlePriceAdd = (id) => {
    let temp = [...datas.price];
    temp.push({
      id: id,
      last: price.last,
      discount: price.discount,
    });
    setDatas({ ...datas, price: temp });
  };

  const _handlePriceDel = async (idxs) => {
  console.log('여기눌림')
    let res = await datas?.price?.filter(
      (el, idx) => Number(idx) !== Number(idxs)
    );
    setDatas({ ...datas, price: res });
  };

  const _handleCardAdd = async () => {
    let priceData = await datas?.price?.filter((el, idx) => delete el?.id);
    const { url } = await GetUri({ image: image });
    datas.images = url;
    let body = {
      telecomid: datas.telecomid,
      telecomname: datas.telecomname,
      name: datas.name,
      price: priceData,
      tel: datas.tel,
      images: datas.images,
      link: datas.link,
    };
    await createCard(body);
    history.goBack();
  };

  const _handleCardUpdate = async () => {
    let priceData = await datas?.price?.filter((el, idx) => delete el?.id);
    if (image?.length !== 0) {
      const { url } = await GetUri({ image: image });
      datas.images = url;
      console.log(url);
    } else {
      datas.images = imgBase64;
    }
    let body = {
      telecomname: datas.telecomname,
      name: datas.name,
      price: priceData,
      tel: datas.tel,
      images: datas.images,
      link: datas.link,
    };
    await updateCard({ id: Number(match?.params?.id), body: body });
    history.goBack();
  };

  return (
    <Fade Button>
      <Wrapper>
        <Top>
          <TopLabel>제휴카드 관리</TopLabel>
          <TopButton>
            <Add
              onClick={() =>
                match?.params?.id === "add"
                  ? _handleCardAdd()
                  : _handleCardUpdate()
              }
            >
              {match?.params?.id === "add" ? "완료" : "수정"}
            </Add>
          </TopButton>
        </Top>
        <Content
          style={{
            width: "100%",
          }}
        >
          <MainContainer>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>통신사</td>
                <td>
                  <Select
                    styles={{ width: "100%" }}
                    value={{
                      value: datas?.telecomid,
                      label: datas?.telecomname,
                    }}
                    onChange={(e) =>
                      setDatas({
                        ...datas,
                        telecomname: e.label,
                        telecomid: e.value,
                      })
                    }
                    options={teleList}
                  ></Select>
                </td>
              </RightTable>
              <RightTable>
                <td>카드이미지</td>
                <td style={{ padding: 10 }}>
                  {imgBase64?.length !== 0 ? (
                    imgBase64.map((item, idx) => {
                      return (
                        <Imgskeleton
                          onClick={() => handleRemove(idx)}
                          src={item}
                        />
                      );
                    })
                  ) : (
                    <div style={{ paddingTop: 12, paddingBlock: 12 }}>
                      <LabelImage for="upload-file">추가</LabelImage>
                      <input
                        type="file"
                        name="upload-file"
                        style={{ display: "none" }}
                        onChange={handleCreate}
                        id="upload-file"
                      />
                    </div>
                  )}
                </td>
              </RightTable>
              <RightTable>
                <td>카드명</td>
                <td>
                  <InputMain
                    value={datas.name}
                    onChange={(e) =>
                      setDatas({ ...datas, name: e.target.value })
                    }
                    placeholder="카드명을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>고객센터 전화번호</td>
                <td>
                  <InputMain
                    value={datas.tel}
                    onChange={(e) =>
                      setDatas({ ...datas, tel: e.target.value })
                    }
                    placeholder="고객센터 전화번호를 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>카드 링크</td>
                <td>
                  <InputMain
                    value={datas.link}
                    onChange={(e) =>
                      setDatas({ ...datas, link: e.target.value })
                    }
                    placeholder="고객센터 주소를 입력해주세요"
                  />
                </td>
              </RightTable>
            </table>
            <div style={{ marginTop: 25 }}>
              <InputLabel>할인금</InputLabel>
              <SalesContainer
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ProductSupportDiscountInput L>
                  <input
                    style={{ width: "100%" }}
                    value={price?.last}
                    onChange={(e) =>
                      setPrice({ ...price, last: e.target.value })
                    }
                    placeholder="전월실적을 입력해주세요"
                  />
                </ProductSupportDiscountInput>
                <p style={{ fontWeight: "bold" }}>이상</p>
                <ProductSupportDiscountInput R>
                  <input
                    style={{ width: "100%" }}
                    value={price?.discount}
                    onChange={(e) =>
                      setPrice({ ...price, discount: e.target.value })
                    }
                    placeholder="할인금을 입력해주세요"
                  />
                </ProductSupportDiscountInput>
                <p style={{ fontWeight: "bold" }}>X 24개월</p>
                <FLexBleButton
                  style={{display:'flex',justifyContent:'center',alignItems:'center',width:'25'}}
                  onClick={() => _handlePriceAdd(datas?.price?.length)}
                >
                  추가
                </FLexBleButton>
              </SalesContainer>
              <table
                id="customers"
                style={{
                  marginTop: 24,
                  width: "100%",
                }}
              >
                <thead>
                  <RightTable>
                    <td>전월실적</td>
                    <td>할인금 / 월</td>
                    <td>삭제</td>
                  </RightTable>
                </thead>
                <tbody>
                  {datas.price.map((el, idx) => {
                    return (
                      <RightTable>
                        <td>
                          <div style={{ margin: "10px 0px" }}>{el.last}</div>
                        </td>
                        <td>
                          <div style={{ margin: "10px 0px" }}>
                            {el.discount}
                          </div>
                        </td>
                        <td>
                          <ProductMemoryInputAddBtn
                            style={{ backgroundColor: "red" }}
                            onClick={() => _handlePriceDel(idx)}
                          >
                            삭제
                          </ProductMemoryInputAddBtn>
                        </td>
                      </RightTable>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </MainContainer>
        </Content>
       
      </Wrapper>
    </Fade>
  );
};

export default withRouter(CardDetail);
