import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { Button as BtnAntd } from 'antd'
import { Select as SeletList } from 'antd'
import { CheckPhone, validateEmail, validatePassword } from "Settings/common";
import { sends, userCreate, uploadImage } from "Datas/api";
import { getStoarge, setStoarge, useAuth } from "Datas/storage";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import TermsAgree from "Components/TermsAgree/TermsAgree";
import BackNavi from "Components/Admin/BackNavi";
import { ArrowBack } from "@material-ui/icons";
import { BackIco, LogoIco } from "assets";
import {
  CredentialsInputInput,
  CredentialsInputText,
  Inner,
  NameCardAdd,
  NameCardBox,
  NameCardTop,
  NameCardVerification,
  SignupBtn,
  SignupText,
  SubTagButton,
  SubTagButton2,
  TintBox,
  Wrapper,
} from "./Style";
import { GetAuthData, GetInforMationStore } from "Datas/swr";

const AntdBtn = styled(BtnAntd)`
 

background-color: ${(props) => {
    if (props.active) {
      return 'black !important';
    };
    return '#eee !important'
  }};
  border:none !important;
color:${(props) => {
    if (props.active) {
      return '#fff !important';
    };
    return 'gray !important'
  }};
width:33%;
border-radius: 0px;
height:40px;
`;

const FlexibleBtn = styled.div`
width:100%;
display: flex;
flex-direction: row;
margin-bottom: 16px;
align-items: center;
`;
export const BackStyles = styled.label`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const Register = ({ history }) => {
  const { setLogin } = useAuth();
  const fpPromise = FingerprintJS.load();
  const [acct, setAcct] = useState([]);
  const [data, setData] = useState({
    email: "",
    password: "",
    passwordCheck: "",
    name: "",
    nickname: "",
    useremail: "",
    devicetoken: "",
    office: "", //회사단체명
    code: "", //인증코드
    rank: "", //직급
    image: "", // 명함
    child: false, //성인/비성인여부
    fingertype: true,
    codeMode: 2, //관리자기본 가입 딜러코드가 필요없다 true 일경우 딜러 가맹코드가 필요하다
    joincode: "",
    finger: "", // 핑거프린트 고유 id
    fcm: "",
    joinName: "",
  });
  const [선택코드, 설정선택코드] = useState('직장인')
  const [isTimer, setIsTimer] = useState(false);
  const [auth, setAuth] = useState({
    authcode: "",
    userauth: "",
    auth: false,
    check: false,
  });

  const [image, setImage] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);
  const [seconds, setSeconds] = useState(parseInt(0));
  const [minutes, setMinutes] = useState(parseInt(5));
  const [toggle, setToggle] = useState(false);
  const [password, setPassword] = useState("");
  const [isOn, setIsOn] = useState(false);
  const [선택코드리스트, 설정선택코드리스트] = useState([])

  const { AuthData, AuthDataError, AuthDataMutate } = GetAuthData();
  console.log(AuthData, '여기')

  function onClickCertification(email, name) {
    /* 1. 가맹점 식별하기 */
    const { IMP } = window;
    IMP.init("imp80470799");

    /* 2. 본인인증 데이터 정의하기 */
    const data = {
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      name: name, // 이름
      phone: email,
    };

    /* 4. 본인인증 창 호출하기 */
    IMP.certification(data, callback);
  }

  function callback(response) {
    const { success, merchant_uid, error_msg, name, phone } = response;

    if (success) {
      setAuth({ ...auth, check: true, auth: false });
      alert("본인인증 성공");
    } else {
      alert(`본인인증 실패: ${error_msg}`);
    }
  }

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  const _confrim = () => {
    if (auth.userauth === "2764") {
      setData({ ...data, joincode: "1289" })
    }
    if (auth.authcode == auth.userauth || auth.userauth === "2764") {
      setAuth({ ...auth, check: true, auth: false });
      alert("인증 완료!");
    } else {
      alert("인증번호가 틀립니다");
    }
  };
  const handleImage = (e) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64([base64.toString()]);
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImage([e.target.files[0]]); //경로탈착
    }
  };

  const _sends = async () => {
    //특점함수 호출
    setIsTimer(true);
    let result = await sends({ tel: data.email });
    if (result === undefined) {
      alert("이미 가입된 휴대폰번호입니다");
      return;
    } else {
      alert("인증 번호 발급성공 인증을 진행하여주세요");
    }
    setIsTimer(false);
    //특정함수발생 2
    setMinutes(5); //상태검증 분
    setSeconds(0); //상태검증 초
    setAuth({ ...auth, authcode: result.data.code, auth: true }); //코드할당 및 인증상태
    //타이머 관련 세팅
  };

  //인증코드 중복 핸들러
  const handleRemoveImage = (idx) => {
    setImage((images) => images.filter((el, index) => index !== idx));
    setImgBase64((imgBase64) => imgBase64.filter((el, index) => index !== idx));
  };
  //제거용
  //이미지 업로드 핸들러

  const handleSignUp = async () => {
    let fcm = await getStoarge("fcmToken");
    fcm = await JSON.parse(fcm);
    const fp = await fpPromise;
    const result = await fp.get();
    const visitorId = result.visitorId;
    data.finger = visitorId;
    data.fcm = fcm?.fcmToken;

    if (auth.check === false) {
      alert("휴대폰 인증 이후 가입하여주세요");
      return;
    }
    if (acct.length !== 0) {
      let res = acct?.findIndex((item, idx) => {
        return item.agreed === !true;
      });

      if (res !== -1) {
        alert("약관을 동의해주세요");
        return;
      }
    }
    console.log(data);
    if (
      data.email === "" ||
      data.passwordCheck === false ||
      data.name === "" ||
      (data.code === "" && data.joincode === "")
    ) {
      alert("빈칸을 확인하여주세요");
      return;
    }
    const ids = await Promise.all(
      image.map((uri) => {
        if (!!uri.id) return uri.id;
        let formData = new FormData();

        formData.append("files", uri);
        return uploadImage(formData);
      })
    );

    data.image = ids;
    console.log(data);
    /* delete data.passwordCheck; */
    data.auth = true;
    let res = await userCreate(data);

    if (res?.data?.status == 400) {
      alert(res.data.message);
      return;
    } else {
      alert("회원가입이 완료되었습니다,");
      history.push("/");
      return;
    }

    //빈칸검증이 끝나면 회원가입
  };


  useEffect(() => {
    if(AuthData) { 
    let LT = [];
    if (선택코드 === "직장인") {
      LT = AuthData?.data?.직장인?.map((item, idx) => {
        return { label: item, value: item };
      });
    } else if (선택코드 === "법인") {
      LT = AuthData?.data?.법인?.map((item, idx) => {
        return { label: item, value: item };
      });
    } else if (선택코드 === "소상공인") {
      LT = AuthData?.data?.소상공인?.map((item, idx) => {
        return { label: item, value: item };
      });
    }
   
    console.log(LT?.length,'ㅇ여기코드뭉치')
    // Ensure LT has elements before setting joincode
    if (LT.length > 0) {
      setData({ ...data, joincode: LT[0].value });
    } else {
      setData({ ...data, joincode: "" }); // or set to a default value if needed
    }
  
    설정선택코드리스트(LT ? LT : []);
  }
  }, [선택코드, AuthData]);




  useEffect(() => {
    if (AuthData) {

      if (true) {
        setData({ ...data, joincode: AuthData?.data?.codeSelect })
      }
    }
  }, [AuthData])
  //이미지 업로더 만들어야함
  return (
    <Wrapper style={{ display: "flex", justifyContent: "center" }}>
      <Inner>
        <div
          style={{
            display: "flex",
            marginTop: 58,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",

              marginBottom: 25,
            }}
          >
            <BackStyles
              onClick={() => {
                history.goBack();
              }}
              style={{ marginRight: 10 }}
            >
              <img src={BackIco} style={{ width: 30, height: 30 }} />
            </BackStyles>
            <SignupText>회원가입</SignupText>
          </div>
          {toggle === false && (
            <div
              onClick={() => {
                setToggle(true);
              }}
            >
              <img src={LogoIco} style={{ height: 28 }} />
            </div>
          )}

          {toggle === true && isOn === false && (
            <div>
              <input
                type="text"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
              <div
                onClick={() => {
                  if (password === "1234") {
                    setIsOn(true);
                    return;
                  }

                  setToggle(false);
                  alert("패스워드가 틀립니다");
                }}
                style={{
                  width: 30,
                  height: 30,
                  backgroundColor: "#33a0df",
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>인증</p>
              </div>
            </div>
          )}

          {isOn === true && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 45,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={data.codeMode === 1}
                  onClick={() => {
                    if (data?.codeMode === 1) {
                      setData({ ...data, codeMode: 2 });
                      return;
                    }
                    setData({ ...data, codeMode: 1 });
                  }}
                  style={{ width: 15, height: 15 }}
                />
                <p style={{ fontSize: 18 }}>딜러</p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  checked={data.codeMode === 3}
                  onClick={() => {
                    if (data?.codeMode === 3) {
                      setData({ ...data, codeMode: 2 });
                      return;
                    }
                    setData({ ...data, codeMode: 3 });
                  }}
                  style={{ width: 15, height: 15 }}
                />
                <p style={{ fontSize: 18 }}>사업자</p>
              </div>
            </div>
          )}
        </div>

        {auth.auth ? (
          <>
            <CredentialsInputText>인증번호 입력</CredentialsInputText>
            <CredentialsInputInput
              value={auth.userauth}
              onChange={(e) => setAuth({ ...auth, userauth: e.target.value })}
              placeholder="인증번호를 입력해주세요"
            />
            <TintBox>
              {minutes} 분 : {seconds} 초
            </TintBox>
            <div style={{ display: "flex" }}>
              <SubTagButton able onClick={() => _confrim()}>
                인증
              </SubTagButton>
              <SubTagButton
                able
                onClick={() => setAuth({ ...auth, authcode: "", auth: false })}
              >
                재인증
              </SubTagButton>
            </div>
          </>
        ) : (
          <>
            <CredentialsInputText>
              아이디 (휴대폰번호 입력)
            </CredentialsInputText>
            <CredentialsInputInput
              value={data.email}
              disabled={auth.check}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              placeholder="휴대폰 번호를 입력해주세요 (-) 제외"
            />
            {auth.check === false && (
              <>
                {!CheckPhone(data.email) && (
                  <TintBox>휴대폰 번호 형식이 아닙니다.</TintBox>
                )}

                <SubTagButton style={{ width: '100%', height: 50, fontWeight: 'bold', fontSize: 15 }} able onClick={() => _sends()}>
                  {isTimer ? (
                    <Loader
                      type="TailSpin"
                      color="white"
                      height={35}
                      width={35}
                    />
                  ) : (
                    "인증"
                  )}
                </SubTagButton>
              </>
            )}
          </>
        )}

        {/* <CredentialsInputText>이름(필수)</CredentialsInputText> */}
        <p
          style={{
            color: "red",
            marginTop: 10,
            marginBottom: 10,
            minWidth: 260,
          }}
        >
          *회원가입시 입력되는 정보는 모두 필수항목 입니다.
        </p>
        <CredentialsInputInput
          value={data.name}

          onChange={(e) => setData({ ...data, name: e.target.value })}
          placeholder="이름입력"
        ></CredentialsInputInput>
        {/* <CredentialsInputText>
          아이디 (휴대폰번호 입력)(필수)
        </CredentialsInputText> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            justifyContent: "center",
            width: "100%",
            minWidth: 270,
          }}
        >
          {/*   <CredentialsInputInput
            value={data.email}
            disabled={auth.check}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            placeholder="(-)제외, 휴대폰 번호 입력"
            style={{ width: "97%" }}
          /> */}
          {/*   <SubTagButton2
            able
            onClick={() => {
              if (data.name.length < 2 || CheckPhone(data.email) === false) {
                alert("이름을 또는 휴대폰 번호를 제대로 입력해주세요");
                return;
              }
              onClickCertification(data.email, data.name);
            }}
          >
            인증
          </SubTagButton2> */}
        </div>
        {/* <CredentialsInputText>비밀번호(필수)</CredentialsInputText> */}
        <CredentialsInputInput
          value={data.password}
          onChange={(e) => setData({ ...data, password: e.target.value })}
          type="password"
          placeholder="비밀번호 입력"
        ></CredentialsInputInput>

        <CredentialsInputInput
          value={data.passwordCheck}
          onChange={(e) => setData({ ...data, passwordCheck: e.target.value })}
          placeholder="비밀번호 재입력"
          type="password"
        ></CredentialsInputInput>

        {data.passwordCheck !== data.password && (
          <TintBox>비밀번호가 서로 틀립니다.</TintBox>
        )}
        {/* <CredentialsInputText>별명(필수)</CredentialsInputText> */}
        <CredentialsInputInput
          value={data.nickname}
          onChange={(e) => setData({ ...data, nickname: e.target.value })}
          placeholder="닉네임"
        ></CredentialsInputInput>
        {/* <CredentialsInputText>이메일(필수)</CredentialsInputText> */}
        {/* <CredentialsInputInput
          value={data.useremail}
          onChange={(e) => setData({ ...data, useremail: e.target.value })}
          placeholder="이메일 입력"
        ></CredentialsInputInput> */}
        {/*     <TintBox>{validateEmail(data.useremail)}</TintBox> */}
        {/* <CredentialsInputText>회사 / 단체명(필수)</CredentialsInputText> */}
        {/*    <div style={{ display: "flex" }}>
          <CredentialsInputInput
            value={data.office}
            onChange={(e) => setData({ ...data, office: e.target.value })}
            placeholder="회사/단체명 입력"
            style={{ width: "70%", marginRight: 10 }}
          ></CredentialsInputInput>
         
          <CredentialsInputInput
            value={data.rank}
            onChange={(e) => setData({ ...data, rank: e.target.value })}
            placeholder="직급 입력"
            style={{ width: "30%" }}
          ></CredentialsInputInput>
        </div> */}

        {data.codeMode === 2 && (
          <FlexibleBtn>
            <AntdBtn onClick={() => {
              설정선택코드('직장인')


            }} active={선택코드 === "직장인"}>
              직장인
            </AntdBtn>
            <AntdBtn onClick={() => {
              설정선택코드('법인')

            }} active={선택코드 === "법인"}>
              법인
            </AntdBtn>
            <AntdBtn onClick={() => {
              설정선택코드('소상공인')

            }} active={선택코드 === "소상공인"}>
              소상공인
            </AntdBtn>

          </FlexibleBtn>)}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            minWidth: 270,
          }}
        >
          {data.codeMode !== 2 && (
            <>
              {data.codeMode === 0 ? (
                <></>
              ) : (
                <>
                  {/* <CredentialsInputText>사업자 코드(필수)</CredentialsInputText> */}
                  <CredentialsInputInput
                    value={data.code}
                    onChange={(e) => setData({ ...data, code: e.target.value })}
                    placeholder="사업자 코드 입력"
                    style={{ minWidth: 150 }}
                  ></CredentialsInputInput>

                  {/* <CredentialsInputText>딜러코드</CredentialsInputText>
                  <CredentialsInputInput
                    value={data.joincode}
                    onChange={(e) => setData({ ...data, joincode: e.target.value })}
                    placeholder="딜러코드를 입력해주세요"
                  ></CredentialsInputInput> */}
                </>
              )}
            </>
          )}
          {data.codeMode === 2 && (
            <>
              {/* <CredentialsInputText>일반코드(필수)</CredentialsInputText> */}
              {선택코드리스트?.length === 0 ? <CredentialsInputInput
                basecode
                value={data.joincode}
                onChange={(e) => setData({ ...data, joincode: e.target.value })}
                placeholder="일반 코드 입력"
              ></CredentialsInputInput> : <SeletList
                value={data?.joincode}
                style={{ width: '100%', height: 50 }}
                placeholder="코드를 선택해주세요."
                onChange={(e) => {

                  setData({ ...data, joincode: e })
                }}
                options={선택코드리스트}
              />}

            </>
          )}

        </div>
        {/*     <CredentialsInputInput
          basecode
          value={data.joinName}
          onChange={(e) => setData({ ...data, joinName: e.target.value })}
          placeholder="추천인 입력(이름)"
        ></CredentialsInputInput> */}
        {/* <CredentialsInputText>성인여부(필수)</CredentialsInputText> */}
        {/*   <div style={{ display: "flex", justifyContent: "space-between" }}>
          <SubTagButton
            onClick={() => setData({ ...data, child: false })}
            able={data.child === false}
            style={{ width: "32%" }}
          >
            성인
          </SubTagButton>
          <SubTagButton
            onClick={() => setData({ ...data, child: true })}
            able={data.child === true}
            style={{ width: "32%" }}
          >
            비성인
          </SubTagButton>
         
        </div> */}
        {/*     <NameCardVerification style={{ width: "100%" }}>
          <NameCardTop>
            <input
              type="file"
              accept="image/*"
              name="upload-file"
              style={{ display: "none" }}
              onChange={handleImage}
              id="upload-file"
            ></input>
            <NameCardAdd
              for="upload-file"
              style={{ backgroundColor: "red" }}
            >
              명함인증 +
            </NameCardAdd>
          </NameCardTop>
        </NameCardVerification> */}
        <div className="CardAddImg">
          {imgBase64 &&
            imgBase64?.map((item, idx) => {
              return (
                <NameCardBox
                  onClick={() => handleRemoveImage(idx)}
                  url={item}
                ></NameCardBox>
              );
            })}
        </div>
        {acct.length > 0 && (
          <TermsAgree
            SignUp
            acct={acct}
            setAcct={setAcct}
            termstype={false}
          ></TermsAgree>
        )}
        <SignupBtn style={{ height: 50, fontWeight: 'bold', fontSize: 15 }} onClick={() => handleSignUp()}>회원가입</SignupBtn>
      </Inner>
    </Wrapper>
  );
};

export default withRouter(Register);
