import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import Select from "react-select";
import { withRouter } from "react-router";
import {
  createPlan,
  createTelecomCategory,
  updatePlan,
  updateTelecomCategory,
} from "Datas/api";
import { GetPlanList, GetTelecomCategoryList, GetTelecomList } from "Datas/swr";
import { Fade } from "react-reveal";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;
const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-left: 30px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
  background: #fff;
  border-radius: 7px;
`;

const Content = styled.div`
  //margin: 15px 0 0 20px;
  //max-width: 690px;
`;

const UsageCategoryDetail = ({ match, history, location }) => {
  const [teleList, setTeleList] = useState([]);

  // SWR
  const { TelecomListData } = GetTelecomList();

  const { TelecomCategoryData, TelecomCategoryMutate, isLoading } =
    GetTelecomCategoryList(match?.params?.telecom);

  const [datas, setDatas] = useState({
    title: "",
    giga: "",
    option: "",
  });

  useEffect(async () => {
    if (!isLoading && match?.params?.id !== "add") {
      console.log("location?.state");
      console.log(location?.state);
      setDatas({
        telecomid: location?.state?.telecomid,
        telecomname: location?.state?.telecomname,
        title: location?.state?.title,
        giga: location?.state?.giga,
        option: location?.state?.option,
      });
    }
  }, [match?.params, isLoading]);

  useEffect(() => {
    if (TelecomListData !== undefined) {
      let newArray = [];
      if (TelecomListData.length !== 0) {
        for (let i = 0; i < TelecomListData.length; i++) {
          newArray.push({
            value: TelecomListData[i].id,
            label: TelecomListData[i].name,
          });
        }
        setTeleList(newArray);
        if (match.params.id === "add") {
          let datas1 = TelecomListData?.map((item, idx) => {
            if (item.id === Number(match?.params?.telecom)) {
              return { value: item.id, label: item.name };
            }
          }).filter((item, idx) => {
            return item !== undefined;
          })[0];
          console.log(datas1, "데이터스1");
          console.log(match?.params?.telecom);
          setDatas({
            ...datas,
            telecomname: datas1?.label,
            telecomid: datas1?.value,
          });
          console.log("데이터스셋", datas1);
        }
      }
    }
  }, [TelecomListData]);

  const _handleCreate = async () => {
    console.log(datas);
    await createTelecomCategory(datas);
    history.goBack();
  };

  const _handleUpdate = async () => {
    await updateTelecomCategory({ id: match?.params?.id, body: datas });
    history.goBack();
  };

  console.log(datas,'데이터스');

  
  return (
    <Fade Button>
      <Wrapper style={{ width: "100%" }}>
        <Top style={{ width: "80%", margin: "0px auto", marginTop: 25 }}>
          <TopLabel>카테고리 정보</TopLabel>
          <TopButton>
            <Add
              onClick={() =>
                match?.params?.id === "add" ? _handleCreate() : _handleUpdate()
              }
            >
              완료
            </Add>
          </TopButton>
        </Top>
        <Content
          style={{
            width: "100%",
          }}
        >
          <MainContainer>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>통신사</td>
                <td>
                  <Select
                    value={{
                      value: datas?.telecomname,
                      label: datas?.telecomname,
                    }}
                    onChange={(e) => {
                      setDatas({
                        ...datas,
                        telecomname: e.label,
                        telecomid: e.value,
                      });
                    }}
                    style={{ paddingLeft: 15 }}
                    options={teleList}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>타이틀 명</td>
                <td>
                  <InputMain
                    placeholder="타이틀을 설정해주세요"
                    value={datas.title}
                    onChange={(e) =>
                      setDatas({ ...datas, title: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>통신사</td>
                <td>
                  <InputMain
                    placeholder="제공 기가수를 작성하여주세요"
                    value={datas.giga}
                    onChange={(e) =>
                      setDatas({ ...datas, giga: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>옵션</td>
                <td>
                  <InputMain
                    placeholder="옵션을 입력해주세요"
                    value={datas.option}
                    onChange={(e) =>
                      setDatas({ ...datas, option: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
            </table>
          </MainContainer>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(UsageCategoryDetail);
