import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { withRouter } from "react-router";
import { updateInfo, updateInfoDL, uploadImage, userUpdate } from "Datas/api";
import { GetuserProfile } from "Datas/swr";
import { Fade } from "react-reveal";
import Button from "@mui/material/Button";
import { ToggleButtonGroup } from "@mui/material";
import { ToggleButton } from "@mui/material";
import { TextField } from "@material-ui/core";

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
`;

const Add = styled.div`
  display: flex;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
  border-radius: 7px;
  padding-left: 15px;
`;

const Images = styled.img`
  height: 345px;
  border: none;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  width: 100%;
  border-radius: 7px;
  //margin-bottom: 110px;
  object-fit: cover;
`;
const InputMaintext = styled.textarea`
  height: 345px;
  border: none;
  width: 100%;
  background: #f4f4f4;
  border-radius: 7px;
  margin-bottom: 110px;
`;

const Content = styled.div``;

const NameCardAdd = styled.label`
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  font-size: 17px;
  background: #6091ed;
  border-radius: 30px;
  padding: 9px 15px;
  color: #fff;
  margin-left: auto;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;
const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const UserDetail = ({ location, history }) => {
  console.log(history);

  const { UserProfileData, UserProfileMutate } = GetuserProfile(
    location.state.userData.id
  );

  const [data, setData] = useState({
    name: "",
    email: "",
    useremail: "",
    office: "",
    rank: "",
    image: "",
    code: "",
    joincode: "",
    DLMode: false,
  });
  const [datas2, setDatas2] = useState({
    sktPrice: 0,
    ktPrice: 0,
    lguPrice: 0,
  });
  const [userStore, setStore] = useState("");
  const [image, setImage] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);
  console.log(UserProfileData, "정보");
  console.log(UserProfileData);
  const handleRemoveImage = (idx) => {
    setImage((images) => images.filter((el, index) => index !== idx));
    setImgBase64((imgBase64) => imgBase64.filter((el, index) => index !== idx));
  };
  console.log(UserProfileData);
  useEffect(() => {
    if (UserProfileData !== undefined) {
      setStore(UserProfileData.profile?.Store?.name);
      delete UserProfileData?.profile?.Store;
      delete UserProfileData?.profile?.id;
      delete UserProfileData?.profile?.salt;
      delete UserProfileData?.profile?.updatedAt;
      delete UserProfileData?.profile?.tel;
      delete UserProfileData?.profile?.storeid;
      delete UserProfileData?.profile?.devicetoken;
      delete UserProfileData?.profile?.createdAt;
      console.log(UserProfileData?.profile);
      let temp = { ...UserProfileData?.profile };
      setData(temp);
      setDatas2(UserProfileData?.information);
      setImgBase64([temp.image[0]]);
    }
  }, [UserProfileData]);
  console.log(imgBase64);
  /* console.log(data.image[0]); */

  const handleImage = (e) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64([base64.toString()]);
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImage([e.target.files[0]]); //경로탈착
    }
  };

  const _userUpdate = async () => {
    //이미지업로드로직
    if (image.length > 0) {
      const ids = await Promise.all(
        image.map((uri) => {
          if (!!uri.id) return uri.id;
          let formData = new FormData();
          formData.append("files", uri);
          return uploadImage(formData);
        })
      );
      data.image = ids;
    }
    await userUpdate(location.state.userData.id, data);
    await updateInfoDL({ ...datas2 }, datas2.id);
    await UserProfileMutate();
    alert("업데이트 완료!");
    history.goBack();
  };
  const handleChange1 = (event, newAlignment) => {
    let letr = newAlignment === null ? false : newAlignment;
    console.log(newAlignment);
    setData({ ...data, DLMode: letr });
  };
  return (
    <Fade Button>
      <Wrapper style={{ width: "100%" }}>
        <Top>
          <TopLabel>회원정보</TopLabel>
          <TopButton>
            <Button
              color="success"
              variant="contained"
              onClick={() => _userUpdate()}
            >
              완료
            </Button>
          </TopButton>
        </Top>
        <Content
          style={{
            width: "100%",
          }}
        >
          <MainContainer>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>이름</td>
                <td>
                  <InputMain
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>아이디 / 전화번호</td>
                <td>
                  <InputMain
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>이메일</td>
                <td>
                  <InputMain
                    value={data.useremail}
                    onChange={(e) =>
                      setData({ ...data, useremail: e.target.value })
                    }
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>소속</td>
                <td>
                  <InputMain value={userStore} disabled={true} />
                </td>
              </RightTable>
              <RightTable>
                <td>딜러분류</td>
                <td>
                  <ToggleButtonGroup
                    color="primary"
                    value={data.DLMode}
                    disabled={true}
                    exclusive
                  >
                    <ToggleButton value={false}>회원</ToggleButton>
                    <ToggleButton value={true}>딜러</ToggleButton>
                  </ToggleButtonGroup>
                </td>
              </RightTable>
              <RightTable>
                <td>별명</td>
                <td>
                  <InputMain
                    value={data?.joincode ? data?.joincode : data?.code}
                    disabled={true}
                  />
                </td>
              </RightTable>

              <RightTable>
                <td>명함</td>
                <td>
                  {imgBase64?.length !== 0 ? (
                    imgBase64.map((item, idx) => {
                      return (
                        <label for="upload-file">
                          <Images src={item} />
                        </label>
                      );
                    })
                  ) : (
                    <div style={{ padding: 8 }}>
                      <NameCardAdd for="upload-file">이미지 추가</NameCardAdd>
                    </div>
                  )}
                </td>
              </RightTable>
            </table>
            {UserProfileData?.profile?.gm >= 1 && (
              <>
                <input
                  type="file"
                  name="upload-file"
                  style={{ display: "none" }}
                  onChange={handleImage}
                  id="upload-file"
                ></input>
                <InputLabel>통신사별 추가수수료</InputLabel>
                <TextField
                  fullWidth
                  type="number"
                  value={datas2?.sktPrice}
                  onChange={(e) =>
                    setDatas2({ ...datas2, sktPrice: e.target.value })
                  }
                  style={{
                    marginTop: 20,
                  }}
                  id="outlined-basic"
                  label="SKT"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  type="number"
                  value={datas2?.ktPrice}
                  onChange={(e) =>
                    setDatas2({ ...datas2, ktPrice: e.target.value })
                  }
                  style={{
                    marginTop: 20,
                  }}
                  id="outlined-basic1"
                  label="KT"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  type="number"
                  value={datas2?.lguPrice}
                  onChange={(e) =>
                    setDatas2({ ...datas2, lguPrice: e.target.value })
                  }
                  style={{
                    marginTop: 20,
                  }}
                  id="outlined-basic"
                  label="LGU+"
                  variant="outlined"
                />
              </>
            )}
            {UserProfileData?.profile?.DLMode === true && (
              <>
                <input
                  type="file"
                  name="upload-file"
                  style={{ display: "none" }}
                  onChange={handleImage}
                  id="upload-file"
                ></input>
                <InputLabel>통신사별 추가수수료</InputLabel>
                <TextField
                  fullWidth
                  type="number"
                  value={datas2?.sktPrice}
                  onChange={(e) =>
                    setDatas2({ ...datas2, sktPrice: e.target.value })
                  }
                  style={{
                    marginTop: 20,
                  }}
                  id="outlined-basic"
                  label="SKT"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  type="number"
                  value={datas2?.ktPrice}
                  onChange={(e) =>
                    setDatas2({ ...datas2, ktPrice: e.target.value })
                  }
                  style={{
                    marginTop: 20,
                  }}
                  id="outlined-basic1"
                  label="KT"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  type="number"
                  value={datas2?.lguPrice}
                  onChange={(e) =>
                    setDatas2({ ...datas2, lguPrice: e.target.value })
                  }
                  style={{
                    marginTop: 20,
                  }}
                  id="outlined-basic"
                  label="LGU+"
                  variant="outlined"
                />
              </>
            )}
          </MainContainer>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default UserDetail;
