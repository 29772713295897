import axios from "axios";

const sends = async (body) => {
  let result;
  try {
    result = await axios.post("/user/send", body);
  } catch {
    result = undefined;
  }
  return result;
};

const sendsPw = async (body) => {
  let result;
  try {
    result = await axios.post("/user/sendsFind", body);
  } catch {
    result = undefined;
  }
  return result;
};
const userCreate = async (body) => {
  let result;
  try {
    result = await axios.post("/user", body);
  } catch {
    result = undefined;
  }
  return result;
};

//qa생성

const createdQa = async (body) => {
  let result;
  try {
    result = await axios.post("/product/createdQa", body);
  } catch {
    result = undefined;
  }
  return result;
};

//qa업데이트
const updatedQa = async (body, id) => {
  let result;
  try {
    result = await axios.put(`/product/updatedQa/${id}`, body);
  } catch {
    result = undefined;
  }
  return result;
};

//qa 삭제

const deleteQa = async (body) => {
  let result;
  try {
    result = await axios.post("/product/deleteQa", body);
  } catch {
    result = undefined;
  }
  return result;
};

//createProductGroupList

export const createProductGroupList = async (body) => {
  let result;
  try {
    result = await axios.post("/category/createProductGroupList", body);
  } catch {
    result = undefined;
  }
  return result;
};

//deleteProductGroupList
export const deleteProductGroupList = async (body) => {
  let result;
  try {
    result = await axios.post("/category/deleteProductGroupList", body);
  } catch {
    result = undefined;
  }
  return result;
};

//그룹생성
export const createGroup = async (body) => {
  let result;
  try {
    result = await axios.post("/category/createProductGroup", body);
  } catch {
    result = undefined;
  }
  return result;
};
//그룹삭제

export const deleteGroup = async (body) => {
  let result;
  try {
    result = await axios.post("/category/deleteProductGroup", body);
  } catch {
    result = undefined;
  }
  return result;
};
//Store
const createStore = async (body) => {
  let result = undefined;
  try {
    result = await axios.post("/user/storeCreate", body);
  } catch {}
  return result;
};
const createInfo = async (body) => {
  let result = undefined;
  try {
    result = await axios.post("/banner/createInformation", body);
  } catch {}
  return result;
};

const updateInfo = async (body, id) => {
  let result = undefined;
  try {
    result = await axios.put(`/banner/updateInformation/${id}`, body);
  } catch {}
  return result;
};

export const updateInfoDL = async (body, id) => {
  let result = undefined;
  try {
    result = await axios.put(`/banner/updateInfoDiler/${id}`, body);
  } catch {}
  return result;
};
//updateInfoDiler
const updateInfoTarget = async (body, id) => {
  let result = undefined;
  console.log(id, "아이디");
  console.log(body, "바디");
  try {
    result = await axios.put(`/banner/updateInformationtarget/${id}`, body);
  } catch {}
  return result;
};
const uploadImage = async (image) => {
  try {
    const { data } = await axios.post("upload/", image);
    return data[0][("id", "url")];
  } catch (err) {
    alert("이미지 업로드 실패");
    console.log(err.response.data);
  }
};

const LoginUser = async (body, type) => {
  let res;
  try {
    res = await axios.post(`user/login/${type}`, body);
  } catch {
    res = false;
  }
  return res;
};

const updateStore = async ({ id, body }) => {
  try {
    await axios.post(`/user/storeUpdate/${id}`, body);
    alert("가맹점 수정 성공");
  } catch {
    alert("가맹점 수정 실패");
  }
};

const deleteStore = async (body) => {
  try {
    await axios.post(`/user/stoblackelete`, body);
    alert("가맹점 삭제 성공");
  } catch {
    alert("가맹점 삭제 실패");
  }
};

//유저업데이트
const userUpdate = async (id, body) => {
  let result;
  try {
    result = await axios.post(`/user/update/${id}`, body);
  } catch {
    result = undefined;
  }
  return result;
};

export const userPointAct = async ({ id, body }) => {
  let result;
  try {
    result = await axios.post(`/user/userPointAct/${id}`, body);
  } catch {
    result = undefined;
  }
  return result;
};

//유저삭제

const userDelete = async (body) => {
  let result;
  try {
    result = await axios.post(`/user/delete`, body);
  } catch {
    result = undefined;
  }
  return result;
};

// Banner
const createBanner = async (body) => {
  try {
    await axios.post("/banner", body);
    alert("배너 생성 성공");
  } catch {
    alert("배너 생성 실패");
  }
};

const updateBanner = async ({ id, body }) => {
  try {
    await axios.post(`/banner/update/${id}`, body);
    alert("배너 수정 성공");
  } catch {
    alert("배너 수정 실패");
  }
};

const deleteBanner = async (body) => {
  try {
    await axios.post(`/banner/deleteBanner`, body);
    alert("배너 삭제 성공");
  } catch {
    alert("배너 삭제 실패");
  }
};

//Card

const createCard = async (body) => {
  try {
    await axios.post(`/product/cardCreate`, body);
    alert("제휴카드 생성 성공");
  } catch {
    alert("제휴카드 생성 성공");
  }
};

const updateCard = async ({ id, body }) => {
  try {
    await axios.post(`/product/cardUpdate/${id}`, body);
    alert("제휴카드 수정 성공");
  } catch {
    alert("제휴카드 수정 실패");
  }
};
const updateOrder = async (body, id) => {
  try {
    await axios.put(`/product/updateOrder/${id}`, body);
    alert("주문정보 수정 성공");
  } catch {
    alert("주문정보 수정 실패");
  }
};

const deleteCard = async (body) => {
  try {
    await axios.post(`/product/deleteCard`, body);
    alert("제휴카드 삭제 성공");
  } catch {
    alert("제휴카드 삭제 실패");
  }
};

const createCategory = async (body) => {
  try {
    await axios.post(`/category`, body);
    alert("카테고리 생성 성공!");
  } catch {
    alert("카테고리 생성 실패!");
  }
};

const updateCategory = async ({ id, body }) => {
  try {
    await axios.post(`/category/updateCateogry/${id}`, body);
    alert("카테고리 수정 성공");
  } catch {
    alert("카테고리 수정 실패");
  }
};

const deleteCategory = async (body) => {
  try {
    await axios.post(`/category/deleteCategory`, body);
    alert("카테고리 삭제 성공");
  } catch {
    alert("카테고리 삭제 실패");
  }
};

// 요금제

const createProduct = async (body) => {
  try {
    await axios.post("/product", body);
    alert("상품 생성 성공!");
  } catch {
    alert("상품 생성 실패");
  }
};

const updateProduct = async (id, body) => {
  console.log(id);
  console.log(body);

  try {
    await axios.post(`/product/productUpdate/${id}`, body);
    alert("상품 수정 성공!");
  } catch {
    alert("상품 수정 실패");
  }
};

const deleteProduct = async (body) => {
  try {
    await axios.post(`/product/delete`, body);
    alert("상품 삭제 성공!");
  } catch {
    alert("상품 삭제 실패");
  }
};

const deletePointList = async (body) => {
  try {
    await axios.post(
      `/user/deletePointAct
    `,
      body
    );
    alert("포인트 내역 삭제 성공!");
  } catch {
    alert("포인트 내역 삭제 실패");
  }
};

const createPlan = async (body) => {
  try {
    await axios.post(`/product/planCreate`, body);
    alert("요금제 생성 성공");
  } catch {
    alert("요금제 생성 실패");
  }
};

const CopyProduct = async (body) => {
  try {
    await axios.post(`/product/copyProjProduct`, body);
    alert("상품 복제 성공");
  } catch {
    alert("상품 복제 실패");
  }
};

const createTelecomCategory = async (body) => {
  try {
    await axios.post(`/category/typeTelecomCategoryCreate`, body);
    alert("요금제 카테고리 생성 성공");
  } catch {
    alert("요금제 카테고리 생성 실패");
  }
};
const updateTelecomCategory = async ({ id, body }) => {
  try {
    await axios.post(`/category/typeTelecomCategoryUpdate/${id}`, body);
    alert("요금제 카테고리 수정 성공");
  } catch {
    alert("요금제 카테고리 수정 실패");
  }
};

const updatePlan = async ({ id, body }) => {
  try {
    await axios.post(`/product/planUpdate/${id}`, body);
    alert("요금제 수정 성공");
  } catch {
    alert("요금제 수정 실패");
  }
};

const deletePlan = async (id) => {
  try {
    await axios.post(`/product/planDelete/${id}`);
    alert("요금제 삭제 성공");
  } catch {
    alert("요금제 삭제 실패");
  }
};

const deleteTelecomCategory = async (id) => {
  try {
    await axios.post(`/category/typeTeleComCategryDelete/${id}`);
    alert("카테고리 삭제 성공");
  } catch {
    alert("카테고리 삭제 실패");
  }
};

// 프로모션
const createPromotion = async (body) => {
  try {
    await axios.post(`/category/createPromotion`, body);
    alert("프로모션 생성 성공");
  } catch {
    alert("프로모션 생성 실패");
  }
};

const updatePromotion = async (body) => {
  try {
    await axios.post(`/category/updatePromotion`, body);
    alert("프로모션 수정 성공");
  } catch {
    alert("프로모션 수정 실패");
  }
};

const deletePromotion = async (body) => {
  try {
    await axios.post(`/category/deletePromotion/`, body);
    alert("프로모션 삭제 성공");
  } catch {
    alert("프로모션 삭제 실패");
  }
};

// 인터넷+TV
const createPost = async (body) => {
  try {
    await axios.post(`/user/boardCreate/`, body);
    alert("인터넷+TV 생성 성공");
  } catch {
    alert("인터넷+TV 생성 실패");
  }
};

const createPostrec = async (body) => {
  try {
    await axios.post("/banner/createBoardrec/", body);
    alert("이벤트 생성 성공");
  } catch {
    alert("이벤트 생성 실패");
  }
};

const updatePost = async ({ id, body }) => {
  try {
    await axios.post(`/user/boardUpdate/${id}`, body);
    alert("인터넷+TV 수정 성공");
  } catch {
    alert("인터넷+TV 수정 실패");
  }
};

const deletePost = async (id) => {
  try {
    await axios.post(`/user/boardDelete/${id}`);
    alert("인터넷+TV 삭제 성공");
  } catch {
    alert("인터넷+TV 삭제 실패");
  }
};

const updateUser = async ({ id, body }) => {
  try {
    await axios.post(`/user/update/${id}`, body);
    return true;
  } catch {
    alert("회원 정보 수정 실패");
    return false;
  }
};
export const deleteMe = async () => {
  try {
    await axios.post(`/user/deleteMe`);
    return true;
  } catch {
    alert("회원 정보 삭제 실패");
    return false;
  }
};
const createContract = async (body) => {
  try {
    await axios.post(`/user/userOrder`, body);
  } catch {
    alert("개통신청 실패");
  }
};

const UpdateContract = async (id, body) => {
  try {
    await axios.post(`/user/orderUpdate/${id}`, body);
    return true;
  } catch {
    return false;
  }
};

const DeleteContract = async (body) => {
  try {
    await axios.post(`/user/orderDelete`, body);
    alert("개통삭제 성공");
  } catch {
    alert("개통삭제 실패");
  }
};

const updateBoardRec = async (id, body) => {
  console.log("body");
  console.log(body);

  try {
    await axios.post(`/banner/updateBoardrec/${id}`, body);
    alert("이벤트 수정 성공");
  } catch {
    alert("이벤트 수정 실패");
  }
};

const deleteBoardRec = async (body) => {
  try {
    await axios.post(`/banner/deleteBoardrec`, body);
    alert("이벤트 삭제 성공");
  } catch {
    alert("이벤트 삭제 실패");
  }
};

const createTelecomList = async (body) => {
  console.log(body);

  try {
    await axios.post(`/category/createTelecomLists`, body);
  } catch {
    alert("초기 설정 실패");
  }
};

const createCategoryList = async (body) => {
  console.log(body);

  try {
    await axios.post(`/category/createCategory`, body);
  } catch {
    alert("초기 설정 실패");
  }
};

const sendPushNoti = async (body) => {
  console.log(body);

  try {
    await axios.post(`user/firebaseSend`, body);
    alert("푸쉬알림 전송 완료");
  } catch {
    alert("푸쉬알림 전송 실패");
  }
};

const SendSecure = async (body) => {
  let res;
  try {
    res = await axios.post(`user/secureSends`, body);
    alert("인증번호가 발송되었습니다");
  } catch {
    alert("네트워크 에러");
    res = false;
  }
  return res;
};
const CreateTerms = async (body) => {
  let res;
  try {
    res = await axios.post("/product/termsCreate", body);
    alert("이용약관 등록이 완료되었습니다");
  } catch {
    alert("네트워크 에러");
    res = false;
  }
  return res;
};

const DestroyTerms = async (body) => {
  let res;
  try {
    res = await axios.post("/product/termsDestory", body);
    alert("이용약관 삭제가 완료되었습니다");
  } catch {
    alert("네트워크 에러");
    res = false;
  }
  return res;
};
const UpdateTerms = async (body, id) => {
  let res;
  try {
    res = await axios.put(`/product/termsUpdate/${id}`, body);
    alert("이용약관 수정이 완료되었습니다");
  } catch {
    alert("네트워크 에러");
    res = false;
  }
  return res;
};

const DeleteReview = async (id) => {
  let res;
  try {
    res = await axios.post(`/product/liviewDelete/${id}`);
  } catch {
    return (res = false);
  }
  return res;
};

const deletePushLogs = async (body) => {
  try {
    await axios.post(`user/deletePushLogs`, body);
  } catch {
    alert("푸쉬알림 삭제 실패");
  }
};

export {
  deletePushLogs,
  DestroyTerms,
  createTelecomList,
  createCategoryList,
  DeleteReview,
  updateBoardRec,
  CreateTerms,
  deleteBoardRec,
  updateInfo,
  UpdateTerms,
  createContract,
  DeleteContract,
  UpdateContract,
  SendSecure,
  sends,
  LoginUser,
  createBanner,
  updateBanner,
  deleteBanner,
  userCreate,
  userUpdate,
  createStore,
  updateStore,
  userDelete,
  deleteStore,
  createCard,
  updateCard,
  uploadImage,
  deleteCard,
  createCategory,
  deleteTelecomCategory,
  CopyProduct,
  updateCategory,
  deleteCategory,
  createPlan,
  updatePlan,
  updateOrder,
  deletePlan,
  createPromotion,
  createInfo,
  sendsPw,
  updatePromotion,
  deletePromotion,
  createPost,
  createTelecomCategory,
  createProduct,
  createPostrec,
  updateProduct,
  deleteProduct,
  updatePost,
  updateTelecomCategory,
  deletePost,
  updateInfoTarget,
  updateUser,
  sendPushNoti,
  deletePointList,
  createdQa,
  updatedQa,
  deleteQa,
};
