import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { withRouter } from "react-router";
import { GetBoarderecByIds, GetBoardList, GetBoardrecList } from "Datas/swr";

import moment from "moment";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

const Wrapper = styled.div``;

const Post = styled.div`
  margin: 0 auto 50px;
  margin-top: 150px;
  max-width: 1024px;
  width: 100%;
  padding: 0 20px;
  @media (max-width: 1300px) {
    margin-top: 200px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 170px;
  }
  @media screen and (max-width: 415px) {
    margin: 150px 0 50px;
  }
  @media screen and (max-width: 415px) {
    margin: 130px 0 50px;
  }
`;

const PostTop = styled.div`
  align-items: center;
  display: flex;
  @media (max-width: 340px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const EventTextBox = styled.div`
  width: 120px;
  height: 35px;
  background-color: #f4125c;
  font-size: 15px;
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 20px;
  border-radius: 4px;
  font-weight: bold;
  @media (max-width: 415px) {
    min-width: 90px;
    width: 90px;
  }
  @media (max-width: 340px) {
    margin-bottom: 10px;
  }
`;

const PostTopMetadata = styled.div`
  display: flex;
  flex-direction: column;
`;

const PostTopText = styled.div`
  color: #feb43c;
  font-size: 25px;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`;

const PostTopTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  @media (max-width: 415px) {
    font-size: 18px;
    min-width: 240px;
  }
`;

const PostTopDate = styled.div`
  font-size: 17px;
  color: #666;
  margin-left: auto;
  min-width: 150px;
  @media screen and (max-width: 580px) {
    display: none;
  }
`;

const PostTopDate2 = styled.div`
  display: none;
  font-size: 17px;
  color: #666;
  margin-left: auto;
  @media screen and (max-width: 580px) {
    display: block;
    margin: 25px 0 -13px;
  }
  @media screen and (max-width: 430px) {
    font-size: 15px;
    min-width: 150px;
  }
`;

const PostContentArea = styled.div`
  margin-top: 20px;
  font-size: 18px;
  border: 1px solid #ccc;
  padding: 40px 50px;
  border-radius: 10px;
  min-width: 268px;
  @media (max-width: 580px) {
    padding: 20px 25px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
  }
`;

export const EventProductText = styled.div`
  font-size: 15px;
  background-color: #000;
  color: #fff;
  width: 140px;
  height: 40px;
  margin-top: 50px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  border-radius: 6px;
  @media screen and (max-width: 415px) {
    margin: 25px 0 -10px;
  }
`;

const PostContent = styled.div`
  width: 100%;
  font-size: 18px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ReviewBox = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #fff;
  /* width: calc(100% / 4 - 54px); */
  min-width: 250px;
  height: 250px;
  min-height: 250px;
  margin-bottom: 15px;
  border-radius: 7px;
  margin-right: 15px;
  @media screen and (max-width: 850px) {
    min-width: 180px;
    min-height: 180px;
    height: 180px;
  }
  @media screen and (max-width: 630px) {
    min-width: 140px;
    min-height: 140px;
    height: 140px;
  }
  @media screen and (max-width: 505px) {
    min-width: 110px;
    min-height: 110px;
    height: 110px;
  }
  @media screen and (max-width: 415px) {
    min-width: 95px;
    min-height: 95px;
    height: 95px;
    margin-right: 9px;
    margin-bottom: 9px;
  }
  @media screen and (max-width: 415px) {
    min-width: 95px;
    min-height: 95px;
    height: 133px;
  }
`;

const PreOrderDetail = ({ match, location }) => {
 
  const { BoardrecData, BoardrecMutate } = GetBoarderecByIds({
    id: match?.params?.id,
  });
 

  return (
    <Fade Button>
      <Wrapper>
        <Post>
          <PostTop>
            <EventTextBox>이벤트</EventTextBox>
            <PostTopMetadata>
              <PostTopTitle>{BoardrecData?.title}</PostTopTitle>
            </PostTopMetadata>
            <PostTopDate>
              작성일 : {moment(BoardrecData?.createdAt).format("YYYY.MM.DD")}
            </PostTopDate>
          </PostTop>
          <PostTopDate2>
            작성일 : {moment(BoardrecData?.createdAt).format("YYYY.MM.DD")}
          </PostTopDate2>
          <PostContentArea>
            <PostContent
              dangerouslySetInnerHTML={{
                __html: BoardrecData?.contents,
              }}
            ></PostContent>
          </PostContentArea>
          <EventProductText>이벤트 상품</EventProductText>
          <div
            style={{
              marginTop: 25,
              flexWrap: "wrap",
              display: "flex",
              minWidth: 320,
            }}
          >
            {BoardrecData?.url !== null &&
              BoardrecData?.url?.length !== 0 &&
              BoardrecData?.url?.map((el, idx) => {
                return (
                  <ReviewBox
                    style={{
                      position: "relative",
                      zIndex: 1,
                      cursor: "pointer",
                    }}
                  >
                    <Link to={el} style={{ width: "100%", height: "100%" }}>
                      {BoardrecData?.images?.length !== 0 && (
                        <img
                          src={BoardrecData?.images[idx]}
                          style={{
                            width: "100%",
                            height: "100%",
                            resize: "both",
                            objectFit: "contain",
                            borderRadius: 9,
                          }}
                        />
                      )}
                    </Link>
                  </ReviewBox>
                );
              })}
          </div>
        </Post>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(PreOrderDetail);
