import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { CounselingArea, StateBox } from "./styles";
import { GetCardMain, GetReqTelList } from "./swr";

const Counseling = ({ data }) => {
  const scrollRef = useRef(null);

  const [isDrag, setIsDrag] = useState(false);
  const [startY, setStartY] = useState();
  const [datas, setDatas] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (data?.length > 5) {
      let res = data
        ?.map((item, idx) => {
          if (idx > 5) {
            return;
          }
          return { ...item };
        })
        .filter((item, idx) => {
          return item !== undefined;
        });

      setDatas(res);
      setCount(6);
    }
  }, [data]);
  useEffect(() => {
    const countdown = setInterval(() => {
      if (count >= data?.length) {
        if (data?.length > 5) {
          let res = data
            ?.map((item, idx) => {
              if (idx > 5) {
                return;
              }
              return { ...item };
            })
            .filter((item, idx) => {
              return item !== undefined;
            });

          setDatas(res);
          setCount(6);
        } else {
          setDatas([]);
          setCount(0);
        }
        return;
      }

      datas.push(data[count]);
      setCount(count + 1);
      setDatas(datas);
    }, 2500);
    return () => clearInterval(countdown);
  }, [count, data]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: count * 50,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [count]);
  return (
    <>
      <CounselingArea>
        <h1>상담현황 내역</h1>
        <table>
          <thead>
            <tr className="head">
              <th>접수일</th>
              <th>고객명</th>
              <th>상담현황</th>
            </tr>
            <div className="conselingList" ref={scrollRef}>
              {datas?.map((item, idx) => {
                return (
                  <tr className="content">
                    <th className="date">
                      {moment(item?.createdAt).format("YYYY.MM.DD")}
                    </th>

                    <>
                      <th>{item?.name.substr(0, 1) + "**"}</th>
                    </>

                    <th className="state">
                      <StateBox
                        State={
                          item?.isvalidate === 0
                            ? "상담대기"
                            : item?.isvalidate === 1
                            ? "상담중"
                            : "상담완료"
                        }
                      >
                        {item?.isvalidate === 0
                          ? "상담대기"
                          : item?.isvalidate === 1
                          ? "상담중"
                          : "상담완료"}
                      </StateBox>
                    </th>
                  </tr>
                );
              })}
            </div>
          </thead>
        </table>
      </CounselingArea>
    </>
  );
};

export default Counseling;
