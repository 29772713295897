import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import {
  GetStoredL,
  GetStoreList,
  GetStoreListTele,
  GetuserList,
} from "Datas/swr";
import { userDelete, userPointAct, userUpdate } from "Datas/api";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import { withRouter } from "react-router";
import Pagination from "react-bootstrap/Pagination";
import PageItem from "react-bootstrap/PageItem";
import { useConfirm } from "Settings/util";
import CustomPagination from "Settings/pagination";
import moment from "moment";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  width: 100%;
  margin-top: 36px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
  }
`;

export const TopSelectComp = styled.div`
  width: ${(props) => {
    if (props.invite) {
      return "10%";
    }
    if (props.inviteR) {
      return "50%";
    }
    return "180px";
  }};
  margin-left: ${(props) => {
    if (props.inviteR) {
      return "34px";
    }
    if (props.invite) {
      return "12px";
    }
  }};

  margin-right: 12px;
  margin-top: -12px;
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    margin-left: 0px;
    margin-top: 0px;
    width: 80%;
  }
`;
const TopButton1 = styled.div`
  display: flex;
  width: 300px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;

    margin-top: 12px;
    width: 100%;
  }
`;
const TopButton = styled.div`
  display: flex;
  width: 140px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;

    margin-top: 12px;
    width: 100%;
  }
`;
export const ButtonCompoPath = styled.div`
  margin-left: 12px;
  margin-right: ${(props) => {
    if (props.turn) {
      return "12px";
    }
  }};

  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    margin-left: 0px;
    width: 100%;
    margin-top: 12px;
    margin-right: 0px;
  }
`;

const Add = styled.div`
  display: flex;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
`;

const Delete = styled.div`
  display: flex;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  background: #ed6060;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
`;

const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const HoverTag = styled.td`
  border-bottom: 1.5px solid #eee;
  &:hover {
    opacity: 0.5;
  }
`;

const SearchBox = styled.input`
  width: 100%;
  height: 40px;
  border: none;

  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #f8f8f8;
  padding: 16px;
`;

const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;
const useStyles = makeStyles({
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#3c4b64",
      padding: 7,
    },
  },

  tableBody: {
    "& .MuiTableBody-root": {
      padding: 7,
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      padding: 7,
    },
  },
});

const TableContainer = styled(Paper)`
  width: 100%;
  overflow: auto;
`;
const TextCompo = styled.div`
  margin-left: ${(props) => {
    if (props.left) {
      return "12px";
    }
  }};
  width: ${(props) => {
    if (props.Sales) {
      return "180px";
    }
    return "300px";
  }};
  margin-top: -12px;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;
const User = ({ profile, history }) => {
  const [userSelect, setUserSelect] = useState(0);
  const [page, setPage] = useState([]);
  const [filter, setFilter] = useState(null);
  const [search, setSearch] = useState(null);
  const [checked, setChecked] = useState(false);
  const [offset, setOffset] = useState(0);
  const [DLMode, setDLMode] = useState(false);
  const { StoreListTeleData, isLoading, StoreListTeleMutate } =
    GetStoreListTele(0);

  const [sale, setSale] = useState(0);
  const [Salea, setSalseA] = useState(0);
  const [dc, setDc] = useState(0);
  const [storeSelect, setStoreSelect] = useState("전체"); //gm이 1 이거나 DLMode 가 트루면 스토어아디는 해당스토어아이디
  const { StoredLList, StoredLMutate } = GetStoredL({ storeid: storeSelect });

  const [dlSelect, setDlSelect] = useState("전체");
  const [select, setSelect] = useState(null);
  const [userlist, setUserList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [DlList, setDlList] = useState([]);
  const { userListData, userListMutate } = GetuserList(
    storeSelect,
    userSelect,
    offset,
    filter,
    search,
    DLMode,
    profile.DLMode,
    dlSelect
  );
  const _handlePoint = async (type) => {
    console.log(select);
    if (select === null) {
      alert("선택된 정보가 없습니다.");
      return;
    }

    await userPointAct({
      id: select,
      body: {
        dc: dc,
        type: type,
        option: "Point",
        formid: select,
      },
    });
    setSelect(null);
    await userListMutate();
  };

  const _handleAddPrice = async (type) => {
    if (sale === null) {
      alert("수수료를 입력해주세요");
      return;
    }

    if (select === null) {
      alert("선택된 정보가 없습니다.");
      return;
    }

    //가맹점 수수료 패치 멀티.
    await userPointAct({
      id: select,
      body: {
        type: type,
        sale: sale,
        option: "price",
        formid: select,
      },
    });
    setSelect(null);
    await userListMutate();
  };
  const _updateUser = async () => {
    //스토어를업데이트한다
    console.log(select);
    await userPointAct({
      id: select,
      body: {
        fomrid: select,
        sale: Salea,
      },
    });
    setSelect(null);
    await userListMutate();
  };

  useEffect(() => {
    if (StoredLList !== undefined) {
      let Arrays = [];

      for (let i = 0; i < StoredLList?.length + 1; i++) {
        if (StoredLList?.length === i) {
          Arrays.push({
            value: "전체",
            label: "전체",
          });
        } else {
          Arrays.push({
            value: StoredLList?.[i]?.code,
            label: StoredLList?.[i]?.code,
          });
        }
      }

      setDlList(Arrays);
    }
  }, [StoredLList]);

  useEffect(() => {
    if (StoreListTeleData?.rows !== undefined) {
      let Arrays = [];
      if (StoreListTeleData?.rows?.length !== 0) {
        for (let i = 0; i < StoreListTeleData?.rows?.length + 1; i++) {
          if (StoreListTeleData?.rows?.length === i) {
            Arrays.push({
              value: "전체",
              label: "전체",
            });
          } else {
            Arrays.push({
              value: StoreListTeleData?.rows?.[i]?.id,
              label: StoreListTeleData?.rows?.[i]?.name,
            });
          }
        }
        console.log(Arrays);
        setStoreList(Arrays);
      }
    }
  }, [StoreListTeleData]);

  useEffect(() => {
    if (profile?.gm === 1 || profile.DLMode === true) {
      setStoreSelect(profile?.storeid);
    }
  }, [profile]);
  //유저리스트데이터를 관리해주는 이펙트
  useEffect(async () => {
    if (userListData?.rows !== undefined) {
      console.log("useEffect for restore");
      let temp = [...userListData?.rows];
      await setUserList(temp);
    }
  }, [userListData]);

  useEffect(() => {
    let checkd = userlist.every((item) => {
      return item.validate;
    });
    setChecked(checkd);
  }, [userlist]);

  //엑세스승인
  const _userAccess = async () => {
    let data = userlist.filter((item) => item.validate === true);
    let data1 = userlist.filter((item) => item.validate === false);
    let resdata;

    if (DLMode === true) {
      const data = await userUpdate(Number(select), {
        access: true,
        fingertype: true,
      });
      if (data === undefined) {
        return;
      } else {
        alert("승인 완료");
      }
    } else {
      if (data.length !== 0) {
        Promise.all(
          data.map(async (item, idx) => {
            resdata = await userUpdate(item.id, {
              access: true,
              fingertype: true,
            });
            if (resdata === undefined) {
              return;
            }
          })
        ); //요청
        await setUserList(data1);
      }
    }
  };
  const _userDLFalse = async () => {
    let data = userlist.filter((item) => item.validate === true);
    let data1 = userlist.filter((item) => item.validate === false);
    let resdata;

    if (data.length !== 0) {
      Promise.all(
        data.map(async (item, idx) => {
          resdata = await userUpdate(item.id, {
            DLMode: false,
          });
          if (resdata === undefined) {
            alert("요청 실패");
            return;
          }
        })
      ); //요청
      await setUserList(data1);
      alert("딜러 권한 해제 성공");
    }
  };

  const _userDLTrue = async () => {
    let checkd = userlist.some((item, idx) => {
      if (item.validate === true) {
        return item.gm > 0;
      }
    });

    let data = userlist.filter((item) => item.validate === true);

    let data1 = userlist.filter((item) => item.validate === false);
    let resdata;

    if (data.length !== 0) {
      Promise.all(
        data.map(async (item, idx) => {
          resdata = await userUpdate(item.id, {
            DLMode: true,
          });
          if (resdata === undefined) {
            alert("딜러 권한 발급 실패!");
            return;
          }
        })
      ); //요청

      await setUserList(data1);
      alert("딜러 권한 발급 성공!");
    }
  };
  //엑세스 삭제
  const _userDelete = () => {
    const desk = useConfirm(
      "삭제하시겠습니까?",
      async () => {
        let data = userlist.filter((item) => item.validate === true);
        let data1 = userlist.filter((item) => item.validate === false);
        let resdata;

        if (data.length !== 0) {
          Promise.all(
            data.map(async (item, idx) => {
              console.log("data.map");
              resdata = await userDelete({ id: item.id });

              if (resdata === undefined) {
                alert("삭제 실패!");
                return;
              }
            })
          ); //요청
          await setUserList(data1);
          alert("삭제 성공!");
        }
      },
      () => {
        alert("삭제 를 취소하였습니다.");
      }
    );
    desk();
    /*   
    } */
    //아이디만 보내기
  };
  const _userDeleteDL = () => {
    const desk = useConfirm(
      "삭제하시겠습니까?",
      async () => {
        if (select === null) {
          alert("선택된 정보가 없습니다.");
          return;
        }
        await userDelete({ id: select });
      },
      () => {
        alert("삭제 를 취소하였습니다.");
      }
    );
    desk();
    /*   
    } */
    //아이디만 보내기
  };

  const _handleSelect = (id) => {
    //셀렉트 메서드를 정렬시켜줌
    /* let newArray = [...select];
    let temp = [...Storedata];
    temp?.map((el, idx) => {
      if (el?.id === id) {
        let data = {
          ...el,
          isvalidate: !el?.isvalidate,
        };
        temp[idx] = data;

        if (!el?.isvalidate) {
          setDc(el.dc);

          if (newArray.findIndex((item) => item === el.id) === -1) {
            newArray.push(el.id);
          }
        } else {
          newArray = newArray.filter((item) => item !== el.id);

          setSale("");
          setDc("");
          console.log("언체크");
        }
      }
    }); */
    /* 
    
    setStoredata(temp); */
    setSelect(id);
    if (select === id) {
      setSelect(null);
      return;
    }
    const DC = userlist
      ?.map((item, idx) => {
        if (item.id === id) {
          return item.dc;
        }
      })
      .filter((item, idx) => {
        return item !== undefined;
      })[0];

    console.log(DC);
    setDc(DC);
    //셀렉트처리
  };
  const userCheck = (idx) => {
    let data = [...userlist];
    data[idx].validate = !data[idx].validate;
    setUserList(data);
  };

  const _handleAllSelct = () => {
    //전체샐렉트동기화
    console.log("세팅");
    let data = [...userlist];
    let resdata;
    if (checked) {
      resdata = data.map((item) => {
        return { ...item, validate: false };
      });
    } else {
      resdata = data.map((item) => {
        return { ...item, validate: true };
      });
    }

    setUserList(resdata);
    setChecked(true);
  };
  const _userhandlePush = () => {
    //링크로 발송리스트 넘기기

    let data = userlist.filter((item) => item.validate === true);
    if (DLMode === true) {
      data = userlist.filter((item) => item.id === select);
    }
    console.log(data);
    if (data.length <= 0) {
      alert("대상을 선택해주세요");
      return;
    }
    data = data.map((item, idx) => {
      return item.fcm;
    });
    if (data.length === 0) {
      alert("현재 기기로그인 기록이없는 계정입니다");
      return;
    }

    history.push({
      pathname: "pushnoti/detail",
      state: { users: data },
    });
  };
  const classes = useStyles();

  console.log(userlist, "유저");
  useEffect(() => {
    console.log('오프셋 변경')
    window.scrollTo(0, 0);
  }, [offset]);
  return (
    <Fade Button>
      <Wrapper style={{ height: "100vh" }}>
        <TopLabel style={{ marginTop: 12, marginBottom: 12 }}>
          회원현황
        </TopLabel>

        <Top>
          <TopSelectComp>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">승인여부</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userSelect}
                onChange={(e) => setUserSelect(e.target.value)}
              >
                <MenuItem value={1}>승인</MenuItem>
                <MenuItem value={0}>비승인</MenuItem>
                <MenuItem value={2}>재인증</MenuItem>
              </Select>
            </FormControl>
          </TopSelectComp>

          {profile?.gm > 1 && (
            <TopSelectComp>
              <FormControl fullWidth>
                <InputLabel id="store-label">가맹점</InputLabel>
                <Select
                  labelId="store-label"
                  id="store-select"
                  value={storeSelect}
                  onChange={(e) => setStoreSelect(e.target.value)}
                >
                  {storeList?.map((item, idx) => {
                    return (
                      <MenuItem value={item.value}>{item?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TopSelectComp>
          )}
          {profile?.gm > 1 && (
            <>
              {storeSelect !== "전체" && (
                <>
                  {DLMode === false && (
                    <TopSelectComp>
                      <FormControl fullWidth>
                        <InputLabel id="store-label">딜러</InputLabel>
                        <Select
                          labelId="store-label"
                          id="store-select"
                          value={dlSelect}
                          onChange={(e) => setDlSelect(e.target.value)}
                        >
                          {DlList?.map((item, idx) => {
                            return (
                              <MenuItem value={item.value}>
                                {item?.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TopSelectComp>
                  )}
                </>
              )}
            </>
          )}
          <TopButton>
            <ButtonCompoPath>
              <Button
                fullWidth
                variant="contained"
                color="success"
                onClick={() => _userAccess()}
              >
                승인
              </Button>
            </ButtonCompoPath>

            {DLMode === false && (
              <ButtonCompoPath>
                <Button
                  fullWidth
                  color="error"
                  variant="contained"
                  onClick={() => _userDelete()}
                >
                  삭제
                </Button>
              </ButtonCompoPath>
            )}
          </TopButton>
        </Top>

        <Top>
          <TopButton1>
            {/*  <ButtonCompoPath>
              <Button
                fullWidth
                variant="contained"
                onClick={() => _userhandlePush()}
              >
                푸쉬 발송
              </Button>
            </ButtonCompoPath> */}
            {profile?.gm > 0 && (
              <>
                {profile?.gmbase === false && (
                  <>
                    <ButtonCompoPath>
                      <Button
                        fullWidth
                        color="success"
                        variant="contained"
                        onClick={() => _userDLTrue()}
                      >
                        딜러 설정
                      </Button>
                    </ButtonCompoPath>
                    <ButtonCompoPath>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          setOffset(0);
                          setDLMode(!DLMode);
                        }}
                      >
                        {DLMode ? "회원 보기" : "딜러 보기"}
                      </Button>
                    </ButtonCompoPath>
                    <ButtonCompoPath>
                      <Button
                        fullWidth
                        color="error"
                        variant="contained"
                        onClick={() => _userDeleteDL()}
                      >
                        딜러 제거
                      </Button>
                    </ButtonCompoPath>
                  </>
                )}
              </>
            )}
          </TopButton1>
        </Top>
        {DLMode && (
          <Top>
            <TextCompo Sales>
              <TextField
                fullWidth
                type="text"
                id="standard-basic"
                label={"딜러 수수료"}
                variant="standard"
                value={Salea}
                onChange={(e) => {
                  setSalseA(e.target.value);
                }}
              />
            </TextCompo>
            <ButtonCompoPath turn>
              <Button
                onClick={() => {
                  _updateUser();
                }}
                fullWidth
                variant="contained"
              >
                변경
              </Button>
            </ButtonCompoPath>

            <TextCompo Sales>
              <TextField
                fullWidth
                type="text"
                id="standard-basic"
                value={sale}
                onChange={(e) => {
                  setSale(e.target.value);
                }}
                label={"딜러 추가 수수료"}
                variant="standard"
              />
            </TextCompo>

            <ButtonCompoPath>
              <Button
                onClick={() => {
                  _handleAddPrice("Add");
                }}
                fullWidth
                variant="contained"
              >
                추가
              </Button>
            </ButtonCompoPath>
            <ButtonCompoPath
              onClick={() => {
                _handleAddPrice("Miner");
              }}
              turn
            >
              <Button fullWidth variant="contained" color="success">
                환급
              </Button>
            </ButtonCompoPath>
            <TextCompo Sales left>
              <TextField
                fullWidth
                type="text"
                id="standard-basic"
                value={dc}
                onChange={(e) => {
                  setDc(e.target.value);
                }}
                label={"포인트 입력"}
                variant="standard"
              />
            </TextCompo>

            <ButtonCompoPath>
              <Button
                fullWidth
                onClick={() => {
                  _handlePoint("Add");
                }}
                variant="contained"
              >
                추가
              </Button>
            </ButtonCompoPath>
            <ButtonCompoPath>
              <Button
                onClick={() => {
                  _handlePoint("Miner");
                }}
                fullWidth
                color="error"
                variant="contained"
              >
                차감
              </Button>
            </ButtonCompoPath>
          </Top>
        )}
        <SelectContainer>
          <TopSelectComp invite>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">분류</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
              >
                <MenuItem value={0}>아이디</MenuItem>
                <MenuItem value={1}>이름</MenuItem>
              </Select>
            </FormControl>
          </TopSelectComp>
          <TopSelectComp inviteR>
            <TextField
              fullWidth
              type="text"
              value={search}
              onChange={(e) => {
                setOffset(0);
                setPage([])
                if (filter === null) {
                  alert("필터를 설정해주세요");
                  return;
                }
                if (e.target.value === "") {
                  setSearch(null);
                } else {
                  setSearch(e.target.value);
                }
              }}
              id="standard-basic"
              label={filter === 0 ? "아이디 " : "이름"}
              variant="standard"
            />
          </TopSelectComp>
        </SelectContainer>
        {/*     <DataTable>
          <colgroup></colgroup>
          <thead>
            <tr>
              <LinedTag style={{ width: 80, textAlign: "center" }}>
                <input
                  type="checkbox"
                  checked={checked}
                  onClick={() => {
                    _handleAllSelct();
                  }}
                  style={{ WebkitAppearance: "auto" }}
                />
              </LinedTag>

              <th>아이디(휴대폰번호)</th>
              <th>이름</th>
              <th>이메일</th>
              <th>소속</th>

              <th>성인여부</th>
              <th>더보기</th>
            </tr>
          </thead>

          {userlist?.map((item, idx) => {
            return (
              <tbody>
                <tr>
                  <LinedTag style={{ width: 80, textAlign: "center" }}>
                    <input
                      type="checkbox"
                      checked={item.validate}
                      onClick={() => userCheck(idx)}
                    />
                  </LinedTag>

                  <LinedTag>{item.email}</LinedTag>
                  <LinedTag>{item.name}</LinedTag>
                  <LinedTag>{item.useremail}</LinedTag>
                  <LinedTag>{item.Store?.name}</LinedTag>

                  <LinedTag>{item.child ? "비성인" : "성인"}</LinedTag>
                  <HoverTag>
                    <Link
                      to={{
                        pathname: "/admin/user/Detail",
                        state: {
                          userData: item,
                        },
                      }}
                    >
                      더보기
                    </Link>
                  </HoverTag>
                </tr>
              </tbody>
            );
          })}
        </DataTable> */}

        <TableContainer style={{ marginTop: 24 }}>
          <Table style={{ minWidth: 1100 }} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  {DLMode === false && (
                    <Checkbox
                      size="small"
                      color="success"
                      checked={checked}
                      onClick={() => {
                        _handleAllSelct();
                      }}
                    />
                  )}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  회원 분류
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  아이디(휴대폰번호)
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  이름
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  가맹점
                </TableCell>
                {DLMode && (
                  <TableCell className={classes.tableCell} align="center">
                    소속회원수
                  </TableCell>
                )}
                {DLMode ? (
                  <>
                    {" "}
                    <TableCell className={classes.tableCell} align="center">
                      딜러 수수료
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      누적 수수료
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      포인트
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell className={classes.tableCell} align="center">
                      소속
                    </TableCell>
                  </>
                )}

                {DLMode && (
                  <TableCell className={classes.tableCell} align="center">
                    딜러코드
                  </TableCell>
                )}
                <TableCell className={classes.tableCell} align="center">
                  가입일
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  추천인
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  더보기
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBody}>
              {userlist?.map((item, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell className={classes.tableCell} align="center">
                      {/*    <input
                          type="checkbox"
                          checked={item.validate}
                          onClick={() => userCheck(idx)}
                        /> */}
                      {DLMode ? (
                        <Checkbox
                          size="small"
                          onClick={() => _handleSelect(item.id)}
                          checked={item.id === select}
                          color="success"
                        />
                      ) : (
                        <Checkbox
                          size="small"
                          checked={item.validate}
                          onClick={() => userCheck(idx)}
                          color="success"
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item.gm > 0
                        ? "관리자"
                        : item.DLMode === true
                          ? "딜러"
                          : "회원"}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item.email}
                    </TableCell>

                    <TableCell className={classes.tableCell} align="center">
                      {item.name}
                    </TableCell>
                    {DLMode && (
                      <>
                        <TableCell className={classes.tableCell} align="center">
                          {item?.Store?.name}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {item?.count}
                        </TableCell>
                      </>
                    )}

                    {DLMode ? (
                      <>
                        <TableCell className={classes.tableCell} align="center">
                          {item.sale}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {item.salea}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {item.dc}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        {" "}
                        <TableCell className={classes.tableCell} align="center">
                          {item.Store?.name}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {item.joincode ? item?.joincode : "딜러"}
                        </TableCell>
                      </>
                    )}

                    {DLMode && (
                      <TableCell className={classes.tableCell} align="center">
                        {item?.code}
                      </TableCell>
                    )}
                    <TableCell className={classes.tableCell} align="center">
                      {moment(item?.createdAt).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item?.joinName}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <Link
                        to={{
                          pathname: "/admin/user/Detail",
                          state: {
                            userData: item,
                          },
                        }}
                      >
                        더보기
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <CustomPagination
            data={userListData}
            setOffset={setOffset}
            page={page}
            setPage={setPage}
          />
        </TableContainer>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(User);
