import React from "react";
import { useState } from "react";
import { withRouter } from "react-router";
import AdminLayer from "./AdminLayer";
// others

const Admin = ({ profile }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerToggleFasle = () => {
    setMobileOpen(false);
  };
  return (
    <div className="App">
      <AdminLayer
        profile={profile}

        mobileOpen={mobileOpen}
        onDrawerToggle={handleDrawerToggle}
        handleDrawerToggleFasle={handleDrawerToggleFasle}
      />
    </div>
  );
};

export default withRouter(Admin);
