import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { GetInforMation, GetStoreInfo } from "Datas/swr";
import { updateStore } from "Datas/api";
import { Fade } from "react-reveal";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
`;

const Content = styled.div``;

const Discount = ({ profile }) => {
  const { InfoData, InfoDataMutate } = GetInforMation();

  //SWR
  const { StoreInfoData, StoreInfoMutate, isLoading } = GetStoreInfo(
    profile?.storeid
  );

  const [datas, setDatas] = useState({
    dc: "",
    sale: "",
    salea: "",
  });

  useEffect(() => {
    if (!isLoading) {
      setDatas({
        dc: StoreInfoData?.dc,
        sale: StoreInfoData?.sale,
        salea: StoreInfoData?.salea,
      });
    }
  }, [StoreInfoData]);

  const _handleUpdate = async () => {
    await updateStore({ id: profile?.storeid, body: datas });
  };

  return (
    <Fade Button>
      <Wrapper style={{ width: "100%" }}>
        <Top style={{ width: "80%", margin: "0px auto" }}>
          <TopLabel>할인율 관리</TopLabel>
          <TopButton>
            <Add onClick={_handleUpdate}>완료</Add>
          </TopButton>
        </Top>
        <Content style={{ width: "100%" }}>
          <div style={{ width: "80%", margin: "0px auto" }}>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <tr>
                <td>{InfoData!== undefined && InfoData.pagenmae} D.C.</td>
                <td>
                  <InputMain
                    value={datas.dc}
                    onChange={(e) => setDatas({ ...datas, dc: e.target.value })}
                    placeholder="D.C 금액을 입력해주세요"
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </tr>
              <tr>
                <td>복지할인률</td>
                <td>
                  <InputMain
                    value={datas.sale}
                    onChange={(e) =>
                      setDatas({ ...datas, sale: e.target.value })
                    }
                    placeholder="복지할인률을 입력해주세요"
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </tr>
              <tr>
                <td>가맹점수수료</td>
                <td>
                  <InputMain
                    disabled
                    value={datas.salea}
                    onClick={() =>
                      alert("수수료를 변경하시려면 최종 관리자에게 문의하세요")
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </tr>
            </table>
          </div>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default Discount;
