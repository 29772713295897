import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { GetBoardList, GetBoardrecList } from "Datas/swr";
import { Link } from "react-router-dom";
import { deleteBoardRec, deletePost } from "Datas/api";
import { Fade } from "react-reveal";
import CustomPagination from "Settings/pagination";
import { useConfirm } from "Settings/util";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";
const Wrapper = styled.div``;

const TopLabel = styled.div`
  margin-right: 12px;
  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
`;

const Top = styled.div`
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;

const TopButton = styled.div`
  display: flex;
  width: 140px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;

    margin-top: 12px;
    width: 100%;
  }
`;
export const ButtonCompoPath = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;
const useStyles = makeStyles({
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#3c4b64",
      padding: 7,
    },
  },

  tableBody: {
    "& .MuiTableBody-root": {
      padding: 7,
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      padding: 7,
    },
  },
});

const TableContainer = styled(Paper)`

  width: 100%;
  overflow: auto;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const Delete = styled.div`
  display: flex;
  background: #ed6060;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const HoverTag = styled.td`
  border-bottom: 1.5px solid #eee;
  &:hover {
    opacity: 0.5;
  }
`;

const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;

const Imgskeleton = styled.img`
  max-width: 200px;
  height: 100px;
  border: none;
  resize: both;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: contain;
`;

const Postrec = () => {
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState([]);

  //SWR
  const { BoardListrecData, BoardListrecMutate, isLoading } =
    GetBoardrecList(offset);

  const [BoardData, setBoardData] = useState([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    let checkd = BoardData.every((item) => {
      return item.validate;
    });
    setChecked(checkd);
  }, [BoardData]);

  const _handleAllSelct = () => {
    //전체샐렉트동기화

    let data = [...BoardData];
    let resdata;
    if (
      BoardData?.some((item, idx) => {
        return item.validate === false;
      }) === true
        ? false
        : true
    ) {
      resdata = data.map((item) => {
        return { ...item, validate: false };
      });
    } else {
      resdata = data.map((item) => {
        return { ...item, validate: true };
      });
    }

    setBoardData(resdata);
    setChecked(true);
  };

  useEffect(() => {
    if (!isLoading) {
      const Boards = BoardListrecData?.rows?.map((item, idx) => {
        return { ...item, validate: false };
      });
      setBoardData(Boards);
    }
  }, [BoardListrecData]);

  console.log(BoardListrecData);

  const _handleSelect = (id) => {
    let temp = [...BoardData];
    BoardData?.map((el, idx) => {
      if (el?.id === id) {
        let data = {
          ...el,
          validate: !el?.validate,
        };
        temp[idx] = data;
        console.log(data);
      }
    });
    setBoardData(temp);
  };
  const desk = useConfirm(
    "삭제하시겠습니까?",
    async () => {
      for (let index = 0; index < BoardData.length; index++) {
        if (BoardData[index]?.validate) {
          let id = BoardData[index]?.id;
          await deleteBoardRec({ id: id });
        }
      }
    },
    () => {
      alert("삭제 를 취소하였습니다.");
    }
  );
  const _handleDelete = async () => {
    desk();
  };
  const classes = useStyles();
  return (
    <Fade Button>
      <Wrapper>
        <Top>
          <TopLabel>이벤트 관리</TopLabel>
          <TopButton>
            <ButtonCompoPath>
              <Link to="/admin/postrec/detail/add">
                <Button fullWidth variant="contained" color="success">
                  추가
                </Button>
              </Link>
            </ButtonCompoPath>
            <ButtonCompoPath>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={_handleDelete}
              >
                삭제
              </Button>
            </ButtonCompoPath>
          </TopButton>
        </Top>

        <TableContainer style={{ marginTop: 24 }}>
          <Table style={{ minWidth: 1100 }} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    size="small"
                    color="success"
                    checked={
                      BoardData?.some((item, idx) => {
                        return item.validate === false;
                      }) === true
                        ? false
                        : true
                    }
                    onClick={() => {
                      _handleAllSelct();
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  제목
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  썸네일
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  더보기
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBody}>
              {BoardData?.length !== 0 &&
                BoardData?.map((el, idx) => {
                  console.log(el);

                  return (
                    <TableRow key={idx}>
                      <TableCell className={classes.tableCell} align="center">
                        <Checkbox
                          size="small"
                          color="success"
                          checked={el?.validate}
                          onClick={() => _handleSelect(el?.id)}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.title}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.titleimages?.length !== 0 ? (
                          <Imgskeleton src={el?.titleimages[0]}></Imgskeleton>
                        ) : (
                          "없음"
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <Link
                          to={{
                            pathname: `/admin/postrec/detail/${el?.id}`,
                            state: el,
                          }}
                        >
                          수정
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomPagination
            data={BoardListrecData}
            setOffset={setOffset}
            page={page}
            setPage={setPage}
          />
        </TableContainer>

        <CustomPagination
          data={BoardListrecData}
          setOffset={setOffset}
          page={page}
          setPage={setPage}
        />
      </Wrapper>
    </Fade>
  );
};

export default Postrec;
