import React, { Component } from "react";
import styled from "styled-components";

import { GetBoardList, GetBoardrecList, GetInforMation } from "Datas/swr";
import { Link } from "react-router-dom";
import Sliders from "Components/Sliders";
import { Fade } from "react-reveal";
import { useState } from "react";
import { useEffect } from "react";
import CustomPagination from "Settings/pagination";

const Wrapper = styled.div`
min-height: 72vh;
`;

const Leaderboard = styled.div`
  width: 100%;
  margin-top: 100px;
  height: 550px;
  background: #f0f0f0;

  @media screen and (max-width: 768px) {
    height: 320px;
  }

  @media screen and (max-width: 375px) {
    height: 207px;
  }
`;

const AnnouncementWrapper = styled.div`
  width: 1380px;
  display: flex;
  flex-direction: column;
  max-width: 1380px;
  width: 100%;
  margin: 0 auto;
  margin-top: 140px;

  @media screen and (max-width: 1304px) {
    width: 96.5%;
    margin-left: 30px;
  }
  @media screen and (max-width: 1100px) {
    width: 96.5%;
    margin-left: 30px;
  }
  @media screen and (max-width: 768px) {
    width: 95%;
    margin-left: 25px;
  }
`;

const AnnouncementText = styled.div`
  font-size: 25px;
  color: #feb43c;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    font-weight: 600;
    margin-left: 2%;
  }
  @media screen and (max-width: 375px) {
    font-size: 14px;
    font-weight: 600;
    margin-left: 2%;
  }
`;

const AnnouncementSubText = styled.div`
  margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    text-align: center;
    margin: 0px 0px 35px -25px;
    font-size: 25px;
  }
`;

const AnnouncementBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

const AnnouncementBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  border: 1px solid #dcdcdc;
  max-width: 735px;
  background: #fff;
  width: 31.8%;
  margin-bottom: 35px;
  border-radius: 10px;
  :nth-of-type(3n) {
    margin-right: 0px;
  }
  @media (max-width: 1304px) {
    width: 31.4%;
  }
  @media (max-width: 1080px) {
    width: 47.5%;
    :nth-of-type(2n) {
      margin-right: 0px;
    }
  }
  @media (max-width: 768px) {
    width: 46.5%;
  }
  @media (max-width: 600px) {
    width: 93.5%;
  }
  @media (max-width: 360px) {
    width: 88%;
  }
`;

const BoxThunbmail = styled.div`
  :hover {
    opacity: 0.5;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  object-fit: cover;
  height: 228px;
  border-radius: 10px 10px 0 0;
`;

const BoxTitle_B = styled.div`
  padding: 3px 10px;
  color: white;
  background-color: #f4125c;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
`;

const BoxTitle = styled.div`
  padding: 5px 13px 3px;
  font-size: 13px;
  font-weight: 500;
  color: #777;
  @media screen and (max-width: 768px) {
    font-size: 13px;
    font-weight: 500;
  }
  @media screen and (max-width: 375px) {
    font-size: 13px;
    font-weight: 500;
  }
`;

const BoxDesc = styled.div`
  margin: 10px 20px 25px 20px;
  color: #a7a7a7;
`;

const PreOrder = () => {
  //SWR
  const [offset, setOffset] = useState(0);
  const { BoardListrecData, isLoading } = GetBoardrecList(offset);
  const { InfoData, InfoDataMutate } = GetInforMation();
  const [page, setPage] = useState([]);
  const [BoardData, setBoardData] = useState([]);

  console.log(BoardListrecData);
  useEffect(() => {
    if (!isLoading) {
      setBoardData(BoardListrecData?.rows);
    }
  }, [BoardListrecData]);

  console.log(BoardData, "데이터");
  return (
    <Fade Button>
      <Wrapper>
        {/*   <Sliders /> */}
        <AnnouncementWrapper>
          <AnnouncementSubText>이벤트</AnnouncementSubText>
          <AnnouncementBoxWrapper>
            {BoardData?.map((el, idx) => {
              return (
                <>
                  <AnnouncementBox>
                    <Link
                      to={{
                        pathname: `/preorder/detail/${el?.id}`,
                        state: {
                          id: el?.id,
                          title: el?.title,
                          createdAt: el?.createdAt,
                          contents: el?.contents,
                          url: el?.url,
                          titleimages: el?.titleimages,
                          images: el?.images,
                        },
                      }}
                    >
                      <BoxThunbmail>
                        <img
                          style={{
                            resize: "contain",
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                          src={el?.titleimages[0]}
                        />
                      </BoxThunbmail>

                      <div
                        style={{
                          display: "flex",
                          paddingBottom: 16,
                          paddingTop: 7,
                          paddingLeft: 15,
                          alignItems: "center",
                        }}
                      >
                        <BoxTitle_B>이벤트</BoxTitle_B>
                        <BoxTitle>{el?.title}</BoxTitle>
                      </div>
                    </Link>
                  </AnnouncementBox>
                </>
              );
            })}
            <CustomPagination
              data={BoardListrecData}
              setOffset={setOffset}
              page={page}
              setPage={setPage}
            />
          </AnnouncementBoxWrapper>
        </AnnouncementWrapper>
      </Wrapper>
    </Fade>
  );
};

export default PreOrder;
