import React from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
// Material-ui 컴포넌트들
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// 아이콘들
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import TableIcon from "@material-ui/icons/TableChart";
import ChartIcon from "@material-ui/icons/ShowChart";
import PagesIcon from "@material-ui/icons/Pages";
import IconsIcon from "@material-ui/icons/Mood";
import { GetInforMation, GetInformationOrigin } from "Datas/swr";
// others
import styled from "styled-components";
import { AddCircleOutlineRounded } from "@material-ui/icons";
import { WebRounded } from "@material-ui/icons";
import { PersonRounded } from "@material-ui/icons";
import { ShoppingCart } from "@material-ui/icons";
import { ShoppingCartOutlined } from "@material-ui/icons";
import { PersonOutlineRounded } from "@material-ui/icons";
import { ImageSearchOutlined } from "@material-ui/icons";
import { LibraryAddRounded } from "@material-ui/icons";
import { LibraryAddOutlined } from "@material-ui/icons";
import { ShopOutlined } from "@material-ui/icons";
import { CreditCardOutlined } from "@material-ui/icons";
import { LocalPhoneOutlined } from "@material-ui/icons";
import { PermPhoneMsgOutlined } from "@material-ui/icons";
import { CategoryOutlined } from "@material-ui/icons";
import { EventOutlined } from "@material-ui/icons";
import { NotificationsOutlined } from "@material-ui/icons";
import { ContactlessOutlined } from "@material-ui/icons";
import { ControlPointDuplicateOutlined } from "@material-ui/icons";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,

    padding: "16px 30px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    color: "#ffffff",
    fontSize: "1.2em",
    backgroundColor: "#3c4b64",
  },
  logoimg: {
    width: "32px",
    height: "32px",
    verticalAlign: "middle",
  },
  mainmenuBox: { padding: "15px 0", backgroundColor: "#3c4b64", fontSize: 12 },
  mainmenuWrap: { padding: "10px 34px 10px 30px" },
  mainmenu: { color: "#fff", fontSize: 15 },
}));

const SideProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SideProfileRow = styled.div`
  display: flex;
  align-items: center;
`;

const SideProfileName = styled.div`
  font-size: 12px;
  color: #fff;
`;

const SideProfileJob = styled.div`
  font-size: 12px;
  color: #fff;
`;
const SideImg = styled.div`
  background-image: ${(props) => {
    if (props.url) {
      return `url(${props.url})`;
    }
  }};
  background-size: contain;
  background-color: #fff;
  width: 95px;
  height: 95px;
  border-radius: 999px;
  margin-bottom: 10px;
  border: 1px solid #01c9af;
`;
const SideImgFlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SideProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #3c4b64;

  align-items: center;
  justify-content: center;
  padding: 0px;
  padding-bottom: 16px;
  padding-top: 16px;
`;
const MainLogo = styled.div`
  width: 175px;
  height: 30px;
  background-image: ${(props) => {
    if (props.src) {
      return `url('${props.src}')`;
    }
  }};
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
`;
const ResponsiveDrawer = ({ profile, onDrawerToggle }) => {
  const history = useHistory();
  const classes = useStyles();
  const { OriginData, OriginMutate } = GetInformationOrigin();

  const locaton = useLocation();
  console.log(locaton, "현재로케이션");

  const Navigator = () => {
    onDrawerToggle();
  };
  useEffect(() => {
    let unlisten = history.listen((location) => {
      if (history.action === "PUSH") {
        onDrawerToggle();
      }
      if (history.action === "POP") {
        onDrawerToggle();
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);
  console.log(OriginData?.image, "제임베이스");
  console.log(profile, "프로필");
  return (
    <div>
      <NavLink to="/admin/user" activeClassName="selected">
        <div className={classes.toolbar}>
          {/* <img src={LogoImage} alt="Logo" className={classes.logoimg} /> */}

          {/* 작업 시작 */}

          <MainLogo
            src={OriginData?.image?.length > 0 && OriginData?.image[0]}
          />
        </div>
      </NavLink>
      <SideProfileWrapper>
        <SideImgFlexBox>
          <SideImg
            url={profile?.image.length > 0 && profile?.image[0]}
          ></SideImg>
          <SideProfile>
            <SideProfileRow style={{ marginBottom: 5 }}>
              <SideProfileName>
                {profile?.name}{" "}
                {profile?.gm > 1 && <>{profile?.gm < 3 && "가맹주 님"}</>}
                {profile?.DLMode && "딜러님"}
              </SideProfileName>
            </SideProfileRow>
            <SideProfileJob></SideProfileJob>
            {profile.gm > 0 && (
              <>
                <SideProfileJob>
                  누적수수료: {Number(profile?.salea).toLocaleString()}원
                </SideProfileJob>
                <SideProfileJob>
                  수수료: {Number(profile?.sale).toLocaleString()}원
                </SideProfileJob>
                <SideProfileJob>
                  가맹포인트: {Number(profile?.point).toLocaleString()}원
                </SideProfileJob>
              </>
            )}
            {profile.DLMode && (
              <>
                <SideProfileJob>
                  누적수수료: {Number(profile?.salea).toLocaleString()}원
                </SideProfileJob>
                <SideProfileJob>
                  수수료: {Number(profile?.sale).toLocaleString()}원
                </SideProfileJob>
                <SideProfileJob>
                  가맹포인트: {Number(profile?.point).toLocaleString()}원
                </SideProfileJob>
              </>
            )}
          </SideProfile>
        </SideImgFlexBox>
      </SideProfileWrapper>

      <List
        style={{ marginTop: 0, paddingTop: 0 }}
        className={classes.mainmenuBox}
      >
        <NavLink to="/admin/mainsetting" activeClassName="selected">
          <ListItem
            selected={locaton.pathname.indexOf("/admin/mainsetting") !== -1}
            className={classes.mainmenuWrap}
            button
          >
            <WebRounded
              style={{ marginRight: 12 }}
              className={classes.mainmenu}
            />

            <ListItemText>
              <Typography className={classes.mainmenu}>
                홈페이지 관리
              </Typography>
            </ListItemText>
          </ListItem>
        </NavLink>
        {profile?.gm > 1 ? (
          <NavLink to="/admin/user" activeClassName="selected">
            <ListItem
              selected={locaton.pathname.indexOf("/admin/user") !== -1}
              className={classes.mainmenuWrap}
              button
            >
              <PersonOutlineRounded
                style={{ marginRight: 12 }}
                className={classes.mainmenu}
              />
              <ListItemText>
                <Typography className={classes.mainmenu}>회원관리</Typography>
              </ListItemText>
            </ListItem>
          </NavLink>
        ) : (
          <NavLink to="/admin/user" activeClassName="selected">
            <ListItem
              className={classes.mainmenuWrap}
              selected={locaton.pathname.indexOf("/admin/user") !== -1}
              button
            >
              <PersonOutlineRounded
                style={{ marginRight: 12 }}
                className={classes.mainmenu}
              />
              <ListItemText>
                <Typography className={classes.mainmenu}>
                  가맹회원관리
                </Typography>
              </ListItemText>
            </ListItem>
          </NavLink>
        )}
        <NavLink to="/admin/pointact" activeClassName="selected">
          <ListItem
            className={classes.mainmenuWrap}
            selected={locaton.pathname.indexOf("/admin/pointact") !== -1}
            button
          >
            <AddCircleOutlineRounded
              style={{ marginRight: 12 }}
              className={classes.mainmenu}
            />

            <ListItemText>
              <Typography className={classes.mainmenu}>
                포인트/누적수수료 관리
              </Typography>
            </ListItemText>
          </ListItem>
        </NavLink>

        {OriginData?.gmbase === false && (
          <>
            {profile?.gm > 0 && (
              <>
                <NavLink
                  onClick={() => {
                    Navigator();
                  }}
                  to="/admin/product"
                  activeClassName="selected"
                >
                  <ListItem
                    className={classes.mainmenuWrap}
                    selected={locaton.pathname.indexOf("/admin/product") !== -1}
                    button
                  >
                    <ShoppingCartOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        상품관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>

                <NavLink to="/admin/banner" activeClassName="selected">
                  <ListItem
                    className={classes.mainmenuWrap}
                    selected={locaton.pathname.indexOf("/admin/banner") !== -1}
                    button
                  >
                    <ImageSearchOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        배너관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
                <NavLink to="/admin/qa" activeClassName="selected">
                  <ListItem
                    className={classes.mainmenuWrap}
                    selected={locaton.pathname.indexOf("/admin/banner") !== -1}
                    button
                  >
                    <ImageSearchOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        QA관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
              </>
            )}
          </>
        )}
        <NavLink to="/admin/contract" activeClassName="selected">
          <ListItem
            className={classes.mainmenuWrap}
            selected={locaton.pathname.indexOf("/admin/contract") !== -1}
            button
          >
            <LibraryAddOutlined
              style={{ marginRight: 12 }}
              className={classes.mainmenu}
            />
            <ListItemText>
              <Typography className={classes.mainmenu}>개통관리</Typography>
            </ListItemText>
          </ListItem>
        </NavLink>
        {profile?.gm >= 2 && (
          <>
            <NavLink to="/admin/store" activeClassName="selected">
              <ListItem
                selected={locaton.pathname.indexOf("/admin/store") !== -1}
                className={classes.mainmenuWrap}
                button
              >
                <ShopOutlined
                  style={{ marginRight: 12 }}
                  className={classes.mainmenu}
                />
                <ListItemText>
                  <Typography className={classes.mainmenu}>
                    가맹점 관리
                  </Typography>
                </ListItemText>
              </ListItem>
            </NavLink>
          </>
        )}
        {OriginData?.gmbase === false && (
          <>
            {profile?.gm > 0 && (
              <>
                <NavLink to="/admin/usage" activeClassName="selected">
                  <ListItem
                    selected={locaton.pathname === "/admin/usage"}
                    className={classes.mainmenuWrap}
                    button
                  >
                    <LocalPhoneOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        요금제 관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
                <NavLink to="/admin/groupusage" activeClassName="selected">
                  <ListItem
                    selected={locaton.pathname === "/admin/groupusage"}
                    className={classes.mainmenuWrap}
                    button
                  >
                    <LocalPhoneOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        요금제 그룹 관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
                <NavLink to="/admin/usageCategory" activeClassName="selected">
                  <ListItem
                    selected={
                      locaton.pathname.indexOf("/admin/usageCategory") !== -1
                    }
                    className={classes.mainmenuWrap}
                    button
                  >
                    <PermPhoneMsgOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        요금제 카테고리 관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
                <NavLink to="/admin/card" activeClassName="selected">
                  <ListItem
                    selected={locaton.pathname.indexOf("/admin/card") !== -1}
                    className={classes.mainmenuWrap}
                    button
                  >
                    <CreditCardOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        제휴카드 관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>

                <NavLink to="/admin/category" activeClassName="selected">
                  <ListItem
                    selected={
                      locaton.pathname.indexOf("/admin/category") !== -1
                    }
                    className={classes.mainmenuWrap}
                    button
                  >
                    <CategoryOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        카테고리 관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
                <NavLink to="/admin/promotion" activeClassName="selected">
                  <ListItem
                    selected={
                      locaton.pathname.indexOf("/admin/promotion") !== -1
                    }
                    className={classes.mainmenuWrap}
                    button
                  >
                    <EventOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        프로모션 관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
                <NavLink to="/admin/post" activeClassName="selected">
                  <ListItem
                    selected={locaton.pathname === "/admin/post"}
                    className={classes.mainmenuWrap}
                    button
                  >
                    <NotificationsOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        공지사항 관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
                <NavLink to="/admin/TeleAc" activeClassName="selected">
                  <ListItem
                    selected={locaton.pathname.indexOf("/admin/TeleAc") !== -1}
                    className={classes.mainmenuWrap}
                    button
                  >
                    <ContactlessOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        통신사 관리
                      </Typography>
                    </ListItemText>
                  </ListItem>

                </NavLink>
              </>
            )}
          </>
        )}
     <NavLink to="/admin/pushnoti/detail" activeClassName="selected">
                  <ListItem
                    selected={
                      locaton.pathname.indexOf("/admin/pushnoti/detail") !== -1
                    }
                    className={classes.mainmenuWrap}
                    button
                  >
                    <CategoryOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        푸시 발송
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
        <NavLink to="/admin/pushnoti" activeClassName="selected">
          <ListItem
            selected={locaton.pathname.indexOf("/admin/pushnoti") !== -1}
            className={classes.mainmenuWrap}
            button
          >
            <ContactlessOutlined
              style={{ marginRight: 12 }}
              className={classes.mainmenu}
            />
            <ListItemText>
              <Typography className={classes.mainmenu}>푸쉬 로그</Typography>
            </ListItemText>
          </ListItem>
        </NavLink>

        {OriginData?.gmbase === false && (
          <>
            {profile?.gm > 0 && (
              <>
                <NavLink to="/admin/postrec" activeClassName="selected">
                  <ListItem
                    selected={locaton.pathname === "/admin/postrec"}
                    className={classes.mainmenuWrap}
                    button
                  >
                    <EventOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        이벤트 관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
                <NavLink to="/admin/terms" activeClassName="selected">
                  <ListItem
                    selected={locaton.pathname.indexOf("/admin/terms") !== -1}
                    className={classes.mainmenuWrap}
                    button
                  >
                    <ControlPointDuplicateOutlined
                      style={{ marginRight: 12 }}
                      className={classes.mainmenu}
                    />
                    <ListItemText>
                      <Typography className={classes.mainmenu}>
                        이용약관 관리
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
              </>
            )}
          </>
        )}
      </List>
    </div>
  );
};

export default ResponsiveDrawer;
