import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { withRouter } from "react-router";
import {
  createCategory,
  createPost,
  updateCategory,
  updatePost,
} from "Datas/api";

import { GetUri, ImageHandler } from "Settings/imageHandler";

import { GetBoardList, GetCategoryList } from "Datas/swr";
import { Fade } from "react-reveal";
import Editor from "Components/Editor";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;
const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;
const BannerImg = styled.img`
  width: 690px;
  background: #f0f0f0;
  height: 345px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const LabelImage = styled.label`
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  font-size: 17px;
  background: #6091ed;
  border-radius: 30px;
  padding: 9px 15px;
  color: #fff;
  margin-left: auto;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-left: 30px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
  background: #f4f4f4;
  border-radius: 7px;
  &:hover {
    opacity: 0.75;
  }
  &:focus {
    border: 1.5px solid #a7a7a7;
  }
`;

const Content = styled.div``;

const CategoryDetail = ({ match, history, location }) => {
  //SWR
  const [image, setImage] = useState([]);

  const [filesdata, setfilesCheck] = useState([]);
  const [bdContents, setBdContents] = useState(null);
  const [files, setFiles] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);
  const { BoardListData, BoardListMutate, isLoading } = GetBoardList();
  const { handleRemove, handleCreate } = ImageHandler({
    setImgBase64: setImgBase64,
    setImage: setImage,
  });
  const [datas, setDatas] = useState({
    title: "",
    contents: "",
    images: [],
  });
  const onEditorChange = (value) => {
    setBdContents(value);
  };
  //에디터의 데이터가 바뀔경우 렌더되는 공지사항
  const onFilesChange = (files) => {
    setFiles(files);
  };

  //파일정보가 변경될경우 렌더되는 공지사항

  useEffect(() => {
    if (!isLoading && match?.params?.id !== "add") {
      /* let res = BoardListData?.filter(
        (el, idx) => Number(el?.id) === Number(match?.params?.id)
      ); */
      setImgBase64(location?.state?.images);
      setDatas({
        title: location?.state?.title,
        contents: location?.state?.contents,
        images: location?.state?.images,
      });
      setBdContents(location?.state?.contents);
    }
  }, [match, isLoading]);

  const _handleCreate = async () => {
    const { url } = await GetUri({ image: image });
    console.log(url);
    datas.images = url;
    datas.contents = bdContents;
    await createPost(datas);
    history.goBack();
  };

  const _handleUpdate = async () => {
    if (image?.length !== 0) {
      const { url } = await GetUri({ image: image });
      datas.images = url;
      console.log(url);
    } else {
      datas.images = imgBase64;
    }
    datas.contents = bdContents;
    await updatePost({
      id: Number(match?.params?.id),
      body: datas,
    });
    history.goBack();
  };

  return (
    <Fade Button>
      <Wrapper>
        <Top>
          <TopLabel>공지사항 정보</TopLabel>
          <TopButton>
            <Add
              onClick={() =>
                match?.params?.id === "add" ? _handleCreate() : _handleUpdate()
              }
            >
              완료
            </Add>
          </TopButton>
        </Top>
        <Content>
          <MainContainer>
            <InputLabel>제목</InputLabel>
            <InputMain
              value={datas.title}
              onChange={(e) => setDatas({ ...datas, title: e.target.value })}
              style={{
                paddingLeft: 15,
              }}
              placeholder="제목을 입력해주세요"
            />
            <InputLabel>내용</InputLabel>
            <Editor
              value={bdContents ? bdContents : "공지사항 내용을 작성해주세요"}
              placeholder={"공지사항 내용을 작성해주세요"}
              onEditorChange={onEditorChange}
              setfilesCheck={setfilesCheck}
              filesdata={filesdata}
              onFilesChange={onFilesChange}
            />
            <div style={{ marginTop: 24 }}>
              {imgBase64?.length !== 0 ? (
                imgBase64.map((item, idx) => {
                  return (
                    <BannerImg
                      onClick={() => handleRemove(idx)}
                      src={item}
                    ></BannerImg>
                  );
                })
              ) : (
                <div style={{ paddingTop: 12, paddingBlock: 12 }}>
                  <LabelImage style={{ borderRadius: 0 }} for="upload-file">
                    썸네일 등록
                  </LabelImage>
                  <input
                    type="file"
                    name="upload-file"
                    style={{ display: "none" }}
                    onChange={handleCreate}
                    id="upload-file"
                  />
                </div>
              )}
              <input
                type="file"
                name="upload-file"
                style={{ display: "none" }}
                onChange={handleCreate}
                id="upload-file"
              />
            </div>
          </MainContainer>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(CategoryDetail);
