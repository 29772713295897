import React, { Component, useState } from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import ReactStars from "react-stars";
import EditorMobile from "Components/EditorMobile";
import { GetUri, ImageHandler } from "Settings/imageHandler";
import axios from "axios";
import { GetUserOrderList } from "Datas/swr";
import { Forward } from "@material-ui/icons";
import AlertModal from "Modal";
const Wrapper = styled.div``;

const WriteReviewText = styled.div`
  min-width: 120px;
  font-size: 24px;
  font-weight: bolder;
  margin-right: 20px;
  margin-bottom: 15px;
  font-weight: 700;
  @media screen and (max-width: 450px) {
    font-size: 25px;
    margin-bottom: 0px;
  }
`;

const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 750px;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  margin-top: 200px;
  margin-bottom: 110px;
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 450px) {
    margin-top: 165px;
  }
`;

export const ReviewTitleArea = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid #000;
  @media (max-width: 450px) {
    display: block;
    margin-top: -30px;
    text-align: center;
    padding-bottom: 35px;
  }
`;

export const ReviewTitleMessage = styled.div`
  min-width: 230px;
  font-size: 15px;
  color: #777;
  line-height: 34px;
`;

export const SectionArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const SectionEditArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  @media (max-width: 450px) {
    display: block;
  }
`;

const CredentialsInputText = styled.div`
  min-width: 120px;
  font-size: 16px;
  color: #333;
  width: 25%;
  height: 40px;
  background-color: #f3f3f3;
  text-align: left;
  padding-left: 10px;
  line-height: 40px;
  border-radius: 7px;
  margin-right: 25px;
  @media (max-width: 450px) {
    background-color: #fff;
    min-width: 50px;
    width: 15%;
    padding-left: 0;
  }
`;

const CredentialsImgText = styled.div`
  min-width: 120px;
  font-size: 16px;
  color: #333;
  width: 25%;
  height: 40px;
  background-color: #f3f3f3;
  text-align: left;
  padding-left: 10px;
  line-height: 40px;
  border-radius: 7px;
  margin-right: 25px;
  @media (max-width: 450px) {
    background-color: #fff;
    padding-left: 0;
    margin-right: 15px;
  }
`;

const CredentialsEditInputText = styled.div`
  min-width: 120px;
  font-size: 16px;
  color: #333;
  width: 25%;
  height: 40px;
  background-color: #f3f3f3;
  text-align: left;
  padding-left: 10px;
  line-height: 40px;
  border-radius: 7px;
  margin-right: 25px;
  @media screen and (max-width: 450px) {
    margin-bottom: 5px;
    background-color: #fff;
    min-width: 50px;
    width: 15%;
    padding-left: 0;
  }
`;

const CredentialsInputInput = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  color: #000;
  padding: 10px;

  @media screen and (max-width: 450px) {
    height: 40px;
  }
`;

const ReviewContentWrapper = styled.div``;

const ReviewContentTextarea = styled.textarea`
  width: 100%;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  resize: none;
  height: 255px;
`;

const FinishBtn = styled.div`
  width: 35%;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  height: 55px;
  color: #fff;
  background: #000;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  margin: 30px auto;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const ReviewImgTop = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`;

const ReviewImgImg = styled.div`
  background: #f0f0f0;
  height: 330px;
  width:220px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => {
    let url;
    if (props.url) {
      url = `url(${props.url})`;
    } else {
    }
    return url;
  }};
  margin-top: 10px;
  border-radius: 10px;
`;

const ReviewImgText = styled.div`
  font-size: 16px;
  color: #333;

  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`;

const ReviewImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReviewImgAddBtn = styled.label`
  min-width: 150px;
  width: 150px;
  height: 40px;
  display: flex;
  font-size: 14px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  margin-left: auto;
  background: #fff;
  font-weight: 500;
  border-radius: 6px;
  padding: 5px 11px;
  color: #111;
  border: 1px solid #333;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
`;
{
  /* 바꾸고싶다면 이부분을 플랫폼 분기하여주세용 :D -Murphy Studios */
}
const WriteReview = ({ profile, match, history }) => {
  const [data, setData] = useState({
    title: "",
    contetns: "",
    star: 0,
    images: "",
  });
  console.log(profile);
  console.log(match);
  const [BoardData, setBoardData] = useState("");
  const [filesdata, setfilesCheck] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);
  const [image, setImage] = useState([]);
  const [files, setFiles] = useState([]);
  const { GetUserOrderListData, GetUserOrderListMutate } = GetUserOrderList(
    profile?.userId
  );
  const { handleRemove, handleCreate } = ImageHandler({
    setImgBase64: setImgBase64,
    setImage: setImage,
  });

  const onFilesChange = (files) => {
    console.log(files);
    setFiles(files);
  };
  const onEditorChange = (value) => {
    setBoardData(value);
  };

  const _handleSubmit = async () => {
    const { url } = await GetUri({ image: image });
    data.contents = BoardData;
    data.images = url;
    data.userid = profile?.userId;
    data.productid = match.params.id;
    data.orderid = match.params.orderid;
    try {
      let res = await axios.post("product/liviewCreate", data);
      if (res.data.status === 400) {
        setIsModal(true);
        return;
      }

      await GetUserOrderListMutate();
      history.goBack();
    } catch {
      alert("리뷰 등록실패!");
    }
  };
  const [isModal, setIsModal] = useState(false);
  return (
    <Fade Button>
      <Wrapper>
        <AlertModal
          open={isModal}
          close={() => {
            setIsModal(false);
          }}
          header="알림"
        >
          이미 리뷰를 작성하셨습니다.
        </AlertModal>
        <ReviewWrapper>
          <ReviewTitleArea>
            <WriteReviewText>리뷰 쓰기</WriteReviewText>
            <ReviewTitleMessage>
              상품구매 리뷰를 작성하여 주세요.
            </ReviewTitleMessage>
          </ReviewTitleArea>
          <SectionArea>
            <CredentialsInputText>제목</CredentialsInputText>
            <CredentialsInputInput
              value={data?.title}
              onChange={(e) => setData({ ...data, title: e.target.value })}
            ></CredentialsInputInput>
          </SectionArea>

          <SectionArea style={{ justifyContent: "flex-start" }}>
            <CredentialsInputText>별점</CredentialsInputText>
            <div style={{ display: "flex", width: "100%" }}>
              <ReactStars
                count={5}
                value={data.star}
                onChange={(e) => setData({ ...data, star: e })}
                size={35}
                color2={"orange"}
              />
            </div>
          </SectionArea>

          <SectionEditArea style={{ alignItems: "flex-start" }}>
            <CredentialsEditInputText>내용</CredentialsEditInputText>
            <EditorMobile
              value={BoardData}
              onEditorChange={onEditorChange}
              setfilesCheck={setfilesCheck}
              filesdata={filesdata}
              onFilesChange={onFilesChange}
            />
          </SectionEditArea>

   {/*        <SectionArea
            style={{
              borderBottom: "1px solid #222",
              paddingBottom: 20,
            }}
          >
            <CredentialsImgText>타이틀 이미지</CredentialsImgText>
            <div
              style={{
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ReviewImgAddBtn for="upload-file">파일추가 +</ReviewImgAddBtn>
              <input
                type="file"
                accept="image/*"
                name="upload-file"
                style={{ display: "none" }}
                onChange={handleCreate}
                id="upload-file"
              ></input>
            </div>
            {imgBase64.length !== 0 && (
              <ReviewImgImg
                onClick={() => handleRemove(0)}
                url={imgBase64.length !== 0 && imgBase64[0]}
              />
            )}
          </SectionArea> */}
          <FinishBtn onClick={() => _handleSubmit()}>작성완료</FinishBtn>
        </ReviewWrapper>
      </Wrapper>
    </Fade>
  );
};

export default WriteReview;
