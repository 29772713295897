import Navigation from "./Settings/router";
import axios from "axios";
import { baseurl } from "./Settings/common";
import { getStoarge, setStoarge } from "./Datas/storage";
import { SWRConfig } from "swr";
import { UserProvider } from "./Datas/storage";
import { useEffect, useCallback } from "react";
import "./Settings/glStyles.css";
import { useState } from "react";
import Layer from "Settings/Layer";
import { RecoilRoot, useRecoilState } from "recoil";
import { detect } from "detect-browser";

axios.defaults.baseURL = baseurl;
axios.interceptors.request.use(
  async (config) => {
    const jwt = await getStoarge("jwt");

    if (jwt) {
      config.headers.Authorization = `Bearer ${jwt}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

const App = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const browser = detect();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerToggleFasle = () => {
    setMobileOpen(false);
  };
  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", async (post) => {
        let fcm = JSON.parse(post.data).fcmToken;

        if (
          (await setStoarge("fcmToken")) === undefined ||
          setStoarge("fcmToken") !== fcm
        ) {
          await localStorage.setItem(
            "fcmToken",
            JSON.stringify({ fcmToken: fcm })
          );
        }
      });
      window.addEventListener("message", async (post) => {
        let fcm = JSON.parse(post.data).fcmToken;
        if (
          (await setStoarge("fcmToken")) === undefined ||
          setStoarge("fcmToken") !== fcm
        ) {
          await localStorage.setItem(
            "fcmToken",
            JSON.stringify({ fcmToken: fcm })
          );
        }
      });
    }
  });

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  return (
    <SWRConfig
      value={{
        fetcher: (path, options) =>
          axios(path, options).then((res) => res.data),
      }}
    >
      <RecoilRoot>
        <UserProvider>
          <Layer
            mobileOpen={mobileOpen}
            onDrawerToggle={handleDrawerToggle}
            handleDrawerToggleFasle={handleDrawerToggleFasle}
          />
        </UserProvider>
      </RecoilRoot>
    </SWRConfig>
  );
};

export default App;
