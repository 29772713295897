import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { CheckPhone, validateEmail, validatePassword } from "Settings/common";
import {
  sends,
  userCreate,
  uploadImage,
  updateUser,
  SendSecure,
} from "Datas/api";
import { getStoarge, setStoarge, useAuth } from "Datas/storage";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { LoginUser } from "Datas/api";

const Wrapper = styled.div``;

const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 570px;
  padding: 0 20px;
`;

const SignupText = styled.div`
  font-size: 35px;
  //padding-top: 1000px;
  @media screen and (max-width: 450px) {
    font-size: 25px;
  }
  margin-bottom: 15px;
  font-weight: 700;
`;

const CredentialsInputText = styled.div`
  font-size: 25px;
  color: #838383;
  margin-top: 20px;

  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`;

const CredentialsInputInput = styled.input`
  width: 100%;
  margin-top: 5px;
  height: 65px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  color: #000;
  padding: 10px;

  @media screen and (max-width: 450px) {
    height: 45px;
  }
`;

const SignupBtn = styled.div`
  width: 100%;

  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  height: 65px;
  color: #fff;
  background: #feb43c;
  border: 1px solid #feb43c;
  border-radius: 10px;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  @media screen and (max-width: 450px) {
    height: 45px;
    font-size: 18px;
  }
  margin-bottom: 80px;
`;

const SubTagButton = styled.div`
  width: 100%;
  height: 60px;
  :hover {
    opacity: 0.65;
  }
  cursor: pointer;
  color: #fff;
  background: ${(props) => {
    let color;
    if (props.able === true) {
      color = "#feb43c";
    } else {
      color = "#eee";
    }
    return color;
  }};
  border: 1px solid #feb43c;
  border-radius: 10px;
  margin-right: 12px;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
`;

const TintBox = styled.p`
  color: red;
  margin-top: 12px;
`;

const FingerReset = ({ history, location, match }) => {
  console.log(match?.params);

  const { setLogin } = useAuth();
  const fpPromise = FingerprintJS.load();
  const [data, setData] = useState({
    email: "",
  });
  const [isTimer, setIsTimer] = useState(false);
  const [auth, setAuth] = useState({
    authcode: "",
    userauth: "",
    auth: false,
    check: false,
  });
  const [image, setImage] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);
  const [seconds, setSeconds] = useState(parseInt(0));
  const [minutes, setMinutes] = useState(parseInt(5));

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  const _confrim = async () => {
    if (auth.authcode == auth.userauth) {
      setAuth({ ...auth, check: true, auth: false });
      alert("인증 완료!");
      const fp = await fpPromise;
      const result = await fp.get();
      const visitorId = result.visitorId;

      let body = {
        finger: visitorId,
        fingertype: false,
      }; //인증대기 모드
      //핑거타입 테이블 추가
      await updateUser({ id: match?.params?.id, body: body });

      alert(
        "기기 재인증 요청이 완료되었습니다 관리자에서 승인후 이용가능합니다."
      );
      history.push("/");
    } else {
      alert("인증번호가 틀립니다");
    }
  };

  const _sends = async () => {
    //특점함수 호출
    setIsTimer(true);
    let result = await SendSecure({ userId: match?.params?.id });
    if (result === undefined) {
      alert("인증번호 발급실패 네트워크를 확인하여주세요");
    } else {
      alert("인증 번호 발급성공 인증을 진행하여주세요");
    }

    setAuth({ ...auth, authcode: result.data.code, auth: true }); //코드할당 및 인증상태
    //타이머 관련 세팅
  };

  //제거용
  //이미지 업로드 핸들러

  //로그인날려주기

  //이미지 업로더 만들어야함
  return (
    <Wrapper>
      <Inner>
        <SignupText style={{ marginTop: 154 }}>기기 인증</SignupText>
        {auth.auth ? (
          <>
            <CredentialsInputText>인증번호 입력</CredentialsInputText>
            <CredentialsInputInput
              value={auth.userauth}
              onChange={(e) => setAuth({ ...auth, userauth: e.target.value })}
              placeholder="인증번호를 입력해주세요"
            />
            <TintBox>
              {minutes} 분 : {seconds} 초
            </TintBox>
            <div style={{ display: "flex" }}>
              <SubTagButton able onClick={() => _confrim()}>
                인증
              </SubTagButton>
              <SubTagButton
                able
                onClick={() => setAuth({ ...auth, authcode: "", auth: false })}
              >
                재인증
              </SubTagButton>
            </div>
          </>
        ) : (
          <>
            <CredentialsInputText>기기인증 진행</CredentialsInputText>

            {auth.check === false && (
              <>
                <SubTagButton able onClick={() => _sends()}>
                  {isTimer ? (
                    <Loader
                      type="TailSpin"
                      color="white"
                      height={35}
                      width={35}
                    />
                  ) : (
                    "인증"
                  )}
                </SubTagButton>
              </>
            )}
          </>
        )}
      </Inner>
    </Wrapper>
  );
};

export default withRouter(FingerReset);
