import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { withRouter } from "react-router";
import {
  createStore,
  updateBanner,
  updateInfo,
  updateInfoTarget,
  updateStore,
} from "Datas/api";
import { GetStoredetail, GetStoreInfo } from "Datas/swr";
import { Fade } from "react-reveal";
import { ToggleButtonGroup } from "@mui/material";
import { ToggleButton } from "@mui/material";
import { TextField } from "@material-ui/core";

const Wrapper = styled.div``;

const TopLabel = styled.div``;
const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;
const TopButton = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-left: 30px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputMain = styled.input`
  height: 45px;
  border: none;
  width: 100%;
`;
const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const Content = styled.div``;

const Storedetail = ({ match, history, location }) => {
  const { StoredetailData, StoredetailMutate, isLoading } = GetStoredetail(
    match?.params?.id
  );
  const [datas, setDatas] = useState({
    name: "",
    code: "",
    tel: "",
    email: "",
    price: "",
    salea: "",
    dc: "",
    slae: "",
    admintype: false,
    changeType: false,
  });
  const [datas2, setDatas2] = useState({
    gmbase: false,
    pagename: "",
    sktPrice: 0,
    ktPrice: 0,
    lguPrice: 0,
  });
  useEffect(() => {
    if (!isLoading && match?.params?.id !== "add") {
      setDatas({
        name: StoredetailData?.name,
        code: StoredetailData?.code,
        tel: StoredetailData?.tel,
        email: StoredetailData?.email,
        price: StoredetailData?.price,
        dc: StoredetailData?.dc,
        sale: StoredetailData?.sale,
        salea: StoredetailData?.salea,
        admintype: StoredetailData?.admintype,
        changeType: true,
      });
      setDatas2({
        gmbase: StoredetailData?.gmbase,
        pagename: StoredetailData?.pagename,
        sktPrice: StoredetailData?.sktPrice,
        ktPrice: StoredetailData?.ktPrice,
        lguPrice: StoredetailData?.lguPrice,

      });
    }
  }, [StoredetailData]);
  const handleChange = (event, newAlignment) => {
    let letr = newAlignment === null ? false : newAlignment;
    console.log(newAlignment);
    setDatas2({ ...datas2, gmbase: letr });
  };

  const _handleCreate = async () => {
    await createStore(datas);
    history.goBack();
  };

  const _handleUpdate = async () => {
    await updateStore({ id: match?.params?.id, body: datas });
    console.log(datas2?.gmbase);
    await updateInfoTarget(
      {
        gmbase: datas2?.gmbase,
        pagename: datas2?.pagename,
        sktPrice: datas2?.sktPrice,
        ktPrice: datas2?.ktPrice,
        lguPrice: datas2?.lguPrice,

      },
      match?.params?.id
    );

    history.goBack();
  };
console.log(StoredetailData)
  return (
    <Fade Button>
      <Wrapper style={{ width: "100%" }}>
        <Top>
          <TopLabel>가맹점 정보</TopLabel>
          <TopButton>
            {match?.params?.id !== "add" && (
              <span>누적 수수료 {StoredetailData?.price}원</span>
            )}
            <Add
              onClick={() =>
                match?.params?.id === "add" ? _handleCreate() : _handleUpdate()
              }
            >
              완료
            </Add>
          </TopButton>
        </Top>
        <Content style={{ width: "100%" }}>
          <MainContainer>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>가맹점명</td>
                <td>
                  <InputMain
                    placeholder="가맹점명을 입력해주세요"
                    value={datas.name}
                    onChange={(e) =>
                      setDatas({ ...datas, name: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>이메일</td>
                <td>
                  <InputMain
                    placeholder="이메일을 입력해주세요"
                    value={datas.email}
                    onChange={(e) =>
                      setDatas({ ...datas, email: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>인증코드</td>
                <td>
                  <InputMain
                    placeholder="인증코드를 입력해주세요"
                    value={datas.code}
                    onChange={(e) =>
                      setDatas({ ...datas, code: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>가맹점 누적 수수료</td>
                <td>
                  <InputMain
                    placeholder="가맹점 누적 수수료를 입력해주세요"
                    value={datas.salea}
                    disabled={true}
                    onChange={(e) =>
                      setDatas({ ...datas, salea: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>사이트명</td>
                <td>
                  <InputMain
                    placeholder="사이트명"
                    value={datas2.pagename}
                    onChange={(e) =>
                      setDatas2({ ...datas, pagename: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>가맹점 누적 포인트</td>
                <td>
                  <InputMain
                    placeholder="가맹점 누적 포인트를 입력해주세요"
                    value={datas.dc}
                    disabled={true}
                    onChange={(e) => setDatas({ ...datas, dc: e.target.value })}
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>가맹점 수수료</td>
                <td>
                  <InputMain
                    placeholder="가맹점 수수료를 입력해주세요"
                    value={datas.sale}
                    disabled={true}
                    onChange={(e) =>
                      setDatas({ ...datas, sale: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>대표번호</td>
                <td>
                  <InputMain
                    placeholder="대표번호를 입력해주세요"
                    value={datas.tel}
                    onChange={(e) =>
                      setDatas({ ...datas, tel: e.target.value })
                    }
                    style={{ paddingLeft: 15 }}
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>관리자 모드</td>
                <td>
                  {datas.admintype ? (
                    <div
                      onClick={() => {
                        setDatas({ ...datas, admintype: !datas.admintype });
                      }}
                      style={{
                        backgroundColor: "#33a0df",
                        width: 120,
                        height: 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        borderRadius: 16,
                        cursor: "pointer",
                      }}
                    >
                      최고관리자
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setDatas({ ...datas, admintype: !datas.admintype });
                      }}
                      style={{
                        backgroundColor: "#33a0df",
                        width: 120,
                        height: 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        borderRadius: 16,
                        cursor: "pointer",
                      }}
                    >
                      기본운영자
                    </div>
                  )}
                </td>
              </RightTable>
             {/*  <InputLabel>웹사이트 구성설정</InputLabel>
              <ToggleButtonGroup
                color="primary"
                value={datas2.gmbase}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton value={false}>기본 정보 출력</ToggleButton>
                <ToggleButton value={true}>관리자 정보 출력</ToggleButton>
              </ToggleButtonGroup> */}
            </table>
            <InputLabel>통신사별 추가수수료</InputLabel>
            <TextField
              fullWidth
              type="number"
              value={datas2?.sktPrice}
              onChange={(e) => setDatas2({ ...datas2, sktPrice: e.target.value })}
              style={{
                marginTop: 20,
              }}
              id="outlined-basic"
              label="SKT"
              variant="outlined"
            />
            <TextField
              fullWidth
              type="number"
              value={datas2?.ktPrice}
              onChange={(e) => setDatas2({ ...datas2, ktPrice: e.target.value })}
              style={{
                marginTop: 20,
              }}
              id="outlined-basic1"
              label="KT"
              variant="outlined"
            />
            <TextField
              fullWidth
              type="number"
              value={datas2?.lguPrice}
              onChange={(e) => setDatas2({ ...datas2, lguPrice: e.target.value })}
              style={{
                marginTop: 20,
              }}
              id="outlined-basic"
              label="LGU+"
              variant="outlined"
            />
          </MainContainer>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(Storedetail);
