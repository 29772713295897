import React, { Component } from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 560px;
    width: 100%;
    margin-top: 200px;
`;

const Top = styled.div`
    display: flex;
`;

const Text = styled.div`
    display: flex;
    & > div:first-child {
        font-size: 35px;
    }
    & > div:last-child {
        font-size: 25px;
        color: #989898;
    }
    align-items: center;
`;

const Logout = styled.div`
    margin-left: auto;
    color: #FF3333;
    align-items: center;
    display: flex;
`;

const AlertBox = styled.div`
    background: #F0F0F0;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px 20px;
`;

const AlertTop = styled.div`
    display: flex;
    align-items: flex-end;
`;

const AlertTitle = styled.div`
    font-size: 25px;
`;

const AlertDate = styled.div`
    margin-left: auto;
    font-size: 20px;
    color: #989898;
`;

const AlertText = styled.div`
    margin-top: 20px;
    color: #989898;
    font-size: 20px;
`;

class Alert extends Component {
    
    render() {
        return(
            <React.Fragment>
     
                <Wrapper>
                    <Top>
                        <Text>
                            <div>회원정보</div>
                            <div>알림</div>
                        </Text>
                        <Logout>로그아웃</Logout>
                    </Top>
                    <AlertBox>
                        <AlertTop>
                            <AlertTitle>할인율 변동 알림</AlertTitle>
                            <AlertDate>2021.01.01</AlertDate>
                        </AlertTop>
                        <AlertText>여기에는 내용이 들어갑니다!</AlertText>
                    </AlertBox>
                </Wrapper>
            </React.Fragment>
        ); 
    }
}

export default Alert;