import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router";
import {
  GetAllTypeTelecom,
  GetByIdTypeTelecom,
  GetCategoryDatas,
  GetCategoryList,
  GetProductGroup,
  GetProductGroupById,
  GetProductInfo,
  GetProductInfoAdm,
  GetPromotionDatas,
  GetPromotionList,
  GetTelecomList,
} from "Datas/swr";
import Editor from "Components/Editor";
import { withRouter } from "react-router";
import { Fade } from "react-reveal";
import { createProduct, updateProduct } from "Datas/api";
import { GetUri } from "Settings/imageHandler";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { SketchPicker } from "react-color";

import { FormControl } from "@material-ui/core";
import Select1 from "react-select";
import _ from "lodash";
import { Checkbox } from "@material-ui/core";
import {
  DataSheetGrid,
  checkboxColumn,
  textColumn,
  keyColumn,
} from "react-datasheet-grid";

// Import the style only once in your app!
import "react-datasheet-grid/dist/style.css";
import { Kt_IC, T_IC, U_IC } from "assets";
import { intColumn } from "react-datasheet-grid";

const Wrapper = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;

const TopLabel = styled.div``;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
`;

const Add = styled.div`
  display: flex;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
`;

const ProductImageWrapper = styled.div`
  display: flex;
  overflow-y: scroll;
`;

const ProductImageLabel = styled.div`
  margin-bottom: 5px;
`;

const ProductImageskeleton = styled.img`
  max-width: 335px;
  height: 175px;
  border: none;
  resize: both;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: contain;
  display: flex;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 450px) {
    width: 216px;
    height: 113px;
  }
`;
const IC_Box = styled.img`
  width: 30px;
  height: 30px;
  resize: contain;
`;
const TelecomICBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #f3f3f3;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #ddd;
  align-items: center;
`;
const TelecomIcText = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-left: 12px;
`;
const PickerWrapper = styled.div`
  margin-bottom: ${(props) => {
    if (props.picker) {
      return "-303px";
    }
  }};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => {
    if (props.picker) {
      return "flex-start";
    }
    return "center";
  }};
`;
const TelecomPriceBox = styled.div`
  margin-top: 16px;
`;
const ProductNameLabel = styled.div`
  margin: 35px 0 5px 0;
`;
export const ColumnFlexBox = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ProductNameInput = styled.input`
  width: 100%;
  border-radius: 7px;
  border: none;
  padding: 0 20px;
  height: 45px;
`;
const ProductNameInput2 = styled.textarea`
  width: 100%;
  border-radius: 7px;
  border: none;
  padding: 0 20px;
  height: 45px;
`;

export const Xbox = styled.div`
  width: 22px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
`;
export const XboxTint = styled.p`
  font-size: 14px;
  margin-top: 2px;
  color: red;
  font-weight: bold;
`;
const ProductFromWrapper = styled.div`
  display: flex;
  /* max-width: 690px; */
  width: 100%;
  & > div:first-child {
    margin-right: 25px;
  }
`;

const ProductFromBtn = styled.div`
  display: flex;
  width: 100%;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  color: ${(props) => (props.selected === true ? "#fff" : "#333")};
  background: ${(props) => (props.selected === true ? "#6C74F4" : "#F4F4F4")};
`;

const ProductPromotionWrapper = styled.div``;

const ProductPromotion = styled.div`
  width: 690px;
  height: 45px;
  background: #f4f4f4;
  border-radius: 7px;
  border: none;
  padding: 0 20px;
`;

const ProductMemoryWrapper = styled.div`
  width: 690px;
  border-radius: 7px;
  border: none;
`;

const ProductMemoryInput = styled.div`
  display: flex;
  & > input {
    width: 100%;
    height: 45px;
    border-radius: 7px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    background: #f4f4f4;
    padding: 0 20px;
  }
  & > span {
    color: #de4949;
    background: #f4f4f4;
    display: flex;
    white-space: nowrap;
    align-items: center;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    justify-content: center;
  }
  margin: 15px 0;
`;

const ProductMemoryInputAddBtn = styled.div`
  background: #6c74f4;
  padding: 6px 11px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
  //margin-left: 10px;
`;

const Content = styled.div`
  margin: 0px;
`;

const ProductSupportWrapper = styled.div``;

const ProductSupportDiscuntLabel = styled.div`
  margin-top: 35px;
  margin-bottom: 5px;
`;
const MarkingBox = styled.div`
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
  background-color: #feb43c;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 24px;
  color: white;
  font-weight: bold;
  height: 35px;
`;

const ProductSupportDiscountInput = styled.div`
  background: #f4f4f4;
  height: 50px;
  display: flex;
  align-items: center;
  max-width: 690px;
  border-radius: 7px;
  & > input {
    height: 100%;
    border: none;
    background: transparent;
    padding: 0 15px;
  }
  & > span:nth-child(3) {
    color: red;
    margin-left: auto;
  }
  padding: 0 20px;
`;

const ProductSupportCOM = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  width: 25%;
  max-width: 690px;
  & > input {
    height: 45px;
    border: none;
    background: #f4f4f4;
    border-radius: 7px;
  }
`;

const ProductSupportCOMLabel = styled.div``;

const ProductSupportDC = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 690px;
  width: 65%;
  & > input {
    height: 45px;
    border: none;
    background: #f4f4f4;
    border-radius: 7px;
  }
`;

const Flex = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;
  max-width: 690px;
`;

const ProductSupportDCLabel = styled.div``;

const ProductSupportADD = styled.div`
  width: 10%;
  display: flex;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  margin-left: 15px;
  background: #6c74f4;
  align-items: center;
  justify-content: center;
  padding: 6px 11px;
  color: #fff;
  border-radius: 7px;
`;

const DetailedInfoLabel = styled.div`
  margin-top: 25px;
`;

const MarkDown = styled.div`
  width: 970px;
  height: 364px;
  margin-bottom: 110px;
`;
const MainContainer = styled.div`
  width: 90%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const NameCardAdd = styled.label`
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  font-size: 15px;
  background: #fab43c;
  border-radius: 30px;
  padding: 7px 14px;
  color: #fff;
  margin-left: 15;
  @media screen and (max-width: 450px) {
    font-size: 12px;
  }
`;
const CareerColorSwitch_Inner = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background: ${(props) => {
    if (props.color) {
      return `rgba(${props.color.r}, ${props.color.g}, ${props.color.b}, ${props.color.a})`;
    }
  }};
`;
const CareerColorCover = styled.div``;
const CareerColorPopover = styled.div`
  z-index: 9999;
`;
const CareerColorSwitch = styled.div`
  display: inline-block;
  border: 1px solid #eee;

  padding: ${(props) => {
    if (props.Custom) {
      return "9px";
    }
  }};
  border-radius: ${(props) => {
    if (props.Custom) {
      return "10px";
    }
  }};
  display: ${(props) => {
    if (props.Custom) {
      return "flex";
    }
  }};
  justify-content: ${(props) => {
    if (props.Custom) {
      return "center";
    }
  }};
  align-items: ${(props) => {
    if (props.Custom) {
      return "center";
    }
  }};
  cursor: pointer;
`;
const storageData = [
  { label: "64GB", value: 1 },
  { label: "128GB", value: 2 },
  { label: "256GB", value: 3 },
  { label: "512GB", value: 4 },
  { label: "1TB", value: 5 },
  { label: "직접입력", value: 6 },
];

const InputTextArea = styled.textarea`
  height: 293px;

  width: 100%;
  border-radius: 7px;

  border: none;
`;

const Product = ({ location, history, match }) => {
  const storeId = match.params.storeId;

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //이미지 업로더등록
  const [data, setData] = useState({
    name: "",
    images: null,
    marketprice: 0,
    transtype: [],
    giga: "",
    order: 0, //순서
    sktmemo: "",
    ktmemo: "",
    sktinfo: "",
    lguinfo: "",
    ktinfo: "",
    productinfoS: "",
    productinfoK: "",
    productinfoL: "",
    lgumemo: "",
    windowSize: "",
    memorySize: "",
    ramSize: "",
    sktOn: false,
    lguOn: false,
    ktOn: false,
    newOption: false,
    Groupid: null,
  });
  const [HardData, setHardData] = useState([]);
  const [ClolorData, setColorData] = useState([]);
  const [ArrayDatas, setArrayDatas] = useState();

  const [typeData, setTypeData] = useState();
  const [cateList, setCateList] = useState([]);
  //요금제데이터

  const [prList, setPrList] = useState([]);
  const [cateId, setCateId] = useState(null);
  const [initialType, setInitialType] = useState({});
  const { PromotionDatas, PromotionMutate } = GetPromotionDatas();
  const { CategoryDatas, CategoryMutate } = GetCategoryDatas();
  const { ProductGroupIdsData, ProductGroupIdsMutate } = GetProductGroupById(
    data?.Groupid
  );
  const [ktDatas, setKtDatas] = useState([]);
  const [lguDatas, setLguDatas] = useState([]);
  const [image, setImage] = useState([]);
  const [filesdata, setfilesCheck] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);
  const [productBoardData, setProductBoardData] = useState(null);
  const [type, setType] = useState(false);
  const [telecomData, setTelecomData] = useState([]);
  const [typeListData, setTypeListData] = useState([]);
  const { ProductGroupDatas, ProductGroupMutates } = GetProductGroup();
  const { ProductInfoData, ProductInfoMutate, isLoading } = GetProductInfoAdm(
    location?.state?.id
  );
  const [sktData, setSktData] = useState([]);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [opengiga, setOpenGiga] = useState(false);
  const { ByIdTypeTeleComData, ByIdTypeTeleComMutate } = GetByIdTypeTelecom(
    location?.state?.id
  );
  const [priceGloba, setPriceGlobal] = useState([
    {
      SKTPrice: 0, //요금제아이디
      KTPrice: 0, //프로덕트아이디  선택해야 동기화
      LGUPrice: 0, //텔레콤네임
    },
  ]);
  console.log(location?.state?.id, "아디에스");
  const [files, setFiles] = useState([]);
  console.log(ProductInfoData);
  const onEditorChange = (value) => {
    setProductBoardData(value);
  };

  const onFilesChange = (files) => {
    setFiles(files);
  };
  //주제별 카테고리 출력

  const _handleClolorData = () => {
    if (!ArrayDatas.clname) {
      alert("올바른 값을 입력해주세요");
      return;
    }
    let data = [...ClolorData];
    setColorData([
      ...data,
      { price: 0, name: ArrayDatas.clname, clcolor: ArrayDatas?.clcolor },
    ]);
    setPickerOpen(false);
    setArrayDatas({ ...ArrayDatas, clprice: "", clname: "", clcolor: "" });
  };

  const handleImage = (e) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64([...imgBase64, base64.toString()]);
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImage([...image, e.target.files[0]]); //경로탈착
    }
  };

  //type='Edit'일시 productInfo 데이터 할당

  useEffect(() => {
    if (!isLoading && location?.state?.type === "Edit") {
      setPriceGlobal([
        {
          SKTPrice: ProductInfoData?.SKTPrice, //요금제아이디
          KTPrice: ProductInfoData?.KTPrice, //프로덕트아이디  선택해야 동기화
          LGUPrice: ProductInfoData?.LGUPrice,
        },
      ]);
      setData({
        name: ProductInfoData?.name,
        images: ProductInfoData?.images,
        marketprice: ProductInfoData?.marketprice,
        memoadmin: ProductInfoData?.memoadmin,
        productinfoS: ProductInfoData?.productinfoS,
        productinfoK: ProductInfoData?.productinfoK,
        productinfoL: ProductInfoData?.productinfoL,
        giga: ProductInfoData?.giga,
        price: ProductInfoData?.price,
        order: ProductInfoData?.order,
        type: ProductInfoData?.type,
        categoryid: ProductInfoData?.categoryid,
        promotionid: ProductInfoData?.promotionid,
        sktmemo: ProductInfoData?.sktmemo,
        ktmemo: ProductInfoData?.ktmemo,
        lgumemo: ProductInfoData?.lgumemo,
        sktOn: ProductInfoData?.sktOn,
        lguOn: ProductInfoData?.lguOn,
        ktOn: ProductInfoData?.ktOn,
        sktinfo: ProductInfoData?.sktinfo,
        newOption: ProductInfoData?.newOption,
        ktinfo: ProductInfoData?.ktinfo,
        lguinfo: ProductInfoData?.lguinfo,
        windowSize: ProductInfoData?.windowSize,
        memorySize: ProductInfoData?.memorySize,
        Groupid: ProductInfoData?.Groupid,
        ramSize: ProductInfoData?.ramSize,
      });
      setImgBase64(ProductInfoData?.images);
      setType(ProductInfoData?.type);
      if (ProductInfoData?.type) {
        setCateId(ProductInfoData?.promotionid);
      } else {
        setCateId(ProductInfoData?.categoryid);
      }
      setProductBoardData(ProductInfoData?.ProductBoard?.contents);
      console.log(ProductInfoData, "프인포");
      let CatesData = ProductInfoData?.category_product_rd?.map((item, idx) => {
        return {
          categoryid: item?.id,
          promotionid: null,
          type: false, //프로모션/카테고리 분류
          cateId: item?.id, //프로모션이든 카테고리든 아이디기입
          order: item?.category_product?.order, //출력순서 기입
        };
      });
      let PromotionsData = ProductInfoData?.promotion_product_rd?.map(
        (item, idx) => {
          return {
            categoryid: null,
            promotionid: item?.id,
            type: true, //프로모션/카테고리 분류
            cateId: item?.id, //프로모션이든 카테고리든 아이디기입
            order: item?.promotion_product?.order, //출력순서 기입
          };
        }
      );

      let newdts = [...CatesData, ...PromotionsData];
      setCategoryMap(newdts);
      const totalHard = [];
      ProductInfoData?.HardPrices?.map((el, idx) => {
        const tempData = {
          name: el?.name,
          price: el?.price,
        };
        totalHard.push(tempData);
      });
      setHardData(totalHard);

      const totalColor = [];
      ProductInfoData?.ColorPrices?.map((el, idx) => {
        const tempData = {
          name: el?.name,
          price: el?.price,
          clcolor: el?.clcolor,
        };
        totalColor.push(tempData);
      });
      setColorData(totalColor);

      const totalData = [];
      ProductInfoData?.telecom_product_key_product?.map((el, idx) => {
        const tempData = {
          TelecomId: el?.telecom_product?.TelecomId,
          dc_move_price: el?.telecom_product?.dc_move_price,
          dc_change_price: el?.telecom_product?.dc_change_price,
          change_sales_price: el?.telecom_product?.change_sales_price,
          dc_sales_price: el?.telecom_product?.dc_sales_price,
          dc_chooses_price: el?.telecom_product?.dc_chooses_price,
        };
        totalData.push(tempData);
      });
      setTelecomData(totalData);
    }
  }, [location?.state, isLoading]);
  console.log("총괄데이터", ProductGroupIdsData);

  console.log(ByIdTypeTeleComData, "적용중데이터");
  useEffect(() => {
    if (
      ProductGroupIdsData !== undefined &&
      ByIdTypeTeleComData !== undefined
    ) {
      let newArray = [];
      console.log("뮤테이팅");

      if (location?.state?.type !== "Add") {
        //for과필터구간활용?

        let datas = ProductGroupIdsData?.map((item, idx) => {
          let des = ByIdTypeTeleComData?.findIndex((item2, idx2) => {
            return item.id === item2.TypeTelecomId;
          });

          if (des !== -1) {

            return {
              TypeTelecomId: item.id, //요금제아이디
              ProductId: null, //가져올부분
              name: item.name, //텔레콤네임
              telecomid: ByIdTypeTeleComData[des].telecomid, //텔레콤아이디 분류
              change_sales_price: ByIdTypeTeleComData[des]?.change_sales_price,
              dc_sales_price: ByIdTypeTeleComData[des].dc_sales_price, // 공시지원 할인금 이부분은 가져오기
              dc_sales_mnp_price: ByIdTypeTeleComData[des].dc_sales_mnp_price, //공시지원 mnp 프라이스 이부분 가져오기
              dc_sales_change_price:
                ByIdTypeTeleComData[des].dc_sales_change_price, //공시지원 기변 프라이스
              dc_chooses_price: ByIdTypeTeleComData[des].dc_chooses_price, //선택약정 할인금
              dc_choose_mnp_price: ByIdTypeTeleComData[des].dc_choose_mnp_price, //선택약정 mnp 가격
              dc_sales_new_price: ByIdTypeTeleComData[des].dc_sales_new_price, //공시지원 신규
              dc_choose_new_price: ByIdTypeTeleComData[des].dc_choose_new_price, //선택약정 신규
              dc_choose_change_price:
                ByIdTypeTeleComData[des].dc_choose_change_price, //선택약정 기변금
              validate: ByIdTypeTeleComData[des].validate, //선택사항 디폴트 출력
              opt: ByIdTypeTeleComData[des].opt,
            };
          } else {
            return {
              TypeTelecomId: item.id, //요금제아이디
              ProductId: null, //프로덕트아이디  선택해야 동기화
              name: item.name, //텔레콤네임
              telecomid: item.telecomid, //텔레콤아이디 분류

              change_sales_price: 0,
              dc_sales_price: 0, // 공시지원 할인금
              dc_sales_mnp_price: 0, //공시지원 mnp 프라이스
              dc_sales_change_price: 0, //공시지원 기변 프라이스
              dc_chooses_price: 0, //선택약정 할인금
              dc_choose_mnp_price: 0, //선택약정 mnp 가격
              dc_choose_change_price: 0, //선택약정 기변금
              dc_sales_new_price: 0, //공시지원 신규
              dc_choose_new_price: 0, //선택약정 신규
              validate: false, //선택사항 디폴트 출력
              opt: false,
            };
          }
        });

        /* for (let i = 0; i < ProductGroupIdsData.length; i++) {
            //필터링구간활용
            if (ByIdTypeTeleComData.length < i) {
              newArray.push({
                TypeTelecomId: ProductGroupIdsData[i].id, //요금제아이디
                ProductId: null, //프로덕트아이디  선택해야 동기화
                name: ProductGroupIdsData[i].name, //텔레콤네임
                telecomid: ProductGroupIdsData[i].telecomid, //텔레콤아이디 분류
                dc_sales_price: 0, // 공시지원 할인금
                dc_sales_mnp_price: 0, //공시지원 mnp 프라이스
                dc_sales_change_price: 0, //공시지원 기변 프라이스
                dc_chooses_price: 0, //선택약정 할인금
                dc_choose_mnp_price: 0, //선택약정 mnp 가격
                dc_choose_change_price: 0, //선택약정 기변금
                dc_sales_new_price: 0, //공시지원 신규
                dc_choose_new_price: 0, //선택약정 신규
                validate: false, //선택사항 디폴트 출력
                opt: false,
              });
              continue;
            }
            console.log('돈다', ByIdTypeTeleComData[i]?.TypeTelecomId)
            if (
              ByIdTypeTeleComData[i]?.TypeTelecomId ===
              ProductGroupIdsData[i]?.id
            ) {
              console.log("같은값확인");
              console.log(ByIdTypeTeleComData[i]);
              //같은아이디일경우
              newArray.push({
                TypeTelecomId: ProductGroupIdsData[i].id, //요금제아이디
                ProductId: null, //가져올부분
                name: ProductGroupIdsData[i].name, //텔레콤네임
                telecomid: ByIdTypeTeleComData[i].telecomid, //텔레콤아이디 분류
                dc_sales_price: ByIdTypeTeleComData[i].dc_sales_price, // 공시지원 할인금 이부분은 가져오기
                dc_sales_mnp_price: ByIdTypeTeleComData[i].dc_sales_mnp_price, //공시지원 mnp 프라이스 이부분 가져오기
                dc_sales_change_price:
                  ByIdTypeTeleComData[i].dc_sales_change_price, //공시지원 기변 프라이스
                dc_chooses_price: ByIdTypeTeleComData[i].dc_chooses_price, //선택약정 할인금
                dc_choose_mnp_price: ByIdTypeTeleComData[i].dc_choose_mnp_price, //선택약정 mnp 가격
                dc_sales_new_price: ByIdTypeTeleComData[i].dc_sales_new_price, //공시지원 신규
                dc_choose_new_price: ByIdTypeTeleComData[i].dc_choose_new_price, //선택약정 신규
                dc_choose_change_price:
                  ByIdTypeTeleComData[i].dc_choose_change_price, //선택약정 기변금
                validate: ByIdTypeTeleComData[i].validate, //선택사항 디폴트 출력
                opt: ByIdTypeTeleComData[i].opt,
              });
            } else {
              newArray.push({
                TypeTelecomId: ProductGroupIdsData[i].id, //요금제아이디
                ProductId: null, //프로덕트아이디  선택해야 동기화
                name: ProductGroupIdsData[i].name, //텔레콤네임
                telecomid: ProductGroupIdsData[i].telecomid, //텔레콤아이디 분류
                dc_sales_price: 0, // 공시지원 할인금
                dc_sales_mnp_price: 0, //공시지원 mnp 프라이스
                dc_sales_change_price: 0, //공시지원 기변 프라이스
                dc_chooses_price: 0, //선택약정 할인금
                dc_choose_mnp_price: 0, //선택약정 mnp 가격
                dc_choose_change_price: 0, //선택약정 기변금
                validate: false, //선택사항 디폴트 출력
                dc_sales_new_price: 0, //공시지원 신규
                dc_choose_new_price: 0, //선택약정 신규
                opt: false,
              });
            }
          } */
        setTypeListData(datas);

        const SktDatas = datas
          ?.map((item, idx) => {
            if (item.telecomid === 1) {
              return {
                요금제: item.name,
                ProductId: item?.ProductId,
                TypeTelecomId: item.TypeTelecomId,
                telecomid: item?.telecomid,
                전환지원금: item?.change_sales_price,
                공시지원: item?.dc_sales_price,
                DC신규공시: item?.dc_sales_new_price,
                DCMNP공시: item?.dc_sales_mnp_price,
                DC기변공시: item?.dc_sales_change_price,
                선택약정: item?.dc_chooses_price,
                DC신규선택: item?.dc_choose_new_price,
                DCMNP선텍: item?.dc_choose_mnp_price,
                DC기변선택: item?.dc_choose_change_price,
                선택: item?.validate,
                선택약정출력분류: item?.opt,
              };
            }
          })
          .filter((item, idx) => {
            return item !== undefined;
          });
        const ktData = datas
          ?.map((item, idx) => {
            if (item.telecomid === 2) {
              return {
                요금제: item.name,
                ProductId: item?.ProductId,
                TypeTelecomId: item.TypeTelecomId,
                telecomid: item?.telecomid,
                전환지원금: item?.change_sales_price,
                공시지원: item?.dc_sales_price,
                DC신규공시: item?.dc_sales_new_price,
                DCMNP공시: item?.dc_sales_mnp_price,
                DC기변공시: item?.dc_sales_change_price,
                선택약정: item?.dc_chooses_price,
                DC신규선택: item?.dc_choose_new_price,
                DCMNP선텍: item?.dc_choose_mnp_price,
                DC기변선택: item?.dc_choose_change_price,
                선택: item?.validate,
                선택약정출력분류: item?.opt,
              };
            }
          })
          .filter((item, idx) => {
            return item !== undefined;
          });
        const LguData = datas
          ?.map((item, idx) => {
            if (item.telecomid === 3) {
              return {
                요금제: item.name,
                ProductId: item?.ProductId,
                TypeTelecomId: item.TypeTelecomId,
                telecomid: item?.telecomid,
                전환지원금: item?.change_sales_price,
                공시지원: item?.dc_sales_price,
                DC신규공시: item?.dc_sales_new_price,
                DCMNP공시: item?.dc_sales_mnp_price,
                DC기변공시: item?.dc_sales_change_price,
                선택약정: item?.dc_chooses_price,
                DC신규선택: item?.dc_choose_new_price,
                DCMNP선텍: item?.dc_choose_mnp_price,
                DC기변선택: item?.dc_choose_change_price,
                선택: item?.validate,
                선택약정출력분류: item?.opt,
              };
            }
          })
          .filter((item, idx) => {
            return item !== undefined;
          });

        setSktData(SktDatas);
        setKtDatas(ktData);
        setLguDatas(LguData);
      } else {
        for (let i = 0; i < ProductGroupIdsData.length; i++) {
          newArray.push({
            TypeTelecomId: ProductGroupIdsData[i].id, //요금제아이디
            ProductId: null, //프로덕트아이디  선택해야 동기화
            name: ProductGroupIdsData[i].name, //텔레콤네임
            telecomid: ProductGroupIdsData[i].telecomid, //텔레콤아이디 분류
            dc_sales_new_price: 0,
            dc_sales_price: 0, // 공시지원 할인금
            change_sales_price: 0, // 전환지원금
            dc_sales_mnp_price: 0, //공시지원 mnp 프라이스
            dc_sales_change_price: 0, //공시지원 기변 프라이스
            dc_chooses_price: 0, //선택약정 할인금
            dc_choose_mnp_price: 0, //선택약정 mnp 가격
            dc_choose_change_price: 0, //선택약정 기변금
            validate: false, //선택사항 디폴트 출력
            opt: false,
          });
        }
        setTypeListData(newArray);
        const SktDatas = newArray
          ?.map((item, idx) => {
            if (item.telecomid === 1) {
              return {
                요금제: item.name,
                ProductId: item?.ProductId,
                TypeTelecomId: item.TypeTelecomId,
                telecomid: item?.telecomid,
                공시지원: item?.dc_sales_price,
                전환지원금: item?.change_sales_price,
                DC신규공시: item?.dc_sales_new_price,
                DCMNP공시: item?.dc_sales_mnp_price,
                DC기변공시: item?.dc_sales_change_price,
                선택약정: item?.dc_chooses_price,
                DC신규선택: item?.dc_choose_new_price,
                DCMNP선텍: item?.dc_choose_mnp_price,
                DC기변선택: item?.dc_choose_change_price,
                선택: item?.validate,
                선택약정출력분류: item?.opt,
              };
            }
          })
          .filter((item, idx) => {
            return item !== undefined;
          });
        const ktData = newArray
          ?.map((item, idx) => {
            if (item.telecomid === 2) {
              return {
                요금제: item.name,
                ProductId: item?.ProductId,
                TypeTelecomId: item.TypeTelecomId,
                telecomid: item?.telecomid,
                공시지원: item?.dc_sales_price,
                전환지원금: item?.change_sales_price,
                DC신규공시: item?.dc_sales_new_price,
                DCMNP공시: item?.dc_sales_mnp_price,
                DC기변공시: item?.dc_sales_change_price,
                선택약정: item?.dc_chooses_price,
                DC신규선택: item?.dc_choose_new_price,
                DCMNP선텍: item?.dc_choose_mnp_price,
                DC기변선택: item?.dc_choose_change_price,
                선택: item?.validate,
                선택약정출력분류: item?.opt,
              };
            }
          })
          .filter((item, idx) => {
            return item !== undefined;
          });
        const LguData = newArray
          ?.map((item, idx) => {
            if (item.telecomid === 3) {
              return {
                요금제: item.name,
                ProductId: item?.ProductId,
                TypeTelecomId: item.TypeTelecomId,
                telecomid: item?.telecomid,
                공시지원: item?.dc_sales_price,
                전환지원금: item?.change_sales_price,
                DC신규공시: item?.dc_sales_new_price,
                DCMNP공시: item?.dc_sales_mnp_price,
                DC기변공시: item?.dc_sales_change_price,
                선택약정: item?.dc_chooses_price,
                DC신규선택: item?.dc_choose_new_price,
                DCMNP선텍: item?.dc_choose_mnp_price,
                DC기변선택: item?.dc_choose_change_price,
                선택: item?.validate,
                선택약정출력분류: item?.opt,
              };
            }
          })
          .filter((item, idx) => {
            return item !== undefined;
          });

        setSktData(SktDatas);
        setKtDatas(ktData);
        setLguDatas(LguData);
      }

      //데이터세팅
    }
  }, [ProductGroupIdsData, ByIdTypeTeleComData]);

  const _handleDelete = (idx1) => {
    let temp = [...HardData];
    let data = temp.filter((item, idx) => idx !== idx1);
    setHardData(data);
  };

  const _handleDeleteColor = (idx1) => {
    let temp = [...ClolorData];
    let data = temp.filter((item, idx) => idx !== idx1);
    setColorData(data);
  };

  const _handleAddHard = () => {
    console.log(ArrayDatas);
    if (!ArrayDatas?.hdname || !ArrayDatas?.hdname || !ArrayDatas?.hdprice) {
      alert("올바른 값을 입력해주세요");
      return;
    }
    let check = HardData.findIndex((item) => item.name === ArrayDatas.hdname);
    if (check !== -1) {
      alert("이미 존재하는 GB 설정입니다");
      return;
    }
    let data = [...HardData];
    //정렬함수
    let sortData = [
      ...data,
      { price: ArrayDatas.hdprice, name: ArrayDatas.hdname },
    ];
    sortData = sortData.sort((a, b) => {
      return a.name.replace("GB", "") - b.name.replace("GB", "");
    });
    setHardData(sortData);
    setArrayDatas({ ...ArrayDatas, hdprice: "", hdname: "" });
  };

  const handleRemoveImage = (idx) => {
    setImage((images) => images.filter((el, index) => index !== idx));
    setImgBase64((imgBase64) => imgBase64.filter((el, index) => index !== idx));
  };

  console.log(location?.state?.type);
  console.log(data, "프로덕트");
  const _handleSubmit = async () => {
    if (
      categoryMap.some((item, idx) => {
        if (item?.cateId === false) {
          return true;
        }
      }) === true
    ) {
      alert("카테고리 관련 설정을 마무리해주세요");
      return;
    }
    let cates = [];
    let promos = [];
    cates = categoryMap?.filter((item, idx) => {
      return item?.type === false;
    });
    promos = categoryMap?.filter((item, idx) => {
      return item?.type === true;
    });

    cates = cates?.map((item, idx) => {
      return { order: item?.order, CategoryId: item?.cateId };
    });
    promos = promos?.map((item, idx) => {
      return { order: item?.order, PromotionId: item?.cateId };
    });

    if (data?.Groupid === null) {
      alert("요금제 그룹을 설정하세요");
      return;
    }
    let productdata = data;
    {
      /* 04-07 패치버전 - 출고가별도 동기화  */
    }
    productdata.SKTPrice = priceGloba[0]?.SKTPrice;
    productdata.LGUPrice = priceGloba[0]?.LGUPrice;
    productdata.KTPrice = priceGloba[0]?.KTPrice;

    {
      /* */
    }
    if (type === false) {
      productdata.categoryid = cateId;
      productdata.promotionid = null;
    } else {
      productdata.promotionid = cateId;
      productdata.categoryid = null;
    }

    productdata.type = type;
    if (location?.state?.type === "Add") {
      if (image.length === 0) {
        alert("이미지를 등록해주세요");
        return;
      }
    }
    if (image?.length !== 0) {
      const { url } = await GetUri({ image: image });
      productdata.images = url;
    } else {
      productdata.images = imgBase64;
    }
    //타입리스트 검증
    //유효성검증

    let telele1Type = false;
    let telele2Type = false;
    let telele3Type = false;
    let newDatas = [...sktData, ...ktDatas, ...lguDatas];
    newDatas = newDatas.map((item, idx) => {
      return {
        TypeTelecomId: item.TypeTelecomId, //요금제아이디
        ProductId: item.ProductId, //가져올부분
        name: item.요금제, //텔레콤네임
        telecomid: item.telecomid, //텔레콤아이디 분류
        dc_sales_price: item?.공시지원, // 공시지원 할인금 이부분은 가져오기
        change_sales_price: item?.전환지원금,
        dc_sales_mnp_price: item?.DCMNP공시, //공시지원 mnp 프라이스 이부분 가져오기
        dc_sales_change_price: item?.DC기변공시, //공시지원 기변 프라이스
        dc_chooses_price: item?.선택약정, //선택약정 할인금
        dc_choose_mnp_price: item?.DCMNP선텍, //선택약정 mnp 가격
        dc_sales_new_price: item?.DC신규공시, //공시지원 신규
        dc_choose_new_price: item?.DC신규선택, //선택약정 신규
        dc_choose_change_price: item?.DC기변선택, //선택약정 기변금
        validate: item?.선택, //선택사항 디폴트 출력
        opt: item?.선택약정출력분류,
      };
    });
    telele1Type = newDatas.findIndex((item, idx) => {
      if (item.telecomid === 1) {
        if (item.validate === true) {
          return true;
        }
      }
    });
    telele2Type = newDatas.findIndex((item, idx) => {
      if (item.telecomid === 2) {
        if (item.validate === true) {
          return true;
        }
      }
    });
    telele3Type = newDatas.findIndex((item, idx) => {
      if (item.telecomid === 3) {
        if (item.validate === true) {
          return true;
        }
      }
    });
    if (telele1Type === -1) {
      alert("LG 기본요금제를 선택해주세요");
      return;
    }
    if (telele2Type === -1) {
      alert("SKT 기본요금제를 선택해주세요");
      return;
    }
    if (telele3Type === -1) {
      alert("KT 기본 요금제를 선택해주세요");
      return;
    }
    if (data.name === "") {
      alert("상품명을 입력해주세요");
      return;
    }
    if (data.giga === "") {
      alert("5G/LTE 를 입력해주세요");
      return;
    }

    if (HardData.length === 0) {
      alert("하드용량을 설정해주세요");
      return;
    }
    if (ClolorData.length === 0) {
      alert("색상 데이터를 설정해주세요");
      return;
    }

    let body = {
      productdata: productdata, //하드
      hardData: HardData, //기기
      colorData: ClolorData, //색상
      productBoardData: productBoardData, //콘텐츠
      telecoms: newDatas,
      cates: cates,
      promos: promos,
    };

    if (location?.state?.type === "Add") {
      console.log(body);
      await createProduct(body);
    } else {
      await updateProduct(ProductInfoData?.id, body);
    }
    history.goBack();
    await ProductInfoMutate();
  };
  const [categoryMap, setCategoryMap] = useState([
    {
      categoryid: null,
      promotionid: null,
      type: false,
      cateId: false,
      order: 0,
    },
  ]);
  const orginData = {
    categoryid: null,
    promotionid: null,
    type: false,
    cateId: false,
    order: 0,
  };
  console.log(productBoardData);
  const _handleChange = (e, idx) => {
    //해당 네임값 기준으로 벨류 분산
    let types = [...typeListData];
    let datasA = { ...typeListData[idx], [e.target.name]: e.target.value };
    types[idx] = datasA;
    setTypeListData(types);
  };

  const [testdata, setTestData] = useState([
    { active: true, firstName: "Elon", lastName: "Musk" },
    { active: false, firstName: "Jeff", lastName: "Bezos" },
  ]);

  {
    /*04-07 (금) 컬럼프라이스 ) -출고가 */
  }
  const columnsPrice = [
    {
      ...keyColumn("SKTPrice", intColumn),
      title: "SKT출고가",
    },
    {
      ...keyColumn("KTPrice", intColumn),
      title: "KT출고가",
    },
    {
      ...keyColumn("LGUPrice", intColumn),
      title: "LGU+출고가",
    },
  ];

  {
    /*04-07 (금) 컬럼프라이스 ) -출고가 */
  }
  const columns = [
    {
      ...keyColumn("요금제", textColumn),
      title: "요금제",
      disabled: true,
      minWidth: 170,
    },
    {
      ...keyColumn("공시지원", intColumn),
      title: "공시지원",
    },
    {
      ...keyColumn("전환지원금", intColumn),
      title: "전환지원금",
    },
    {
      ...keyColumn("DC신규공시", intColumn),
      title: "DC신규",
    },
    {
      ...keyColumn("DCMNP공시", intColumn),
      title: "DC MNP",
    },
    {
      ...keyColumn("DC기변공시", intColumn),
      title: "DC 기변",
    },
    {
      ...keyColumn("선택약정", intColumn),
      title: "선택약정",
    },

    {
      ...keyColumn("DC신규선택", intColumn),
      title: "DC신규",
    },
    {
      ...keyColumn("DCMNP선텍", intColumn),
      title: "DC MNP",
    },
    {
      ...keyColumn("DC기변선택", intColumn),
      title: "DC 기변",
    },
    {
      ...keyColumn("선택", checkboxColumn),
      title: "선택",
    },
    {
      ...keyColumn("선택약정출력분류", checkboxColumn),
      title: "선택약정출력",
    },
  ];

  return (
    <Fade Button>
      <Wrapper id="Top">
        <Content
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "1200vh",
          }}
        >
          <Top>
            <TopLabel>상품관리</TopLabel>
            <TopButton>
              <Add onClick={() => _handleSubmit()}>
                {location?.state?.type === "Add" ? "상품 추가" : "상품 수정"}
              </Add>
              <Add
                onClick={() => {
                  let res = [...categoryMap, orginData];
                  setCategoryMap(res);
                }}
              >
                카테고리 추가
              </Add>
            </TopButton>
          </Top>
          <p style={{ color: "gray" }}>상품이미지 크기 263 x 372</p>
          <MainContainer>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <tr>
                <td>상품 이미지</td>
                <td style={{ padding: 10 }}>
                  <input
                    type="file"
                    name="upload-file"
                    style={{ display: "none" }}
                    onChange={handleImage}
                    id="upload-file"
                  ></input>
                  {imgBase64 !== undefined &&
                    imgBase64 !== null &&
                    imgBase64?.length !== 0 ? (
                    <ProductImageWrapper>
                      {imgBase64 &&
                        imgBase64.map((item, idx) => {
                          return (
                            <ProductImageskeleton
                              onClick={() => handleRemoveImage(idx)}
                              src={item}
                            ></ProductImageskeleton>
                          );
                        })}
                    </ProductImageWrapper>
                  ) : (
                    <NameCardAdd for="upload-file">추가</NameCardAdd>
                  )}
                </td>
              </tr>
              <tr>
                <td>상품 명</td>
                <td>
                  <ProductNameInput
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </td>
              </tr>
              <tr>
                <td>5G/LTE</td>
                <td>
                  <ProductNameInput
                    value={data.giga}
                    onChange={(e) => setData({ ...data, giga: e.target.value })}
                  />
                </td>
              </tr>
              {categoryMap?.map((item, idx) => {
                let Cates = [];
                if (item.type === false) {
                  Cates = CategoryDatas?.map((item, idx) => {
                    return { label: item.name, value: item.id };
                  });
                }
                if (item.type === true) {
                  console.log("프로모션 뿌리기");
                  Cates = PromotionDatas?.map((item, idx) => {
                    return { label: item.title, value: item.id };
                  });
                }

                return (
                  <>
                    <tr>
                      <td>상품 소속</td>
                      <td style={{ padding: 10 }}>
                        <ProductFromWrapper>
                          <ProductFromBtn
                            onClick={() => {
                              let res = [...categoryMap];
                              res[idx].type = true;
                              setCategoryMap(res);
                            }}
                            selected={item?.type === true ? true : false}
                          >
                            프로모션
                          </ProductFromBtn>
                          <ProductFromBtn
                            onClick={() => {
                              let res = [...categoryMap];
                              res[idx].type = false;
                              setCategoryMap(res);
                            }}
                            selected={item?.type === true ? false : true}
                          >
                            카테고리
                          </ProductFromBtn>
                        </ProductFromWrapper>
                      </td>
                    </tr>
                    <tr>
                      <td>출력순서</td>
                      <td>
                        <ProductNameInput
                          value={item.order}
                          type="number"
                          onChange={(e) => {
                            let res = [...categoryMap];
                            res[idx].order = e.target.value;
                            setCategoryMap(res);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {item?.type === true ? "프로모션" : "카테고리"}{" "}
                        <Xbox
                          onClick={() => {
                            let res = [...categoryMap];
                            const resdata = res.filter((items, idxs) => {
                              return idxs !== idx;
                            });
                            setCategoryMap(resdata);
                          }}
                        >
                          <XboxTint>-</XboxTint>
                        </Xbox>
                      </td>
                      <td style={{ padding: 10 }}>
                        <FormControl fullWidth>
                          <Select
                            fullWidth
                            value={item?.cateId}
                            onChange={(e) => {
                              const newCateId = e.target.value;
                              let updatedCategoryMap = [...categoryMap];
                            
                              updatedCategoryMap[idx].cateId = newCateId;
                            
                              const hasDuplicate = updatedCategoryMap.some((item, index) => 
                                index !== idx && item.type === updatedCategoryMap[idx].type && item.cateId === newCateId
                              );
                            
                              if (hasDuplicate) {
                                alert("같은 카테고리를 지정할 수 없습니다.");
                                return;
                              } else {
                                setCategoryMap(updatedCategoryMap);
                              }
                            }}
                            style={{
                              marginTop: 20,
                            }}
                            variant="outlined"
                          >
                            {Cates?.map((item, idx) => {
                              return (
                                <MenuItem value={item.value}>
                                  {item?.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </td>
                    </tr>
                  </>
                );
              })}

              {/*   <tr>
                <td>출고가격</td>
                <td>
                  <ProductNameInput
                    value={data.marketprice}
                    type="number"
                    onChange={(e) =>
                      setData({ ...data, marketprice: e.target.value })
                    }
                  />
                </td>
              </tr> 출고가격제거  */}

              <tr>
                <td>화면크기</td>
                <td>
                  <ProductNameInput
                    value={data.windowSize}
                    onChange={(e) =>
                      setData({ ...data, windowSize: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>내장 메모리</td>
                <td>
                  <ProductNameInput
                    value={data.memorySize}
                    onChange={(e) =>
                      setData({ ...data, memorySize: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>배터리</td>
                <td>
                  <ProductNameInput
                    value={data.ramSize}
                    onChange={(e) =>
                      setData({ ...data, ramSize: e.target.value })
                    }
                  />
                </td>
              </tr>
            </table>

            <ProductNameLabel>기기 용량</ProductNameLabel>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "95%",
                }}
              >
                <ProductMemoryInput
                  style={{
                    marginRight: 12,
                    width: 200,
                  }}
                >
                  <Select1
                    placeholder="용량"
                    onChange={(e) => {
                      if (e.label === "직접입력") {
                        //옵션온
                        setOpenGiga(true);
                      } else {
                        setOpenGiga(false);
                        setArrayDatas({
                          ...ArrayDatas,
                          hdname: e.label,
                        });
                      }
                    }}
                    options={storageData}
                  />
                </ProductMemoryInput>
                {opengiga && (
                  <>
                    <ProductMemoryInput style={{ marginRight: 12 }}>
                      <input
                        placeholder="용량GB"
                        onChange={(e) =>
                          setArrayDatas({
                            ...ArrayDatas,
                            hdname: e.target.value,
                          })
                        }
                        style={{
                          border: "none",
                          backgroundColor: "none",
                        }}
                      />
                    </ProductMemoryInput>
                  </>
                )}
                <input
                  style={{
                    width: "45%",
                    height: 40,
                    border: "none",
                    backgroundColor: "#f0f0f0",
                    paddingLeft: 15,
                    borderRadius: 5,
                  }}
                  placeholder="출고가"
                  value={ArrayDatas?.hdprice}
                  onChange={(e) =>
                    setArrayDatas({
                      ...ArrayDatas,
                      hdprice: e.target.value,
                    })
                  }
                />
              </div>
              <ProductMemoryInputAddBtn
                style={{ fontSize: 15, width: 100, height: 40 }}
                onClick={() => _handleAddHard()}
              >
                추가
              </ProductMemoryInputAddBtn>
            </div>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <thead>
                <RightTable>
                  <td>용량</td>
                  <td>출고가</td>
                  <td>삭제</td>
                </RightTable>
              </thead>
              <tbody>
                {HardData.map((item, idx) => {
                  return (
                    <RightTable>
                      <td>
                        <input
                          value={item?.name}
                          placeholder="용량"
                          disabled={true}
                          style={{
                            border: "none",
                            backgroundColor: "none",
                          }}
                        />
                      </td>
                      <td>
                        <input
                          style={{
                            border: "none",
                            backgroundColor: "#fff",
                          }}
                          placeholder="가격"
                          value={item?.price.toLocaleString()}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <ProductMemoryInputAddBtn
                          style={{ backgroundColor: "red" }}
                          onClick={() => _handleDelete(idx)}
                        >
                          삭제
                        </ProductMemoryInputAddBtn>
                      </td>
                    </RightTable>
                  );
                })}
              </tbody>
            </table>

            <ProductNameLabel>기기 색상</ProductNameLabel>
            <PickerWrapper picker={pickerOpen}>
              <div
                style={{
                  display: "flex",
                  zIndex: 9999,
                  width: "95%",
                }}
              >
                <input
                  style={{
                    width: "45%",
                    height: 40,
                    marginRight: 15,
                    border: "none",
                    backgroundColor: "#f0f0f0",
                    paddingLeft: 15,
                    borderRadius: 5,
                  }}
                  value={ArrayDatas?.clname}
                  placeholder="색상"
                  onChange={(e) =>
                    setArrayDatas({ ...ArrayDatas, clname: e.target.value })
                  }
                />
                <div style={{ marginRight: 13 }}>
                  <CareerColorSwitch
                    style={{ marginTop: -10 }}
                    onClick={() => {
                      setPickerOpen(!pickerOpen);
                    }}
                  >
                    <CareerColorSwitch_Inner
                      color={ArrayDatas?.clcolor}
                    ></CareerColorSwitch_Inner>
                  </CareerColorSwitch>
                  {pickerOpen && (
                    <CareerColorPopover>
                      <CareerColorCover>
                        <SketchPicker
                          color={ArrayDatas?.clcolor}
                          onChange={(e) => {
                            setArrayDatas({ ...ArrayDatas, clcolor: e.rgb });
                          }}
                        ></SketchPicker>
                      </CareerColorCover>
                    </CareerColorPopover>
                  )}
                </div>
              </div>
              <ProductMemoryInputAddBtn
                style={{ fontSize: 15, width: 100, height: 40 }}
                onClick={() => _handleClolorData()}
              >
                추가
              </ProductMemoryInputAddBtn>
            </PickerWrapper>
            <table
              id="customers"
              style={{
                marginTop: 24,

                width: "100%",
              }}
            >
              <thead>
                <RightTable>
                  <td style={{ zIndex: -999 }}>색상이름</td>
                  <td style={{ zIndex: -999 }}>컬러</td>
                  <td style={{ zIndex: -999 }}>삭제</td>
                </RightTable>
              </thead>
              <tbody>
                {ClolorData.map((item, idx) => {
                  return (
                    <RightTable>
                      <td style={{ zIndex: -999 }}>
                        <input
                          style={{
                            border: "none",
                          }}
                          value={item?.name}
                          placeholder="색상이름"
                          disabled={true}
                        />
                      </td>
                      <td style={{ zIndex: -999 }}>
                        <CareerColorSwitch style={{ zIndex: -999 }}>
                          <CareerColorSwitch_Inner
                            style={{ zIndex: -999 }}
                            color={pickerOpen === false && item?.clcolor}
                          ></CareerColorSwitch_Inner>
                        </CareerColorSwitch>
                      </td>
                      <td style={{ zIndex: -999 }}>
                        <ProductMemoryInputAddBtn
                          style={{ backgroundColor: "red" }}
                          onClick={() => _handleDeleteColor(idx)}
                        >
                          삭제
                        </ProductMemoryInputAddBtn>
                      </td>
                    </RightTable>
                  );
                })}
              </tbody>
            </table>

            <ProductNameLabel>
              요금제 별 설정 [체크시 비활성화]
            </ProductNameLabel>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data?.Groupid}
                onChange={async (e) => {
                  setData({ ...data, Groupid: e.target.value });
                }}
              >
                {ProductGroupDatas?.map((item, idx) => {
                  return (
                    <MenuItem value={item?.id}>{item?.GroupName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <ColumnFlexBox>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>신규가입 사용여부</p>
                <Checkbox
                  size="small"
                  color="success"
                  checked={data?.newOption}
                  onClick={() => {
                    setData({ ...data, newOption: !data.newOption });
                  }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>SKT 미사용여부</p>
                <Checkbox
                  size="small"
                  color="success"
                  checked={data?.sktOn}
                  onClick={() => {
                    setData({ ...data, sktOn: !data.sktOn });
                  }}
                />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <p>KT 미사용여부</p>
                <Checkbox
                  size="small"
                  color="success"
                  checked={data?.ktOn}
                  onClick={() => {
                    setData({ ...data, ktOn: !data.ktOn });
                  }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>LGU 미사용여부</p>
                <Checkbox
                  size="small"
                  color="success"
                  checked={data?.lguOn}
                  onClick={() => {
                    setData({ ...data, lguOn: !data.lguOn });
                  }}
                />
              </div>
            </ColumnFlexBox>
            {/*04-07 금요일 Path - 출고가 */}
            <TelecomPriceBox>
              <TelecomICBox>
                <TelecomIcText>요금제별 출고가 설정</TelecomIcText>
              </TelecomICBox>
              <DataSheetGrid
                lockRows={true}
                value={priceGloba}
                columns={columnsPrice}
                onChange={(e) => {
                  setPriceGlobal(e);
                }}
              />
            </TelecomPriceBox>
            {/* 04-07 금요일 */}
            <TelecomPriceBox>
              <TelecomICBox>
                <IC_Box src={T_IC}></IC_Box>
                <TelecomIcText>SKT</TelecomIcText>
              </TelecomICBox>
              <DataSheetGrid
                lockRows={true}
                value={sktData}
                onChange={(e) => {
                  let idx = e.filter((item, idx) => {
                    return item?.선택 === true;
                  });
                  if (idx.length === 2) {
                    let idxLguDatas = sktData?.findIndex((item, idx) => {
                      return item?.선택 === true;
                    });
                    let newDts = e;
                    newDts[idxLguDatas].선택 = false;
                    setSktData(newDts);
                  } else {
                    setSktData(e);
                  }
                }}
                columns={columns}
              />
              <table
                id="customers"
                style={{
                  width: "100%",
                }}
              >
                <RightTable>
                  <td style={{ textAlign: "center" }}>요금제 메모</td>
                  {/* SKT*/}
                  <td colSpan={"12"}>
                    <input
                      type="text"
                      value={data.sktmemo}
                      onChange={(e) =>
                        setData({ ...data, sktmemo: e.target.value })
                      }
                      style={{
                        border: "none",
                        width: "100%",
                      }}
                    ></input>
                  </td>
                </RightTable>
                <RightTable>
                  <td style={{ textAlign: "center" }}>상품정보</td>
                  {/* SKT*/}
                  <td colSpan={data?.newOption ? "12" : "8"}>
                    <input
                      type="text"
                      value={data.sktinfo}
                      onChange={(e) =>
                        setData({ ...data, sktinfo: e.target.value })
                      }
                      style={{
                        border: "none",
                        width: "100%",
                      }}
                    ></input>
                  </td>
                </RightTable>
              </table>
            </TelecomPriceBox>
            <TelecomPriceBox>
              <TelecomICBox>
                <IC_Box src={Kt_IC}></IC_Box>
                <TelecomIcText>KT</TelecomIcText>
              </TelecomICBox>
              <DataSheetGrid
                lockRows={true}
                value={ktDatas}
                onChange={(e) => {
                  let idx = e.filter((item, idx) => {
                    return item?.선택 === true;
                  });
                  if (idx.length === 2) {
                    let idxLguDatas = ktDatas?.findIndex((item, idx) => {
                      return item?.선택 === true;
                    });
                    let newDts = e;
                    newDts[idxLguDatas].선택 = false;
                    setKtDatas(newDts);
                  } else {
                    setKtDatas(e);
                  }
                }}
                columns={columns}
              />
              <table
                id="customers"
                style={{
                  width: "100%",
                }}
              >
                <RightTable>
                  <td style={{ textAlign: "center" }}>요금제 메모</td>
                  {/* SKT*/}
                  <td colSpan={"12"}>
                    <input
                      type="text"
                      value={data.ktmemo}
                      onChange={(e) =>
                        setData({ ...data, ktmemo: e.target.value })
                      }
                      style={{
                        border: "none",
                        width: "100%",
                      }}
                    ></input>
                  </td>
                </RightTable>
                <RightTable>
                  <td style={{ textAlign: "center" }}>상품정보</td>
                  {/* SKT*/}
                  <td colSpan={"12"}>
                    <input
                      type="text"
                      value={data.ktinfo}
                      onChange={(e) =>
                        setData({ ...data, ktinfo: e.target.value })
                      }
                      style={{
                        border: "none",
                        width: "100%",
                      }}
                    ></input>
                  </td>
                </RightTable>
              </table>
            </TelecomPriceBox>
            <TelecomPriceBox>
              <TelecomICBox>
                <IC_Box src={U_IC}></IC_Box>
                <TelecomIcText>LGU+</TelecomIcText>
              </TelecomICBox>
              <DataSheetGrid
                lockRows={true}
                value={lguDatas}
                onChange={(e) => {
                  let idx = e.filter((item, idx) => {
                    return item?.선택 === true;
                  });
                  if (idx.length === 2) {
                    let idxLguDatas = lguDatas?.findIndex((item, idx) => {
                      return item?.선택 === true;
                    });
                    let newDts = e;
                    newDts[idxLguDatas].선택 = false;
                    setLguDatas(newDts);
                  } else {
                    setLguDatas(e);
                  }
                }} //배열에서필터를돌려서 한군대라도 체크된게잇다면 체크값은 전체해제 그리고 선택한걸체크
                columns={columns}
              />
              <table
                id="customers"
                style={{
                  width: "100%",
                }}
              >
                <RightTable>
                  <td style={{ textAlign: "center" }}>요금제 메모</td>
                  {/* SKT*/}
                  <td colSpan={"12"}>
                    <input
                      type="text"
                      value={data.lgumemo}
                      onChange={(e) =>
                        setData({ ...data, lgumemo: e.target.value })
                      }
                      style={{
                        border: "none",
                        width: "100%",
                      }}
                    ></input>
                  </td>
                </RightTable>
                <RightTable>
                  <td style={{ textAlign: "center" }}>상품정보</td>
                  {/* SKT*/}
                  <td colSpan={"12"}>
                    <input
                      type="text"
                      value={data.lguinfo}
                      onChange={(e) =>
                        setData({ ...data, lguinfo: e.target.value })
                      }
                      style={{
                        border: "none",
                        width: "100%",
                      }}
                    ></input>
                  </td>
                </RightTable>
              </table>
            </TelecomPriceBox>

            <table
              id="customers"
              style={{ marginTop: 24, border: "1px soild #eee", width: "100%" }}
            >
              <tbody>
                <RightTable>
                  <td style={{ width: 55, textAlign: "center" }}>메모</td>
                  <td>
                    <ProductNameInput
                      value={data.memoadmin}
                      onChange={(e) =>
                        setData({ ...data, memoadmin: e.target.value })
                      }
                    />
                  </td>
                </RightTable>
                <RightTable>
                  <td style={{ width: 205, textAlign: "center" }}>
                    SKT 부가서비스
                  </td>
                  <td>
                    <ProductNameInput2
                      value={data.productinfoS}
                    
                      onChange={(e) =>
                        setData({ ...data, productinfoS: e.target.value })
                      }
                    />
                  </td>
                </RightTable>
                <RightTable>
                  <td style={{ width: 205, textAlign: "center" }}>
                    KT 부가서비스
                  </td>
                  <td>
                    <ProductNameInput2
                      value={data.productinfoK}
                      onChange={(e) =>
                        setData({ ...data, productinfoK: e.target.value })
                      }
                    />
                  </td>
                </RightTable>
                <RightTable>
                  <td style={{ width: 205, textAlign: "center" }}>
                    LGU+ 부가서비스
                  </td>
                  <td>
                    <ProductNameInput2
                      value={data.productinfoL}
                      onChange={(e) =>
                        setData({ ...data, productinfoL: e.target.value })
                      }
                    />
                  </td>
                </RightTable>
              </tbody>
            </table>

            {/*스크린 완료 작없시작 */}
            <div
              style={{
                marginTop: 36,
                width: "100%",
              }}
            >
              <DetailedInfoLabel>
                상세 정보 [너비 1240 높이 무제한]
              </DetailedInfoLabel>{" "}
              {/* quill */}
              <MarkDown
                style={{ marginTop: 24, width: "100%", marginBottom: 30 }}
              >
                <Editor
                  value={
                    productBoardData
                      ? productBoardData
                      : "상품 정보를 입력해주세요."
                  }
                  placeholder={"상품 정보를 입력해주세요."}
                  onEditorChange={onEditorChange}
                  setfilesCheck={setfilesCheck}
                  filesdata={filesdata}
                  onFilesChange={onFilesChange}
                />
              </MarkDown>
            </div>
          </MainContainer>
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(Product);
