import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Front from "Components/Front/Front.js";
import Login from "Components/Login/Login.js";
import Register from "Components/Register/Register.js";
import TermsAgree from "Components/TermsAgree/TermsAgree.js";
import Announcement from "Components/Announcement/Announcement.js";
import AnnouncementPost from "Components/AnnouncementPost/AnnouncementPost.js";
import WriteReview from "Components/WriteReview/WriteReview.js";
import Alert from "Components/Alert/Alert.js";
import Profile from "Components/Profile/Profile.js";
import Contract from "Components/Contract/Contract.js";
import ContractList from "Components/ContractList/ContractList.js";
import Product from "Components/Product/Product.js";
import ProductNew from "Components/Product/ProductNew.js";
import Category from "Components/Category/Category.js";
import Admin from "Components/Admin/Admin.js";
import { createBrowserHistory } from "history";
import { GetMe } from "Datas/storage";
import AuthRoute from "./authRouter";
import Loader from "react-loader-spinner";
import { useState } from "react";
import SettingPage from "Components/Admin/SettingPage";
import { GetAdminData } from "Datas/swr";
import Header from "Components/Header/Header";
import Success from "Components/Success";
import PreOrder from "Components/PreOrder";
import PreOrderDetail from "Components/PreOrder/detail.js";
import FingerReset from "../Components/Login/FingerReset";
import PushNoti from "../Components/Admin/Pushnoti/Pushnoti";
import FindPw from "Components/Login/FindPw";
import Footer from "Components/Footer/Footer";
import StartTalkBox from "Components/StartTalk/StartTalkBox";
import Qa from "Components/Admin/Qa";


var history = createBrowserHistory();
//초기 유저리스트를 불어온다 초기셋팅관련 페이지 생성
const Routes = () => {
  const { profile, loading } = GetMe();
  const { AdminsData, AdminDataMutate } = GetAdminData();

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  //초기에 test값을받는다 swr 을통해

  if (loading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader type="TailSpin" color="orange" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <Router history={history}>
     
        {AdminsData?.type ? (
          profile ? (
            <>
              <Header
                sidebar={sidebar}
                setSidebar={setSidebar}
                profile={profile}
              />
            </>
          ) : null
        ) : null}
        <Switch>
          <Route
            exact
            path="/"
            authenticated={profile}
            render={(props) =>
              AdminsData?.type ? (
                profile ? (
                  <Front {...props} />
                ) : (
                  <Login />
                )
              ) : (
                <SettingPage />
              )
            }
          />
          {/* <Front /> */}
          {/*비밀번호 찾기 */}
          <Route exact path="/findpw" component={FindPw} />
          {/* 메인페이지 */}
          <Route exact path="/register" component={Register} /> {/* 회원가입 */}
          <Route exact path="/resetFinger/:id" component={FingerReset} />
          <AuthRoute
            exact
            path="/alert"
            authenticated={profile}
            render={(props) => <Alert {...props} profile={profile} />}
          />
          {/* 알림 */}
          <AuthRoute
            exact
            path="/termsagree"
            authenticated={profile}
            render={(props) => <TermsAgree {...props} />}
          />
          {/* 약관동의 */}
          <AuthRoute
            exact
            path="/announcement"
            authenticated={profile}
            render={(props) => <Announcement {...props} />}
          />
          {/* 공지사항 */}
          <AuthRoute
            exact
            path="/announcementpost/:id"
            authenticated={profile}
            render={(props) => <AnnouncementPost {...props} />}
          />
          {/* 공지사항 게시물 */}
          <AuthRoute
            exact
            path="/profile"
            authenticated={profile}
            render={(props) => <Profile {...props} profile={profile} />}
          />
          {/* 프로필 */}
          <AuthRoute
            exact
            path="/writereview/:id/:orderid"
            authenticated={profile}
            render={(props) => <WriteReview {...props} profile={profile} />}
          />
          {/* 리뷰작성 */}
          <AuthRoute
            exact
            path="/contract"
            authenticated={profile}
            render={(props) => <Contract {...props} profile={profile} />}
          />
          {/* 개통 신청 */}
          <AuthRoute
            exact
            path="/contractlist"
            authenticated={profile}
            render={(props) => <ContractList {...props} profile={profile} />}
          />
          {/* 개통신청내역 */}
          <AuthRoute
            exact
            path="/product/:id/:type/:tel/:newtel/:chooseBase/:discount"
            authenticated={profile}
            render={(props) => <Product {...props} profile={profile} />}
          />
          {/* <AuthRoute
            exact
            path="/product"
            authenticated={profile}
            render={(props) => <ProductNew {...props} profile={profile} />}
          /> */}
          {/* 상품 상세 */}
          <Route
            path="/admin"
            render={(props) => <Admin {...props} profile={profile} />}
          />
          {/* 어드민 */}
          <AuthRoute
            exact
            path="/category/:type/:id"
            authenticated={profile}
            component={Category}
          />
          {/* 카테고리 */}
          <AuthRoute
            exact
            path="/category/:type"
            authenticated={profile}
            render={(props) => <Category {...props} />}
          />
          {/* 카테고리 */}
          <AuthRoute
            exact
            path="/success"
            authenticated={profile}
            render={(props) => <Success {...props} />}
          />
          <AuthRoute
            exact
            path="/preorder"
            authenticated={profile}
            render={(props) => <PreOrder {...props} />}
          />
          <AuthRoute
            exact
            path="/preorder/detail"
            authenticated={profile}
            render={(props) => <PreOrderDetail {...props} />}
          />
          {/* 카테고리 */}
          <AuthRoute
            exact
            path="/pushnoti"
            authenticated={profile}
            render={(props) => <PushNoti {...props} />}
          />
        </Switch>
        {AdminsData?.type ? (
          profile ? (
            <>
              <StartTalkBox />
              <Footer />
            </>
          ) : null
        ) : null}
      </Router>
    );
  }
};

export default Routes;
