import { GetInforMation, GetInforMationStore } from "Datas/swr";
import React, { useState } from "react";
import { useEffect } from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import { sendPushNoti, updateInfo, uploadImage } from "../../../Datas/api";
import { TextField } from "@material-ui/core";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Button from "@mui/material/Button";
import { TopSelectComp } from "../User/User";
const Wrapper = styled.div`
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const TopLabel = styled.div``;
const Top = styled.div`
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 30px;
`;

const InputTextArea = styled.textarea`
  height: 293px;
  padding: 16px;
  width: 100%;
  border-radius: 7px;
  background-color: #f4f4f4;
  border: none;
`;

const TextFieldtext = styled.textarea`
  height: 345px;
  border: none;
  width: 100%;
  background: #f4f4f4;
  border-radius: 7px;
  margin-bottom: 110px;
`;

const Content = styled.div`
  margin: 15px 0 0 20px;
  max-width: 690px;
  @media screen and (max-width: 768px) {
    margin: 0px;
  }
`;
const NameCardAdd = styled.label`
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  font-size: 17px;
  background: #6091ed;
  border-radius: 30px;
  padding: 9px 15px;
  color: #fff;
  margin-left: auto;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;
const Images = styled.div`
  width: 175px;
  height: 30px;
  background-image: ${(props) => {
    if (props.src) {
      return `url(${props.src})`;
    }
  }};
  background-size: contain;
  background-repeat: no-repeat;
`;

const TeleAc = () => {
  const [datas, setDatas] = useState({
    sktPrice: 0,
    ktPrice: 0,
    lguPrice: 0,
  });
  const [image, setImage] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);
  const { InfoStoreData, InfoStoreDataMutate, isLoading } =
    GetInforMationStore();
  //불러오기로직실행
  useEffect(() => {
    if (!isLoading) {
      setDatas({
        sktPrice: InfoStoreData[0]?.sktPrice,
        ktPrice: InfoStoreData[0]?.ktPrice,
        lguPrice: InfoStoreData[0]?.lguPrice,
      });
    }
  }, [InfoStoreData]);

  const _handleUpdate = async () => {
    let res = await updateInfo(
      {
        sktPrice: datas.sktPrice,
        ktPrice: datas.ktPrice,
        lguPrice: datas.lguPrice,
      },
      InfoStoreData[0]?.id
    );
    if (res === undefined) {
      alert("잘못된 데이터입니다다");
      return;
    } else {
      alert("수정완료");
      await InfoStoreDataMutate();
    }
    //업데이트로직실행
    //조건부검증
  };

  return (
    <Fade Button>
      <Wrapper>
        <Top>
          <TopLabel>통신사 관리</TopLabel>
          <TopButton>
            <Button
              fullWidth
              variant="contained"
              color="success"
              onClick={() => _handleUpdate()}
            >
              저장
            </Button>
          </TopButton>
        </Top>
        <Content>
          <InputLabel>통신사별 추가수수료</InputLabel>
          <TextField
            fullWidth
            type="number"
            value={datas?.sktPrice}
            onChange={(e) => setDatas({ ...datas, sktPrice: e.target.value })}
            style={{
              marginTop: 20,
            }}
            id="outlined-basic"
            label="SKT"
            variant="outlined"
          />
          <TextField
            fullWidth
            type="number"
            value={datas?.ktPrice}
            onChange={(e) => setDatas({ ...datas, ktPrice: e.target.value })}
            style={{
              marginTop: 20,
            }}
            id="outlined-basic"
            label="KT"
            variant="outlined"
          />
          <TextField
            fullWidth
            type="number"
            value={datas?.lguPrice}
            onChange={(e) => setDatas({ ...datas, lguPrice: e.target.value })}
            style={{
              marginTop: 20,
            }}
            id="outlined-basic"
            label="LGU+"
            variant="outlined"
          />
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default TeleAc;
