import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import { GetBannerList } from "Datas/swr";
import { deleteBanner } from "Datas/api";
import { Fade } from "react-reveal";
import { Pagination } from "react-bootstrap";
import CustomPagination from "Settings/pagination";
import { useConfirm } from "Settings/util";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";
const Wrapper = styled.div``;
const Top = styled.div`
  border: 1px solid #eee;
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;

const TopLabel = styled.div``;
const TableContainer = styled(Paper)`

  width: 100%;
  overflow: auto;
`;
const TopButton = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const Delete = styled.div`
  display: flex;
  background: #ed6060;
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const DataTable = styled.table`
  width: 100%;
  margin-top: 30px;

  thead tr th {
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
  }
  tbody tr td input {
    -webkit-appearance: auto;
  }
  tbody tr td {
    padding: 20px 0;
    text-align: center;
  }
`;

const Imgskeleton = styled.img`
  max-width: 200px;
  height: 100px;
  border: none;
  resize: both;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: contain;
`;

const HoverTag = styled.td`
  border-bottom: 1.5px solid #eee;
  &:hover {
    opacity: 0.5;
  }
`;
const useStyles = makeStyles({
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#3c4b64",
      padding: 7,
    },
  },

  tableBody: {
    "& .MuiTableBody-root": {
      padding: 7,
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      padding: 7,
    },
  },
});
const LinedTag = styled.td`
  border-bottom: 1.5px solid #eee;
`;
export const ButtonCompoPath = styled.div`
  margin-right: 12px;
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    margin-top: 12px;
    margin-right: 0px;
    width: 90%;
  }
`;
const Banner = () => {
  const classes = useStyles();
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState([]);
  //SWR
  const { BannerListData, BannerListMutate, isLoading } = GetBannerList(offset);
  const [checked, setChecked] = useState(false);
  const [BannerData, setBannerData] = useState([]);

  useEffect(() => {
    if (BannerListData !== undefined) {
      let Banners = [...BannerListData?.rows];
      setBannerData(Banners);
    }
  }, [BannerListData]);

  useEffect(() => {
    let checkd = BannerData.every((item) => {
      return item.validate;
    });
    setChecked(checkd);
  }, [BannerData]);

  const _handleAllSelct = () => {
    //전체샐렉트동기화
    console.log("세팅");
    let data = [...BannerData];
    let resdata;
    if (checked) {
      resdata = data.map((item) => {
        return { ...item, validate: false };
      });
    } else {
      resdata = data.map((item) => {
        return { ...item, validate: true };
      });
    }

    setBannerData(resdata);
    setChecked(true);
  };

  const _handleSelect = (id) => {
    let temp = [...BannerData];
    BannerData?.map((el, idx) => {
      if (el?.id === id) {
        let data = {
          ...el,
          validate: !el?.validate,
        };
        temp[idx] = data;
        console.log(data);
      }
    });
    setBannerData(temp);
  };
  const desk = useConfirm(
    "삭제하시겠습니까?",
    async () => {
      for (let index = 0; index < BannerData.length; index++) {
        if (BannerData[index]?.validate) {
          let body = {
            id: BannerData[index]?.id,
          };
          await deleteBanner(body);
        }
      }
      await BannerListMutate();
    },
    () => {
      alert("삭제 를 취소하였습니다.");
    }
  );
  const _handleDelete = async () => {
    desk();
  };

 
  return (
    <Fade Button>
      <Wrapper>
        <TopLabel>배너관리</TopLabel>
        <Top>
          <TopButton>
            <ButtonCompoPath>
              <Link to="/admin/banner/detail/add">
                <Button fullWidth color="success" variant="contained">
                  배너 추가
                </Button>
              </Link>
            </ButtonCompoPath>

            <ButtonCompoPath>
              <Button
                fullWidth
                color="error"
                variant="contained"
                onClick={_handleDelete}
              >
                삭제
              </Button>
            </ButtonCompoPath>
          </TopButton>
        </Top>
        <TableContainer style={{ marginTop: 24 }}>
          <Table style={{minWidth:1100}} stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
            size="small"        color="success"
                    checked={checked}
                    onClick={() => {
                      _handleAllSelct();
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  이미지
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  배너순서
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  포인트/수수료
                </TableCell>

                <TableCell className={classes.tableCell} align="center">
                더보기
                </TableCell>
          
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {BannerData?.length !== 0 &&
                BannerData?.map((el, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell className={classes.tableCell} align="center">
                       <Checkbox
            size="small"        color="success"
                          onClick={() => _handleSelect(el?.id)}
                          checked={el.validate}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <Imgskeleton src={el?.image[0]} />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.order}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {el?.ison ? "활성화" : "비활성화"}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <Link
                          to={{
                            pathname: `/admin/banner/detail/${el?.id}`,
                            state: el,
                          }}
                        >
                          더보기
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <CustomPagination
          data={BannerListData}
          setOffset={setOffset}
          page={page}
          setPage={setPage}
        />
      </Wrapper>
    </Fade>
  );
};

export default Banner;
