import { useEffect } from "react";
import { useState } from "react";

const useConfirm = (message = null, onConfirm, onCancel) => {
  if (!onConfirm || typeof onConfirm !== "function") {
    return;
  }
  if (onCancel && typeof onCancel !== "function") {
    return;
  }

  const confirmAction = () => {
    if (window.confirm(message)) {
      onConfirm();
    } else {
      onCancel();
    }
  };

  return confirmAction;
};

const handlerTopMovement = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

export const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };
    const enterHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
    const rightHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    const LeftHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    window.addEventListener("keyleft", rightHandler);
    window.addEventListener("ketright", LeftHandler);
    window.addEventListener("keyenter", enterHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
      window.addEventListener("keyleft", rightHandler);
      window.addEventListener("ketright", LeftHandler);
      window.addEventListener("keyenter", enterHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export { useConfirm, handlerTopMovement };
